export const MASTER_DATA_KEYS = {
  CIVIL_STATE: "CIVIL_STATE",
  NEAREST_BRANCH: "NEAREST_BRANCH",
  TITLE: "TITLE",
  ID_TYPE: "ID_TYPE",
  LANGUAGE: "LANGUAGE",
  CITY: "CITY",
  CONTACT_TYPE: "CONTACT_TYPE",
  EMAIL_TYPE: "EMAIL_TYPE",
  SOCIAL_MEDIA_TYPE: "SOCIAL_MEDIA_TYPE",
  PROVINCE: "PROVINCE",
  GENDER: "GENDER",
  DISTRICT: "DISTRICT",
  GN_DIVISION: "GN_DIVISION",
  RESIDENTIAL_STATUS: "RESIDENTIAL_STATUS",
  EDUCATION_GRADE: "EDUCATION_GRADE",
  AWARD_TYPE: "AWARD_TYPE",
  NVQ_TYPE: "NVQ_TYPE",
  EMPLOYMENT_STATUS: "EMPLOYMENT_STATUS",
  OCCUPATION: "OCCUPATION",
  INDUSTRY_TYPE: "INDUSTRY_TYPE",
  BUSINESS_SECTOR: "BUSINESS_SECTOR",
  BUSINESS_SUB_SECTOR: "BUSINESS_SUB_SECTOR",
  NO_OF_EMPLOYEES: "NO_OF_EMPLOYEES",
  INCOME_CATEGORY: "INCOME_CATEGORY",
  INCOME_HOLDER: "INCOME_HOLDER",
  EMPLOYMENT_CATEGORY: "EMPLOYMENT_CATEGORY",
  EMPLOYMENT_TYPE: "EMPLOYMENT_TYPE",
  INCOME_TYPE: "INCOME_TYPE",
  SALARY_ASSIGNMENT: "SALARY_ASSIGNMENT",
  EXPENSES_CATEGORY: "EXPENSES_CATEGORY",
  EXPENSES_HOLDER: "EXPENSES_HOLDER",
  EXPENSES_TYPE: "EXPENSES_TYPE",
  NO_OF_DEPENDENTS: "NO_OF_DEPENDENTS",
  BANK_NAME: "BANK_NAME",
  BANK_BRANCH: "BANK_BRANCH",
  BANK_ACCOUNT_TYPE: "BANK_ACCOUNT_TYPE",
  BANK_ACC_OWNERSHIP: "BANK_ACC_OWNERSHIP",
  ASSETS_CATEGORY: "ASSETS_CATEGORY",
  ASSET_TYPE: "ASSETS_TYPE",
  TYPE_OF_BUSINESS: "TYPE_OF_BUSINESS",
  PARTICIPANT_TYPE: "PARTICIPANT_TYPE",
  FUND_APPROVAL_STATUS: "FUND_APPROVAL_STATUS",
  REPAYMENT_TYPE: "REPAYMENT_TYPE",
  INCOME_FREQUENCY: "INCOME_FREQUENCY",
  APPLICATION_PRT_TYPE: "APPLICATION_PRT_TYPE",
  CRIB_RECODE: "CRIB_RECODE",
  BANK_RELATION: "BANK_RELATION",
  PRE_REG_FIN_SYS: "PRE_REG_FIN_SYS",
  INCOME_SECTOR: "INCOME_SECTOR",
  INCOME_SUB_SECTOR: "INCOME_SUB_SECTOR",
  OBLIGATION_TYPE: "OBLIGATION_TYPE",
  LOAN_PURPOSE: "LOAN_PURPOSE",
  CRIB_STATUS: "CRIB_STATUS",
  REPAYMENT_DATE: "REPAYMENT_DATE",
  RACE: "RACE",
  LOAN_FREQUENCY: "LOAN_FREQUENCY",
  FUND_SOURCE: "FUND_SOURCE",
  SOURCE_OF_INCOME: "INCOME_SOURCE",
  BUSINESS_EXPENSES: "BUSINESS_EXPENSES",
  LIABILITY_TYPE: "LIABILITY_TYPE",
  STATUS_OF_LOAN: "STATUS_OF_LOAN",
  DS_DIVISION: "DS_DIVISION",
  PRVG_MAIN_SEC: "PRVG_MAIN_SEC",
  PRVG_SUB_SEC: "PRVG_SUB_SEC",
  RELATION_WITH_MA: "RELATION_WITH_MA",
  POSTAL_CODE: "POSTAL_CODE",
  ARM_CODE: "ARM_CODE",
  USER_DESIGNATION: "USER_DESIGNATION",
  EMPLOYEE_ID: "EMPLOYEE_ID",
  INSURANCE_COMPANY: "INSURANCE_COMPANY",
  INSURANCE_COMPANY_TYPE: "INSURANCE_COMPANY_TYPE",
  OWNERSHIP: "OWNERSHIP",
  CROP_TYPE: "CROP_TYPE",
  STATEMENT_TEMPLATE: "STATEMENT_TEMPLATE",
  STATEMENT_FREQUENCY: "STATEMENT_FREQUENCY",
  ARTICLE_TYPE: "ARTICLE_TYPE",
  KARATAGE_ARTICLE: "KARATAGE_ARTICLE",
  VEHICLE_CATEGORY: "VEHICLE_CATEGORY",
  FUEL_TYPE: "FUEL_TYPE",
  MANUFACTURER_CODE: "MANUFACTURER_CODE",
  THIRD_PARTY_DEALER_CODE: "THIRD_PARTY_DEALER_CODE",
  TAX_TYPE: "TAX_TYPE",
  MACHINE_TYPE: "MACHINE_TYPE",
  MANUFACTURER_CONDITIONS: "MANUFACTURER_CONDITIONS",
  NATURE_OF_WARRANTY: "NATURE_OF_WARRANTY",
  TYPE_OF_MORTGAGE: "TYPE_OF_MORTGAGE",
  LOCATION_CODE: "LOCATION_CODE",
  LOCAL_AUTHORITY: "LOCAL_AUTHORITY",
  TYPE_OF_ROAD: "TYPE_OF_ROAD",
  WAREHOUSE_RENTED_BANK_CLIENT_NAME: "WAREHOUSE_RENTED_BANK_CLIENT_NAME",
  SOURCE_POWER: "SOURCE_POWER",
  LAND_MEASUREMENT_TYPE: "LAND_MEASUREMENT_TYPE",
  DIRECT_SELLING_AGENT: "DIRECT_SELLING_AGENT",
  MARKETTING_CHANNEL_CODE: "MARKETTING_CHANNEL_CODE",
  EMPLOYER_NAME: "EMPLOYER_NAME",
  DECISION_RULE_TYPE: "DECISION_RULE_TYPE",
  INSURANCE_POLICY_TYPE: "INSURANCE_POLICY_TYPE",
  SECURITY_TYPE_MASTER: "SECURITY_TYPE_MASTER",
  ZONE: "ZONE",
  CUSTOMER_TYPE: "CUSTOMER_TYPE",
  INSURANCE_DETAILS_DESCRIPTION: "INSURANCE_DETAILS_DESCRIPTION",
  MOVABLE_CUSTOMER_TYPE: "MOVABLE_CUSTOMER_TYPE",
  LEASE_PROPERTY_IS_UNDER: "LEASE_PROPERTY_IS_UNDER",
  VULNERABILITY_CLIMATIC_AND_ENV_FACTORS: "VULNERABILITY_CLIMATIC_AND_ENV_FACTORS",
  SECURITY_DOC_REF_TYPES: "SECURITY_DOC_REF_TYPES",
  EMAIL_TYPE_EMPLOYMENT: "EMAIL_TYPE_EMPLOYMENT",
  ROOF: "ROOF",
  WALLS: "WALLS",
  NO_OF_ROOMS: "NO_OF_ROOMS",
  DRINKING_WATER: "DRINKING_WATER",
  HOUSE: "HOUSE",
  EXTENT_OF_PROPERTY: "EXTENT_OF_PROPERTY",
  FLOOR: "FLOOR",
  TOILETS: "TOILETS",
  ELECTRICITY: "ELECTRICITY",
  MODE_OF_TRANSPORT: "MODE_OF_TRANSPORT",
  SOCIAL_DATA_OWNERSHIP: "SOCIAL_DATA_OWNERSHIP",
  DEPARTMENT: "DEPARTMENT",
  USE_OF_ASSET: "USE_OF_ASSET",
  PURPOSE_OF_INSPECTION: "PURPOSE_OF_INSPECTION",
  ATHAMARU_DESIGNATIONS: "ATHAMARU_DESIGNATIONS",
  BUSINESS_CATEGORY: "BUSINESS_CATEGORY",
  HOW_DO_YOU_IDENTIFY_YOURSELF: "HOW_DO_YOU_IDENTIFY_YOURSELF",
  AUTHORITY_OR_CERTIFICATION: "AUTHORITY_OR_CERTIFICATION",
  RELATIONSHIP: "RELATIONSHIP",
  USE_OF_THE_ASSET: "USE_OF_THE_ASSET",
  FIXED_OR_FLOATING: "FIXED_OR_FLOATING",
  BUSINESS_FIXED_ASSET_TYPE: "BUSINESS_FIXED_ASSET_TYPE",
  BUSINESS_CURRENT_ASSET_TYPE: "BUSINESS_CURRENT_ASSET_TYPE",
  EMPLOYEE_CONTACT_TYPE: "EMPLOYEE_CONTACT_TYPE",
  PRIMARY_FOCUS_AREA: "PRIMARY_FOCUS_AREA",
  ENVIRONMENTAL_SUBCATEGORIES:"ENVIRONMENTAL_SUBCATEGORIES"
};

export const FORM_BUILDER_KEYS = {
  sectionId: "SECTION_ID",
  sectionKey: "SECTION_KEY",
  sectionName: "SECTION_NAME",
  subSectionId: "SUB_SECTION_ID",
  subSectionKey: "SUB_SECTION_KEY",
  subSectionName: "SUB_SECTION_NAME",
  fieldId: "FIELD_ID",
  fieldKey: "FIELD_KEY",
  fieldName: "FIELD_NAME",
};

export const MD_KEYS = {
  code: "code",
  isActive: "isActive",
  order: "order",
  parent: "parent",
  descEN: "descEN",
  descSI: "descSI",
  purposeCode: "purposeCode",
};
