import { Box } from "@mui/material";
import { useState } from "react";

const EditableField = ({ label }) => {
    const [text, setText] = useState("");

    return (
        <Box
            contentEditable
            suppressContentEditableWarning
            onBlur={(e) => setText(e.target.innerText)}
            sx={{
                display: "inline-block",
                paddingX: "8px",
                minWidth: "100px",
                borderBottom: "1px dashed black",
                cursor: "text",
                color: text === label ? "gray" : "black",
                outline: "none",
                "&:focus": {
                    backgroundColor: "#e3f2fd",
                    borderBottom: "1px solid #1976d2",
                },
                "&:empty:before": {
                    content: `"${label}"`,
                    color: "gray",
                },
            }}
        >
            {text}
        </Box>
    );
};

export default EditableField;