import { useState } from "@hookstate/core";
import { Grid } from "@mui/material";
import React from "react";
import { masterDataDetails } from "../../../../../configs/mainStore";
import AppConfig from "../../../../../utility/AppConfig";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import CreditFileDocumentsViewSection from "../CreditFileDocumentsViewSection";
import ApplicationHeader from "./ApplicationHeader";
import { BusinessDetailsDirector } from "./BusinessDetails/AthamaruProductBusinessDetails/DirectorDetails";
import { BusinessDetailsIndividual } from "./BusinessDetails/AthamaruProductBusinessDetails/IndividualDetails";
import { TypeBusinessIsRunWIth } from "./BusinessDetails/AthamaruProductBusinessDetails/OtherInputFeilds";
import { BusinessDetailsPartnership } from "./BusinessDetails/AthamaruProductBusinessDetails/PatnershipDetails";
import { ShareHolderDetails } from "./BusinessDetails/AthamaruProductBusinessDetails/ShareholderDetails";
import { BusinessDetailsAsset } from "./BusinessDetails/BusinessDetailsAssets";
import { BusinessDetailsMeta } from "./BusinessDetails/BusinessDetailsMeta";
import { BusinessDetailsTax } from "./BusinessDetails/BusinessDetailsTax";

const BusinessDetails = ({ data, previewOpen, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion = false, printMode = false, isGuarantor = false, isJointBurrower = false, guarantorJBSection = "", innerComponentData, tabsToEdit }) => {
  const masterData: any = useState(masterDataDetails);
  const businessDetails: any = data?.["formData"]?.["businessData"];
  const currentBusinesses = businessDetails?.["currentBusiness"] || [];
  let label = "Business Details";

  try {
    const { primaryApplicant = undefined } = data?.formStructure;
    label = primaryApplicant?.[innerComponentData?.id]?.label;
  } catch {
  }

  return (
    <div className={`mainSectionWrapper ${isMainContactDetails ? "full-width full-height" : "full-width"}`}>
      {isMainContactDetails && (!isRawVersion || printMode) && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={label ? label : "Business Details"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={isRawVersion} disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)} />}
      {!isMainContactDetails && (
        <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
          {label ? label : "Business Details"}
        </Grid>
      )}
      <Grid container className={isMainContactDetails ? "inner-component-height padding-sm" : "margin-xs-t"}>
        <Grid container>
          <Grid container className={`border-radius-5 ${isMainContactDetails ? (isRawVersion ? "" : "sub-header-height") : ""}`}>
            {currentBusinesses?.[0]?.typeOfBusiness !== AppConfig.config.noOtherBusiness ? (
              <>
                <CreditFileDocumentsViewSection data={data} applicationId={applicationId} isGuarantor={isGuarantor} guarantorJBSection={guarantorJBSection} isJointBurrower={isJointBurrower} innerComponentData={innerComponentData} />

                {currentBusinesses?.filter((obj) => obj)
                  ?.filter((obj) => !obj.removedItem)?.map((business, index) => {
                    if (!business?.businessRegNo && !business?.typeOfBusiness) {
                      if (previewOpen) {
                        return (
                          <Grid container m={1} p={1} className="data-box">
                            <Grid item xs={4}>
                              <div className={`basic-font basic-font-color font-size-14`}>Primary Applicant does not have Any Business</div>
                            </Grid>
                          </Grid>
                        );
                      }
                      return (
                        <div className="full-width" key={index}>
                          {toBeRenderings.map((component: any) =>
                            React.createElement(component, {
                              key: component.toString(),
                              typeOfBusiness: business?.typeOfBusiness,
                              business,
                              formStructure: data?.formStructure,
                              masterData,
                              isMainContactDetails,
                              sectionKey: index,
                            })
                          )}
                        </div>
                      );
                    }
                    return (
                      <div className="full-width" key={index}>
                        {toBeRenderings.map((component: any) =>
                          React.createElement(component, {
                            key: component.toString(),
                            typeOfBusiness: business?.typeOfBusiness,
                            business,
                            masterData,
                            formStructure: data?.formStructure?.primaryApplicant || {},
                            isMainContactDetails,
                            sectionKey: index,
                          })
                        )}
                      </div>
                    );
                  })}
              </>
            ) : (
              <ErrorMessageComponent headMessage={""} errorMessage={"No Other Business"} showImg={false} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default BusinessDetails;

const GetComponentFromTheType = ({ typeOfBusiness, business, isMainContactDetails, index, masterData }) => {
  const props = { business, isMainContactDetails, masterData };

  switch (typeOfBusiness) {
    case 1:
      return React.createElement(BusinessDetailsPartnership, props);
    case 2:
      return React.createElement(BusinessDetailsIndividual, props);
    default:
      return React.createElement(BusinessDetailsDirector, props);
  }
};

const toBeRenderings: Array<any> = [BusinessDetailsMeta, TypeBusinessIsRunWIth, BusinessDetailsAsset, BusinessDetailsTax, GetComponentFromTheType, ShareHolderDetails];
