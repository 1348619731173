import { Grid, Stack } from "@mui/material";
import { ErrorMessageComponent } from "../../UserCreditComponents/ErrorMessageComponent";
import ApplicationHeader from "../ApplicationHeader";
import InsuranceDetails from "./InsuranceDetails";


const InsuranceDetailsContainer = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion = false, printMode = false, isGuarantor = false, isJointBurrower = false, guarantorJBSection = "", innerComponentData, tabsToEdit }) => {
    let label = "Insurance Details";
    let insurance: any = data?.formData?.insuranceData || undefined;

    try {
        const { primaryApplicant = undefined } = data?.formStructure;
        label = primaryApplicant?.[innerComponentData?.id]?.label;
    } catch {
    }

    return (
        <Stack width={"100%"}>
            {isMainContactDetails && (!isRawVersion || printMode) && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={label ? label : "Insurance Details"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={isRawVersion} disableAccordingToTab={tabsToEdit?.includes(innerComponentData?.id)} />}

            {!isMainContactDetails && (
                <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
                    <Grid item>
                        {label ? label : "Insurance Details"}
                    </Grid>
                </Grid>
            )}
            <BuildLayout insurance={insurance} />
        </Stack>
    )
}

export default InsuranceDetailsContainer

const BuildLayout = ({ insurance }) => {

    if (!insurance || !insurance.length) {
        return <ErrorMessageComponent headMessage={""} errorMessage={"No Other insurance Data"} showImg={false} />
    }

    return (
        <>
            {insurance.map((data, index) => <InsuranceDetails state={data} />)}
        </>
    )
}