import { Downgraded } from "@hookstate/core";
import { assetsDetailsValidationOnNext } from "../../../configs/validations/application-form-on-next-validation-handlers/assets-details/assetsDetailsValidationOnNext";
import { otherValidationHandler } from "../../CustomHooks/useValidateSubmit";
import { messageMappings } from "./messageMapping";
import { formStateArrayTypeHandler } from "./structureValidation";
import { insuranceDetailsValidationOnNext } from "../../../configs/validations/application-form-on-next-validation-handlers/insurance-details/insuranceDetailsValidationOnNext";

export const insuranceDetails = (structure, state, isGuarantor = false, isJointBorrower = false, participantState, isSecuritySectionNext?) => {
    let insuranceDetails: any[] = state.attach(Downgraded).get();

    insuranceDetails.reduce((itemObject = [], cuurItem: any, itemIndex: number) => {
        delete cuurItem["sectionErrorStatus"];

        if (Object.keys(cuurItem).length) return [...itemObject, cuurItem];

        return itemObject
    }, []);

    if (!insuranceDetails.length) return ['Insurance Details cannot be Empty!'];

    let errArrInArrays = formStateArrayTypeHandler({ structure, state: insuranceDetails });

    if (errArrInArrays.length) {
        return messageMappings(errArrInArrays);
    }

    let resultOtherErrors = insuranceDetailsValidationOnNext(insuranceDetails);

    if (resultOtherErrors !== 1) {
        resultOtherErrors = otherValidationHandler(resultOtherErrors);
    } else {
        resultOtherErrors = [];
    }

    const preparedErrorMessages = [...errArrInArrays, ...resultOtherErrors];
    return preparedErrorMessages;
};
