import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop"
import { Box, Stack, Typography } from "@mui/material"
import moment from "moment"
import { useCallback, useRef } from "react"
import { useReactToPrint } from "react-to-print"
import { securityDocumentPermissions } from "../../../../../../configs/constants/contants"
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent"
import { disableAccess } from "../../UserCreditComponents/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted"
import A4Container from "../A4Container"
import EditableField from "../EditableField"

const PowerOfAttorney = ({ data }) => {
    const fullName = `${data?.formData?.personalData?.initialsInFull} ${data?.formData?.personalData?.primaryLastName}`;
    const primaryAddress = data?.formData?.addressData?.permanentAddress;
    let paAddressSegments = [primaryAddress?.addressLine1 || "", primaryAddress?.addressLine2 || "", primaryAddress?.addressLine3 || "", primaryAddress?.addressLine4 || ""].filter((obj) => obj);
    const address = paAddressSegments.join(", ").trim();
    const componentRef: any = useRef();

    const {
        applicationId,
        approvedLoanAmount,
        amount,
        originationBranch: usedBranchId,
        trialData: { trailCalParams = {} } = {},
        formData: { personalData: { lastName = "" } = {} } = {},
    } = (data as any);
    const documentId = securityDocumentPermissions.powerOfAttorney;

    const noPermissionPrint = disableAccess({
        documentComponentId: documentId,
        privilegeKeyforSec: "SEC_DOC_PRINT_SECURITY_DOC",
    });

    const renderNicDetails = useCallback((type = "", item: any = {}) => {
        if (type == "") {
            return null;
        }

        let nic = "";
        if (type == "PA") {
            const { newNic, oldNic } = data.formData.personalData;
            nic = newNic || oldNic;
        } else if (type == "JB") {
            const { newNic, oldNic } = item?.personalData;
            nic = newNic || oldNic;
        } else if (type == "GR") {
            const { newNic, oldNic } = item?.personalData;
            nic = newNic || oldNic;
        }

        return nic;
    }, [data.formData.personalData]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
        @media print {
          #Header, #Footer {
            display: none !important;
          }
        }
      `,
    });
    return (
        <Stack width={'100%'} spacing={2}>
            <div ref={componentRef}>
                <A4Container>
                    <Typography variant="h6" sx={{ textAlign: "center", fontWeight: "bold" }}>
                        POWER OF ATTORNEY
                    </Typography>

                    <Typography variant="body1" sx={{ marginTop: 2, lineHeight: 1.6 }}>
                        I, {fullName},
                        holder of NIC No. {renderNicDetails("PA")},
                        hereby grant <EditableField label="Lender Name" /> full legal
                        authority to repossess, transfer, and sell the leased asset in case of default.
                    </Typography>

                    <Typography variant="body1" sx={{ marginTop: 2, lineHeight: 1.6 }}>
                        This Power of Attorney includes the right to sign all documents necessary
                        for such actions and to represent me in legal proceedings related to the asset.
                    </Typography>

                    <Box sx={{ marginTop: 4 }} display={"flex"} flexDirection={"column"} gap={2}>
                        <Typography>Borrower:</Typography>
                        <Typography>Signature: ______________________</Typography>
                        <Typography>
                            Name: {fullName}
                        </Typography>
                        <Typography>
                            Date: {moment().format("DD/MM/YYYY")}
                        </Typography>
                        <Typography>
                            Signed at: <EditableField label="Location" />
                        </Typography>
                    </Box>
                </A4Container>
            </div>
            <Stack direction="row-reverse" spacing={2} >
                <ButtonComponent
                    startIcon={<LocalPrintshopIcon />}
                    title={"Print PDF"}
                    variant="contained"
                    onClick={() => {
                        handlePrint();
                    }}
                    style={{ maxHeight: "40px", marginTop: "auto" }}
                    disabled={noPermissionPrint}
                    loadingbtn={true}
                    loading={false}
                />
            </Stack>
        </Stack>
    )
}

export default PowerOfAttorney