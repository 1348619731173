import { useState } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { letterDocumentStructure } from "../../../../../../../configs/mainStore";
import { formatCurrency } from "../../../../../../../services/currencyFormater";
import { numberToWords, trimWithDelimiter } from "../../../../../../../utility/util";
import { openGlobalModal } from "../../../../../GlobalModal/GlobalModal";
import { PromissioryNoteWitness } from "./PromissioryNoteWitness";

export const PromissioryNoteBody = ({ data, requestedAmount, branch, applicationId, letterDocumentData, promissioryNoteDataArr, loanAmount, promissaryInterestRate, disableAccordingToTab, validator, isLoggedInUserNotTheAssignee = false, typeCaseClass, promissoryAmount }) => {
  const letterDocumentStructureData: any = useState(letterDocumentStructure);
  const creditDataObj: any = data?.formData?.creditData;
  const PersonalDataObj: any = data?.formData?.personalData;

  const fullname = [PersonalDataObj?.initialsInFull, data?.formData?.personalData?.primaryLastName].join(" ");

  const primaryAddress = data?.formData?.addressData?.permanentAddress;

  const [annualInterestRate, setAnnualInterestRate]: any = React.useState();
  const [type, setType] = React.useState<string>("");
  const today = new Date().toLocaleDateString();

  const numberOnly = /^\d+$/;
  const numberWithDecimals = /^[1-9]\d*(\.\d+)?$/;

  useEffect(() => {
    getAnnualInterestRateAmount();
    getDocumentSavedData();
    validateTypableVariables();

    if (promissoryAmount === "N/A") {
      setType("N/A");
    }
  }, []);

  useEffect(() => {
    getAnnualInterestRateAmount();
  }, [promissaryInterestRate]);

  useEffect(() => {
    validateTypableVariables();
  }, [type]);

  useEffect(() => {
    getDocumentSavedData();
  }, [promissioryNoteDataArr]);

  const getAnnualInterestRateAmount = () => {
    let interestRate: any = 0.0;
    if (promissaryInterestRate != null && promissaryInterestRate != "") {
      interestRate = parseFloat(promissaryInterestRate);
    } else {
      interestRate = parseFloat(creditDataObj.interestRate);
    }

    let regularPayFactor = creditDataObj.regularPaymentFactor != null && creditDataObj.regularPaymentFactor != "" ? parseFloat(creditDataObj.regularPaymentFactor) : 0;

    if (regularPayFactor >= 1) {
      let annualInterestRate = interestRate + regularPayFactor;
      setAnnualInterestRate(annualInterestRate);
    } else {
      setAnnualInterestRate(interestRate);
    }
  };

  const PAAdress = (paData) => {
    if (!paData || paData.length === 0) {
      return <></>;
    }
    let paAddresses: String[] = [];
    let paAddressSegments = [primaryAddress?.addressLine1 || "", primaryAddress?.addressLine2 || "", primaryAddress?.addressLine3 || "", primaryAddress?.addressLine4 || ""].filter((obj) => obj);
    const paAddress = paAddressSegments.join(", ").trim();
    paAddresses.push(paAddress);
    return (
      <>
        {paAddresses.map((item, i) => {
          return (
            <span key={i} className={`${typeCaseClass}`}>
              {" "}
              {item}
            </span>
          );
        })}
      </>
    );
  };

  let promissoryNoteObj = {};

  const onClickCreate = (modalID, label, id) => {
    if (disableAccordingToTab === false && isLoggedInUserNotTheAssignee == false) {
      openGlobalModal({
        modalSize: "md",
        title: "",
        bodyId: modalID,
        close: false,
        modalParams: {
          applicationId: applicationId,
          callBackOnAction: callBackOnAction,
          modalHeading: label,
          modalInputLabel: label,
          type: id,
          letterDocumentData: promissioryNoteDataArr,
        },
      });
    }
  };

  const callBackOnAction = async (value, loanAgreementType) => {
    promissoryNoteObj = {
      ...letterDocumentData?.promissoryNoteObj,
      [loanAgreementType]: value,
    };

    letterDocumentStructureData?.["promissoryNoteObj"].set(promissoryNoteObj);

    if (loanAgreementType === "promissioryNoteAmount") {
      if (numberOnly.test(value)) {
        value = formatCurrency(value, 2);
      } else if (numberWithDecimals.test(value)) {
        value = formatCurrency(value, 2);
      }

      setType(value || "N/A");
    }
  };

  const validate = () => {
    const errorObjectArr: any = [];

    if (type == "") {
      errorObjectArr.push({ message: "Deductions can not be empty", key: "Type" });
    }
    return errorObjectArr;
  };

  const getDocumentSavedData = () => {
    const letterBodyData = promissioryNoteDataArr?.LETTER_BODY;
    if (letterBodyData) {
      const letterBodyDataObj = JSON.parse(letterBodyData);

      let value = letterBodyDataObj?.promissioryNoteAmount;

      if (numberOnly.test(value)) {
        value = formatCurrency(value, 2);
      } else if (numberWithDecimals.test(value)) {
        value = formatCurrency(value, 2);
      }

      if (!type) {
        setType(value || "N/A");
      }
    }
  };

  const validateTypableVariables = () => {
    validator.promissoryNoteBody = () => {
      // return validate(); // Validation removed - Refer - 1968
      return [];
    };
  };

  const JBAdress = ({ JBData }) => {
    if (!JBData || JBData.length === 0) {
      return <></>;
    }
    let jbAddresses: String[] = [];
    let jbAddressSegments = [JBData?.addressData?.permanentAddress?.addressLine1 || "", JBData?.addressData?.permanentAddress?.addressLine2 || "", JBData?.addressData?.permanentAddress?.addressLine3 || "", JBData?.addressData?.permanentAddress?.addressLine4 || ""].filter((obj) => obj);
    const jbAddress = trimWithDelimiter(jbAddressSegments.join(", ").trim(), ",");
    jbAddresses.push(jbAddress);
    return (
      <>
        {jbAddresses.map((item, i) => {
          return (
            <span key={i} className={`${typeCaseClass}`}>
              {" "}
              {item}
            </span>
          );
        })}
      </>
    );
  };

  function RenderNames() {
    const applicantFormData = data.formData;
    const applicantJBData = applicantFormData?.jointBorrowerData;

    const renderedNames = applicantJBData
      ?.filter((obj) => obj?.personalData?.initialsInFull !== undefined && obj?.personalData?.primaryLastName !== undefined)
      .map((obj, index) => (
        <React.Fragment key={index}>
          {" and "}
          <span className={`security-doc-type-variable ${typeCaseClass}`}>{obj?.personalData?.initialsInFull}</span> <span className={`security-doc-type-variable ${typeCaseClass}`}>{obj?.personalData?.primaryLastName}</span>
          <JBAdress JBData={obj} />
        </React.Fragment>
      ));

    return <>{renderedNames}</>;
  }

  const loanAmountInWords = numberToWords(loanAmount);

  return (
    <>
      <Grid container p={1}>
        <Stack direction={"row"} mt={3}>
          <Stack direction={"column"} flex={1} sx={{ paddingRight: "1.5%", borderRight: "1px solid #000000" }}>
            <Stack>
              <Grid container>
                <Grid item xs={2}>
                  1.
                </Grid>
                <Grid item xs={10}>
                  Capital sum borrowed Rs. {formatCurrency(loanAmount, 2)}
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item xs={2}>
                  2.
                </Grid>
                <Grid item xs={10}>
                  Interest premium or charges deducted or paid in advance (if any) of Rs.{" "}
                  <span className={`typable-text-item security-doc-type-variable ${typeCaseClass}`} style={{ textDecoration: !type ? "underline" : "" }} onClick={() => onClickCreate("security-document-type-modal", "Deductions", "promissioryNoteAmount")}>
                    {type ? type : "N/A"}
                  </span>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
          <Stack direction={"column"} flex={3} alignItems={"stretch"} justifyContent={"flex-start"} ml={2} style={{ display: "inline" }}>
            <Grid container columns={12} mb={2}>
              <Grid item xs={6} textAlign={"right"}>
                Rs. {formatCurrency(loanAmount, 2)}
              </Grid>
              <Grid item xs={6} textAlign={"right"}>
                <span className={`security-doc-type-variable ${typeCaseClass}`}>{branch}</span> Branch
              </Grid>
            </Grid>
            <div className="promissory-note-body">
              <span style={{ marginRight: "5px" }}>On Demand the undersigned</span> <span className={`security-doc-type-variable ${typeCaseClass}`}>{fullname}</span> of {<PAAdress />} {<RenderNames />} promise to pay to the Pradeshiya Sanwardhana Bank of No 933, Kandy Road, Wedamulla, Kelaniya or at its branch office at <span className={`security-doc-type-variable ${typeCaseClass}`}>{branch}</span>, or order the sum of <span className={`security-doc-type-variable ${typeCaseClass}`}>{loanAmountInWords}</span>, in currency of Sri Lanka for value received with interest thereon at the rate of {annualInterestRate} per centum per annum from the date hereof.
            </div>
            <PromissioryNoteWitness data={data} date={today} typeCaseClass={typeCaseClass} />
          </Stack>
        </Stack>
      </Grid>
    </>
  );
};
