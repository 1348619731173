export const sectionMapping = {
    'primary-applicant-details': 'personalData',
    'credit-details': 'creditData',
    'contact-details': 'contactData',
    'address-details': 'addressData',
    'education-details': 'educationData',
    'employment-details': 'employedData',
    'income-details': 'incomeData',
    'expenses-details': 'expenseData',
    'bank-details': 'bankData',
    'assets-details': 'assetsData',
    'guarantor-details': 'guarantorData',
    'security-details': 'securityData',
    'joint-borrower-details': 'jointBorrowerData',
    'tax-details': 'taxDetailsData',
    'analysis-of-the-loan-requirement': 'analysisOfLoanRequirementData',
    'risk-conditions-general-facts': 'risksConditionsAndGeneralFactsData',
    'inquiry-of-obligations': 'inquiryOfObligationsData',
    'reports-previous-borrowings': 'previousBorrowingsData',
    'business-details': 'businessData',
    'life-insurance-details': 'lifeInsuranceData',
    "lease-details": "leaseAssetsData",
    "insurance-details": "insuranceData"
};