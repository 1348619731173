import { Grid } from "@mui/material";
import React from "react";
import { formatCurrency } from "../../../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../../../services/onDemandValidators";
import { TextBoxComponent } from "../../../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { FieldSectionWrapper } from "../../../../../../../../FieldSectionsWrapper.tsx/FieldSectionWrapper";
import { SubSectionsWrapper } from "../../../../../../../../SubSectionsWrapper/SubSectionsWrapper";
import GridDataTable from "../../../../../FormBuilder/Tools/Helpers/Components/GridDataTable";
import { debtors } from "../../../../../FormBuilder/Tools/Helpers/inspectionStores/inspectionMainStore";

export const WorkingCapitalDebtors = ({ otherParams, data: debtorsData }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const { isAmountAboveTwoPointFiveAndEqual } = otherParams;

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <SubSectionsWrapper>
          <FieldSectionWrapper>
            <GridDataTable
              openModal={openModal}
              setOpenModal={setOpenModal}
              addButtonInvisible={true}
              totalBtnVisility={false}
              sourceArray={debtorsData}
              defaultDataObject={JSON.parse(JSON.stringify(debtors))}
              mandatoryKeys={[]}
              headerTitle={["Description", "Total Credit Income", "Number of Days", "Amount",]}
            >
              {debtorsData
                ?.filter((itemObj) => !itemObj?.removedItem?.get())
                .map((items, index) => (
                  <Grid item xs={12} md={12} key={items?.dataId.get() || index}>
                    <Grid container gap={1}>
                      <Grid item xs={2}>
                        <TextBoxComponent
                          label={"Description"}
                          required={isAmountAboveTwoPointFiveAndEqual === true ? true : false}
                          value={items?.description?.get()}
                          fullWidth={true}
                          onChange={(e) => items?.description?.set(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextBoxComponent
                          label={"Total Credit Income"}
                          required={Boolean(isAmountAboveTwoPointFiveAndEqual)}
                          value={formatCurrency(items?.operatingCosts?.get()) || 0}
                          onInput={OnDemandValueFormatter.numeric}
                          fullWidth={true}
                          onChange={(e) => items?.operatingCosts?.set(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextBoxComponent
                          label={"Number of Days"}
                          onBlur={(e) => {
                            const calc = (Number(items?.operatingCosts?.get() || 0) / 365) * Number(e.target.value);
                            items?.amount?.set(calc);
                          }}
                          required={isAmountAboveTwoPointFiveAndEqual === true ? true : false}
                          value={formatCurrency(items?.numberOfDays?.get())}
                          fullWidth={true}
                          onInput={OnDemandValueFormatter.numeric}
                          onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value <= 500) {
                              items?.numberOfDays?.set(e.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextBoxComponent
                          style={{ backgroundColor: "#D3D3D3", fontWeight: "bold" }}
                          disabled={true}
                          required={isAmountAboveTwoPointFiveAndEqual === true ? true : false}
                          value={formatCurrency(items?.amount?.get())}
                          onInput={OnDemandValueFormatter.numeric}
                          fullWidth={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </GridDataTable>
          </FieldSectionWrapper>
        </SubSectionsWrapper>
      </Grid>
    </Grid>
  );
};
