import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Stack } from "@mui/material";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { securityDocumentPermissions } from "../../../../../../configs/constants/contants";
import { disableAccess } from "../../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import DeedOfAssignment from "./DeedOfAssignment";

const DeedOfAssignmentLayout = ({ data }) => {
    const fullName = `${data?.formData?.personalData?.initialsInFull} ${data?.formData?.personalData?.primaryLastName}`;
    const componentRef: any = useRef();

    const {
        applicationId,
        approvedLoanAmount,
        amount,
        originationBranch: usedBranchId,
        trialData: { trailCalParams = {} } = {},
        formData: { personalData: { lastName = "" } = {} } = {},
    } = (data as any);
    const documentId = securityDocumentPermissions.deedOfAssignment || "deed_of_assignment";

    const noPermissionPrint = disableAccess({
        documentComponentId: documentId,
        privilegeKeyforSec: "SEC_DOC_PRINT_SECURITY_DOC",
    });

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
        @media print {
          #Header, #Footer {
            display: none !important;
          }
        }
      `,
    });

    return (
        <Stack spacing={2}>
            <div ref={componentRef}>
                <DeedOfAssignment />
            </div>
            <Stack direction="row-reverse" spacing={2} >
                <ButtonComponent
                    startIcon={<LocalPrintshopIcon />}
                    title={"Print PDF"}
                    variant="contained"
                    onClick={() => {
                        handlePrint();
                    }}
                    style={{ maxHeight: "40px", marginTop: "auto" }}
                    disabled={noPermissionPrint}
                    loadingbtn={true}
                    loading={false}
                />
            </Stack>
        </Stack>
    )
}

export default DeedOfAssignmentLayout