import { Downgraded, useState } from "@hookstate/core";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Box, Grid, Stack } from "@mui/material";
import { standardDateFormatter } from "los-util";
import React, { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { excludeTypesFromSpecialConditions } from "../../../../configs/constants/conditionListExcludes";
import { securityDocumentPermissions, securityDocumentTextCase } from "../../../../configs/constants/contants";
import { branchDetails, creditData, letterDocumentStructure, masterDataDetails, userDetails } from "../../../../configs/mainStore";
import { getConditionList, saveLetterDocumentsData } from "../../../../services/creditFileApiCall";
import { disableAccess } from "../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { compare } from "../../../../utility/other";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../InputComponents/SelectComponent/SelectComponent";
import { DocumentFooter } from "../../../InputHelperComponents/DocumentFooterComponent/DocumentFooterComponent";
import { addToaster, Toast } from "../../GlobalToast";
import { CircularProgressComponent } from "../../ProgressComponent/ProgressComponent";
import CommonDocReference from "./SecurityDocuments/CommonDocReference";
import { SinglePageBreak } from "./SecurityDocuments/CommonPageBreaks";
import CommonSecurityDocHeader from "./SecurityDocuments/CommonSecurityDocHeader";
import SecurityDoceumentsFileDocumentsViewSection from "./SecurityDocumentsFileDocumentsViewSection";
import GeneralConditions from "./SummaryLetterComponents/OfferLetter/GeneralConditions";
import Letter from "./SummaryLetterComponents/OfferLetter/Letter";
import LoanTerms from "./SummaryLetterComponents/OfferLetter/LoanTerms";
import Security from "./SummaryLetterComponents/OfferLetter/Security";
import Signatures from "./SummaryLetterComponents/OfferLetter/Signatures";
import SpecialConditions from "./SummaryLetterComponents/OfferLetter/SpecialConditions";

const printValidator = {
  letterConditionsList: () => [],
  letterThankYou: () => [],
};

const OfferLetterLayout = ({ applicationId, existingDataArr, setDocumentDataUpdated, data }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [offerLetterDataArr, setOfferLetterDataArr] = React.useState<any>({});
  const masterData: any = useState(masterDataDetails);
  const componentRef: any = useRef();
  const creditFileData: any = creditData.attach(Downgraded).get();
  const userData: any = userDetails.attach(Downgraded).get();
  const usedBranchId = creditFileData["originationBranch"] || 1;
  const nearestBranch = branchDetails.branchMap[usedBranchId].name.get();
  const [disburseLoanAmount, setDisburseLoanAmount] = React.useState<any>(0.0);
  const [applicableRate, setApplicableRate] = React.useState<any>(0.0);
  const [loanTenor, setLoanTenor]: any = React.useState(0);
  const [repaymentDate, setRepaymentDate] = React.useState<any>("");
  const [installmentValue, setInstallementValue] = React.useState<any>(0.0);
  const [trialCalcResults, setTrialCalcResults]: any[] = React.useState([]);
  const [coreBankData, setCoreBankData]: any = React.useState({});
  const documentId = securityDocumentPermissions.offerLetter || "offer_letter_acceptance";
  const [offerLetterConditionsList, setOfferLetterConditionsList]: any[] = React.useState([]);
  const [typeCase, setTypeCase] = React.useState(1);
  const [typeCaseClass, settypeCaseClass] = React.useState();
  const [isHavingTypeVariables]: any = React.useState(false);
  const [referenceType, setReferenceType]: any = React.useState(1);
  const [referenceStyles, setReferenceStyles]: any = React.useState({
    top: {
      display: "none",
    },
    bottom: {
      display: "none",
    },
  });
  const [positionStyleType, setPositionStyleType] = React.useState("page-footer-bottom");

  const permissionToEditSave = disableAccess({
    documentComponentId: documentId,
    privilegeKeyforSec: "SEC_DOC_EDIT_SAVE_CHANGES",
  });

  const permissionToPrint = disableAccess({
    documentComponentId: documentId,
    privilegeKeyforSec: "SEC_DOC_PRINT_SECURITY_DOC",
  });

  let { trialData: trailObjectData, coreData } = data || {};

  useEffect(() => {
    (async function () {
      if (applicationId) {
        const excludeString = excludeTypesFromSpecialConditions.map((item) => JSON.stringify(item)).join(",");

        const executions = [
          getConditionList(applicationId, excludeString)
        ];

        const [conditionListResponse] = await Promise.all(executions);

        if (conditionListResponse && conditionListResponse.length > 0) {
          const sortedList = conditionListResponse.sort((a, b) => compare(b, a, "id"));
          setOfferLetterConditionsList(sortedList || []);
        }

        // Get Trial Cal Result Data
        const { LoanAmount, loanAmount, LoanInterestRate, loanInterestRate } = trailObjectData?.trailCalParams;
        setDisburseLoanAmount(LoanAmount || loanAmount || "");
        let trialInterestRate = LoanInterestRate || loanInterestRate || "";
        setApplicableRate(trialInterestRate || 0.0);

        let loanTenture = trailObjectData?.trailCalParams?.LoanTenure;
        setLoanTenor(loanTenture);

        let firstTermObj = trailObjectData?.trailCalResult[0];
        let firstTermPayDate = firstTermObj?.paymentDate || "";

        if (firstTermPayDate && !firstTermPayDate.includes("/")) {
          firstTermPayDate = standardDateFormatter(firstTermPayDate);
        }

        setRepaymentDate(firstTermPayDate);

        if (trailObjectData?.trailCalResult.length > 0) {
          setTrialCalcResults(trailObjectData?.trailCalResult);
          setInstallementValue(trailObjectData?.trailCalResult[1]?.termlyInstallment);
        } else {
          setTrialCalcResults([]);
          setInstallementValue(0.0);
        }
      }

      const selectedAccountObj = coreData;

      const {
        INVESTMENT_ACCOUNT: investmentAccount = "",
        INVESTMENT_AMOUNT: standingOrderAmount = "",
        REC_ACC: recoveryAccount = ""
      } = selectedAccountObj || {};

      setCoreBankData({ standingOrderAmount, recoveryAccount, investmentAccount });

      setIsLoading(false);
    })();

    if (existingDataArr) {
      let offerLetterDataObj = existingDataArr.find((obj) => obj?.LETTER_TYPE === "offerLetter");
      setOfferLetterDataArr(offerLetterDataObj);
    }
  }, []);

  useEffect(() => {
    changeTypeCase();
  }, [typeCase]);

  useEffect(() => {
    if (referenceType == 1) {
      setReferenceStyles((previousState) => {
        return { bottom: { display: "block" }, top: { display: "none" } };
      });
      setPositionStyleType("page-footer-bottom");
    } else if (referenceType == 2) {
      setReferenceStyles((previousState) => {
        return { top: { display: "block" }, bottom: { display: "none" } };
      });
      setPositionStyleType("page-footer-top");
    }
  }, [referenceType]);

  const today = new Date().toLocaleDateString();
  const letterDocumentStructureData: any = useState(letterDocumentStructure);
  const existingLetterDocumentData = letterDocumentStructureData.attach(Downgraded).get();

  const saveDocumentData = async () => {
    let letterBodyObj = {};

    setIsLoading(true);

    const updatedData = existingLetterDocumentData?.offerLetterObj;
    const letterBodyData = offerLetterDataArr?.LETTER_BODY;
    if (letterBodyData) {
      const letterBodyDataObj = JSON.parse(letterBodyData);

      letterBodyObj = {
        ...letterBodyDataObj,
        ...updatedData,
      };
    } else {
      letterBodyObj = {
        ...updatedData,
      };
    }
    const data = {
      APPLICATION_ID: applicationId,
      WORKFLOW_ID: "",
      LETTER_BODY: JSON.stringify(letterBodyObj),
      STATUS: 1,
      CREATED_BY: userData?.userId,
      UPDATED_BY: userData?.userId,
      LETTER_TYPE: "offerLetter",
    };

    const res = await saveLetterDocumentsData(data);

    if (res?.status === 1) {
      setDocumentDataUpdated((prev) => !prev);
    }

    setIsLoading(false);

    if (res?.status === 1) {
      addToaster({
        status: "success",
        title: "Success",
        message: "Letter Document Data Added",
      });
    } else {
      addToaster({
        status: "error",
        title: "Error",
        message: "Letter Document Data Adding Falied",
      });
    }
  };

  const handlePrint = () => {
    let documentErrors: any[] = [];
    let validateStatus = true;

    for (const key of Object.keys(printValidator)) {
      try {
        const errors = printValidator[key]();
        if (errors.length > 0) {
          errors.forEach((error) => {
            documentErrors.push(error.message);
          });
          validateStatus = false;
        }
      } catch (error) {
        continue;
      }
    }

    if (documentErrors.length > 0) {
      let formattedMessage = ["Failed to proceed with empty placeholders.\n\n"];

      for (const item of documentErrors) {
        formattedMessage.push(`\n\n👉 ${item}`);
      }
      Toast.error(formattedMessage, "Error")
      validateStatus = false;
    }

    return validateStatus;
  };

  const proceedPrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @media print {
        #Header, #Footer {
          display: none !important;
        }
      }
    `,
  });

  const changeTypeCase = () => {
    settypeCaseClass(securityDocumentTextCase[typeCase || 1].typeCaseClass);
  };

  const referenceHandler = (value) => {
    setReferenceType(value);
  };

  const renderTitles = (primaryTitle) => {
    if (primaryTitle == "") {
      return null;
    }

    const title: any = masterData?.TITLE?.get()?.find((t) => t.id == primaryTitle);
    return `${title?.name}.`;
  };

  const getPageMargins = () => {
    return `@page { margin: ${"7mm"} ${"10mm"}  ${"15mm"} ${"15mm"} !important; }`;
  };

  if (isLoading) {
    return <>
      <Grid container className="inner-component-full-height basic-font">
        <style>{getPageMargins()}</style>
        <div className={`full-width full-height`}>
          <Stack alignItems="center" justifyContent="center" height="300px">
            <CircularProgressComponent size={30} />
          </Stack>
        </div>
      </Grid>
    </>;
  }

  return (
    <>
      <Grid container className="inner-component-full-height basic-font">
        <style>{getPageMargins()}</style>
        <div className={`full-width full-height`}>
          <SecurityDoceumentsFileDocumentsViewSection data={data} applicationId={applicationId} isSecurityDocument={true} securityDocumentKey={"offer-letter"} />
          <div ref={componentRef}>
            <div style={referenceStyles.top}>
              <DocumentFooter positionStyleType={positionStyleType} />
            </div>
            <Grid container pt={2} pr={2} pl={2} pb={0}>
              <CommonSecurityDocHeader documentName={"Offer Letter"} textCase={"lower"} />
            </Grid>
            <Grid container pr={7} pl={7} pb={7} mt={-3}>
              {/* Branch / Date */}
              <Letter data={creditFileData} branch={nearestBranch} date={today} typeCaseClass={typeCaseClass} renderTitles={renderTitles} />
              <SinglePageBreak />
              {/* Loan Terms */}
              <LoanTerms data={creditFileData} disburseLoanAmount={disburseLoanAmount} applicableRate={applicableRate} loanTenor={loanTenor} repaymentDate={repaymentDate} installmentValue={installmentValue} trialCalcResults={trialCalcResults} typeCaseClass={typeCaseClass} />
              <SinglePageBreak />
              {/* Security */}
              <Security data={creditFileData} disburseLoanAmount={disburseLoanAmount} typeCaseClass={typeCaseClass} />
              {/* Special Conditions */}
              <SpecialConditions data={creditFileData} recoveryAccount={coreBankData.recoveryAccount} offerLetterConditionsList={offerLetterConditionsList} typeCaseClass={typeCaseClass} />
              <SinglePageBreak />
              {/* General Conditions */}
              <GeneralConditions data={creditFileData} applicableRate={applicableRate} coreBankData={coreBankData} typeCaseClass={typeCaseClass} />
              <SinglePageBreak />
              {/* Signatures */}
              <Signatures data={creditFileData} branch={nearestBranch} typeCaseClass={typeCaseClass} />
            </Grid>
            <div style={referenceStyles.bottom}>
              <DocumentFooter positionStyleType={positionStyleType} />
            </div>
          </div>
          <Box m={1}>
            <Grid container columns={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Grid item xs={4} pr={1}>
                <CommonDocReference referenceHandler={referenceHandler} referenceType={referenceType} />
              </Grid>
              <Grid item xs={4} pr={1}>
                <SelectComponent label={"Select Typecase"} value={typeCase} values={Object.values(securityDocumentTextCase)} onChange={(e) => setTypeCase(e.target.value)} required={false} defaultPlaceholder={true} />
              </Grid>
              <Grid item xs={4}>
                {isHavingTypeVariables ? (
                  <ButtonComponent
                    title={"Save"}
                    variant="contained"
                    onClick={() => {
                      saveDocumentData();
                    }}
                    style={{
                      maxHeight: "40px",
                      marginTop: "auto",
                      marginRight: "10px",
                    }}
                    disabled={permissionToEditSave}
                    loadingbtn={true}
                    loading={isLoading}
                  />
                ) : (
                  ""
                )}

                <ButtonComponent
                  startIcon={<LocalPrintshopIcon />}
                  title={"Print PDF"}
                  variant="contained"
                  onClick={() => {
                    Boolean(handlePrint()) && proceedPrint();
                  }}
                  style={{ maxHeight: "40px", marginTop: "auto" }}
                  disabled={permissionToPrint}
                  loadingbtn={true}
                  loading={false}
                />
              </Grid>
            </Grid>
          </Box>
        </div>
      </Grid>
    </>
  );
};

export default OfferLetterLayout;
