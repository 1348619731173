import { Downgraded, useState } from "@hookstate/core";
import { Grid, Paper, Stack, styled, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";

import Grid2 from "@mui/material/Unstable_Grid2";
import moment from "moment";
import { appraisalInterestCalculationState, creditData, userDetails } from "../../../../../../../../configs/mainStore";
import { getInterstCalculatedData, getTrailCalData, saveInterestCalculation } from "../../../../../../../../services/creditFileApiCall";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import { getIsDisbursed } from "../../../../../../../../utility/helpers/getIsDisbursed";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { toast } from "../../../../../../GlobalToast";
import DetailItem from "../../../../DetailItem";
import AppraisalInterestHeader from "./AppraisalInterestHeader";
import { fieldsMapping } from "./config";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(5),
    color: theme.palette.text.secondary,
}));

const AppraisalInterestCalculation = ({ data, applicationId, currentApplicationWfData, innerComponentData, tabsToEdit }) => {
    const appraisalInterestCalculation: any = useState(appraisalInterestCalculationState);
    const [isLoading, setIsLoading] = React.useState(true);


    const creditDetailsState: any = useState(creditData);
    const { disbursed, formData: { inquiryOfObligationsData: { loanLease } } }: any = creditDetailsState.attach(Downgraded).get();

    const { userId }: any = userDetails.attach(Downgraded).get();
    const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

    const currentYear = Number(moment().year());
    const nextYear = Number(moment().year()) + 1;
    const yearAfterNext = nextYear + 1;

    useEffect(() => {
        (async function () {
            let [
                trailCalData = {},
                existingInterestCalculatedData = {}
            ]: any = await Promise.all([
                getTrailCalData(applicationId),
                getInterstCalculatedData({ applicationId: applicationId }),
            ]);

            const { trailCalResult } = trailCalData;

            // Get average interest rate from existing data
            const getAvgInterestRateAssume = existingInterestCalculatedData?.[currentYear]?.avgInterestRateAssume || "";

            // Set average interest rate
            appraisalInterestCalculation[currentYear]?.avgInterestRateAssume.set(getAvgInterestRateAssume);

            // Calculate current total outstanding
            const _loanLease = loanLease || [];
            const currentTotalOutstanding = _loanLease
                .filter((item) => item.exisistingFacilty === 1 && item.ifContinued === 1)
                .reduce((sum, item) =>
                    sum + (Number(item.currentOutstanding) || 0), 0
                );

            // Set current total outstanding
            appraisalInterestCalculation[currentYear]?.projectRelatedTotalLoansOutstanding.set(currentTotalOutstanding);

            // Calculate proposed loans
            const totalInterestRate = trailCalResult.slice(0, Math.min(12, trailCalResult.length));
            const yearAfterTotalInterest = trailCalResult.slice(12, Math.min(24, trailCalResult.length));

            const totalInterest = totalInterestRate.reduce((sum, item) =>
                Number(sum) + Number(item.interestRecovery), 0).toFixed(2);

            const yearAfterTotalInterestRate = yearAfterTotalInterest.reduce((sum, item) =>
                Number(sum) + Number(item.interestRecovery), 0).toFixed(2);

            // Set proposed loans
            appraisalInterestCalculation[nextYear]?.proposedLoan.set(totalInterest);
            appraisalInterestCalculation[yearAfterNext]?.proposedLoan.set(yearAfterTotalInterestRate);

            // Calculate additional values as in handleInputFieldChange
            const interestRate = Number(getAvgInterestRateAssume);

            if (interestRate) {
                const formatNumber = (num: number): string => num.toFixed(2);

                // Annual Interest Expenses
                const annualInterestExpenses = (currentTotalOutstanding * interestRate) / 100;

                // Next Year Existing Loan
                const nextYearExistingLoan = annualInterestExpenses * 0.8;

                // Year After Existing Loan
                const yearAfterExistingLoan = annualInterestExpenses * 0.6;

                // Next Year Total Interest
                const nextYearTotalInterest = nextYearExistingLoan + Number(totalInterest);

                // Set calculated values
                appraisalInterestCalculation[currentYear]?.annualInterestExpenses.set(
                    formatNumber(annualInterestExpenses)
                );

                appraisalInterestCalculation[nextYear]?.existingLoan.set(
                    formatNumber(nextYearExistingLoan)
                );

                appraisalInterestCalculation[nextYear]?.totalInterest.set(
                    formatNumber(nextYearTotalInterest)
                );

                if (appraisalInterestCalculation[yearAfterNext]) {
                    appraisalInterestCalculation[yearAfterNext].existingLoan.set(
                        formatNumber(yearAfterExistingLoan)
                    );

                    // Calculate and set yearAfterNext total interest
                    const yearAfterNextTotalInterest = yearAfterExistingLoan + Number(yearAfterTotalInterestRate);
                    appraisalInterestCalculation[yearAfterNext].totalInterest.set(
                        formatNumber(yearAfterNextTotalInterest)
                    );
                }
            }

            setIsLoading(false);
        })();
    }, []);

    const checkPrivilegeStatus = () => {
        let isDisabled = true;
        if (tabsToEdit.includes(innerComponentData?.id)) {
            isDisabled = true;
        } else {
            isDisabled = getIsDisbursed(null, disbursed) || isLoggedInUserNotTheAssignee;
        }
        return isDisabled;
    };

    const handleInputFieldChange = (key, year, value) => {
        // Current Year
        const currentYearData = appraisalInterestCalculation[currentYear];

        // Next Year
        const nextYearData = appraisalInterestCalculation[nextYear];

        if (!currentYearData || !nextYearData) return;

        // Project Related Total Loans Outstanding Balance
        const loansOutstanding = Number(currentYearData.projectRelatedTotalLoansOutstanding.get());

        // Proposed Loan
        const nextYearProposedLoan = Number(nextYearData.proposedLoan.get());

        // Average Interest Rate Assumed
        const interestRate = Number(value);

        // Annual Interest Expenses
        const annualInterestExpenses = (loansOutstanding * interestRate) / 100;

        // Next Year Existing Loan (eg - 2026)
        const nextYearExistingLoan = annualInterestExpenses * 0.8;

        // Year After Existing Loan (eg - 2027)
        const yearAfterExistingLoan = annualInterestExpenses * 0.6;

        // Next Year Total Loan (eg - 2026)
        const nextYearTotalInterest = nextYearExistingLoan + nextYearProposedLoan;

        const formatNumber = (num: number): string => num.toFixed(2);

        currentYearData.avgInterestRateAssume.set(interestRate);
        currentYearData.annualInterestExpenses.set(formatNumber(annualInterestExpenses));

        nextYearData.existingLoan.set(formatNumber(nextYearExistingLoan));
        nextYearData.totalInterest.set(formatNumber(nextYearTotalInterest));

        if (appraisalInterestCalculation[yearAfterNext]) {
            appraisalInterestCalculation[yearAfterNext].existingLoan.set(
                formatNumber(yearAfterExistingLoan)
            );
        }
    }

    const handleOnSubmit = async () => {
        const _appraisalInterestCalculation = appraisalInterestCalculation.attach(Downgraded).get();

        if (_appraisalInterestCalculation?.[currentYear]?.avgInterestRateAssume === "") {
            toast({ status: "error", message: "Avarage Intrest Rate Assume cannot be empty" });

            return;
        }

        const params = {
            applicationId,
            data: _appraisalInterestCalculation
        }

        const res = await saveInterestCalculation(params);

        if (res?.status === 1) {
            toast({ status: "success", message: "Avarage Intrest Rate saved" });
        } else {
            toast({ status: "error", message: "Avarage Intrest Rate not saved" });
        }
    }

    if (isLoading) {
        return (
            <Stack justifyContent={"center"} alignItems={"center"}>
                <CircularProgressComponent
                    size={30}
                    sx={{ marginTop: "20px", marginBottom: "20px", marginLeft: "auto", marginRight: "auto", zIndex: 100 }}
                />
            </Stack>
        );
    }

    return (
        <div className="full-width full-height">
            <AppraisalInterestHeader
                title={innerComponentData?.title}
                onSubmit={handleOnSubmit}
            />
            {Object.keys(appraisalInterestCalculation).length > 0 && (
                <Grid container className="inner-component-height">
                    <Stack spacing={1} m={1} className="full-width">
                        {Object.entries(appraisalInterestCalculation).map(([year, yearData]: any) => (
                            <Item key={year}>
                                <Typography variant="body1">{year}</Typography>
                                <Grid2 container spacing={5} p={2}>
                                    {Object.entries(yearData).map(([fieldKey, fieldValue]: any) => {
                                        const field = fieldsMapping[fieldKey];
                                        if (!field) return null;

                                        const value = fieldValue.get();

                                        switch (field.fieldType) {
                                            case "detail":
                                                return (
                                                    <Grid2 key={fieldKey}>
                                                        <DetailItem
                                                            title={field.fieldName}
                                                            value={formatCurrency(value || 0)}
                                                            valueFontClass="font-size-15"
                                                            titleFontClass="font-size-16"
                                                        />
                                                    </Grid2>
                                                );
                                            case "text":
                                                return (
                                                    <Grid2 key={fieldKey}>
                                                        <TextBoxComponent
                                                            label={field.fieldName}
                                                            value={formatCurrency(value)}
                                                            fullWidth={true}
                                                            onChange={(e) => handleInputFieldChange(fieldKey, year, e.target.value)}
                                                            required={true}
                                                            onInput={OnDemandValueFormatter.numeric}
                                                        />
                                                    </Grid2>
                                                );
                                            default:
                                                return null;
                                        }
                                    })}
                                </Grid2>
                            </Item>
                        ))}
                    </Stack>
                </Grid>
            )}
        </div>
    );
};

export default AppraisalInterestCalculation;
