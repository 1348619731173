import { Grid, Stack, Typography } from "@mui/material";
import { standardDateFormatter } from "los-util";
import { LeasePropertyisUnder } from "../../../../../../../configs/constants/contants";
import { MASTER_DATA_KEYS } from "../../../../../../../configs/constants/masterDataKeys";
import { formatCurrency } from "../../../../../../../services/currencyFormater";
import isProductOf from "../../../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import MasterDataProvider from "../../../../../../../utility/MasterDataProvider";
import { addItem } from "../Helpers";

export const TypeBusinessIsRunWIth = ({ business, masterData, isMainContactDetails }) => {
  const masterdataDetails = MasterDataProvider.provideMasterData();

  const masterDataObj = {
    relationshipWithtThePrimaryApplicant: masterdataDetails?.getMasterDataDescription(MASTER_DATA_KEYS?.RELATION_WITH_MA, business?.relationshipWithtThePrimaryApplicant),
    leaseAgreementUnder: masterdataDetails?.getMasterDataDescription(MASTER_DATA_KEYS?.LEASE_PROPERTY_IS_UNDER, business?.leaseAgreementUnder),
    typeOfLeasePropertyAgreements: (typeId) => {
      if (typeId) {
        let typeName;
        LeasePropertyisUnder.forEach((obj) => {
          if (obj["id"] === typeId) {
            typeName = obj["name"];
          }
        });
        return typeName;
      }
    },
  };
  if (isProductOf({ primaryApplicant: isMainContactDetails })) {
    return (
      <Grid mb={2} container>
        <Grid container item p={1} spacing={1} className="data-box">
          <Stack
            direction={"row"}
            className="basic-font basic-font-color-bold font-size-16 align-left"
          >
            <Typography variant="subtitle2" color={"gray"} ml={1}>
              {`Ownership of Business Property
              ${business && business?.isLeaseAgreementUnderSelected ? "Lease Agreement" : business?.isPropertyIsUnderSelected ? "Inherited Property" : ""}`}
            </Typography>
          </Stack>
          <Grid container item p={1} spacing={1}>
            {business && business?.isLeaseAgreementUnderSelected && (
              <>
                {addItem(business, "Lease Agreement Under", masterDataObj?.leaseAgreementUnder ?? "N/A")}
                {business?.agreementPeriod && addItem(business, "Agreement Period", business?.agreementPeriod ?? "N/A")}
                {addItem(business, "From Date", `${standardDateFormatter(business?.fromDate) ?? "N/A"}`)}
                {addItem(business, "To Date", `${standardDateFormatter(business?.toDate) ?? "N/A"}`)}
                {addItem(business, "Description", business?.description ?? "N/A")}
              </>
            )}

            {business && business?.isPropertyIsUnderSelected && (
              <>
                {addItem(
                  business,
                  "Property Is Under",
                  masterDataObj?.typeOfLeasePropertyAgreements(
                    business?.propertyIsUnder
                  ) ?? "N/A"
                )}
                {addItem(
                  business,
                  "Description",
                  business?.description ?? "N/A"
                )}
              </>
            )}

            {business &&
              (business?.leaseAgreementUnder === 2 ||
                business?.propertyIsUnder === 2) && (
                <>
                  {addItem(
                    business,
                    "Other Name",
                    business?.otherName ?? "N/A"
                  )}
                  {addItem(
                    business,
                    "Relationship With the Primary Applicant",
                    masterDataObj?.relationshipWithtThePrimaryApplicant ?? "N/A"
                  )}
                </>
              )}
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return <></>;
  }
};

export const VulnerabilityClimaticAndEnvironmentalFactors = (business, isMainContactDetails) => {
  if (isProductOf({ primaryApplicant: isMainContactDetails })) {
    return (
      <>
        {/* {addItem(business, "Vulnerability Climatic And Environmental Factorst", masterData?.getMasterDataDescription(MASTER_DATA_KEYS?.VULNERABILITY_CLIMATIC_AND_ENV_FACTORS, business?.vulnerabilityClimaticAndEnvironmentalFactors) ?? "N/A")} */}
        {addItem(business, "Current Year Sales", formatCurrency(business?.currentYearSales) ?? "N/A")}
        {addItem(business, "Previous Year Sales", formatCurrency(business?.previousYearSales) ?? "N/A")}
      </>
    );
  } else {
    return <></>;
  }
};
