import React, { useEffect, useRef } from "react";
import SubSectionHeader from "../../Helpers/SubSectionHeader/SubSectionHeader";
import TotalProjectContributionDataTable from "./subComponents/TotalProjectContributionDataTable";

export const defaultTableData = {
    proposedProject: {
        equity: {
            amount: 0,
            description: "Sum of all Proposed Equity from 'Analysis of the Loan Requirement' table",
            ratioFormula: "Proposed Project Amount-Equity/Total Proposed Project Amount×100"
        },
        bank: {
            amount: 0,
            description: "Sum of all Proposed Bank from 'Analysis of the Loan Requirement' table",
            ratioFormula: "Proposed Project Amount-Bank/Total Proposed Project Amount×100"
        }
    },
    existing: {
        equity: {
            amount: 0,
            description: "Sum of Existing + Proposed Equity from 'Analysis of the Loan Requirement' table",
            ratioFormula: "Total Project Amount -Equity/Total Project Total Amount×100"
        },
        bank: {
            amount: 0,
            description: "Sum of Existing + Proposed Bank from 'Analysis of the Loan Requirement' table",
            ratioFormula: "Total Project Amount -Bank/Total Project Total Amount×100"
        }
    }
};

const TotalProjectContribution = ({ analysisOfLoanRequirementDetails, applicationId, isSuccessFullResponse }) => {
    const [reload, setReload] = React.useState(1);
    const tableData = useRef(defaultTableData);

    useEffect(() => {
        if (isSuccessFullResponse.get()) {
            let proposedProjectEquity = 0;
            let proposedProjectBank = 0;
            let existingEquity = 0;
            let existingBank = 0;

            const data: any[] = [];

            Object.values(analysisOfLoanRequirementDetails.get()).forEach((item: any) => {
                data.push(item?.data);
            })

            data.forEach((item) => {
                proposedProjectEquity += Number(item?.proposedEquality || 0);
                proposedProjectBank += Number(item?.proposedBank || 0);
                existingEquity += Number(item?.existingEquality || 0);
                existingBank += Number(item?.existingBank || 0);
            });

            tableData.current.proposedProject.equity.amount = proposedProjectEquity;
            tableData.current.proposedProject.bank.amount = proposedProjectBank;
            tableData.current.existing.equity.amount = existingEquity;
            tableData.current.existing.bank.amount = existingBank;

            if (reload) {
                setReload(prev => prev + 1);
            }

            isSuccessFullResponse.set(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessFullResponse.get()]);

    return (
        <section style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
            <SubSectionHeader title={"Total Project Contribution (%)"} btn={true} btnProps={{
                title: "Genarate Contribution", onClick: () => {
                    if (!isSuccessFullResponse.get()) {
                        isSuccessFullResponse.set(true);
                        setReload(prev => prev + 1);
                    }
                }
            }} />
            <TotalProjectContributionDataTable data={tableData.current} />
        </section>
    )
}

export default React.memo(TotalProjectContribution)