import { Downgraded } from "@hookstate/core";
import { productDetails } from "../configs/mainStore";
import AppConfig from "./AppConfig";

export default class ProductDataProvider {
  applicationData: any;

  public constructor(creditData) {
    this.applicationData = creditData;
  }

  public creditFileDowngrader() {
    try {
      const creditFileData = this.applicationData.attach(Downgraded).get();
      if (creditFileData) {
        if (creditFileData?.formData) {
          return creditFileData?.formData?.creditData;
        } else {
          return creditFileData;
        }
      } else {
        return 0.0;
      }
    } catch (err) {
      return this.applicationData;
    }
  }

  public salaryProportionFactor() {
    try {
      const productMasterData: any = productDetails.attach(Downgraded).get();
      const creditData: any = this.creditFileDowngrader();
      if (!this.applicationData) {
        return 0.0;
      }
      const keyString = `${creditData.type}-${creditData.sector}-${creditData.scheme}`;
      const salaryPrportionAccordingToScheme =
        productMasterData?.productCatalogData["allProductCatalogsWithKeys"][keyString]["SALARY_PROPORTION_FACTOR"];
      return parseFloat(salaryPrportionAccordingToScheme.toString()) / 100.0;
    } catch (err) {
      return 0.0;
    }
  }

  public salaryProportionFactorIncomeBased() {
    try {
      const productMasterData: any = productDetails.attach(Downgraded).get();
      const creditData: any = this.creditFileDowngrader();
      if (!this.applicationData) {
        return 0.0;
      }
      const keyString = `${creditData.type}-${creditData.sector}-${creditData.scheme}`;
      const incomeData = this.applicationData?.formData?.incomeData;
      const _incomeData = incomeData.attach(Downgraded).get();

      const { incomeData: { personal = 1 } = {}, incomeProptionFactors } = AppConfig.config;

      let _incomePortionFactor = incomeProptionFactors?.[keyString];

      _incomePortionFactor = _incomePortionFactor ? _incomePortionFactor : incomeProptionFactors?.["*-*-*"];

      let result = 0.0;

      if (_incomePortionFactor) {
        if (_incomeData.hasOwnProperty("personnelIncome")) {
          const _personnelIncome = _incomeData?.personnelIncome || [];

          for (const item of _personnelIncome) {
            const sourceOfIncome = item.sourceOfIncome;

            if (!sourceOfIncome) {
              continue;
            }

            result += _incomePortionFactor?.[`${personal}.${sourceOfIncome}`] || 0.0;
          }
        }

        return result;
      }

      const salaryPrportionAccordingToScheme =
        productMasterData?.productCatalogData["allProductCatalogsWithKeys"][keyString]["SALARY_PROPORTION_FACTOR"];
      return parseFloat(salaryPrportionAccordingToScheme.toString()) / 100.0;
    } catch (err) {
      return 0.0;
    }
  }

  public salaryProportionFactorValue() {
    try {
      const productMasterData: any = productDetails.attach(Downgraded).get();
      const creditData: any = this.creditFileDowngrader();
      if (!this.applicationData) {
        return 0.0;
      }
      const keyString = `${creditData.type}-${creditData.sector}-${creditData.scheme}`;
      const salaryPrportionAccordingToScheme =
        productMasterData?.productCatalogData["allProductCatalogsWithKeys"][keyString]["SALARY_PROPORTION_FACTOR"];
      return parseFloat(salaryPrportionAccordingToScheme.toString());
    } catch (err) {
      return 0.0;
    }
  }

  public productCode() {
    try {
      const productMasterData: any = productDetails.attach(Downgraded).get();
      const creditData: any = this.creditFileDowngrader();
      if (!this.applicationData) {
        return 0.0;
      }
      const keyString = `${creditData.type}-${creditData.sector}-${creditData.scheme}`;
      const productCodeAccordingToScheme =
        productMasterData?.productCatalogData["allProductCatalogsWithKeys"][keyString]["PRODUCT_CODE"];
      return parseInt(productCodeAccordingToScheme.toString());
    } catch (err) {
      return 0.0;
    }
  }

  public getSchemeName() {
    try {
      const productMasterData: any = productDetails.attach(Downgraded).get();
      const creditData: any = this.creditFileDowngrader();
      if (!this.applicationData) {
        return "";
      }
      for (let index = 0; index < productMasterData?.productSchemes.length; index++) {
        if (productMasterData?.productSchemes[index]?.SCHEME_ID === creditData?.scheme) {
          return productMasterData?.productSchemes[index].TYPE_DESC;
        } else {
          continue;
        }
      }
    } catch (err) {
      return "";
    }
  }
}
