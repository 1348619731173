type InspectionExpensesType = { id: number, name: string }
type InspectionExpensesTypeString = { id: string, name: string }

export const widthDefault = "99.1%";

export const inspectionBusinessLiabilityExpensesCategories: InspectionExpensesType[] = [
    { id: 1, name: "Loan / Lease" },
    { id: 2, name: "Overdraft" },
    { id: -1, name: "No Other Liabilities" },
]

export const inspectionBusinessLiabilityExpensesType: InspectionExpensesType[] = [
    { id: 1, name: "Lease" },
    { id: 2, name: "Loan" },
    // { id: 3, name: "Overdraft" },
];

export const otherDirectCostType: InspectionExpensesTypeString[] = [
    { id: "labourCost", name: "Labour Cost" },
    { id: "electricityPowerCost", name: "Electricity And Power Cost" },
    { id: "otherFixedCost", name: "Other Fixed Cost" },
]
