import { Downgraded, useHookstate } from "@hookstate/core";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { creditData } from "../../../../../configs/mainStore";
import { underwritingPolicyValidations } from "../../../../../services/creditFileApiCall";
import { MainSectionsWrapper } from "../../../MainSectionsWrapper/MainSectionsWrapper";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { ValidationResultContainer, ValidationResults } from "./FraudCheck";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";

export const UnderwritingPolicyCheck = () => {
  const creditFileData: any = useHookstate(creditData);
  const usersData = creditFileData?.attach(Downgraded)?.get();

  const [policyState, setPolicyStatus] = useState([]);
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const applicationId = usersData?.applicationId?.[0];

    if (applicationId) {
      validate(applicationId);
    }
  }, [usersData?.applicationId]);

  const validate = async (applicationId) => {
    setIsLoading(true);
    const response = await underwritingPolicyValidations(applicationId);

    if (response) {
      setPolicyStatus(response);
    }

    const isError = response?.every((result) => result?.code === 1);
    setStatus(isError);
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <Stack alignItems="center" justifyContent={"center"} height={"100px"}>
        <Stack width={"300px"} direction={"row"} spacing={2}>
          <CircularProgressComponent size={24} />
          <Typography color={"gray"}>Underwriting Policy Check...</Typography>
        </Stack>
      </Stack>
    );
  }

  if (!policyState?.length) {
    return <Stack></Stack>;
  }

  return (
    <MainSectionsWrapper
      showSubText={false}
      showStatus={false}
      title="Underwriting Policy Check"
      keyCode={undefined}
      noBtn={true}
      decisionState={status}
    >
      <ValidationResultContainer results={policyState} containerWidth={6}>
        {policyState.map((item: any, index: number) => (
          <Box key={crypto.randomUUID()} display="flex" m={1}>
            <Box>
              {item.code === 2 ? (
                <InfoIcon sx={{ color: "#FF0000" }} />
              ) : (
                <CheckCircleIcon sx={{ color: "#00AB71" }} />
              )}
            </Box>
            <Box ml={2}>
              <Tooltip title={item.result} arrow>
                <Typography>{item.decision}</Typography>
              </Tooltip>
            </Box>
          </Box>
        ))}
      </ValidationResultContainer>
    </MainSectionsWrapper>
  );
};
