import { Downgraded, useState } from "@hookstate/core";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DoneIcon from "@mui/icons-material/Done";
import { Grid } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { microBankGuaranteeDetails, microChequeReturnsDetails, microCurrentAccountDetails, microLoanDetailsCribDetails } from "../../../../../../../../configs/mainStore";
import { getAppraisalData, getBankGuaranteeDetails, getChequeReturnsDetails, getCurrentAccountDetails, getLoanCribDetails, saveAppraisalData, saveBankGuaranteeDetails, saveChequeReturnsDetails, saveCurrentAccountDetails, saveLoanCribDetails } from "../../../../../../../../services/creditFileApiCall";
import { disableAccess } from "../../../../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { getBankListWithoutRDB } from "../../../../../../../../utility/helpers/getBankList";
import { isPermitted } from "../../../../../../../../utility/helpers/getIshavePermission";
import { responseSuccessCode } from "../../../../../../../../utility/other";
import { ButtonComponent } from "../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../../../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../../../../GlobalToast";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";
import ApplicationHeader from "../../../../Application/ApplicationHeader";
import BankSelect from "../../Helpers/BankSelect/BankSelect";
import BankGuaranteeDetails from "./subComponents/BankGuaranteeDetails";
import ChequeReturnDetails from "./subComponents/ChequeReturnDetails";
import CurrentAccountDetails from "./subComponents/CurrentAccountDetails";
import LoanDetailsCrib from "./subComponents/LoanDetailsCrib";

const DirectOtherBankLiabilityAnalysis = ({ data, applicationId, isMainContactDetails = true, mainTabData = { isAppraisal: false }, isRawVersion, innerComponentData, tabsToEdit }) => {
  const [loanRequirementList, setLoanRequirementList] = React.useState<any>([]);
  const isLoading = useState(false);
  const isSaving = useState(false);

  //New Commercial Variables
  const chequeReturnDetails = useState(microChequeReturnsDetails);
  const loanDetailsCribDetails = useState(microLoanDetailsCribDetails);
  const currentAccountDetails = useState(microCurrentAccountDetails);
  const bankGuaranteeDetails = useState(microBankGuaranteeDetails);

  const [chequeReturnBank, setChequeReturnBank]: any = React.useState("");
  const [loanCribBank, setLoanCribBank]: any = React.useState("");
  const [accountDetailsBank, setAccountDetailsBank]: any = React.useState("");
  const [guaranteeDetailsBank, setGuaranteeDetailsBank]: any = React.useState("");
  const [isChequeReturnsLoading, setIsChequeReturnsLoading] = React.useState(false);
  const [isLoanDetailsCribLoading, setIsLoanDetailsCribLoading] = React.useState(false);
  const [isCurrentAccountsLoading, setIsCurrentAccountsLoading] = React.useState(false);
  const [isBankGuaranteeLoading, setIsBankGuaranteeLoading] = React.useState(false);

  const bankChangeHandler = (value, type) => {
    if (type == "chequeReturn") {
      setChequeReturnBank(value);
    } else if (type == "loanCribBank") {
      setLoanCribBank(value);
    } else if (type == "accountDetailsBank") {
      setAccountDetailsBank(value);
    } else if (type == "guaranteeDetailsBank") {
      setGuaranteeDetailsBank(value);
    }
  };

  const getSelectedBank = (bankId) => {
    const bankList: any = getBankListWithoutRDB();
    const objectKeys = Object.keys(bankList || {});
    let bank = {};
    objectKeys.forEach((key) => {
      try {
        if (bankList[key] != undefined && bankList[key] != null && bankList[key]?.id == bankId) {
          bank = bankList[key];
        }
      } catch (error) {
        bank = {};
      }
    });

    return bank;
  };

  const addNewRequirementHandler = (type: any = "") => {
    if (type == "chequeReturn") {
      if (!chequeReturnBank) {
        addToaster({
          status: "error",
          title: "Empty",
          message: "Please select a bank",
        });
      } else {
        let existingRowItems: any = chequeReturnDetails.attach(Downgraded).get();
        const dataKeys = Object.keys(existingRowItems);
        const selectedBank: any = getSelectedBank(chequeReturnBank);
        let lastObjectIndex: any = dataKeys?.length > 0 ? dataKeys[dataKeys.length - 1] : 0;

        let defaultItemObj = {
          bank: selectedBank.name ?? "",
          reason: "",
          numberOfCheques: 0,
          amount: 0,
        };

        if (dataKeys?.length < 1) {
          existingRowItems[lastObjectIndex] = defaultItemObj;
        } else {
          existingRowItems[lastObjectIndex + 1] = defaultItemObj;
        }

        chequeReturnDetails.set(existingRowItems);
      }
    } else if (type == "loanCribBank") {
      if (!loanCribBank) {
        addToaster({
          status: "error",
          title: "Empty",
          message: "Please select a bank",
        });
      } else {
        let existingRowItems: any = loanDetailsCribDetails.attach(Downgraded).get();
        const dataKeys = Object.keys(existingRowItems);
        const selectedBank: any = getSelectedBank(loanCribBank);
        let lastObjectIndex: any = dataKeys?.length > 0 ? dataKeys[dataKeys.length - 1] : 0;

        let defaultItemObj = {
          business: "",
          nameoOfInstitution: selectedBank.name ?? "",
          type: 1,
          grantedAmount: 0,
          securities: "",
          reasonOfDelay: "",
          installment: 0,
          outstanding: 0,
        };

        if (dataKeys?.length < 1) {
          existingRowItems[lastObjectIndex] = defaultItemObj;
        } else {
          existingRowItems[lastObjectIndex + 1] = defaultItemObj;
        }

        loanDetailsCribDetails.set(existingRowItems);
      }
    } else if (type == "accountDetailsBank") {
      if (!accountDetailsBank) {
        addToaster({
          status: "error",
          title: "Empty",
          message: "Please select a bank",
        });
      } else {
        let existingRowItems: any = currentAccountDetails.attach(Downgraded).get();
        const dataKeys = Object.keys(existingRowItems);
        const selectedBank: any = getSelectedBank(accountDetailsBank);
        let lastObjectIndex: any = dataKeys?.length > 0 ? dataKeys[dataKeys.length - 1] : 0;

        let defaultItemObj = {
          bank: selectedBank.name ?? "",
          accountNumber: "",
          debit: 0,
          credit: 0,
          balanceOfMonthEnd: 0,
        };

        if (dataKeys?.length < 1) {
          existingRowItems[lastObjectIndex] = defaultItemObj;
        } else {
          existingRowItems[lastObjectIndex + 1] = defaultItemObj;
        }

        currentAccountDetails.set(existingRowItems);
      }
    } else if (type == "guaranteeDetailsBank") {
      if (!guaranteeDetailsBank) {
        addToaster({
          status: "error",
          title: "Empty",
          message: "Please select a bank",
        });
      } else {
        let existingRowItems: any = bankGuaranteeDetails.attach(Downgraded).get();
        const dataKeys = Object.keys(existingRowItems);
        const selectedBank: any = getSelectedBank(guaranteeDetailsBank);
        let lastObjectIndex: any = dataKeys?.length > 0 ? dataKeys[dataKeys.length - 1] : 0;

        let defaultItemObj = {
          financialInstitute: selectedBank.name ?? "",
          amount: 0,
          issuedDate: "",
          timePeriodMonths: 0,
          issuedCompany: "",
        };

        if (dataKeys?.length < 1) {
          existingRowItems[lastObjectIndex] = defaultItemObj;
        } else {
          existingRowItems[lastObjectIndex + 1] = defaultItemObj;
        }

        bankGuaranteeDetails.set(existingRowItems);
      }
    }
  };

  const getChequeReturnsData = async () => {
    setIsChequeReturnsLoading(true);
    const params = {
      applicationId: applicationId,
    };

    try {
      let response = await getChequeReturnsDetails(params);
      chequeReturnDetails?.set(response ?? {});
      setIsChequeReturnsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const saveChequeReturnsData = async (actionType = "") => {
    setIsChequeReturnsLoading(true);
    let existingRowItems: any = chequeReturnDetails.attach(Downgraded).get();
    const dataKeys = Object.keys(existingRowItems);
    let errors: string[] = [];

    if (dataKeys.length < 1) {
      addToaster({
        status: "error",
        title: "Empty Cheque Returns!",
        message: `Please add cheque returns before saving!`,
      });
      setIsChequeReturnsLoading(false);
      return;
    }

    for (const key of dataKeys) {
      let item = existingRowItems[key];

      if (!item.bank) {
        errors.push(`Bank is required.`);
      }

      if (!item.numberOfCheques || item.numberOfCheques <= 0) {
        errors.push(`Number of cheques is required.`);
      }

      if (!item.amount || item.amount <= 0) {
        errors.push(`Amount is required.`);
      }
    }

    if (errors.length > 0) {
      addToaster({
        status: "error",
        title: "Validation Errors",
        message: errors.map(error => `${error}`).join("\n"),
      });
      setIsChequeReturnsLoading(false);
      return;
    }

    try {
      let obj: any = {
        applicationId: parseInt(applicationId),
        chequeReturnsData: existingRowItems,
      };

      let response = await saveChequeReturnsDetails(obj);

      if (response?.status == 1) {
        let responseObj = JSON.parse(response?.data?.chequeReturns);
        chequeReturnDetails?.set(responseObj ?? {});

        addToaster({
          status: "success",
          title: "Saved!",
          message: "Cheque Returns Data Saved successfully!",
        });
      }
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: `Something went wrong while saving the data!`,
      });
    } finally {
      setIsChequeReturnsLoading(false);
    }
  };



  const getLoanDetailsCribData = async () => {
    setIsLoanDetailsCribLoading(true);
    const params = {
      applicationId: applicationId,
    };

    try {
      let response = await getLoanCribDetails(params);
      loanDetailsCribDetails?.set(response ?? {});
      setIsLoanDetailsCribLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const saveLoanDetailsCribData = async (actionType = "") => {
    setIsLoanDetailsCribLoading(true);
    let existingRowItems: any = loanDetailsCribDetails.attach(Downgraded).get();
    const dataKeys = Object.keys(existingRowItems);
    let errors: string[] = [];
      if (dataKeys.length < 1) {
        addToaster({
          status: "error",
          title: "Empty Loan CRIB Details!",
          message: `Please add crib details before saving!`,
        });
        setIsLoanDetailsCribLoading(false);
        return;
      }
    
    for (const key of dataKeys) {
      let item = existingRowItems[key];

      if (!item.type) {
        errors.push(`Type is required.`);
      }

      if (!item.grantedAmount || item.grantedAmount <= 0) {
        errors.push(`Granted Amount is required.`);
      }

      if (!item.securities || item.securities <= 0) {
        errors.push(`Securities is required.`);
      }
      if (!item.installment || item.installment <= 0) {
        errors.push(`Installment is required.`);
      }
      if (!item.outstanding || item.outstanding <= 0) {
        errors.push(`Outstanding is required.`);
      }
    }

    if (errors.length > 0) {
      addToaster({
        status: "error",
        title: "Validation Errors",
        message: errors.map(error => `${error}`).join("\n"),
      });
      setIsLoanDetailsCribLoading(false);
      return;
    }


    try {
      let obj: any = {
        applicationId: parseInt(applicationId),
        cribDetailsData: existingRowItems,
      };

      let response = await saveLoanCribDetails(obj);

      if (response?.status == 1) {
        let responseObj = JSON.parse(response?.data?.cribDetails);

        loanDetailsCribDetails?.set(responseObj ?? {});
        addToaster({
          status: "success",
          title: "Saved!",
          message: "Loan Details related to Business / Project from CRIB Data Saved successfully !",
        });
      }
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: `Something wrong with data saving!`,
      });
    } finally {
      setIsLoanDetailsCribLoading(false);
    }
  };

  const getCurrentAccountsData = async () => {
    setIsCurrentAccountsLoading(true);
    const params = {
      applicationId: applicationId,
    };

    try {
      let response = await getCurrentAccountDetails(params);
      currentAccountDetails?.set(response ?? {});
      setIsCurrentAccountsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const saveCurrentAccountsData = async (actionType = "") => {
    setIsCurrentAccountsLoading(true);
    let existingRowItems: any = currentAccountDetails.attach(Downgraded).get();
    const dataKeys = Object.keys(existingRowItems);
    let errors: string[] = [];

    if (dataKeys.length < 1) {
      addToaster({
        status: "error",
        title: "Empty Current Account Details!",
        message: `Please add current account details before saving!`,
      });
      setIsCurrentAccountsLoading(false);
      return;
    }

    for (const key of dataKeys) {
      let item = existingRowItems[key];

      if (!item.accountNumber) {
        errors.push(`Account Number is required.`);
      }

      if (!item.debit || item.debit <= 0) {
        errors.push(`Debit is required.`);
      }

      if (!item.credit || item.credit <= 0) {
        errors.push(`Credit is required.`);
      }
      if (!item.balanceOfMonthEnd || item.balanceOfMonthEnd <= 0) {
        errors.push(`Balance of Month End is required.`);
      }
    }

    if (errors.length > 0) {
      addToaster({
        status: "error",
        title: "Validation Errors",
        message: errors.map(error => `${error}`).join("\n"),
      });
      setIsCurrentAccountsLoading(false);
      return;
    }

    try {
      let obj: any = {
        applicationId: parseInt(applicationId),
        currentAccountsData: existingRowItems,
      };

      let response = await saveCurrentAccountDetails(obj);

      if (response?.status == 1) {
        let responseObj = JSON.parse(response?.data?.currentAccounts);

        currentAccountDetails?.set(responseObj ?? {});
        addToaster({
          status: "success",
          title: "Saved!",
          message: "Current Account Details Data Saved successfully !",
        });
      }
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: `Something wrong with data saving!`,
      });
    } finally {
      setIsCurrentAccountsLoading(false);
    }
  };

  const getBankGuaranteeData = async () => {
    setIsBankGuaranteeLoading(true);
    const params = {
      applicationId: applicationId,
    };

    try {
      let response = await getBankGuaranteeDetails(params);
      bankGuaranteeDetails?.set(response ?? {});
      setIsBankGuaranteeLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const saveBankGuaranteeData = async (actionType = "") => {
    setIsBankGuaranteeLoading(true);
    let existingRowItems: any = bankGuaranteeDetails.attach(Downgraded).get();
    const dataKeys = Object.keys(existingRowItems);
    let errors: string[] = [];

    if (dataKeys.length < 1) {
      addToaster({
        status: "error",
        title: "Empty Bank Guarantee Details!",
        message: `Please add bank guarantee details before saving!`,
      });
      setIsBankGuaranteeLoading(false);
      return;
    }

    for (const key of dataKeys) {
      let item = existingRowItems[key];

      if (!item.amount) {
        errors.push(`Amount is required.`);
      }

      if (!item.issuedDate || item.issuedDate <= 0) {
        errors.push(`Issued Date is required.`);
      }

      if (!item.timePeriodMonths || item.timePeriodMonths <= 0) {
        errors.push(`Time Period Month is required.`);
      }
      if (!item.issuedCompany || item.issuedCompany <= 0) {
        errors.push(`Issued Company is required.`);
      }
    }

    if (errors.length > 0) {
      addToaster({
        status: "error",
        title: "Validation Errors",
        message: errors.map(error => `${error}`).join("\n"),
      });
      setIsBankGuaranteeLoading(false);
      return;
    }

    try {
      let obj: any = {
        applicationId: parseInt(applicationId),
        bankGuaranteesData: existingRowItems,
      };

      let response = await saveBankGuaranteeDetails(obj);

      if (response?.status == 1) {
        let responseObj = JSON.parse(response?.data?.bankGuarantees);

        bankGuaranteeDetails?.set(responseObj ?? {});
        addToaster({
          status: "success",
          title: "Saved!",
          message: "Bank Guarantee Details Data Saved successfully !",
        });
      }
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: `Something wrong with data saving!`,
      });
    } finally {
      setIsBankGuaranteeLoading(false);
    }
  };

  const onDeleteRequirementItemRow = useCallback(
    (key: any, type = "") => {
      closeGlobalModal();
      if (type == "chequeReturn") {
        let existingRowItems: any = chequeReturnDetails.attach(Downgraded).get();
        const dataKeys = Object.keys(existingRowItems);
        const newRowItems: any = {};

        dataKeys.forEach((element) => {
          if (element != key) {
            newRowItems[element] = existingRowItems[element];
          }
        });
        chequeReturnDetails.set(newRowItems);
        saveChequeReturnsData("delete");
      } else if (type == "loanCribBank") {
        let existingRowItems: any = loanDetailsCribDetails.attach(Downgraded).get();
        const dataKeys = Object.keys(existingRowItems);
        const newRowItems: any = {};

        dataKeys.forEach((element) => {
          if (element != key) {
            newRowItems[element] = existingRowItems[element];
          }
        });
        loanDetailsCribDetails.set(newRowItems);
        saveLoanDetailsCribData("delete");
      } else if (type == "accountDetailsBank") {
        let existingRowItems: any = currentAccountDetails.attach(Downgraded).get();
        const dataKeys = Object.keys(existingRowItems);
        const newRowItems: any = {};

        dataKeys.forEach((element) => {
          if (element != key) {
            newRowItems[element] = existingRowItems[element];
          }
        });
        currentAccountDetails.set(newRowItems);
        saveCurrentAccountsData("delete");
      } else if (type == "guaranteeDetailsBank") {
        let existingRowItems: any = bankGuaranteeDetails.attach(Downgraded).get();
        const dataKeys = Object.keys(existingRowItems);
        const newRowItems: any = {};

        dataKeys.forEach((element) => {
          if (element != key) {
            newRowItems[element] = existingRowItems[element];
          }
        });
        bankGuaranteeDetails.set(newRowItems);
        saveBankGuaranteeData("delete");
      }
    },
    [chequeReturnDetails, loanDetailsCribDetails, currentAccountDetails, bankGuaranteeDetails]
  );

  const onSaveData = async (tempReqList) => {
    isSaving.set(true);
    const res: any = await saveAppraisalData({
      section: "analysis_loan_requirement",
      applicationId: applicationId,
      data: tempReqList,
    });

    if (res?.status == responseSuccessCode) {
      addToaster({
        status: "success",
        title: "Success",
        message: res?.data?.msg,
      });
    } else {
      addToaster({
        status: "error",
        title: "Error",
        message: res?.data?.msg,
      });
    }
    isSaving.set(false);
  };

  useEffect(() => {
    (async function () {
      isLoading.set(true);
      const params = {
        applicationId: applicationId,
        section: "analysis_loan_requirement",
      };
      const data: any = await getAppraisalData(params);

      if (data && Object.keys(data).length > 0) {
        setLoanRequirementList(data);
      }
      isLoading.set(false);
    })();

    getChequeReturnsData();
    getLoanDetailsCribData();
    getCurrentAccountsData();
    getBankGuaranteeData();
  }, []);

  const checkPrivilegeStatus = disableAccess({
    isTabInEditString: tabsToEdit.includes(innerComponentData?.id),
  });

  return (
    <div className={`mainSectionWrapper ${isMainContactDetails ? "full-width full-height" : "full-width"}`}>
      {isMainContactDetails && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={"Direct Other Bank Liability Analysis"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={true} />}

      {/* Check Returns */}
      <Grid container pt={2} pr={1.5} pb={2} pl={1.5}>
        <Grid item xs={12} p={1} mb={2} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
          Cheque Returns
        </Grid>
        {isChequeReturnsLoading ? (
          <CircularProgressComponent sx={{ left: "50%", top: "50%" }} />
        ) : (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  <InputGrid visibility={1} variant="fullWidth">
                    <BankSelect callBackOnChange={bankChangeHandler} selectedValue={chequeReturnBank} type={"chequeReturn"} />
                  </InputGrid>
                </Grid>
                <Grid item xs={2}>
                  <ButtonComponent disabled={isPermitted()} iconbtn onClick={() => addNewRequirementHandler("chequeReturn")}>
                    <AddCircleOutlineIcon />
                  </ButtonComponent>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
              <ChequeReturnDetails chequeReturnData={chequeReturnDetails} callBackOnDelete={() => onDeleteRequirementItemRow} />
            </Grid>
            <Grid item xs={12} mt={2}>
              <ButtonComponent disabled={isPermitted()} startIcon={<DoneIcon />} title="SUBMIT" variant="contained" onClick={() => saveChequeReturnsData("submit")} loadingbtn={true} color="info" />
            </Grid>
          </>
        )}
      </Grid>

      {/* Loan Details related to Business / Project from CRIB */}
      <Grid container pt={2} pr={1.5} pb={2} pl={1.5} mt={2.5}>
        <Grid item xs={12} p={1} mb={2} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
          Loan Details related to Business / Project from CRIB
        </Grid>
        {isLoanDetailsCribLoading ? (
          <CircularProgressComponent sx={{ left: "50%", top: "50%" }} />
        ) : (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  <InputGrid visibility={1} variant="fullWidth">
                    <BankSelect callBackOnChange={bankChangeHandler} selectedValue={loanCribBank} type={"loanCribBank"} />
                  </InputGrid>
                </Grid>
                <Grid item xs={2}>
                  <ButtonComponent disabled={isPermitted()} iconbtn onClick={() => addNewRequirementHandler("loanCribBank")}>
                    <AddCircleOutlineIcon />
                  </ButtonComponent>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
              <LoanDetailsCrib creditData={data} applicationId={applicationId} loanDetailsCribData={loanDetailsCribDetails} callBackOnDelete={() => onDeleteRequirementItemRow} />
            </Grid>
            <Grid item xs={12} mt={2}>
              <ButtonComponent disabled={isPermitted()} startIcon={<DoneIcon />} title="SUBMIT" variant="contained" onClick={() => saveLoanDetailsCribData("submit")} loadingbtn={true} color="info" />
            </Grid>
          </>
        )}
      </Grid>
      {/* Current Account Details */}
      <Grid container pt={2} pr={1.5} pb={2} pl={1.5} mt={2.5}>
        <Grid item xs={12} p={1} mb={2} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
          Current Account Details
        </Grid>
        {isCurrentAccountsLoading ? (
          <CircularProgressComponent sx={{ left: "50%", top: "50%" }} />
        ) : (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  <InputGrid visibility={1} variant="fullWidth">
                    <BankSelect callBackOnChange={bankChangeHandler} selectedValue={accountDetailsBank} type={"accountDetailsBank"} />
                  </InputGrid>
                </Grid>
                <Grid item xs={2}>
                  <ButtonComponent disabled={isPermitted()} iconbtn onClick={() => addNewRequirementHandler("accountDetailsBank")}>
                    <AddCircleOutlineIcon />
                  </ButtonComponent>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
              <CurrentAccountDetails creditData={data} applicationId={applicationId} currentAccountData={currentAccountDetails} callBackOnDelete={() => onDeleteRequirementItemRow} />
            </Grid>
            <Grid item xs={12} mt={2}>
              <ButtonComponent startIcon={<DoneIcon />} disabled={isPermitted()} title="SUBMIT" variant="contained" onClick={() => saveCurrentAccountsData("submit")} loadingbtn={true} color="info" />
            </Grid>
          </>
        )}
      </Grid>

      {/* Bank Guarantee Details */}
      <Grid container pt={2} pr={1.5} pb={2} pl={1.5} mt={2.5}>
        <Grid item xs={12} p={1} mb={2} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
          Bank Guarantee Details
        </Grid>
        {isBankGuaranteeLoading ? (
          <CircularProgressComponent sx={{ left: "50%", top: "50%" }} />
        ) : (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  <InputGrid visibility={1} variant="fullWidth">
                    <BankSelect callBackOnChange={bankChangeHandler} selectedValue={guaranteeDetailsBank} type={"guaranteeDetailsBank"} />
                  </InputGrid>
                </Grid>
                <Grid item xs={2}>
                  <ButtonComponent disabled={isPermitted()} iconbtn onClick={() => addNewRequirementHandler("guaranteeDetailsBank")}>
                    <AddCircleOutlineIcon />
                  </ButtonComponent>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={2}>
              <BankGuaranteeDetails creditData={data} applicationId={applicationId} bankGuaranteeData={bankGuaranteeDetails} callBackOnDelete={() => onDeleteRequirementItemRow} />
            </Grid>
            <Grid item xs={12} mt={2}>
              <ButtonComponent disabled={isPermitted()} startIcon={<DoneIcon />} title="SUBMIT" variant="contained" onClick={() => saveBankGuaranteeData("submit")} loadingbtn={true} color="info" />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default DirectOtherBankLiabilityAnalysis;
