import { Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { ReactNode } from "react";

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey}`,
    '&.header': {
        textAlign: 'center',

    },
    '&.subheader': {
        textAlign: 'center',
        padding: '10px',
    },
    '&.cell': {
        textAlign: 'center',
    }
}));

interface StyledTableCellProps {
    children?: ReactNode;
    [key: string]: any;
    isTotal?: boolean;
}

const StyledTableCell: React.FC<StyledTableCellProps> = ({ children, isTotal, ...props }) => (
    <CustomTableCell {...props}>
        <Typography variant="subtitle1" color={"gray"} sx={isTotal ? { color: '#555555', fontWeight: 'bold' } : {}}>{children}</Typography>
    </CustomTableCell>
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
}));

const calculateRatio = (amount, total) => {
    return total ? ((amount / total) * 100).toFixed(2) : 0;
};

const TotalProjectContributionDataTable = ({ data }) => {
    const proposedTotal = data.proposedProject.equity.amount + data.proposedProject.bank.amount;
    const existingTotal = data.existing.equity.amount + data.existing.bank.amount;

    return (
        <div style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>

            <TableContainer component={Paper} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ borderRight: "1px solid gray", borderBottom: "none" }} />
                            <StyledTableCell colSpan={2} className="subheader" style={{ borderRight: "1px solid gray", borderBottom: "1px solid gray" }}>
                                Proposed Project
                            </StyledTableCell>
                            <StyledTableCell colSpan={2} className="subheader" style={{ borderBottom: "1px solid gray" }}>
                                Total Project
                            </StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell className="header" style={{ borderRight: "1px solid gray" }} />
                            <StyledTableCell className="header" style={{ borderRight: "1px solid gray" }}>Amount</StyledTableCell>
                            <StyledTableCell className="header" style={{ borderRight: "1px solid gray" }}>Ratio (%)</StyledTableCell>
                            <StyledTableCell className="header" style={{ borderRight: "1px solid gray" }}>Amount</StyledTableCell>
                            <StyledTableCell className="header">Ratio (%)</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* Equity Row */}
                        <StyledTableRow>
                            <StyledTableCell style={{ borderRight: "1px solid gray" }}>Equity</StyledTableCell>
                            <StyledTableCell className="cell" style={{ borderRight: "1px solid gray" }}>
                                <Tooltip title={data.proposedProject.equity.description}>
                                    <span>{data.proposedProject.equity.amount.toLocaleString()}</span>
                                </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell className="cell" style={{ borderRight: "1px solid gray" }}>
                                <Tooltip title={data.proposedProject.equity.ratioFormula}>
                                    <span>{calculateRatio(data.proposedProject.equity.amount, proposedTotal)}%</span>
                                </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell className="cell" style={{ borderRight: "1px solid gray" }}>
                                <Tooltip title={data.existing.equity.description}>
                                    <span>{data.existing.equity.amount.toLocaleString()}</span>
                                </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell className="cell">
                                <Tooltip title={data.existing.equity.ratioFormula}>
                                    <span>{calculateRatio(data.existing.equity.amount, existingTotal)}%</span>
                                </Tooltip>
                            </StyledTableCell>
                        </StyledTableRow>
                        {/* Bank Row */}
                        <StyledTableRow>
                            <StyledTableCell style={{ borderRight: "1px solid gray" }}>Bank</StyledTableCell>
                            <StyledTableCell className="cell" style={{ borderRight: "1px solid gray" }}>
                                <Tooltip title={data.proposedProject.bank.description}>
                                    <span>{data.proposedProject.bank.amount.toLocaleString()}</span>
                                </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell className="cell" style={{ borderRight: "1px solid gray" }}>
                                <Tooltip title={data.proposedProject.bank.ratioFormula}>
                                    <span>{calculateRatio(data.proposedProject.bank.amount, proposedTotal)}%</span>
                                </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell className="cell" style={{ borderRight: "1px solid gray" }}>
                                <Tooltip title={data.existing.bank.description}>
                                    <span>{data.existing.bank.amount.toLocaleString()}</span>
                                </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell className="cell" >
                                <Tooltip title={data.existing.bank.ratioFormula}>
                                    <span>{calculateRatio(data.existing.bank.amount, existingTotal)}%</span>
                                </Tooltip>
                            </StyledTableCell>
                        </StyledTableRow>
                        {/* Total Row */}
                        <StyledTableRow>
                            <StyledTableCell isTotal={true} style={{ borderRight: "1px solid gray" }}>
                                Total
                            </StyledTableCell>
                            <StyledTableCell isTotal={true} className="cell" style={{ borderRight: "1px solid gray" }}>{proposedTotal.toLocaleString()}</StyledTableCell>
                            <StyledTableCell isTotal={true} className="cell" style={{ borderRight: "1px solid gray" }}>{
                                calculateRatio(data.proposedProject.equity.amount + data.proposedProject.bank.amount, proposedTotal)
                            }%</StyledTableCell>
                            <StyledTableCell isTotal={true} className="cell" style={{ borderRight: "1px solid gray" }}>{existingTotal.toLocaleString()}</StyledTableCell>
                            <StyledTableCell isTotal={true} className="cell">{
                                calculateRatio(data.existing.equity.amount + data.existing.bank.amount, existingTotal)
                            }%</StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    )
}

export default TotalProjectContributionDataTable