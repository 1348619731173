import AddCircleIcon from "@mui/icons-material/AddCircle";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { Box, Grid } from "@mui/material";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";
import Dropzone from "react-dropzone";
import { FILE_UPLOAD_COMPONENT_STATUS } from "../../../../../../../../../configs/constants/documentUploadKeys";
import { uploadInspectionFile } from "../../../../../../../../../services/creditFileApiCall";
import { openGlobalModal } from "../../../../../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../../../../../GlobalToast";
import { LinearProgressWithLabel } from "../../../../../../../LinearWithValueLabel/LinearWithValueLabel";
import { CircularProgressComponent } from "../../../../../../../ProgressComponent/ProgressComponent";
import { documentStatusColors } from "../../../../../../../WorkflowComponents/utils";

interface InspectionDocumentUploadComponentProps {
  fileDetails: any;
  uploadDataObject: any;
  isPreviewHave?: boolean;
  status?: any;
  permissionToUploadDocs?: Boolean;
  documentComponentId?: any;
  reloadComponent?: any;
  callBackFunction?: any;
  onNext?: any;
}

export const InspectionDocumentUploadComponent = forwardRef<any, InspectionDocumentUploadComponentProps>(
  (
    {
      fileDetails,
      uploadDataObject,
      isPreviewHave,
      documentComponentId,
      reloadComponent = false,
      onNext,
    }: InspectionDocumentUploadComponentProps,
    ref: any
  ) => {
    const currentRef = fileDetails.ref;

    const [imgStatus, setImgStatus] = React.useState(FILE_UPLOAD_COMPONENT_STATUS.noFile);
    const [file, setFile] = React.useState<any>();
    const [fileUploadProgress, setFileUploadProgress] = React.useState(0);
    const [isUploading, setIsUploading] = React.useState(false);
    const [previewLoading, setPreviewLoading] = React.useState(false);
    const [closeClicked, setCloseClicked] = React.useState(false);
    const [statusColor, setStatusColor] = React.useState<any>("");
    const [previewFile, setPreviewFile]: any = React.useState({});
    const [onNextDisable, setOnNextDisable]: any = React.useState(false);

    const isRevokePrivilages = "false";

    const submitImgChange = () => {
      setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.submitted);
      setFile(null);
      setStatusColor(
        documentStatusColors[0]?.color ? documentStatusColors[0]?.color : documentStatusColors["default"]?.color
      );
    };

    useEffect(() => {
      if (isPreviewHave && !closeClicked) {
        submitImgChange();
      }
    });

    useEffect(() => {
      removeSelectedImg();
    }, [fileDetails]);

    useImperativeHandle(currentRef, () => ({
      uploadSingleImg() {
        return uploadDocumentData().then((res) => {
          return res;
        });
      },
    }));

    const onDrop = useCallback((acceptedFiles) => {
      setFileUploadProgress(0);
      if (acceptedFiles.length === 1) {
        setFile(
          Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0]),
          })
        );

        setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.pending);
      } else {
        setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.noFile);
      }
    }, []);

    const removeSelectedImg = () => {
      setFile(null);
      setFileUploadProgress(0);

      if (isPreviewHave) {
        submitImgChange();
      } else {
        setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.noFile);
      }
    };

    const getNextDoc = () => {
      const currentFile = previewFile;
      const file = onNext.find((obj) => obj.doc_index === currentFile.doc_index + 1);

      if (file) {
        setPreviewFile(file);
      } else {
        setOnNextDisable(true);
      }
    };

    const previewClicked = async () => {
      setPreviewLoading(true);

      openGlobalModal({
        modalSize: "lg",
        title: "",
        bodyId: "inspection-document-data-preview",
        close: true,
        modalParams: {
          documentComponentId: documentComponentId,
          isRevokePrivilages: isRevokePrivilages,
          callBackOnAction: () => handleCallBackAction(),
          fileDetails: previewFile,
          onNext: getNextDoc,
          onNextDisable,
        },
      });

      setPreviewLoading(false);
    };

    useEffect(() => {
      if (Object.keys(previewFile).length > 0) {
        previewClicked();
      }
    }, [previewFile]);

    const handleCallBackAction = () => {
      reloadComponent(true);
    };

    const uploadDocumentData = () => {
      setIsUploading(true);

      if (file) {
        return uploadInspectionFile(uploadDataObject, file, setFileUploadProgress).then((data: any) => {
          if (data?.status === -1) {
            setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.noFile);
            setFile(null);
            addToaster({
              status: "error",
              title: "Error",
              message: "File upload error!",
            });
          } else {
            setIsUploading(false);
            submitImgChange();
            addToaster({
              status: "success",
              title: "Success",
              message: "Document uploaded successfully!",
            });
            reloadComponent(true);
          }
          setIsUploading(false);
          return true;
        });
      } else {
        if (isPreviewHave) {
          submitImgChange();
        } else {
          setImgStatus(FILE_UPLOAD_COMPONENT_STATUS.noFile);
          setFile(null);
        }
        setIsUploading(false);
        return Promise.resolve(true);
      }
    };

    const renderPlaceHolderProps = {
      imgStatus, fileDetails,
      fn: { onDrop, removeSelectedImg, previewClicked },
      state: {
        isUploading, previewLoading, fileUploadProgress, setCloseClicked, setPreviewFile
      },
      statusColor,
    }

    return (
      <>
        <Grid item xs={12} sm={2} md={2} p={1} style={{ maxWidth: "100%" }}>
          <Box
            style={
              imgStatus === FILE_UPLOAD_COMPONENT_STATUS.noFile
                ? {
                  borderRadius: "5px",
                  backgroundColor: "#a2a2a22e",
                  margin: "auto", // border: `${isRequiredFilled ? '1px solid #4386be99' : '3px solid red'}`,
                  border: `1px solid #a2a2a22e`,
                  textAlign: "center",
                }
                : imgStatus === FILE_UPLOAD_COMPONENT_STATUS.pending
                  ? {
                    borderRadius: "5px",
                    margin: "auto",
                    backgroundImage: `url(${file?.preview})`,
                    backgroundSize: "cover",
                  }
                  : imgStatus === FILE_UPLOAD_COMPONENT_STATUS.submitted
                    ? {
                      borderRadius: "5px",
                      backgroundColor: `${statusColor}3d`,
                      margin: "auto",
                      border: `1px solid ${statusColor}`,
                      textAlign: "center",
                    }
                    : {}
            }
          >
            <RenderPlaceholders {...renderPlaceHolderProps} />
          </Box>
        </Grid>
      </>
    );
  }
);


const RenderPlaceholders = (
  {
    imgStatus, fileDetails,
    fn: { onDrop, removeSelectedImg, previewClicked },
    state: { isUploading, previewLoading, fileUploadProgress, setCloseClicked, setPreviewFile
    },
    statusColor,
  }
) => {

  const errorMessage = (fileRejections: any) => {
    if (fileRejections.length === 1) {
      fileRejections[0].errors.map((item) => {
        addToaster({
          status: "error",
          title: "File Error",
          message: item?.message,
        });
      });
    }
  };

  if (imgStatus === FILE_UPLOAD_COMPONENT_STATUS.noFile) {
    return (
      <Dropzone
        accept={fileDetails?.allowedFormats}
        maxFiles={1}
        multiple={false}
        maxSize={fileDetails?.maxFileSize}
        onDropRejected={(fileRejections) => errorMessage(fileRejections)}
        onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
      >
        {({ getRootProps, getInputProps }) => (
          <Box {...getRootProps()}>
            <Grid
              style={{ height: "125px" }}
              container
              item
              direction="row"
              sm={12}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item sm={12}>
                <input {...getInputProps()} />
                <AddCircleIcon
                  style={{
                    color: "#a2a2a2",
                    fontSize: 40,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Dropzone>
    );
  } else if (imgStatus === FILE_UPLOAD_COMPONENT_STATUS.pending) {
    return (
      <>
        {isUploading ? (
          <Grid
            style={{
              backgroundColor: "#dde9f3",
              borderRadius: "5px",

              height: "125px",
              border: "1px solid #4386be99",
            }}
            container
            item
            direction="row"
            sm={12}
            alignItems="end"
            justifyContent="center"
          >
            <Grid item sm={11} sx={{ mb: "10px" }}>
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <LinearProgressWithLabel value={fileUploadProgress} />
            </Grid>
          </Grid>
        ) : (
          <Grid
            style={{
              backgroundColor: "#dde9f3ed",
              borderRadius: "5px",

              height: "125px",
              border: "1px solid #4386be99",
            }}
            container
            item
            direction="row"
            sm={12}
            alignItems="start"
            justifyContent="center"
          >
            <Grid item sm={12} style={{ textAlign: "end" }}>
              <DisabledByDefaultIcon
                onClick={removeSelectedImg}
                style={{
                  color: "#4386be",
                  fontSize: 30,
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  } else if (imgStatus === FILE_UPLOAD_COMPONENT_STATUS.submitted) {
    return (
      <Grid
        style={{ height: "125px", position: "relative" }}
        container
        item
        direction="row"
        sm={12}
        alignItems="center"
        justifyContent="center"
      >
        <Box style={{ position: "absolute", top: "0px", right: "0px" }}>
          <Dropzone
            accept={fileDetails?.allowedFormats}
            maxFiles={1}
            multiple={false}
            maxSize={fileDetails?.maxFileSize}
            onDropRejected={(fileRejections) => errorMessage(fileRejections)}
            onDrop={(acceptedFiles) => {
              setCloseClicked(true);
              onDrop(acceptedFiles);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <Box {...getRootProps()}>
                <section>
                  <div>
                    <input {...getInputProps()} />
                    <FileUploadIcon style={{ color: "#d5c81c", fontSize: 30, cursor: "pointer" }} />
                  </div>
                </section>
              </Box>
            )}
          </Dropzone>
        </Box>
        <Grid item sm={12}>
          {fileDetails.status != -1 && previewLoading ? (
            <CircularProgressComponent size={50} />
          ) : (
            <FindInPageIcon
              style={{
                color: statusColor,
                fontSize: 40,
                cursor: "pointer",
              }}
              onClick={() => {
                setPreviewFile(fileDetails);
                previewClicked();
              }}
            />
          )}
        </Grid>
      </Grid>
    );
  }

  return <></>
};
