const appStrings = {
  localStorage: {
    appState: "appState",
    appForm: "appForm_",
    appSettings: "appSettings",
  },
  title: {
    personalDetails: "Personal Details",
    contactDetails: "Contact Details",
    additionalDetail: "Additional Details",
    customerContactDetails: "Customer Contact Details",
  },
  message: {
    gettingApplicationFromReady: "Getting Application Form Ready",
  },
  formObjects: {
    id: "id",
    key: "key",
    content: "content",
    fields: "fields",
    visible: "visible",
    mandatory: "mandatory",
    desc: "desc",
    json: "json",
    description: "description",
  },
  common: {
    up: "up",
    down: "down",
    back: "Back",
    next: "Next",
    small: "small",
  },
  propTypes: {
    default: "default",
    parallel: "parallel",
    flex: "flex",
    center: "center",
    row: "row",
  },
  currencyTypes: {
    lkr: "LKR",
  },

  mainSectionKeys: {
    personalData: "personalData",
    creditData: "creditData",
    singleCustomerViewDetails: "singleCustomerViewData",
    singleCustomerViewData: "singleCustomerViewData",
    contactData: "contactData",
    addressData: "addressData",
    educationData: "educationData",
    employedData: "employedData",
    businessData: "businessData",
    incomeData: "incomeData",
    expenseData: "expenseData",
    assetsData: "assetsData",
    leaseAssetsData:"leaseAssetsData",
    insuranceData:"insuranceData",
    lifeInsuranceData: "lifeInsuranceData",
    athamaruLifeInsuranceDetailsData: "athamaruLifeInsuranceDetailsData",
    bankData: "bankData",
    guarantorData: "guarantorData",
    jointBorrowerData: "jointBorrowerData",
    taxDetailsData: "taxDetailsData",
    analysisOfLoanRequirementData: "analysisOfLoanRequirementData",
    risksConditionsAndGeneralFactsData: "risksConditionsAndGeneralFactsData",
    inquiryOfObligationsData: "inquiryOfObligationsData",
    previousBorrowingsData: "previousBorrowingsData",
    securityData: "securityData",
    eStatements: "eStatements",
    supplementaryCard: "supplementaryCard",
    nonRelatedReference: "nonRelatedReference",
    autoSettleCCBills: "autoSettleCCBills"
  },

  subSectionKeys: {
    "loan-leases": "loanLease",
    "credit-cards": "creditCard",
    pawning: "pawning",
    "over-draft": "overdraft",
  },

  mainSectionValidationsKeys: {
    "credit-details": "creditData",
    "primary-applicant-details": "personalData",
    "single-customer-view-details": "singleCustomerViewData",
    "employment-details": "employedData",
    "contact-details": "contactData",
    "address-details": "addressData",
    "education-details": "educationData",
    "income-details": "incomeData",
    "expenses-details": "expenseData",
    "bank-details": "bankData",
    "assets-details": "assetsData",
    "business-details": "businessData",
    "life-insurance-details": "lifeInsuranceData",
    "tax-details": "taxDetailsData",
    "inquiry-of-obligations": "inquiryOfObligationsData",
    "joint-borrower-details": "jointBorrowerData",
    "security-details": "securityData",
    securityData: "guarantors",
    jointBorrowerData: "jointBorrowerData",
    "personnel-income-details": "personnelIncome",
    "business-income-details": "businessIncome",
    "cultivation-income-details": "cultivationIncome",
    "e-statements": "eStatements",
    "supplementary-card": "supplementaryCard",
    "details-of-relative-not-living-with-you": "nonRelatedReference",
    "automatic-settlment-of-credit-card-bills": "autoSettleCCBills",
    "lease-details": "leaseAssetsData",
    "insurance-details": "insuranceData"

  },

  keyValueMapping: () => {
    const swappedObject = {};
    for (const [key, value] of Object.entries(appStrings.mainSectionValidationsKeys)) {
      swappedObject[value] = key;
    }
    return swappedObject;
  },
  participantSections: {
    primaryApplicantData: "primaryApplicantData",
    jointBorrowerData: "jointBorrowerData",
    supplementaryCard: "supplementaryCard",
    guarantorData: "guarantorData",
    securityData: "securityData",
  },
  participant: {
    "security-details": "Guarantor",
    "joint-borrower-details": "Joint Borrower",
    "supplementary-card": "supplementaryCard",
    securityData: "guarantorSectionValidations",
    supplementaryCard: "supplementaryCard",
  },
  otherSectionValidation: {
    "joint-borrower-details": "jointBorrowerSectionValidations",
    "supplementary-card": "supplementaryCardSectionValidations",
  },

  participantSectionsId: {
    primaryApplicantData: "1",
    jointBorrowerData: "3",
    guarantorData: "2",
  },

  securitySectionKeys: {},

  validatorModes: {
    onSubmit: "ON_SUBMIT",
    onSectionChange: "ON_SECTION_CHANGE",
  },

  conditionTypeOptions: [
    { id: 1, name: "Stage wise disbursement" },
    {
      id: 2,
      name: "Insurance",
    },
    { id: 3, name: "Approvals" },
    { id: 4, name: "Compulsory savings amount" },
    { id: 5, name: "Special Offer Condition" },
  ],
};

export default appStrings;
