import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Stack } from "@mui/material";
import moment from "moment";
import { useCallback, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { securityDocumentPermissions } from "../../../../../../configs/constants/contants";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { disableAccess } from "../../UserCreditComponents/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import ChattelMortgage from "./ChattelMortgage";

const ChattelMortgageLayout = ({ data }) => {
    const fullName = `${data?.formData?.personalData?.initialsInFull} ${data?.formData?.personalData?.primaryLastName}`;
    const primaryAddress = data?.formData?.addressData?.permanentAddress;
    let paAddressSegments = [primaryAddress?.addressLine1 || "", primaryAddress?.addressLine2 || "", primaryAddress?.addressLine3 || "", primaryAddress?.addressLine4 || ""].filter((obj) => obj);
    const address = paAddressSegments.join(", ").trim();
    const componentRef: any = useRef();

    const renderNicDetails = useCallback((type = "", item: any = {}) => {
        if (type == "") {
            return null;
        }

        let nic = "";
        if (type == "PA") {
            const { newNic, oldNic } = data.formData.personalData;
            nic = newNic || oldNic;
        } else if (type == "JB") {
            const { newNic, oldNic } = item?.personalData;
            nic = newNic || oldNic;
        } else if (type == "GR") {
            const { newNic, oldNic } = item?.personalData;
            nic = newNic || oldNic;
        }

        return nic;
    }, [data.formData.personalData]);

    const {
        applicationId,
        approvedLoanAmount,
        amount,
        originationBranch: usedBranchId,
        trialData: { trailCalParams = {} } = {},
        formData: { personalData: { lastName = "" } = {} } = {},
    } = (data as any);
    const documentId = securityDocumentPermissions.chattelMortgage;

    const noPermissionPrint = disableAccess({
        documentComponentId: documentId,
        privilegeKeyforSec: "SEC_DOC_PRINT_SECURITY_DOC",
    });

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
        @media print {
          #Header, #Footer {
            display: none !important;
          }
        }
      `,
    });

    return (
        <Stack>
            <div ref={componentRef}>
                <ChattelMortgage
                    date={moment().format("DD/MM/YYYY")}
                    borrowerName={fullName}
                    borrowerNIC={renderNicDetails("PA")}
                    borrowerAddress={address}
                    loanAmount={approvedLoanAmount || amount} />
            </div>
            <Stack direction="row-reverse" spacing={2} >
                <ButtonComponent
                    startIcon={<LocalPrintshopIcon />}
                    title={"Print PDF"}
                    variant="contained"
                    onClick={() => {
                        handlePrint();
                    }}
                    style={{ maxHeight: "40px", marginTop: "auto" }}
                    disabled={noPermissionPrint}
                    loadingbtn={true}
                    loading={false}
                />
            </Stack>
        </Stack>
    )
}

export default ChattelMortgageLayout