import { Grid } from "@mui/material";
import { figureFormat } from "../../../../../utility/fomatters";
import CreditFileDocumentsViewSection from "../CreditFileDocumentsViewSection";
import DetailItem from "../DetailItem";
import ApplicationHeader from "./ApplicationHeader";

const TaxDetails = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion = false, printMode = false, isGuarantor = false, isJointBurrower = false, guarantorJBSection = "", innerComponentData, tabsToEdit }) => {
  const taxData: any = data?.formData?.taxDetailsData;
  const personalData: any = data?.formData?.personalData;

  if (personalData?.taxPayer === 0) {
    return <></>
  }

  return (
    <div className={`${isMainContactDetails ? "mainSectionWrapper full-width full-height" : "full-width"}`}>
      {isMainContactDetails && (!isRawVersion || printMode) && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={"Tax Details"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={isRawVersion} disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)} />}
      {!isMainContactDetails && (
        <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
          Tax Details
        </Grid>
      )}
      <Grid container className={isMainContactDetails ? "inner-component-height padding-sm" : "margin-xs-t"}>
        <Grid container>
          <Grid container className={`border-radius-5 ${isMainContactDetails ? (isRawVersion ? "" : "sub-header-height") : ""}`}>
            <CreditFileDocumentsViewSection data={data} applicationId={applicationId} isGuarantor={isGuarantor} guarantorJBSection={guarantorJBSection} isJointBurrower={isJointBurrower} innerComponentData={innerComponentData} />
            <Grid container>
              {taxData?.taxDetails?.length > 0 &&
                taxData?.taxDetails
                  ?.filter((obj) => obj)
                  ?.filter((obj) => !obj.removedItem)
                  ?.map((taxDataItem, index) => {
                    return (
                      <Grid container m={1} className="data-box" key={index}>
                        <Grid container item p={1} spacing={1}>
                          <Grid item xs={12} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left">
                            {`Year ${index + 1}`}
                          </Grid>
                          <Grid item xs={3}>
                            <DetailItem title="Individual Tax Identification No" value={taxData?.taxIdentificationNumber ? taxData?.taxIdentificationNumber : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                          </Grid>
                          <Grid item xs={3}>
                            <DetailItem title="Tax File Number" value={taxDataItem?.taxFileNumber ? taxDataItem?.taxFileNumber : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                          </Grid>
                          <Grid item xs={3}>
                            <DetailItem title="Office" value={taxDataItem?.office ? taxDataItem.office : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                          </Grid>
                          <Grid item xs={3}>
                            <DetailItem title="Year of Assessment" value={taxDataItem?.yearOfAssessment ? taxDataItem?.yearOfAssessment : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                          </Grid>
                          <Grid item xs={3}>
                            <DetailItem title="Statutory Income" value={taxDataItem?.statutoryIncome ? figureFormat(taxDataItem.statutoryIncome) : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                          </Grid>
                          <Grid item xs={3}>
                            <DetailItem title="Estimated Income" value={taxDataItem?.estimatedIncome ? figureFormat(taxDataItem.estimatedIncome) : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                          </Grid>
                          <Grid item xs={3}>
                            <DetailItem title="Value Subject to Tax" value={taxDataItem?.valueSubjectsToTax ? figureFormat(taxDataItem.valueSubjectsToTax) : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                          </Grid>
                          <Grid item xs={3}>
                            <DetailItem title="Value Payable" value={taxDataItem?.valuePayable ? figureFormat(taxDataItem.valuePayable) : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                          </Grid>
                          <Grid item xs={3}>
                            <DetailItem title="Tax Paid" value={taxDataItem?.taxPaid ? figureFormat(taxDataItem.taxPaid) : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TaxDetails;
