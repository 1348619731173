
export const onChangeHandler = ({ value, yearDataKey, itemDataKey, formData, selectedYears }) => {
    selectedYears = selectedYears.filter((year) => year !== 'Variable Rate').sort((a, b) => a - b);

    if (selectedYears.length !== 4) {
        let propsedYear = selectedYears[selectedYears.length - 1];
        selectedYears.push(propsedYear + 1);
    }


    let nextYear = selectedYears[selectedYears.length - 2];
    let previousFormData = formData?.[yearDataKey] || {};

    if (yearDataKey === "variableRate") {
        let regex = /^$|^-?$|^-?(100|[1-9]?[0-9])$/;

        value = regex.test(value.toString()) ? value : formData?.[yearDataKey]?.[itemDataKey] || 0;
    }

    let objectData = {
        ...formData, [yearDataKey]: { ...previousFormData, [itemDataKey]: Number(previousFormData?.[itemDataKey] || 0) + Number(value) }
    }

    // eslint-disable-next-line eqeqeq
    if ((yearDataKey === "variableRate" && value != formData?.[yearDataKey]?.[itemDataKey]) || yearDataKey == nextYear) {
        let followingYear = selectedYears[selectedYears.length - 1];
        let valueOfFollowingYear = 0;
        let variableRateValue: number = Number(objectData?.["variableRate"]?.[itemDataKey] || 0)
        let nextYearValue: number = Number(objectData?.[nextYear]?.[itemDataKey] || 0);

        previousFormData = objectData?.[followingYear] || {};

        valueOfFollowingYear = Math.floor(nextYearValue + (nextYearValue * variableRateValue) / 100);

        objectData = { ...objectData, [followingYear]: { ...previousFormData, [itemDataKey]: valueOfFollowingYear } }
    }

    return objectData;
}

export const onChangeHandlerRevaaamp = ({ value, yearDataKey, itemDataKey, formData, selectedYears }) => {
    selectedYears = selectedYears.filter((year) => year !== 'Variable Rate').sort((a, b) => a - b);

    let nextYear = selectedYears[selectedYears.length - 2];
    let previousFormData = formData?.[yearDataKey] || {};

    if (yearDataKey === "variableRate") {
        let regex = /^$|^-?$|^-?(100|[1-9]?[0-9])$/;

        value = regex.test(value.toString()) ? value : formData?.[yearDataKey]?.[itemDataKey] || 0;
    }

    let objectData = {
        ...formData, [yearDataKey]: { ...previousFormData, [itemDataKey]: value }
    }

    // eslint-disable-next-line eqeqeq
    if ((yearDataKey === "variableRate" && value != formData?.[yearDataKey]?.[itemDataKey]) || yearDataKey == nextYear) {
        let followingYear = selectedYears[selectedYears.length - 1];
        let valueOfFollowingYear = 0;
        let variableRateValue: number = Number(objectData?.["variableRate"]?.[itemDataKey] || 0)
        let nextYearValue: number = Number(objectData?.[nextYear]?.[itemDataKey] || 0);

        previousFormData = objectData?.[followingYear] || {};

        valueOfFollowingYear = Math.floor(nextYearValue + (nextYearValue * variableRateValue) / 100);

        objectData = { ...objectData, [followingYear]: { ...previousFormData, [itemDataKey]: valueOfFollowingYear } }
    }

    return objectData;
}