import { Grid, Stack } from "@mui/material";
import { ErrorMessageComponent } from "../../UserCreditComponents/ErrorMessageComponent";
import ApplicationHeader from "../ApplicationHeader";
import LeaseDetails from "./LeaseDetails";

const LeaseDetailsContainer = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion = false, printMode = false, isGuarantor = false, isJointBurrower = false, guarantorJBSection = "", innerComponentData, tabsToEdit }) => {
    let label = "Lease Details";
    let leaseData: any = data?.formData?.leaseAssetsData || undefined;

    try {
        const { primaryApplicant = undefined } = data?.formStructure;
        label = primaryApplicant?.[innerComponentData?.id]?.label;
    } catch {
    }

    return (
        <Stack width={"100%"}>
            {isMainContactDetails && (!isRawVersion || printMode) && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={label ? label : "Lease Details"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={isRawVersion} disableAccordingToTab={tabsToEdit?.includes(innerComponentData?.id)} />}

            {!isMainContactDetails && (
                <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
                    <Grid item>
                        {label ? label : "Lease Details"}
                    </Grid>
                </Grid>
            )}
            <BuildLayout leaseData={leaseData} />
        </Stack>
    )
}

export default LeaseDetailsContainer

const BuildLayout = ({ leaseData }) => {

    if (!leaseData || !leaseData.length) {
        return <ErrorMessageComponent headMessage={""} errorMessage={"No Other Lease Data"} showImg={false} />
    }

    return (
        <>
            {leaseData.map((data, index) => <LeaseDetails state={data} />)}
        </>
    )
}