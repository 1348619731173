import { MASTER_DATA_KEYS } from "../../../../../../../../../configs/constants/masterDataKeys";
import MasterDataProvider from "../../../../../../../../../utility/MasterDataProvider";
import { generateUniqueId, getCopy } from "../../../../../../../../../utility/util";

type WorkingCapitalWorkerFileProps = {
  e: {
    mainParams: {
      deductionsCostsOfGoods: Record<string, any>;
      businessExpenses: Record<string, any[]>;
      businessIncome: Record<string, any[]>;
      totaleBusinessCurrentAssertOtherDetails: Record<string, any[]>;
      businessLiabilityExpenses: Record<string, any[]>;
    };
    otherParams: {
      otherDirectCostType: { id: string; name: string }[];
      liabilityExpensesType: { id: string; name: string }[];
    };
  };
};

type WorkingCapitalTable = {
  description: string;
  operatingCosts: number;
  numberOfDays: number;
  cost: number;
  amount: number;
  dataId: string;
  cashBasis?: number;
  creditBasis?: number;
};

export const workingCapitalWorkerFile = ({ e }: WorkingCapitalWorkerFileProps) => {
  if (!e.mainParams) {
    return;
  }

  const {
    mainParams: {
      deductionsCostsOfGoods,
      businessExpenses,
      businessIncome,
      totaleBusinessCurrentAssertOtherDetails: currentAssert,
      businessLiabilityExpenses,
    },
    otherParams: { otherDirectCostType, liabilityExpensesType },
  } = e;
  const masterDataStore = MasterDataProvider.provideMasterData();

  const getAssetDesc = (assetCategory: string) => {
    return masterDataStore.getMasterDataDescription(
      MASTER_DATA_KEYS.BUSINESS_CURRENT_ASSET_TYPE,
      assetCategory
    );
  };

  const workingCapitaTableDefault: WorkingCapitalTable = {
    description: "",
    operatingCosts: 0,
    numberOfDays: 0,
    cost: 0,
    amount: 0,
    dataId: generateUniqueId(),
  };

  const workingCapitalData: Record<string, any> = {};
  const yearArray = Object.keys(deductionsCostsOfGoods).sort((a, b) => Number(a) - Number(b));

  try {
    yearArray.forEach((year) => {
      const { rawMaterialsCost, otherDirectCost } = deductionsCostsOfGoods[year];
      const _rawMaterialsCost: Record<string, WorkingCapitalTable> = {};
      const _otherDirectCost: Record<string, WorkingCapitalTable> = {};

      rawMaterialsCost.forEach((element: any) => {
        const description = element.description;
        const _rawDefault = { ...workingCapitaTableDefault };

        if (_rawMaterialsCost[description]) {
          _rawMaterialsCost[description].cost += Number(element.cashBasisDeductionsCOG || 0) + Number(element.crediBasisDeductionsCOG || 0);
          _rawMaterialsCost[description].cashBasis! += Number(element.cashBasisDeductionsCOG || 0);
          _rawMaterialsCost[description].creditBasis! += Number(element.crediBasisDeductionsCOG || 0);
        } else {
          _rawDefault.description = description;
          _rawDefault.cost = Number(element.cashBasisDeductionsCOG || 0) + Number(element.crediBasisDeductionsCOG || 0);
          _rawDefault.cashBasis = Number(element.cashBasisDeductionsCOG || 0);
          _rawDefault.creditBasis = Number(element.crediBasisDeductionsCOG || 0);
          _rawMaterialsCost[description] = _rawDefault;
        }
      });

      otherDirectCost.forEach((element: any) => {
        const description = otherDirectCostType.find((item) => item.id === element.deductionsCOGType)?.name || "Unknown";
        const _otherDefault = { ...workingCapitaTableDefault };

        if (_otherDirectCost[description]) {
          _otherDirectCost[description].cost += Number(element.cashBasisDeductionsCOG || 0) + Number(element.crediBasisDeductionsCOG || 0);
          _otherDirectCost[description].cashBasis! += Number(element.cashBasisDeductionsCOG || 0);
          _otherDirectCost[description].creditBasis! += Number(element.crediBasisDeductionsCOG || 0);
        } else {
          _otherDefault.description = description;
          _otherDefault.cost = Number(element.cashBasisDeductionsCOG || 0) + Number(element.crediBasisDeductionsCOG || 0);
          _otherDefault.cashBasis = Number(element.cashBasisDeductionsCOG || 0);
          _otherDefault.creditBasis = Number(element.crediBasisDeductionsCOG || 0);
          _otherDirectCost[description] = _otherDefault;
        }
      });

      const _businessExpenses: WorkingCapitalTable = { ...workingCapitaTableDefault };
      businessExpenses[year].forEach((element: any) => {
        if (element.expenseType === "administrativeExpenses" || element.expenseType === "sellingDistributionExpenses") {
          _businessExpenses.operatingCosts += Number(element.expense) || 0;
        }
      });

      const _businessIncome: WorkingCapitalTable = { ...workingCapitaTableDefault };
      businessIncome[year].forEach((element: any) => {
        _businessIncome.operatingCosts += Number(element.crediBasisIncome) || 0;
      });

      //OTHER CURRENT ASSETS
      const _otherCurrentAssets = {};
      let _totalCurrentAssert = 0;

      currentAssert[year].forEach((element) => {
        const _currentAssert = { ...workingCapitaTableDefault };
        const description = getAssetDesc(element?.assertType);

        _totalCurrentAssert += Number(element?.marketValue || 0);

        if (_otherCurrentAssets?.[description]) {
          _otherCurrentAssets[description].amount += Number(
            element?.marketValue || 0
          );
        } else {
          _currentAssert.amount += Number(element?.marketValue || 0);
          _currentAssert.description = description;
          _otherCurrentAssets[description] = _currentAssert;
        }
      });

      //OTHER CURRENT LIABILITIES
      const _otherCurrentLiabilities: any = {};
      let _totalCurrentLiabilities = 0;

      businessLiabilityExpenses[year].forEach((element) => {
        const _currentLiabilities = { ...workingCapitaTableDefault };
        const description: string = liabilityExpensesType.find(
          (item) => item.id === element?.liabilityType
        )?.name || "";

        _totalCurrentLiabilities += Number(element?.annualLiabilityExpense || 0);

        if (_otherCurrentLiabilities?.[description]) {
          _otherCurrentLiabilities[description].amount += Number(
            element?.annualLiabilityExpense || 0
          );
        } else {
          _currentLiabilities.amount += Number(element?.annualLiabilityExpense || 0);
          _currentLiabilities.description = description;
          _otherCurrentLiabilities[description] = _currentLiabilities;
        }
      });

      const debtors: WorkingCapitalTable = { ...workingCapitaTableDefault, description: "Debtors", operatingCosts: _businessIncome.operatingCosts };
      const creditors: WorkingCapitalTable = {
        ...workingCapitaTableDefault,
        description: "Creditors",
        operatingCosts: Object.values(_rawMaterialsCost).reduce((acc, item) => acc + (item.creditBasis || 0), 0),
      };

      const rawMaterial = Object.values(_rawMaterialsCost);
      const dirrectCost = Object.values(_otherDirectCost);
      const fixedCost = getCopy([{ ..._businessExpenses, description: "Fixed Cost" }]);

      workingCapitalData[year] = {
        rawMaterialRequirement: Object.values(_rawMaterialsCost),
        workInProgress: getCopy({ rawMaterial, dirrectCost, fixedCost, total: 0 }),
        finishedGoods: getCopy({ rawMaterial, dirrectCost, fixedCost, total: 0 }),
        debtors: [debtors],
        creditors: [creditors],
        currentAssets: Object.values(_otherCurrentAssets),
        currentLiabilities: Object.values(_otherCurrentLiabilities),
      };
    });
  } catch (error) {
    return { status: -1, message: "Error in working capital worker file" };
  }

  return { workingCapitalData };
};

