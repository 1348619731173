import { Box, Typography } from "@mui/material";
import A4Container from "../A4Container";
import EditableField from "../EditableField";

const LeaseAgreement = ({
    borrowerName,
    borrowerNIC,
    borrowerAddress,
    date,
}) => {
    return (
        <A4Container>
            <Typography variant="h6" sx={{ textAlign: "center", fontWeight: "bold" }}>
                LEASE AGREEMENT
            </Typography>

            <Typography variant="body1" sx={{ marginTop: 2, lineHeight: 1.6 }}>
                This Lease Agreement is made and entered into on this {date} at
                <EditableField label="Location" /> by and between:
            </Typography>

            <Typography variant="body1" sx={{ marginTop: 2 }}>
                <span style={{ fontWeight: "bold" }}>1. Lessor: </span> <EditableField label="Lender's Name" />,
                a duly registered financial institution under the laws of Sri Lanka,
                having its principal office at <EditableField label="Address" />.
            </Typography>

            <Typography variant="body1" sx={{ marginTop: 2 }}>
                <span style={{ fontWeight: "bold" }}>2. Lessee: </span> {borrowerName},
                holder of National Identity Card No. {borrowerNIC},
                residing at {borrowerAddress}.
            </Typography>

            <Typography variant="body1" sx={{ marginTop: 2 }}>
                <span style={{ fontWeight: "bold" }}>3. Leased Asset: </span>  The Lessor agrees to lease to the Lessee the following asset:
                <EditableField label="Description of the asset" />.
            </Typography>

            <Typography variant="body1" sx={{ marginTop: 2 }}>
                <span style={{ fontWeight: "bold" }}>4. Lease Term: </span> The term of this lease shall be for a period of
                <EditableField label="Duration" /> commencing on
                <EditableField label="Start Date" /> and terminating on
                <EditableField label="End Date" />.
            </Typography>

            <Box sx={{ marginTop: 4 }} display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={"bold"}>Lessor:</Typography>
                <Typography>Signature: ______________________</Typography>
                <Typography>Name: <EditableField label="Lender Name" /></Typography>
            </Box>

            <Box sx={{ marginTop: 2 }} display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={"bold"}>Lessee:</Typography>
                <Typography>Signature: ______________________</Typography>
                <Typography>Name: {borrowerName}</Typography>
            </Box>
        </A4Container>
    );
};

export default LeaseAgreement;
