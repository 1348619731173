import DoneIcon from "@mui/icons-material/Done";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { isTabActionPrivilege } from "../../../../../../../../utility/helpers/ApplicationMandatoryValidations/isNotPermittedDocs";
import { ButtonComponent } from "../../../../../../../InputComponents/ButtonComponent/ButtonComponent";

interface AppraisalInterestHeaderProps {
    title?: string;
    onSubmit?: any;
    otherParams?: any;
}

const AppraisalInterestHeader: React.FC<AppraisalInterestHeaderProps> = ({ title, onSubmit, otherParams = {} }: AppraisalInterestHeaderProps) => {
    const freezeTabs: any[] = otherParams?.tabsToEdit || [];
    let disableAccordingToTab: any = false;

    if (freezeTabs.length > 0) {
        disableAccordingToTab = freezeTabs.includes(otherParams?.innerComponentData?.id);
    }

    return (
        <Stack
            style={{
                backgroundColor: `#7fb4de3d`,
                margin: "2px",
                borderRadius: "5px",
            }}
        >
            <Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                <Stack>
                    <Typography className="basic-font basic-font-color-bold font-size-16 padding-m align-left">{title ?? ""}</Typography>
                </Stack>
                <Stack direction={"row"} px={1} gap={1}>
                    {Boolean(onSubmit) && <ButtonComponent startIcon={<DoneIcon />} title="SUBMIT" variant="contained" onClick={onSubmit} loadingbtn={true} color="info" disabled={isTabActionPrivilege(disableAccordingToTab)} />}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default AppraisalInterestHeader;
