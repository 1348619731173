import { Grid } from "@mui/material";
import { formatCurrency } from "../../../../../../services/currencyFormater";
import { assetType, typeOfCondition } from "../../../../../../utility/util";
import DetailItem from "../../DetailItem";

const LeaseDetails = ({ state }) => {

    let { typeOfAsset, condition, supplier, leaseAmount, leasePeriod } = state;

    typeOfAsset = assetType.find((asset) => asset.id === Number(typeOfAsset))?.name;
    condition = typeOfCondition.find((cond) => cond.id === Number(condition))?.name;

    return (
        <Grid container m={1} p={1} spacing={1} className="data-box">
            <Grid item xs={3}>
                <DetailItem title="Type Of Asset" value={typeOfAsset ? typeOfAsset : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
            <Grid item xs={3}>
                <DetailItem title="Condition" value={condition ? condition : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
            <Grid item xs={3}>
                <DetailItem title="Supplier" value={supplier ? supplier : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
            <Grid item xs={3}>
                <DetailItem title="Lease Amount" value={leaseAmount ? formatCurrency(leaseAmount) : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
            <Grid item xs={3}>
                <DetailItem title="Lease Period" value={leasePeriod ? leasePeriod : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
        </Grid>
    )
}

export default LeaseDetails