import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { securityDocumentPermissions, securityDocumentTextCase } from "../../../../../../configs/constants/contants";
import {
  branchDetails
} from "../../../../../../configs/mainStore";
import { formatCurrency } from "../../../../../../services/currencyFormater";
import {
  disableAccess
} from "../../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { numberToWords } from "../../../../../../utility/util";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../../../InputComponents/SelectComponent/SelectComponent";
import SecurityDoceumentsFileDocumentsViewSection from "../../SecurityDocumentsFileDocumentsViewSection";
import CommonSecurityDocHeader from "../CommonSecurityDocHeader";
import CommonSignatureBlock from "../CommonSignatureBlock";
import LoanReceiptWitness from "./LoanReceiptWitness";

export const LoanReceipt = ({
  data
}) => {
  const componentRef: any = useRef();
  const [typeCase, setTypeCase] = React.useState(1);
  const [typeCaseClass, settypeCaseClass] = React.useState();
  const fullName = `${data?.formData?.personalData?.initialsInFull} ${data?.formData?.personalData?.primaryLastName}`

  const {
    applicationId,
    approvedLoanAmount,
    amount,
    originationBranch: usedBranchId,
    trialData: { trailCalParams = {} } = {},
    formData: { personalData: { lastName = "" } = {} } = {},
  } = (data as any);

  const nearestBranch = branchDetails.branchMap[usedBranchId].name.get();
  const documentId = securityDocumentPermissions.loanReceipt || "receipt";

  let loanAmount = 0;

  if (!loanAmount) {
    const { LoanAmount: _loanAmount, loanAmount: __loanAmount } = trailCalParams;
    loanAmount = _loanAmount || __loanAmount;
  }

  if (!loanAmount) {
    loanAmount = approvedLoanAmount || amount;
  }

  const noPermissionPrint = disableAccess({
    documentComponentId: documentId,
    privilegeKeyforSec: "SEC_DOC_PRINT_SECURITY_DOC",
  });

  useEffect(() => {
    changeTypeCase();
  }, [typeCase]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
    @media print {
      #Header, #Footer {
        display: none !important;
      }
    }
  `,
  });

  function SummaryBranch({ branch, typeCase }) {
    return (
      <Grid container>
        <Grid item>
          <span className={`security-doc-type-variable ${typeCaseClass}`}>{branch}</span> Branch
        </Grid>
      </Grid>
    );
  }

  const changeTypeCase = () => {
    settypeCaseClass(securityDocumentTextCase[typeCase || 1].typeCaseClass);
  };

  const renderNicDetails = (type = "", item: any = {}) => {
    if (type == "") {
      return null;
    }

    let nic = "";
    if (type == "PA") {
      const { newNic, oldNic } = data.formData.personalData;
      nic = newNic || oldNic;
    } else if (type == "JB") {
      const { newNic, oldNic } = item?.personalData;
      nic = newNic || oldNic;
    } else if (type == "GR") {
      const { newNic, oldNic } = item?.personalData;
      nic = newNic || oldNic;
    }

    return nic;
  };

  function RenderNames() {
    const applicantFormData = data.formData;
    const applicantJBData = applicantFormData?.jointBorrowerData;

    const renderedNames = applicantJBData
      ?.filter(
        (obj) => obj?.personalData?.initialsInFull !== undefined && obj?.personalData?.primaryLastName !== undefined
      )
      .map((obj, index, array) => (
        <React.Fragment key={index}>
          {" and "}
          <span className={`security-doc-type-variable ${typeCaseClass}`}>
            {obj?.personalData?.initialsInFull}
          </span>{" "}
          <span className={`security-doc-type-variable ${typeCaseClass}`}>{obj?.personalData?.primaryLastName}</span>
        </React.Fragment>
      ));

    return <>{renderedNames}</>;
  }

  const getPageMargins = () => {
    return `@page { margin: ${"7mm"} ${"10mm"}  ${"15mm"} ${"15mm"} !important; }`;
  };

  return <Grid container className="inner-component-full-height basic-font">
    <style>{getPageMargins()}</style>
    <div className="full-width full-height">
      <SecurityDoceumentsFileDocumentsViewSection
        data={data}
        applicationId={applicationId}
        isSecurityDocument={true}
        securityDocumentKey={"receipt"}
      />
      <Grid container pt={2} pr={2} pl={2} pb={1} ref={componentRef} columns={24}>
        <CommonSecurityDocHeader documentName={"Loan Receipt"} textCase={"lower"} />
        <Grid item p={1} xs={24}>
          <div className="loan-receipt-branch-details">
            <div className="loan-receipt-branch-details-l">
              The Manager
              <br />
              Pradeshiya Sanwardhana Bank
              <br />
              <SummaryBranch branch={nearestBranch} typeCase={typeCase} />
            </div>
          </div>
        </Grid>

        <Grid item xs={24} mt={3}>
          <Stack direction="row" spacing={1} justifyContent="space-Between" p={1} alignItems="center">
            <Grid item style={{ textAlign: "justify" }}>
              I / We, <span className={`security-doc-type-variable ${typeCaseClass}`}>{fullName}</span>{" "}
              <span className={`security-doc-type-variable ${typeCaseClass}`}>{lastName}</span> <RenderNames />
              <span className="lr-here-by">hereby acknowledge the receipt of a sum of </span>
              <span className={`security-doc-type-variable ${typeCaseClass}`}>
                {numberToWords(loanAmount)}
              </span>
              (Rs {formatCurrency(loanAmount, 2)}) granted to me/ us as a loan.
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={24} mt={3} p={1}>
          {/* Signatures */}
          <CommonSignatureBlock
            creditFileData={data}
            typeCaseClass={typeCaseClass || ""}
            renderNicDetails={renderNicDetails}
            isThisDayAvailable={true}
            isDateAvailable={false}
            isSignAtAvailable={false}
          />
        </Grid>
        <LoanReceiptWitness />
      </Grid>
      <Box m={1}>
        <Grid container columns={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Grid item xs={4} pr={1}>
            <SelectComponent
              label={"Select Typecase"}
              value={typeCase}
              values={Object.values(securityDocumentTextCase)}
              onChange={(e) => setTypeCase(e.target.value)}
              required={false}
              defaultPlaceholder={true}
            />
          </Grid>
          <Grid item xs={4}>
            <ButtonComponent
              startIcon={<LocalPrintshopIcon />}
              title={"Print PDF"}
              variant="contained"
              onClick={() => {
                handlePrint();
              }}
              style={{ maxHeight: "40px", marginTop: "auto" }}
              disabled={noPermissionPrint}
              loadingbtn={true}
              loading={false}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
    {/* <ChattelMortgageLayout data={data} /> */}
  </Grid>;
};
