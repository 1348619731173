export const langDictionary = {
  //titles
  primaryIdDetails: "Primary Identification Details",
  otherIdDetails: "Other Identification Details",
  contactDetails: "Customer Contact Details",
  applicationDetails: "Application Details",
  loanApplicationDetails: "Loan ApplicationDetails",
  IdentificationDetails: "Customer Identification Details",
  residentialDetails: "Customer Residential Details",
  employmentDetails: "Employment Details",
  businessDetails: "Business Details",
  employment: "Employment",
  employmentNo: "Employment Number",
  business: "Business",
  employmentContacts: "Employment Contacts",
  selfEmployedDetails: "Self-Employment Details",
  businessContacts: "Business Contacts",
  residentialAddress: "Residential Address",
  financialDetails: "Customer Financial Details",
  primaryApplicantDetails: "Personal Details",
  primaryContactDetails: "Contact Details",
  guarantorDetails: "Guarantor Details",
  jointBorrowerDetails: "JointBorrower Details",
  addGuarantor: "Add Guarantor",
  guarantor: "Guarantor",
  addJointBorrower: "Add Joint Borrower",
  securityDetails: "Security Details",
  doesTheApplicantHasCloseRelation: "Does the Applicant has close relation who work at RDB ?",
  applicationForm: {
    titles: {
      addressData: "Address Details",
      assetsData: "Asset Details",
      bankData: "Bank Details",
      contactData: "Contact Details",
      businessData: "Business Details",
      personalData: "Personal Details",
      creditData: 'Credit Details',
      inquiryOfObligationsData: "Inquiry Of Obligations Details",
      educationData: "Education Details",
      lifeInsuranceData: "Life Insurance Details",
      singleCustomerViewData: "Single Customer View Details",
      expenseData: "Expense Details",
      incomeData: "Income Details",
      jointBorrowerData: 'Joint Borrower Details',
      securityData: "Security Details",
      employedData: "Employment Details",

      taxDetailsData: "Tax Details",
      leaseAssetsData: "Lease Details",
      insuranceData: "Insurance Details"


    },
    subTitle: {
      personalData: "Personal Details Section",
      creditData: 'Credit Details Section',
      contactData: "Contact Details Section",
      addressData: "Enter Your Address Details Here",
      educationData: "Education Details Section",
      employedData: "Employment Details Section",
      businessData: "Business Details Section",
      incomeData: "Income Details Section",
      expenseData: "Expenses Details Section",
      bankData: "Bank Details Section",
      assetsData: "Assert Details Section",
      lifeInsuranceData: "Enter Your life Insurance Details Here",
      inquiryOfObligationsData: "Inquiry Of Obligations Section",
      leaseAssetsData: "Lease Details Section",
      insuranceData: "Insurance Details Section"
    }

  },

  fieldCustomization: "Field Customization", // header
  ruleCustomization: "Rule Customization", // header
  fieldDescription: "Field Description", // header
  ruleSet: "Rule Set", // header
  fieldsOfProduct: "Fields of Product", // header
  searchRuleByRuleName: "Search rule by rule name", // header
  ruleDescription: "Rule Description", // header
  ruleArgs: "Rule Arguments", // header
  ruleString: "Rule Definition", // header
  selectRule: "Select Rule", // header
  workflowStages: "Workflow Stages", // header
  rulesSet: "Rules Set", // header
  rulesCustomizationOf: "Rules Customization of", // header
  workflowCustomization: "Workflow Customization", // header
  workflowStageDescription: "Workflow Stage Description", // header
  section: "Section", // label
  subSection: "Sub Section", // label
  fields: "Fields", // label
  confirmDiscardChanges: "Confirm Discard Changes", // label
  confirmFlushRules: "Confirm Flush Rules", // label
  active: "Active", // label
  formData: "Form Data", // option
  masterData: "Master Data", // option
  configData: "Config Data", // option
  selectField: "Select Field", // placeholder
  search: "Search...", // placeholder
  ruleStringPH: "Rule Definition", // placeholder
  validationFailMessagePH: "Validation Fail Message", // placeholder
  descriptionPH: "Description", // placeholder
  namePH: "Name", // placeholder
  typePH: "Type", // placeholder
  fieldPH: "Field", // placeholder
  indexPH: "Index", // placeholder
  save: "SAVE", // button
  reset: "RESET", // button
  cancel: "CANCEL", // button
  confirm: "CONFIRM", // button
  creat: "CREATE", // button
  pass: "Pass", // label
  fail: "Fail", // label
  submit: "Submit",

  //common
  language: "Language",
  title: "Title",
  name: "Name",
  initials: "Initials",
  initialsInFull: "Initials In Full",
  firstName: "First Name",
  lastName: "Last Name",
  middleName: "Middle Name",
  other: "Other",
  city: "Nearest City",
  nic: "NIC",
  oldNic: "Old Nic",
  newNic: "New Nic",
  gender: "Gender",
  civilState: "Civil Status",
  birthDay: "Birth Day (DD/MM/YYYY)",
  age: "Age",
  otherNames: "Other Names",
  idType: "ID Type",
  otherIdTypes: "Other ID Types",
  idNo: "ID No",
  idExpDate: "ID Expiry Date (DD/MM/YYYY)",
  taxPayer: "Tax Payer",
  individualTaxNo: "Individual Tax Identification No",
  telephone: "Telephone",
  otherContactNumbers: "Other Contact Numbers",
  socialMediaContact: "Social Media Contacts",
  primaryContactNo: "Primary Contact No",
  primarySmsNo: "Primary SMS No",
  contactType: "Contact Type",
  phoneNumber: "Phone Number",
  email: "Email",
  primaryEmailAddress: "Primary Email Address",
  otherEmailAddress: "Other Email Address",
  emailType: "Email Type",
  socialMedia: "Social Media",
  socialMediaType: "Social Media Type",
  socialMediaUrl: "Social Media Name / URL",
  male: "Male",
  female: "Female",
  yes: "Yes",
  no: "No",
  product: "Product",
  selectProduct: "Select a product to create or edit application form",
  loadingFormEditor: "Getting form editor ready",
  subscribeToBanksNewsSmsAlerts: "Subscribe to bank's news sms alerts",
  subscribeToBanksNewsEmailAlerts: "Subscribe to bank's news email alerts",
  pleaseSpecify: "Please Specify",
  identifyYourself: "How do you identify yourself?",


  //address
  addressDetails: "Address Details",
  addressInfo: "Address Info",
  otherAddressInfo: "Other information of the address",
  previousResidenceAddressInfo: "Previous Residence Address Info",
  addressLine1: "Address Line 1",
  addressLine2: "Address Line 2",
  addressLine3: "Address Line 3",
  addressLine4: "Address Line 4",
  propertyName: "Property Name",
  flatNumber: "Flat Number",
  houseNumber: "House Number",
  streetName: "Street Number",
  cityLine1: "City Line 1",
  cityLine2: "City Line 2",
  streetType: "Street Type",
  province: "Province",
  district: "District",
  postalCode: "Postal Code",
  gnDivision: "GN Division",
  dsDivision: "DS Division",
  residentialState: "Residential State",
  otherResidentialState: "Other Residential State",
  occupantFrom: "Occupant From",
  yearsAtCurrentResidence: "Years at current residence",
  isMailingAddressSame: "Mailing address same as permanent",
  isCurrentAddressSame: "Current address same as permanent",
  relationContact: "Relation Contact",
  relationLandNumber: "Land Number",
  relationship: "Relationship",
  otherRelationship: "Other",
  relationName: "Name",
  years: "Years",
  months: "Months",
  relationContactNumber: "Contact Number",
  mailAddressData: "Malling Address",
  description: "Description",

  //education
  higherEducation: "Higher Education",
  educationDetails: "Education Details",
  primaryEducation: "Primary Education",
  awardType: "Award Type",
  awardName: "Award Name",
  awardedYear: "Awarded Year",
  awardDuration: "Award Duration",
  courseDuration: "Course Duration",
  instituteName: "Institution Name",
  nvqLevel: "NVQ Level",
  grade: "Grade",
  lastPassGradeInSchool: "Last Passed Grade in School",
  highestLevelOfEducation: "Highest Level of Education",

  //employed
  employmentCategory: "Employment Category",
  employmentType: "Employment Type",
  startDate: "Start Date (DD/MM/YYYY)",
  endDate: "End Date (DD/MM/YYYY)",
  totalEmploymentExp: "Total Employment Experience",
  noOfYearsAtEmp: "No of years at employment",
  noOfYearsTillPension: "No of years till pension",
  empStartedFrom: "Employment started from",
  employmentState: "Employment State",
  occupation: "Occupation",
  industryType: "Industry Type",
  designation: "Designation",
  kmpOrNOnKmp: "KMP or Non-KMP?",
  kmpList: "KMP List",
  employerName: "Employer Name",
  contactName: "Contact Name",
  emailAddress: "Email Address",
  employmentStatus: "Employment Status",
  natureOfEmployment: "Nature of Employment",
  fullTimeOrPartTime: "Full Time or Part Time",
  previousEmployment: "Previous Employment",
  employmentExperience: "Do you have previous employment experience ",
  empEmailType: "Email Type",
  pensionableDate: "Retirement Date: (DD/MM/YYYY)",
  permanentDate: 'Date Of Permanent',
  permenantEmployment: "Permanent Employment",


  //self-employed
  typeOfBusiness: "Type of Business",
  businessSector: "Business Sector",
  natureOfBusiness: "Nature of Business",
  businessSubSector: "Business Sub Sector",
  businessRegNo: "Business Reg.No",
  businessCategory: "Business Category",
  brDate: "BR Date (DD/MM/YYYY)",
  noOfEmployees: "No of Employees",
  noOfFemaleEmployees: "Female Employees",
  noOfMaleEmployees: "Male Employees",
  taxPayment: "Tax Payment",
  taxId: "Tax Id",
  noOfYearsInOperation: "No of Years in Operation",
  pastBusinessExperience: "Past Business Experience",
  nameOfTheBusiness: "Name of The Business",
  website: "Web Site",
  businessIsRunWithA: "Ownership of Business Property * ",
  certification: "Authority or Certification",
  sustainableFinance: "Is it maintaining Sustainable Finance ?",
  primaryFocusArea: "Primary Focus Area",
  environmentalSubcategories: "Environmental Subcategories",
  //TAX
  taxFileNumber: "Tax File Number",
  office: "Office",
  indexNumber: "Index Number",
  yearOfAssessment: "Year of Assessment",
  statutoryIncome: "Statutory Income",
  estimatedIncome: "Estimated Income",
  valueSubjectsToTax: "Value Subject to Tax",
  valuePayable: "Value Payable",
  taxPaid: "Tax Paid",

  //income details
  incomeDetails: "Income Details",
  ExpensesDetails: "Expense Details",
  incomeCategory: "Income Category",
  incomeHolder: "Income Holder",
  sector: "Sector",
  subSector: "Sub Sector",
  incomeType: "Income Type",
  salaryAssignment: "Salary Assignment",
  income: "Income",
  frequency: "Frequency",
  hashOfMonths: "Hash Of Months",
  expensesDetails: "Expenses Details",
  expensesCategory: "Expenses Category",
  expensesHolder: "Expenses Holder",
  expensesType: "Expenses Type",
  expenses: "Expenses",
  expenseFrequency: "Expense Frequency",
  expenseAmount: "Expense Amount",
  expenseHashOfMonths: "Expense Hash Of Months",
  noOfDependents: "No Of Dependents",
  annualIncome: "Annual Income",
  annualExpenses: "Annual Expenses",
  addIncome: "Add Income",
  amount: "Amount",
  annualAmount: "Annual Amount",
  addExpense: "Add Expense",
  incomeSector: "Income Sector",
  incomeSubSector: "Income Sub Sector",
  presenceOfRegulatedFinSystem: "Percentage of the regulated financial System",
  personnelIncome: "Personnel Income",
  businessIncome: "Business Income",
  cultivationIncome: "Cultivation Income",

  //Life Insurances Details
  generalInsurance: "General Insurance",
  lifeInsuranceDetails: "Life Insurance Details",
  addLifeInsuranceDetails: "Add Insurance Details",
  insuranceCompany: "Insurance Company",
  insuredAmount: "Insured Amount",
  monthlyPremium: "Monthly Premium",
  beneficiary: "Beneficiary",
  insurancePolicyType: "Insurance PolicyType",

  //bank details
  bankAccountDetails: "Bank Account Details",
  bankName: "Bank Name",
  branch: "Branch",
  accountType: "Account Type",
  accountOwnership: "Account Ownership",
  accountName: "Account Holder Name",
  accountNo: "Account No",
  balance: "Balance",
  bankAccounts: "Bank Accounts",
  accountOpenDate: "Account Open Date (DD/MM/YYYY)",
  maximumBalance: "Maximum Balance",
  asAtDate: "As at Date (DD/MM/YYYY)",
  balanceAsAtDate: "Balance As At Date",
  averageDepositLastSixMonths: "Average Deposits of Last Six Months",
  averageWithdrawalLastSixMonths: "Average Withdrawal Last Six Months",
  monthlyDataEntryAfterDeposits: "Monthly Data Entry after Average Deposits of Last Six Months",
  monthlyDataEntryAfterWithdrawals: "Monthly Data Entry after Average Withdrawals of Last Six Months",


  //assets details
  assetsDetails: "Assets Details",
  addAssets: "Add Assets",
  assetsCategory: "Asset Category",
  assetType: "Asset Type",
  assetDescription: "Asset Description",
  extentOrRegNoOrOther: "Extent/ Registration No. / Other",
  grossValue: "Force Sales Value",
  assets: "Assets",
  ownership: "Ownership",
  mortgageValue: "Mortgage Value",
  forceSalesValue: "Force Sales Value",
  marketValue: "Market Value",
  assetGrossValue: "Asset Gross Value",

  //lease details
  leaseAssetData: "Asset to be Leased",
  addLeaseAssets: "Lease Asset Details",
  typeOfAsset: "Type of Asset",
  condition: "Condition",
  supplier: "Supplier",
  leaseAmount: "Lease Amount(Excluding VAT)",
  leasePeriod: "Lease Period",

  //insurance details
  insuranceData: " Insurance Details",
  addInsurance: "Insurance",
  companyName: "Name of Company",
  companyAddress: "Company Address",
  mobile: "Mobile",
  supplierType: "Supplier Type",

  //credit details
  creditDetails: "Credit Details",

  //application Status
  leadInProgress: "Lead In Progress",
  pendingSubmission: "Pending Submission",
  leadClosed: "Lead Closed",
  submitted: "Submitted",
  closed: "closed",
  // language code
  EN: "English",
  SI: "Sinhala",
  TM: "Tamil",
  TA: "Tamil",

  loanSectionMessagePre: "Lets's Config  Primary Applicant Form of ",
  loanSectionMessagePost: "Loan",
  securityAvailability: "Security Availability",

  // Primary details revamp
  usedName: "Used Name",
  spouseDetails: "Spouse Details",
  spouseNameInFull: "Spouse Name in Full",
  spouseOldNic: "Spouse Old NIC",
  spouseNewNic: "Spouse New NIC",
  spouseEmploymentDetails: "Spouse Employment Details",
  race: "Race",
  preferredCommunicationLanguage: "Preferred Communication Language",
  areYouASriLankanCitizen: "Sri Lankan Citizen",
  byBirth: "By Birth",
  byRegistration: "By Registration",
  issuedDate: "Issued Date (DD/MM/YYYY)",
  cribDeduction: "Crib Deduction",

  relationShipWithTheApplicant: "Relationship with the Primary Applicant",
  percentageOfShares: "Percentage of Shares",
  detailsOfTheRelation: "Details Of The Relation",
  inquiryOfObligations: "Inquiry of Obligations",
  shareholdersAvailable: "Shareholders Available",
  loanAgreement: "Loan Agreement",
  inheritedProperty: "Inherited Property",
  primaryApplicantData: "primaryApplicantData",
  jointBorrowerData: "jointBorrowerData",
  guarantorData: "guarantorData",
  securityData: "securityData",

  sections: {
    errorMessageStart: "⚠️ Below Sections Errors Prevent Your Submission Process. \n",
    personalData: "Personal Details Section",
    contactData: "Contact Details Section",
    addressData: "Address Details Section",
    educationData: "Education Details Section",
    employedData: "Employment Details Section",
    businessData: "Business Details Section",
    incomeData: "Income Details Section",
    expenseData: "Expenses Details Section",
    bankData: "Bank Details Section",
    assetsData: "Assert Details Section",
    "lifeInsuranceData": "Life Insurance Section",
    inquiryOfObligationsData: "Inquiry Of Obligations Section",
    taxDetailsData: "Tax Details Section",
    leaseAssetsData: "Lease Details Section",
    insuranceData: "Insurance Details Section"
  },

  errorMessages: {
    default: "Something Went Wrong. Call System Administrator."
  },

  navigationStepper: {
    0: "Submit",
    1: "Applicant",
    2: "Joint Borrower",
    3: "Collateral",
    4: "Summary",
    6: "Agriculture"
  },
  agriculture: "Agriculture Section Details",
  agricultureForm: "Agriculture Application Form",
  agricultureSection: {
    typeOfAgriculturing: "Type of Agriculturing",
    subTypeOfAgriculturing: "Sub Type of Agriculturing",
    manufacturingCountryOfTheMachine: "Manufacturing Country of the machine",
    priceOfTheMachine: "Price of the Machine",
    registrationNumberIfAvailableForTheAgriProject: "Registration Number if available for the Agri Project",
    agriculturingExperienceInYears: "Agriculturing Experience in Years: ",
    maleEmployees: "Male Employees",
    femaleEmployees: "Female Employees",
    hasTheAgriculturingObtainedAnyTraining: "Has the Agriculturing obtained any Training?",
    nameOfTheInstitute: " Name of the Institute",
    memberInTheFarmersSociety: "Member in the Farmers Society?",
    nameOfTheMemberOfFarmersSociety: "Name of the member of Farmers Society",
    registrationNumberOfTheFarmersSociety: "Registration Number of the Farmers Society",
    agricultureLandIsA: "Agriculture Land is a",
    leaseAgreementUnder: "Lease Agreement Under",
    relationshipWithThePrimaryApplicant: "Relationship with the Primary Applicant",
    agreementPeriod: "Agreement Period ",
    PropertyIsUnder: "Property is Under",
    agriculturingAssetValue: "Agriculturing Asset Value",
    agriculturingAssetType: "Agriculturing Asset Type",
    assetValue: "Asset Value",
    extent: "Extent",
    primaryApplicantData: "Primary Applicant's Agriculture Data",
    jointBorrowerData: "Joint Borrower's Agriculture Data",
    guarantorData: "Guarantor's Agriculture Data",
  },

  numberOfDays: "Number Of Days",
  rawMaterial: "Raw Material",
  operatingCost: "Operating Cost",
  total: "Total",
  scoreCardQuestion: {
    defaultScoreCard: "Default",
    "cultivationModel": "Cultivation",
    "otherThancultivationModel": "Other Than Cultivation ",
    "agricultureModelIndividual": "Agriculture Individual ",
    "agricultureModelJointB": "Agriculture JB ",
    "agricultureModelBusiness": "Agriculture Business ",
    "manufactureTradeAndServiceIndividual": "Manufacture Trade And Service Individual",
    "manufactureTradeAndServiceJointB": "Manufacture Trade And Service Joint Borrower",
    "manufactureTradeAndServiceBusiness": "Manufacture Trade And Service Business",
  },
  scoreCardLayouts: {
    "agriculture_model_individual": "Agriculture Individual Score Card",
    "agriculture_model_business": "Agriculture Business Score Card",
    "manufacturing_trade_services_individual": "Manufacture Trade And Service Individual Score Card",
    "manufacture_trade_and_service_business": "Manufacture Trade And Service Business Score Card",
    "manufacture_trade_and_service_individual": "Manufacture Trade And Service Individual Score Card",
    "cultivation_model": "Cultivation Score Card",
    "other_than_cultivation_model": "Other Than Cultivation Score Card",
    "athamaru_score_card": "Athamaru Score Card",

  }

};
