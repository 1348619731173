import { Grid } from "@mui/material";
import { formatCurrency } from "../../../../../../services/currencyFormater";
import { businessesNature, supplierTypes } from "../../../../../../utility/util";
import DetailItem from "../../DetailItem";

const InsuranceDetails = ({ state }) => {

    let { companyName, businessRegNo, companyAddress, emailAddress, telephone, mobile, website, supplierType, natureOfBusiness } = state;

    supplierType = supplierTypes.find((insurance) => insurance.id === Number(supplierType))?.name;
    natureOfBusiness = businessesNature.find((cond) => cond.id === Number(natureOfBusiness))?.name;

    return (
        <Grid container m={1} p={1} spacing={1} className="data-box">
            <Grid item xs={3}>
                <DetailItem title="Name of Company" value={companyName ? companyName : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
            <Grid item xs={3}>
                <DetailItem title="Business Registration No" value={businessRegNo ? businessRegNo : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
            <Grid item xs={3}>
                <DetailItem title="Company Address" value={companyAddress ? companyAddress : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
            <Grid item xs={3}>
                <DetailItem title="E-mail Address" value={emailAddress ? emailAddress : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
            <Grid item xs={3}>
                <DetailItem title="Telephone" value={telephone ? telephone : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
            <Grid item xs={3}>
                <DetailItem title="Mobile" value={mobile ? mobile : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
            <Grid item xs={3}>
                <DetailItem title="Web Site" value={website ? website : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
            <Grid item xs={3}>
                <DetailItem title="Supplier Type" value={supplierType ? supplierType : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
            <Grid item xs={3}>
                <DetailItem title="Nature of Business" value={natureOfBusiness ? natureOfBusiness : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
            </Grid>
        </Grid>
    )
}

export default InsuranceDetails