import { Stack, Typography } from '@mui/material'
import Avatar from 'react-avatar'
import AppConfig from '../../../../../utility/AppConfig'

const ScoreCardMainWrapper = ({ children, grade, totalScore }) => {
  return (
    <div className="full-width inner-component-full-height">
      <Stack p={1}>
        <Stack p={1} style={{ backgroundColor: `${grade?.color}3d` }}
          className="border-radius-5">
          <div className="basic-font basic-font-color-bold font-size-16 align-left">Overall Score
          </div>
        </Stack>
        <Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
          <Stack direction={"row"} gap={40} p={3} alignItems={"center"}>
            <Stack>
              <Typography variant="caption" color={"gray"}>Total Score</Typography>
              <Typography variant="h5" style={{ color: grade?.color }}>{totalScore && totalScore.toFixed(2)}</Typography>
            </Stack>
            <Stack>
              <Typography variant="caption" color={"gray"}>Grade</Typography>
              <Avatar round={true} value={grade?.name} size={"36px"} color={grade?.color} />
            </Stack>
          </Stack>
        </Stack>
        <Stack direction={"row-reverse"} px={1} display={AppConfig.scoreEvaluationControl ? "flex" : "none"}>
          <Typography variant="caption" color={"gray"} fontStyle={"italic"}> 🎯 Predicted By {AppConfig.aiName}</Typography>
        </Stack>
        {children}
      </Stack>
    </div>
  )
}

export default ScoreCardMainWrapper