import { Downgraded } from "@hookstate/core";
import { assetsDetailsValidationOnNext } from "../../../configs/validations/application-form-on-next-validation-handlers/assets-details/assetsDetailsValidationOnNext";
import { otherValidationHandler } from "../../CustomHooks/useValidateSubmit";
import { messageMappings } from "./messageMapping";
import { formStateArrayTypeHandler } from "./structureValidation";

export const leaseDetails = (structure, state, isGuarantor = false, isJointBorrower = false, participantState, isSecuritySectionNext?) => {
  let leaseDetails: any[] = state.attach(Downgraded).get();

  leaseDetails.reduce((itemObject = [], cuurItem: any, itemIndex: number) => {
    delete cuurItem["sectionErrorStatus"];

    if (Object.keys(cuurItem).length) return [...itemObject, cuurItem];

    return itemObject
  }, []);

  if (!leaseDetails.length) return ['Lease Details cannot be Empty!'];

  let errArrInArrays = formStateArrayTypeHandler({ structure, state: leaseDetails });

  if (errArrInArrays.length) {
    return messageMappings(errArrInArrays);
  }

  let resultOtherErrors = assetsDetailsValidationOnNext(leaseDetails);

  if (resultOtherErrors !== 1) {
    resultOtherErrors = otherValidationHandler(resultOtherErrors);
  } else {
    resultOtherErrors = [];
  }

  const preparedErrorMessages = [...errArrInArrays, ...resultOtherErrors];
  return preparedErrorMessages;
};
