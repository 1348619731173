import { Downgraded, useHookstate } from "@hookstate/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import { creditData } from "../../../../../configs/mainStore";
import { getFraudValidations } from "../../../../../services/creditFileApiCall";
import { MainSectionsWrapper } from "../../../MainSectionsWrapper/MainSectionsWrapper";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

export const FraudCheck = () => {
  const creditFileData: any = useHookstate(creditData);
  const usersData = creditFileData?.attach(Downgraded)?.get();

  const applicationId = usersData.applicationId[0];
  const [nicArray, setNicArray] = useState([]);
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [validationResult, setValidationResult]: any = useState({
    primaryApplicant: [],
    jointBorrower: [],
    guarantor: [],
  });

  const prepareNicArray = async () => {
    setIsLoading(true);
    const response = await getFraudValidations(applicationId);
    setNicArray(response);
    setIsLoading(false);
  };

  useEffect(() => {
    prepareNicArray();
  }, []);

  useEffect(() => {
    if (!nicArray) return;

    const resultKeys = Object.keys(nicArray);

    let newStatus = true;

    const preparedValidations: any = {
      primaryApplicant: {},
      jointBorrower: {},
      guarantor: {},
    };

    resultKeys?.forEach((key) => {
      const targetParticipants = nicArray[key];
      targetParticipants?.forEach((element: any) => {
        if (!element) return;

        if (element?.code !== 1) {
          newStatus = false;
        }

        if (preparedValidations[element.type][element.individualId]) {
          preparedValidations[element.type][element.individualId] = [...preparedValidations[element.type][element.individualId], element];
        } else {
          preparedValidations[element.type][element.individualId] = [element];
        }

        // preparedValidations?.[element.type].push(element);
      });
    });

    setStatus(newStatus);
    setValidationResult(preparedValidations);
  }, [nicArray]);

  const primaryApplicant = Object.keys(validationResult.primaryApplicant);
  const jointBorrower = Object.keys(validationResult.jointBorrower);
  const guarantor = Object.keys(validationResult.guarantor);

  if (isLoading) {
    return <Stack
      alignItems="center"
      justifyContent={"center"}
      height={"100px"}
    >
      <Stack width={"300px"} direction={"row"} spacing={2}>
        <CircularProgressComponent size={24} />
        <Typography color={"gray"}>Fraud Check...</Typography>
      </Stack>
    </Stack>;
  }

  return <MainSectionsWrapper showSubText={false} showStatus={false} title="Fraud Check" keyCode={undefined} noBtn={true} decisionState={status}>
    {primaryApplicant.length > 0 && (
      <>
        <Grid item p={2} mt={3} className="grey-bold-text">
          Primary Applicant
        </Grid>
        {primaryApplicant.map((element, index) => (
          <ValidationResultContainer key={index} results={validationResult.primaryApplicant[element]}>
            <Box p={2}>
              <Box mb={1} style={{ fontWeight: "bold" }}>
                Name : {validationResult.primaryApplicant[element]?.[0]?.name}
              </Box>
              <Box style={{ fontWeight: "bold" }}>NIC : {validationResult.primaryApplicant[element]?.[0]?.nic}</Box>
            </Box>
            {validationResult.primaryApplicant[element]?.map((item: any, index: number) => (
              <Tooltip key={crypto.randomUUID()} title={item.result} arrow>
                <Box>
                  <ValidationResults result={[item]} />
                </Box>
              </Tooltip>
            ))}
          </ValidationResultContainer>
        ))}
      </>
    )}

    {jointBorrower.length > 0 && (
      <>
        <Grid item p={2} mt={3} className="grey-bold-text">
          Joint Borrowers
        </Grid>
        <Grid container>
          {jointBorrower.map((element, index) => (
            <ValidationResultContainer key={"jb" + index} results={validationResult.jointBorrower[element]}>
              <Box p={2}>
                <Box style={{ fontWeight: "bold" }} mb={1}>
                  {" "}
                  Name : {validationResult.jointBorrower[element]?.[0]?.name}
                </Box>
                <Box style={{ fontWeight: "bold" }}> NIC : {validationResult.jointBorrower[element]?.[0]?.nic}</Box>
              </Box>
              {validationResult.jointBorrower[element]?.map((item: any, index: number) => (
                <Tooltip key={crypto.randomUUID()} title={item.result} arrow>
                  <Box>
                    <ValidationResults result={[item]} />
                  </Box>
                </Tooltip>
              ))}
            </ValidationResultContainer>
          ))}
        </Grid>
      </>
    )}

    {guarantor.length > 0 && (
      <>
        <Grid item p={2} mt={3} className="grey-bold-text">
          Guarantors
        </Grid>
        {guarantor.map((element, idx) => (
          <ValidationResultContainer key={"grt" + idx} results={validationResult.guarantor[element]}>
            <Box p={2}>
              <Box mb={1} style={{ fontWeight: "bold" }}>
                Name : {validationResult.guarantor[element]?.[0]?.name}
              </Box>
              <Box style={{ fontWeight: "bold" }}> NIC : {validationResult.guarantor[element]?.[0]?.nic}</Box>
            </Box>
            {validationResult.guarantor[element]?.map((item: any, index: number) => (
              <Tooltip key={crypto.randomUUID()} title={item.result} arrow>
                <Box>
                  <ValidationResults result={[item]} />
                </Box>
              </Tooltip>
            ))}
          </ValidationResultContainer>
        ))}
      </>
    )}
  </MainSectionsWrapper>;
};

export const ValidationResults = ({ result }) => {
  return result?.map((element, idx) => <Grid item key={idx}>
    <Box display="flex" m={1}>
      <Box>{element?.code === 2 ? <InfoIcon sx={{ color: "#FF0000" }} /> : <CheckCircleIcon sx={{ color: "#00AB71" }} /> }</Box>
      <Box ml={2}>{element[element.code == 2 ? "decision" : "decision"]}</Box>
    </Box>
  </Grid>);
};

export const ValidationResultContainer = (props) => {
  const status = props?.results?.every((result) => result?.code === 1);

  return (
    <Grid
      item
      lg={props?.containerWidth ? props?.containerWidth : 4}
      style={{
        padding: "10px",
        color: "#000",
        borderRadius: "10px",
        border: `2px solid ${status ? "#39a21a" : "#f25b5b"}`,
      }}
      m={2}
    >
      {props?.children}
    </Grid>
  );
};
