import { Downgraded, useHookstate } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { getCopy } from "../../../../../../../../../../utility/util";
import { Toast } from "../../../../../../../../GlobalToast";
import { CircularProgressComponent } from "../../../../../../../../ProgressComponent/ProgressComponent";
import { businessDetailsState } from "../../../../../FormBuilder/Tools/Helpers/inspectionStores/dataStore";
import { workingCapitalWorkerFile } from "../../../../../FormBuilder/Tools/Helpers/Workers/workingCapitalWorkerFile";
import { SubSectionHeaderBuilder } from "../../../../../FormBuilder/Tools/Widgets/SubSectionHeaderBuilder";
import { inspectionBusinessLiabilityExpensesType, otherDirectCostType } from "../../../../../Helpers/utility";
import { WorkingCapitalCreditors } from "../Creditors/WorkingCapitalCreditors";
import { WorkingCapitalDebtors } from "../Debtors/WorkingCapitalDebtors";
import YearlyWorkingCapitalBuilder from "../YearlyWorkingCapitalBuilder";
import CurrentLiabilities from "./CurrentLiabilities";
import FinishedGoods from "./FinishedGoods";
import OtherCurrentAssets from "./OtherCurrentAssets";
import RawMaterialRequirement from "./RawMaterialRequirement";
import TotalValueContainer from "./TotalValueContainer";
import WorkinProgress from "./WorkinProgress";

const WorkingCapital = ({ otherParams }) => {
    const business: any = useHookstate(businessDetailsState);
    const [isLoading, setIsLoading] = React.useState(false);

    const onClick = useCallback(() => {
        setIsLoading(true);
        if (!business) return;

        const {
            deductionsCostsOfGoods = undefined,
            businessExpenses = undefined,
            businessIncome = undefined,
            totaleBusinessCurrentAssertOtherDetails = undefined,
            businessLiabilityExpenses = undefined
        } = business.attach(Downgraded).get() || {};

        if (!deductionsCostsOfGoods || !businessExpenses || !businessIncome || !totaleBusinessCurrentAssertOtherDetails || !businessLiabilityExpenses) return;

        const response = workingCapitalWorkerFile({
            e: getCopy({
                mainParams: { deductionsCostsOfGoods, businessExpenses, businessIncome, totaleBusinessCurrentAssertOtherDetails, businessLiabilityExpenses },
                otherParams: {
                    otherDirectCostType, liabilityExpensesType: inspectionBusinessLiabilityExpensesType
                }
            })
        });

        if (response?.status === -1) {
            Toast.error(response?.message);
        }
        else {
            business.workingCapital.set(prev => ({ ...prev, ...response?.workingCapitalData, isSynced: true }));
        }

        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [business?.deductionsCostsOfGoods?.value, business?.businessExpenses?.value, business?.businessIncome?.value]);

    if (isLoading) return <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'}><CircularProgressComponent size={25} /></Stack>

    return (
        <Stack width={'100%'} spacing={2}>
            <YearlyWorkingCapitalBuilder key={'WorkingCapital'} id={`WorkingCapital`} data={business.workingCapital} otherParams={otherParams} Component={WorkingCapitalNode}
                syncItems={{
                    isVisible: !business.workingCapital?.isSynced.get(),
                    onclick: onClick,
                    btnLabel: 'Sync',
                    label: '💡Click the Button For Auto Populate Data From Business Income, Business Expenses, Deductions Costs Of Goods and Total Business Current Assets Other Details'
                }}
            />
        </Stack>
    )
}

export default WorkingCapital

function WorkingCapitalNode({ data, otherParams }) {

    const grossProfit = useMemo(() => {

        const cal = (data) => {
            let total = 0;
            data.rawMaterial.forEach(item => {
                total += Number(item.amount);
            })

            data.dirrectCost.forEach(item => {
                total += Number(item.amount);
            })

            data.fixedCost.forEach(item => {
                total += Number(item.amount);
            })
            return total
        }

        const values = data.get();

        let totalRawMaterialRequirement = values.rawMaterialRequirement.reduce((acc, item) => acc + item.amount, 0);
        let totalWorkInProgress = cal(values.workInProgress);
        let totalFinishedGoods = cal(values.finishedGoods);
        let debtors = values.debtors.reduce((acc, item) => acc + item.amount, 0);
        let currentAssets = values.currentAssets.reduce((acc, item) => acc + item.amount, 0);

        return totalRawMaterialRequirement + totalWorkInProgress + totalFinishedGoods + debtors + currentAssets;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.workInProgress.get(), data.rawMaterialRequirement.get(), data.finishedGoods.get()]);

    const totalCurrentLiabilities = useMemo(() => {
        let total = 0;
        data.creditors.forEach(item => {
            total += Number(item.amount.get());
        })

        data.currentLiabilities.forEach(item => {
            total += Number(item.amount.get());
        })

        return total;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.creditors.get(), data.currentLiabilities.get()]);

    const netWorkingCapitalRequirement = useMemo(() => {
        return (Number(grossProfit) - Number(totalCurrentLiabilities) || 0);
    }, [grossProfit, totalCurrentLiabilities]);

    useEffect(() => {
        data["grossProfit"].set(grossProfit);
        data["totalCurrentLiabilities"].set(totalCurrentLiabilities);
        data["netWorkingCapitalRequirement"].set(netWorkingCapitalRequirement);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [netWorkingCapitalRequirement]);

    return (
        <Grid container rowGap={2}>
            <Grid item xs={12}>
                <RawMaterialRequirement data={data.rawMaterialRequirement} otherParams={otherParams} id={"rawMaterialRequirement"} />
            </Grid>
            <Grid item xs={12}>
                <WorkinProgress data={data.workInProgress} otherParams={otherParams} id={"WorkinProgress"} />
            </Grid>
            <Grid item xs={12}>
                <FinishedGoods data={data.finishedGoods} otherParams={otherParams} id={"finishedGoods"} />
            </Grid>
            <Grid item xs={12}>
                <SubSectionHeaderBuilder metaData={{ title: "Debtors" }} />
            </Grid>
            <Grid item xs={12}>
                <WorkingCapitalDebtors data={data.debtors} otherParams={otherParams} />
            </Grid>
            <Grid item xs={12}>
                <SubSectionHeaderBuilder metaData={{ title: "Other Current Assets" }} />
            </Grid>
            <Grid item xs={12}>
                <OtherCurrentAssets data={data.currentAssets} otherParams={otherParams} />
            </Grid>
            <TotalValueContainer label={"Gross Working Capital Requirement"} total={grossProfit} />
            <Grid item xs={12}>
                <SubSectionHeaderBuilder metaData={{ title: "Creditors" }} />
            </Grid>
            <Grid item xs={12}>
                <WorkingCapitalCreditors data={data.creditors} otherParams={otherParams} />
            </Grid>
            <Grid item xs={12}>
                <SubSectionHeaderBuilder metaData={{ title: "Other Current Liabilities" }} />
            </Grid>
            <Grid item xs={12}>
                <CurrentLiabilities data={data.currentLiabilities} otherParams={otherParams} />
            </Grid>
            <TotalValueContainer label={"Total Current Liabilities"} total={totalCurrentLiabilities} />
            <TotalValueContainer label={"Net Working Capital Requirement"} total={netWorkingCapitalRequirement} />
        </Grid>
    )

}