import { useState } from "@hookstate/core";
import { formStructureSet } from "../../configs/mainStore";
import { contactDetailsValidationOnNext } from "../../configs/validations/application-form-on-next-validation-handlers/contact-details/contactDetailsValidationOnNext";
import { primaryIdentificationDetailsValidationOnNext } from "../../configs/validations/application-form-on-next-validation-handlers/primary-applicant-details/primaryIdentificationDetailsValidationOnNext";
import { validateMandatory } from "../../configs/validations/validation-rules/validationRules";
import { removeNullValues } from "../../services/removeNullValues";
import { isArray, isObject } from "../helpers/getObjectTypes";

export const useValidateSubmit = () => {
  const appFormSet: any = useState(formStructureSet);

  const sectionMapping = {
    "primary-applicant-details": "personalData",
    "credit-details": "creditData",
    "contact-details": "contactData",
    "address-details": "addressData",
    "education-details": "educationData",
    "employment-details": "employedData",
    "income-details": "incomeData",
    "expenses-details": "expenseData",
    "bank-details": "bankData",
    "assets-details": "assetsData",
    "guarantor-details": "guarantorData",
    "security-details": "securityData",
    "joint-borrower-details": "jointBorrowerData",
    "tax-details": "taxDetailsData",
    "analysis-of-the-loan-requirement": "analysisOfLoanRequirementData",
    "risk-conditions-general-facts": "risksConditionsAndGeneralFactsData",
    "inquiry-of-obligations": "inquiryOfObligationsData",
    "reports-previous-borrowings": "previousBorrowingsData",
    "business-details": "businessData",
    "life-insurance-details": "lifeInsuranceData",
    "lease-details": "leaseAssetsData",
    "insurance-details": "insuranceData"
  };

  const onNextValidationMapping = {
    "primary-applicant-details": primaryIdentificationDetailsValidationOnNext,
    "contact-details": contactDetailsValidationOnNext,
  };

  const validate = (states, order: any = []) => {
    let response: any = [];

    // handle objects values
    const handleObjects = (referenceFields, value, parentKey = "") => {
      const fieldSet = Object.keys(value);

      for (let selectedField in fieldSet) {
        if (isObject(value[fieldSet[selectedField]])) {
          handleObjects(referenceFields, value[fieldSet[selectedField]], fieldSet[selectedField]);
        }
        if (isArray(value[fieldSet[selectedField]])) {
          handleArrays(referenceFields, value[fieldSet[selectedField]], fieldSet[selectedField]);
        }

        checkMandatory(referenceFields[fieldSet[selectedField]], value[fieldSet[selectedField]], fieldSet[selectedField], selectedField);
      }
    };

    // handle array values
    const handleArrays = (referenceFields, fieldSet, parentKey = "") => {
      for (let selectedField in fieldSet) {
        if (isObject(fieldSet[selectedField])) {
          handleObjects(referenceFields, fieldSet[selectedField]);
        }
        if (isArray(fieldSet[selectedField])) {
          handleArrays(referenceFields, fieldSet[selectedField]);
        }
      }
    };

    //validation handler
    const validatorHandler = (fields, currentSectionState) => {
      const sectionStateKeys = Object.keys(currentSectionState);

      for (let stateKey in sectionStateKeys) {
        // handle objects states
        if (isObject(currentSectionState[sectionStateKeys[stateKey]])) {
          handleObjects(fields, currentSectionState[sectionStateKeys[stateKey]]);
        }

        // handle array states
        if (isArray(currentSectionState[sectionStateKeys[stateKey]])) {
          handleArrays(fields, currentSectionState[sectionStateKeys[stateKey]]);
        }

        checkMandatory(fields[sectionStateKeys[stateKey]], currentSectionState[sectionStateKeys[stateKey]], sectionStateKeys[stateKey], stateKey);
      }
    };

    //mandatory validator
    const checkMandatory = (targetField, value, fieldKey, index) => {
      if (targetField?.["visible"] === 1 && targetField?.["mandatory"] === 1) {
        if (validateMandatory(value)) {
          response[response.length] = {
            key: fieldKey,
            message: `${fieldKey} is required`,
            // index: index,
          };
        }
      }
    };

    //loop through the json structure
    for (let section in order) {
      //fields according to main section
      let fields: any = {};

      if (order[section]?.["visible"] === 0) {
        return;
      }

      if (order[section]?.["key"] === "security-details" || order[section]?.["key"] === "joint-borrower-details") {
        const participantType = order[section]?.["key"] === "security-details" ? 2 : 3;

        const structure = appFormSet?.filter((form) => form?.PARTICIPANT_TYPE?.get() == participantType)?.[0]?.get()?.FORM_STRUCTURE;

        const dataArray: any = order[section]?.["key"] === "security-details" ? states[sectionMapping[order[section]?.["key"]]]?.guarantors : states[sectionMapping[order[section]?.["key"]]];

        let response2: any = {};

        for (let guarantor in dataArray) {
          response2 = validate(dataArray[guarantor], JSON.parse(structure));

          if (response2?.mandatoryValidations?.length > 0) {
            if (response2.mainSectionKey) {
              response2.mainSectionKey = order[section]?.["key"];
              response2["parentSection"] = participantType == 2 ? "Guarantors" : "Joint Borrowers";
              response2["index"] = guarantor;
            }

            break;
          }
        }

        if (response2?.mandatoryValidations?.length > 0) {
          return response2;
        }

        if (response2?.otherValidationResponse && Object.keys(response2.otherValidationResponse)?.length > 0) {
          return response2;
        }
      }

      //getting fields of a main section
      order[section]?.content?.forEach((subSection) => {
        fields = { ...fields, ...subSection?.["fields"] };
      });

      //current selected main section state
      const currentSectionState = states[sectionMapping[order[section]?.["key"]]]?.get() ? JSON.parse(JSON.stringify(states[sectionMapping[order[section]?.["key"]]].value)) : {};

      if (Object.keys(currentSectionState)?.length === 0) {
        continue;
      }

      //if state is an array
      if (isArray(currentSectionState)) {
        for (let sectionObject in currentSectionState) {
          validatorHandler(fields, currentSectionState[sectionObject]);
        }
      }

      //id state is an object
      if (isObject(currentSectionState)) {
        validatorHandler(fields, currentSectionState);
      }

      const otherValidationResponse = onNextValidationMapping[order[section]?.["key"]]?.(removeNullValues(currentSectionState));

      if (response?.length > 0)
        return {
          mainSectionKey: order[section]?.["key"],
          mandatoryValidations: response,
          otherValidationResponse,
        };
    }

    return response;
  };

  return validate;
};


export const otherValidationHandler = (validationData) => {

  if (Array.isArray(validationData)) {
    let resultErrorArray: string[] = [];

    validationData.forEach((item, index) => {
      let result = otherValidationHandler(item);
      resultErrorArray = [...resultErrorArray, `${index !== 0 ? "\n" : ""}⚠️ Section Item ${index + 1} fields Error`, ...result]
    });

    return resultErrorArray;
  }

  let resultErrorArray: string[] = [];

  validationData = Object.values(validationData);

  return validationData.reduce((validationArray: string[], validatedItem) => {

    if (Array.isArray(validatedItem)) {

      validatedItem.forEach((item) => {
        if (item.status === -1 || item.status === -2) {
          validationArray.push(item.message)
        }
      })
    }

    return validationArray;
  }, [])
}