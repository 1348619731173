import { Box, Grid } from "@mui/material"
import { useMemo } from "react"
import { formatCurrency } from "../../../../../../../../../../services/currencyFormater"
import { OnDemandValueFormatter } from "../../../../../../../../../../services/onDemandValidators"
import { TextBoxComponent } from "../../../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent"
import { SubSectionHeaderBuilder } from "../../../../../FormBuilder/Tools/Widgets/SubSectionHeaderBuilder"
import Table from "./Table"

const WorkinProgress = ({ otherParams, data, id, title = "Work in Progress" }) => {

    const total = useMemo(() => {

        let total = 0;
        data.rawMaterial.get().forEach(item => {
            total += Number(item.amount);
        })

        data.dirrectCost.get().forEach(item => {
            total += Number(item.amount);
        })

        data.fixedCost.get().forEach(item => {
            total += Number(item.amount);
        })

        return total

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.rawMaterial.get(), data.dirrectCost.get(), data.fixedCost.get()])

    return <Grid container rowGap={2}>
        <Grid item xs={12}>
            <SubSectionHeaderBuilder metaData={{ title }} />
        </Grid>
        <Grid item xs={12}>
            <Table data={data.rawMaterial} otherParams={{
                ...otherParams, description: "Raw Material"
            }} />
        </Grid>
        <Grid item xs={12}>
            <Table data={data.dirrectCost} otherParams={{
                ...otherParams, description: "Direct Cost"
            }} />
        </Grid>
        <Grid item xs={12}>
            <Table data={data.fixedCost} otherParams={{
                ...otherParams, description: "Fixed Cost"
            }} />
        </Grid>

        <Grid item xs={12} >
            <Box paddingX={2}>
                <Grid container columnGap={1} justifyContent={"space-between"}>
                    <Grid item xs={8}>
                        <TextBoxComponent style={{ backgroundColor: "#D3D3D3", fontWeight: "bold" }} label={"Total"} value={""} fullWidth={true} onChange={(e) => { }} disabled={true} />
                    </Grid>
                    <Grid item xs={3}>
                        <TextBoxComponent style={{ backgroundColor: "#D3D3D3", fontWeight: "bold" }} label={""} value={formatCurrency(total)} onInput={OnDemandValueFormatter.numeric} onChange={(e) => { }} disabled={true} />
                    </Grid>
                </Grid>
            </Box>
        </Grid>

    </Grid>
}

export default WorkinProgress