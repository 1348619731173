import { Stack, Typography } from "@mui/material";
import { widthDefault } from "../../../Helpers/utility";

export const SubSectionHeaderBuilder = ({ metaData }) => {
  return (
    <Stack width={widthDefault} my={1} px={2}>
      <Stack direction={"row"} sx={{ backgroundColor: "gray" }} borderRadius={"5px"} p={1}>
        <Typography variant="subtitle2" color={"white"} p={1}>
          {metaData?.title}
        </Typography>
      </Stack>
    </Stack>
  );
};
