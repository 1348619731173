import { Downgraded, createState, useHookstate } from '@hookstate/core';
import { useCallback, useMemo } from 'react';
import { InsuranceDetails, LeaseDetails, addressDetails, assetsDetails, autoSettleCCBillsState, bankDetails, businessDetails, contactDetails, creditDetails, eStatementState, educationDetails, employedDetails, expenseDetails, guarantorDetails, incomeDetails, inquiryOfObligationsDetails, joinBorrowerDetails, lifeInsuranceDetails, nonRelatedReferenceState, personalDetails, sectionExpandData, securityDetails, singleCustomerViewDetails, supplementaryCardState, taxDetails } from '../../configs/mainStore';
import { BankDataState, addressDataState, assetsDataState, businessDataState, contactDataState, creditDataState, educationDataState, employeeDataState, expenseDataState, incomeDataState, individualTaxState, inquiryOfObligationsObject, insuranceDataState, leaseDataState, lifeInsurancedataDef, otherParticipantState, personalDataState, resetAutoSettleCCBillsState, resetDefaultNonRelatedReferenceState, resetSupplementaryCardState, securityDataState } from '../../configs/stores/applicationForm';
import { applicantTypeStructure } from '../../services/applicationFormApis';
import { getCopy } from '../util';

export const currentStepIndex = createState<number | string>('0');

type SelectedSteps = Record<number, string[]>;

export type ApplicationFormStore = {
    personalData: any;
    creditData: any;
    singleCustomerViewData: any;
    contactData: any;
    addressData: any;
    educationData: any;
    employedData: any;
    businessData: any;
    incomeData: any;
    expenseData: any;
    assetsData: any;
    lifeInsuranceData: any;
    bankData: any;
    guarantorData: any;
    jointBorrowerData: any;
    taxDetailsData: any;
    securityData: any;
    inquiryOfObligationsData: any;
    supplementaryCard: any;
    eStatements: any;
    nonRelatedReference: any;
    autoSettleCCBills: any;
    leaseAssetsData: any;
    insuranceData: any;
}

const defaultData = () => {
    return {
        personalData: JSON.parse(JSON.stringify(personalDataState)),
        creditData: JSON.parse(JSON.stringify(creditDataState)),
        contactData: JSON.parse(JSON.stringify(contactDataState)),
        addressData: JSON.parse(JSON.stringify(addressDataState)),
        educationData: JSON.parse(JSON.stringify(educationDataState)),
        employedData: JSON.parse(JSON.stringify(employeeDataState)),
        businessData: JSON.parse(JSON.stringify(businessDataState)),
        incomeData: JSON.parse(JSON.stringify(incomeDataState)),
        expenseData: JSON.parse(JSON.stringify(expenseDataState)),
        bankData: [JSON.parse(JSON.stringify(BankDataState))],
        assetsData: [JSON.parse(JSON.stringify(assetsDataState))],
        jointBorrowerData: [JSON.parse(JSON.stringify(otherParticipantState))],
        taxDetailsData: JSON.parse(JSON.stringify(individualTaxState)),
        inquiryOfObligationsData: JSON.parse(JSON.stringify(inquiryOfObligationsObject)),
        lifeInsuranceData: JSON.parse(JSON.stringify(lifeInsurancedataDef)),
        securityData: JSON.parse(JSON.stringify(securityDataState)),
        supplementaryCard: JSON.parse(JSON.stringify(resetSupplementaryCardState)),
        nonRelatedReference: getCopy(resetDefaultNonRelatedReferenceState),
        autoSettleCCBills: getCopy(resetAutoSettleCCBillsState),
        leaseAssetsData: [JSON.parse(JSON.stringify(leaseDataState))],
        insuranceData: [JSON.parse(JSON.stringify(insuranceDataState))],
        
    }
}

export const useApplicationFormStore = () => {
    const personalData: any = useHookstate(personalDetails);
    const eStatements: any = useHookstate(eStatementState);
    const creditData: any = useHookstate(creditDetails);
    const singleCustomerViewData: any = useHookstate(singleCustomerViewDetails);
    const contactData = useHookstate(contactDetails);
    const addressData = useHookstate(addressDetails);
    const educationData = useHookstate(educationDetails);
    const employedData = useHookstate(employedDetails);
    const businessData = useHookstate(businessDetails);
    const incomeData = useHookstate(incomeDetails);
    const expenseData = useHookstate(expenseDetails);
    const assetsData = useHookstate(assetsDetails);
    const bankData = useHookstate(bankDetails);
    const guarantorData = useHookstate(guarantorDetails);
    const jointBorrowerData = useHookstate(joinBorrowerDetails);
    const taxDetailsData = useHookstate(taxDetails);
    const securityData = useHookstate(securityDetails);
    const inquiryOfObligationsData = useHookstate(inquiryOfObligationsDetails);
    const supplementaryCard = useHookstate(supplementaryCardState);
    const nonRelatedReference = useHookstate(nonRelatedReferenceState);
    const autoSettleCCBills = useHookstate(autoSettleCCBillsState);
    const lifeInsuranceData = useHookstate(lifeInsuranceDetails);
    const leaseAssetsData = useHookstate(LeaseDetails);
    const insuranceData = useHookstate(InsuranceDetails);

    return {
        personalData, creditData, singleCustomerViewData,
        contactData, addressData, educationData,
        employedData, businessData, incomeData,
        expenseData, assetsData, lifeInsuranceData,
        bankData, guarantorData, jointBorrowerData,
        taxDetailsData, securityData, inquiryOfObligationsData,
        eStatements, supplementaryCard, nonRelatedReference,
        autoSettleCCBills,leaseAssetsData,insuranceData
    }
};

/*  applicationRequestId is APPLICATION REQUET ID. NOT APPLICATION REFERENCE OR APPLICATION ID */
export const useClearFormSubcribtions = () => {
    const applicationsStates = useApplicationFormStore();
    const sectionExpand = useHookstate(sectionExpandData);
    const applicationDefaultObjects = JSON.parse(JSON.stringify(defaultData()));
    const currentStep = useHookstate(currentStepIndex);

    const clear = () => {

        if (typeof applicationDefaultObjects === 'object' && Object.keys(applicationDefaultObjects).length > 0) {
            Object.keys(applicationDefaultObjects).forEach((applicationFormKey) => {

                if (applicationDefaultObjects[applicationFormKey]) {
                    applicationsStates[applicationFormKey].set(applicationDefaultObjects[applicationFormKey]);
                }
            })
        }
        delete sessionStorage.applicationRequestDtl;
        sectionExpand?.set({ participant: "primary", section: 1, key: "personalData" });
        currentStep.set(0);
    }

    return { clear };

}

export const useApplicationFormStructureHanlder = () => {

    const stepIndexDelegation = useCallback((_participantTypeWiseForm, participantType) => {
        let props = _participantTypeWiseForm[applicantTypeStructure[participantType]].reduce(({ steps, formsContent }, currItem) => {

            let currStepIndex: number | string = currItem.stepIndex;

            if (currStepIndex || currStepIndex == 0) {
                let previousSteps: string[] = steps?.[currStepIndex] || [];

                steps = {
                    ...steps,
                    [currStepIndex]: [...previousSteps, currItem.key]
                }

                formsContent = {
                    ...formsContent,
                    [currItem.key]: currItem
                }

                return { steps, formsContent }
            }
            return { steps, formsContent }
        }, {});

        return { ...props, }

    }, [])

    return { stepIndexDelegation }
}

export const useSpecialEventListiningDelegations = (selectedSteps) => {
    const { personalData } = useApplicationFormStore();

    // const jointBorrowerHandlingDelegation = (selectedSteps: SelectedSteps): SelectedSteps => {
    //     if (typeof selectedSteps !== 'object' || !Object.keys(selectedSteps).length) {
    //         return selectedSteps;
    //     }

    //     const sectionKey = "joint-borrower-details";

    //     Object.values(selectedSteps).forEach((valueArray: string[], index: number) => {
    //         const array: string[] = valueArray;

    //         if (array.includes(sectionKey)) {
    //             delete selectedSteps[index];
    //         }
    //     });

    //     return selectedSteps || {};
    // }

    const taxPayerHandlingDelegations = (selectedSteps: SelectedSteps): SelectedSteps => {
        const sectionKey = "tax-details";

        const newPayLoad = Object.values(selectedSteps).reduce((stepObj, item: string[], index: number) => {
            let array: string[] = item;

            if (array.includes(sectionKey)) {
                array = array.filter((key) => key !== sectionKey);
                return { ...stepObj, [index]: array }
            }
            return { ...stepObj, [index]: item }
        }, {} as SelectedSteps) || {};

        return newPayLoad;
    }

    let sanitizedSteps = useMemo(() => {
        let steps = selectedSteps
        if (!selectedSteps || !Object.keys(selectedSteps).length) return selectedSteps;

        const { taxPayer = '0' }: any = personalData.attach(Downgraded).get()

        if (taxPayer == 0) {
            steps = taxPayerHandlingDelegations(steps)
        }

        return steps;
    }, [personalData.taxPayer.get(), selectedSteps])


    const reOrderStepsFromIndex = (selectedSteps) => Object.keys(selectedSteps).reduce((stepObj, step, index) => {
        return { ...stepObj, [index]: selectedSteps[step] }
    }, {})

    return { selectedSteps: reOrderStepsFromIndex(sanitizedSteps) }
}
