import { Box, Grid } from '@mui/material'
import { formatCurrency } from '../../../../../../../../../../services/currencyFormater'
import { OnDemandValueFormatter } from '../../../../../../../../../../services/onDemandValidators'
import { TextBoxComponent } from '../../../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent'

const TotalValueContainer = ({ label, total }) => {
    return (
        <Grid item xs={12} >
            <Box paddingX={2}>
                <Grid container columnGap={1} justifyContent={"space-between"}>
                    <Grid item xs={8}>
                        <TextBoxComponent style={{ backgroundColor: "#D3D3D3", fontWeight: "bold" }} label={label} value={""} fullWidth={true} onChange={(e) => { }} disabled={true} />
                    </Grid>
                    <Grid item xs={3}>
                        <TextBoxComponent style={{ backgroundColor: "#D3D3D3", fontWeight: "bold" }} label={""} value={formatCurrency(total)} onInput={OnDemandValueFormatter.numeric} onChange={(e) => { }} disabled={true} />
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}

export default TotalValueContainer