import { Downgraded } from "@hookstate/core";
import { loanAmountRanges } from "../../../configs/constants/contants";
import { productDetails } from "../../../configs/mainStore";
import { leadCreditInfoRevampValidationHandler } from "../../../configs/validations/leadvalidations-handlers/LeadCreditDetailsRevampValidationhandler";
import { errorMessageGenerator2 } from "../../../configs/validations/validate-message-generator/errorMessageGenerator";
import AppConfig from "../../AppConfig";
import { getCopy } from "../../util";
import { keyMapping, messageMappings } from "./messageMapping";
import { validateWithStructure } from "./structureValidation";

export const creditDetails = (structure, state, isGuarantor = false, isJointBorrower = false, participantState, isSecuritySectionNext?) => {
  let creditData = getCopy({ ...state });
  let preparedErrorMessages: string[] = []

  let finalKeyArr: any = [];
  let errorArray = validateWithStructure(structure, state);

  finalKeyArr = errorArray?.length > 0 ? errorArray : finalKeyArr;

  if (!state?.type || !state?.sector || !state?.scheme) {
    return messageMappings(finalKeyArr);
  }

  const productCatalog = [creditData?.type, creditData?.sector, creditData?.scheme].join("-");
  let errorArrays: Array<string[]> = loanAmountValidator(finalKeyArr, productCatalog, creditData?.loanAmount, preparedErrorMessages);

  finalKeyArr = errorArrays[0];
  preparedErrorMessages = errorArrays[1];

  const productCatelogKeyData = productDetails?.["productCatalogData"]?.allProductCatalogsWithKeys?.get();
  const errorResponse: Object | number = leadCreditInfoRevampValidationHandler(creditData, productCatelogKeyData);

  if (errorResponse !== 1) {
    preparedErrorMessages = [...preparedErrorMessages, ...errorMessageGenerator2(errorResponse)]
  }

  if (Boolean(AppConfig?._isStable !== 1)) {
    preparedErrorMessages.push("Product Mandatory Validation Error")
  }

  return preparedErrorMessages.concat(messageMappings(finalKeyArr));
};

const loanAmountValidator = (errorArray: string[], productCatalog: string, creditLoanAmount: string, preparedErrorMessagesArray: string[]) => {

  const productCatelogKeyData = productDetails?.["productCatalogData"]?.allProductCatalogsWithKeys?.attach(Downgraded).get();
  const { default: { max, min } } = loanAmountRanges
  const { MIN_LOAN_AMOUNT: minLoanAmount = min, MAX_LOAN_AMOUNT: maxLoanAmount = max } = productCatelogKeyData[productCatalog];

  if (creditLoanAmount === "") {
    if (!errorArray.includes("loanAmount")) {
      errorArray.push("loanAmount")
      return [errorArray, preparedErrorMessagesArray];
    }
  }

  let loanAmount = parseFloat(creditLoanAmount);

  if (loanAmount <= minLoanAmount) {
    preparedErrorMessagesArray.push(keyMapping["minLoanAmount"]);
    return [errorArray, preparedErrorMessagesArray]
  }

  if (loanAmount > maxLoanAmount) {
    preparedErrorMessagesArray.push(keyMapping["maxLoanAmount"])
    return [errorArray, preparedErrorMessagesArray]
  }

  return [errorArray, preparedErrorMessagesArray];
}
