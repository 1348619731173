import { Grid } from "@mui/material";
import React from "react";
import { formatCurrency } from "../../../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../../../services/onDemandValidators";
import { TextBoxComponent } from "../../../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { FieldSectionWrapper } from "../../../../../../../../FieldSectionsWrapper.tsx/FieldSectionWrapper";
import { SubSectionsWrapper } from "../../../../../../../../SubSectionsWrapper/SubSectionsWrapper";
import GridDataTable from "../../../../../FormBuilder/Tools/Helpers/Components/GridDataTable";

export const WorkingCapitalCreditors = ({ otherParams, data: creditorsData }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const { isAmountAboveTwoPointFiveAndEqual } = otherParams;

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <SubSectionsWrapper>
          <FieldSectionWrapper>
            <GridDataTable
              openModal={openModal}
              totalBtnVisility={false}
              setOpenModal={setOpenModal}
              sourceArray={creditorsData}
              addButtonInvisible={true}
              headerTitle={["Description", "Total Credit Deductions", "Number of Days", "Amount"]}
            >
              {creditorsData
                ?.filter((itemObj) => !itemObj?.removedItem?.get())
                .map((items, index) => (
                  <Grid item xs={12} md={12} key={`working-capital-creditors-${items?.dataId.get() || index}`}>
                    <Grid container gap={1}>
                      <Grid item xs={2}>
                        <TextBoxComponent
                          label={"Description"}
                          required={Boolean(isAmountAboveTwoPointFiveAndEqual)}
                          value={items?.description?.get()}
                          fullWidth={true}
                          onChange={(e) => items?.description?.set(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextBoxComponent
                          label={"Total Credit Deductions"}
                          required={Boolean(isAmountAboveTwoPointFiveAndEqual)}
                          value={formatCurrency(items?.operatingCosts?.get()) || 0}
                          onInput={OnDemandValueFormatter.numeric}
                          fullWidth={true}
                          onChange={(e) => items?.operatingCosts?.set(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextBoxComponent
                          label={"Number of Days"}
                          onBlur={(e) => {
                            const calc = (Number(items?.operatingCosts?.get() || 0) / 365) * Number(e.target.value);
                            items?.amount?.set(calc);
                          }}
                          required={Boolean(isAmountAboveTwoPointFiveAndEqual)}
                          value={items?.numberOfDays?.get()}
                          fullWidth={true}
                          onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value <= 500) {
                              items?.numberOfDays?.set(e.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextBoxComponent
                          label={""}
                          style={{ backgroundColor: "#D3D3D3", fontWeight: "bold" }}
                          required={Boolean(isAmountAboveTwoPointFiveAndEqual)}
                          value={formatCurrency(items?.amount?.get()) || 0}
                          onInput={OnDemandValueFormatter.numeric}
                          fullWidth={true}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </GridDataTable>
          </FieldSectionWrapper>
        </SubSectionsWrapper>
      </Grid>
    </Grid>
  );
};
