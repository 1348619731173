import React from "react";
import WorkingCapital from "../../InspectionFormParking/BusinessInspections/OtherComponents/WorkingCapitals/RevaampWC/WorkingCapital";
import MainSectionBuilder from "../MainSectionBuilder";
import { manipulateAndDestrutureCompositeComponents } from "./ManipulateAndDestrutureCompositeComponents";
import AutoPopulatedLabel from "./Widgets/AutoPopulatedLabel";
import BurrowerNameNicComposite from "./Widgets/BorrowerNameNic";
import Column from "./Widgets/Column";
import DatePickerBuilder from "./Widgets/DatePickerBuilder";
import BusinessBorrowerRoadMap from "./Widgets/FileUploadBusinessRoadMap";
import InspectionDocumentUpload from "./Widgets/FileUploadDropone";
import GoogleMapContainer from "./Widgets/GoogleMap/GoogleMapComponent";
import Lable from "./Widgets/Lable";
import PhoneNumberBuilder from "./Widgets/PhoneNumberBuilder";
import Row from "./Widgets/Row";
import SelectComponentsBuilder from "./Widgets/SelectComponentsBuilder";
import { SubSectionHeaderBuilder } from "./Widgets/SubSectionHeaderBuilder";
import { TableContainerBuilder, TableRowBuilder } from "./Widgets/Table/TableContainer";
import {
  TextBoxBuilder,
  TextBoxCurrencyBuilder,
  TextBoxNumberBuilder,
  TextBoxYearBuilder,
} from "./Widgets/TextBoxBuilder";
import InpectionFormOfficerDetails from "./Widgets/ThirdPartyComponents/InpectionFormOfficerDetails";
import { OtherQuestionsInCollaterals } from "./Widgets/ThirdPartyComponents/OtherQuestionsInCollaterals";
import TimePickerBuilder from "./Widgets/TimePickerBuilder";
import ToggleLabel from "./Widgets/ToggleLabel";
import Wrapper from "./Widgets/Wrapper";
import CheckBoxBuilder from "./Widgets/checkBoxBuilder";

const BusinessFixedAssets = React.lazy(
  () => import("../../InspectionFormParking/BusinessInspections/OtherComponents/BusinessAssets/BusinessFixedAssets/BusinessFixedAssets")
);

const BusinessCurrentAssets = React.lazy(
  () => import("../../InspectionFormParking/BusinessInspections/OtherComponents/BusinessAssets/BusinessCurrentAssets/BusinessCurrentAssets")
);

const Creditors = React.lazy(
  () => import("../../InspectionFormParking/BusinessInspections/OtherComponents/WorkingCapitals/Creditors/Layout")
);
const Debtors = React.lazy(
  () => import("../../InspectionFormParking/BusinessInspections/OtherComponents/WorkingCapitals/Debtors/Layout")
);
const BusinessLiabilityExpenses = React.lazy(
  () => import("../../InspectionFormParking/BusinessInspections/OtherComponents/BusinessLiabilityExpenses/BusinessLiabilityExpenses")
);
const SalesandPurchaseInformationofFastMovingItems = React.lazy(
  () =>
    import(
      "../../InspectionFormParking/BusinessInspections/OtherComponents/SalesAndPurchaseInformationOfFastMovingItems/SalesandPurchaseInformationofFastMovingItems"
    )
);
const ParticipationDataSection = React.lazy(
  () => import("../../InspectionFormParking/InspectionParticipation/ParticipationDataSection")
);
const BusinessExpenses = React.lazy(
  () => import("../../InspectionFormParking/BusinessInspections/OtherComponents/BusinessExpenses/BusinessExpenses")
);
const EmployementDetails = React.lazy(
  () => import("../../InspectionFormParking/BusinessInspections/OtherComponents/EmployementDetails")
);
const BusinessThirdPartyDetails = React.lazy(
  () => import("../../InspectionFormParking/BusinessInspections/OtherComponents/BusinessThirdPartyDetails")
);
const BusinessIncome = React.lazy(
  () => import("../../InspectionFormParking/BusinessInspections/OtherComponents/BusinessIncome/BusinessIncome")
);

const EpfEtfPayForEmployees = React.lazy(
  () => import("../../InspectionFormParking/BusinessInspections/OtherComponents/EpfEtfPayForEmployees")
);

const MarketingArrangement = React.lazy(() => import("../../InspectionFormParking/BusinessInspections/OtherComponents/MarketingArrangement"));

const DeductionsCostsOfGoods = React.lazy(() => import("../../InspectionFormParking/BusinessInspections/OtherComponents/DeductionsCostsOfGoods/DeductionsCostsOfGoods"));

export const endWidgets = {
  textArea: TextBoxBuilder,
  currency: TextBoxCurrencyBuilder,
  date: DatePickerBuilder,
  time: TimePickerBuilder,
  number: TextBoxNumberBuilder,
  text: TextBoxBuilder,
  column: Column,
  row: Row,
  wrap: Wrapper,
  button: TextBoxBuilder,
  spacedCapitalize: TextBoxBuilder,
  compositeComponents: manipulateAndDestrutureCompositeComponents,
  google_map: GoogleMapContainer,
  SUB_TITLE: SubSectionHeaderBuilder,
  MAIN_TITLE: MainSectionBuilder,
  borrowerNameNic: BurrowerNameNicComposite,
  label: Lable,
  autoPopulatedLabel: AutoPopulatedLabel,
  toggleLabel: ToggleLabel,
  table: TableContainerBuilder,
  mobile: PhoneNumberBuilder,
  tableRow: TableRowBuilder,
  select: SelectComponentsBuilder,
  radioOption: CheckBoxBuilder,
  filePicker: BusinessBorrowerRoadMap,
  imgDocumentAttach: InspectionDocumentUpload,
  justifyEndBtn: OtherQuestionsInCollaterals,
  year: TextBoxYearBuilder,
};

export const thirdPartyComponants = {
  salesandPurchaseInformationofFastMovingItemsdata: SalesandPurchaseInformationofFastMovingItems,
  businessFixedAssets: BusinessFixedAssets,
  businessCurrentAssets: BusinessCurrentAssets,
  businessLiabilityExpenses: BusinessLiabilityExpenses,
  businessIncome: BusinessIncome,
  businessExpenses: BusinessExpenses,
  workingCapital: WorkingCapital,
  workingCapitalDebtors: Debtors,
  workingCapitalCreditors: Creditors,
  employementDetails: EmployementDetails,
  ParticipationDataSection: ParticipationDataSection,
  businessThirdPartyDetails: BusinessThirdPartyDetails,
  shareholderDetails: BusinessThirdPartyDetails,
  inspectionOfficerDetails: InpectionFormOfficerDetails,
  epfEtfPayForEmployees: EpfEtfPayForEmployees,
  markettingArrangement: MarketingArrangement,
  deductionsCostsOfGoods: DeductionsCostsOfGoods,
};
