import { validateDate, validateEmail, validateExtentOrRegNo, validateLandLineNumber, validatelength, validateMobileNumber, validatePositiveNumberWithDecimalPlaces, validateTextWithCharactersSpacesAndDots, validateTextWithCharactersSpacesAndNumbersCommasFullstop } from "../../validation-rules/validationRules";

export const insuranceDetailsValidationOnNext = (changes) => {
  const insuranceDetailsErrors: any = {};
  for (let i = 0; i < changes?.length; i++) {
    if (!changes[i].removedItem) {
      const companyNameVal = changes[i]?.companyName ? companyNameValidation(`${i + 1} : Company Name`, changes[i]?.companyName) : null;
      const businessRegNoVal = changes[i]?.businessRegNo ? businessRegNoValidation(`${i + 1} :Business Registration No.`, changes[i]?.businessRegNo) : null;

      const companyAddressVal = changes[i]?.companyAddress ? companyAddressValidation(`${i + 1} : Company Address`, changes[i].companyAddress) : null;

      const emailAddressVal = changes[i]?.emailAddress ? emailAddressValidation(`${i + 1} : Email Address`, changes[i].emailAddress) : null;

      const mobileVal = changes[i]?.mobile ? mobileValidation(`${i + 1} : Mobile`, changes[i].mobile) : null;
      const telephoneVal = changes[i]?.telephone ? telephoneValidation(`${i + 1} : TelePhone`, changes[i].telephone) : null;

      if (companyNameVal || businessRegNoVal || companyAddressVal || emailAddressVal || telephoneVal || mobileVal) {
        insuranceDetailsErrors[i] = {
          companyName: companyNameVal,
          businessRegNo: businessRegNoVal,
          companyAddress: companyAddressVal,
          emailAddress: emailAddressVal,
          telephone: telephoneVal,
          mobile: mobileVal
        };
      }
    }
  }

  return Object.keys(insuranceDetailsErrors).length > 0 ? insuranceDetailsErrors : 1;
};

export const companyNameValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkExtentRegNo = validateTextWithCharactersSpacesAndDots(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkExtentRegNo) result.push(checkExtentRegNo);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const businessRegNoValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkBusinessRegNo = validatePositiveNumberWithDecimalPlaces(fieldName, value, 2);
  const checkLength = validatelength(fieldName, value);
  if (checkBusinessRegNo) result.push(checkBusinessRegNo);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

const companyAddressValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkLength = validateTextWithCharactersSpacesAndNumbersCommasFullstop(fieldName, value);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

const emailAddressValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkLength = validateEmail(fieldName, value);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};


const mobileValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkLength = validateMobileNumber(fieldName, value);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

const telephoneValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkLength = validateLandLineNumber(fieldName, value);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};