import { Grid } from "@mui/material";
import { womenEntrepreneursData } from "../../../../../../configs/constants/contants";
import { formatCurrency } from "../../../../../../services/currencyFormater";
import isProductOf from "../../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import MandatoryFilterComponent from "../../../../../DataValidationComponents/MandatoryFilterComponent";
import { ContactDetails } from "../Employment/ContactDetails";
import { EmailDetails } from "../Employment/EmailDetails";
import { AuthorityCertificationDetails } from "./AuthorityCertificationDetails";
import { BusinessAddress } from "./BusinessAddress";
import { addItem, duration } from "./Helpers";

export const BusinessDetailsMeta = ({ formStructure, business, masterData, isMainContactDetails, sectionKey }) => {
  const typedData = (masterType, key) => masterData[masterType]?.get()?.find((t) => t.id === key);
  const businessContent = formStructure?.["business-details"]?.content || [];
  const structure = businessContent.length ? businessContent[0].fields : {};

  const businessExperience = () => {
    return duration(business, "noOfYearsInExperience", "noOfMonthsInExperience");
  };

  const operationExperience = () => {
    return duration(business, "NoYearsOperation", "noOfMonthsInOperation");
  };

  const businessSector = typedData("BUSINESS_SECTOR", business?.businessSector);
  const businessSubSector = typedData("BUSINESS_SUB_SECTOR", business?.businessSubSector);
  const typeOfBusiness = typedData("TYPE_OF_BUSINESS", business?.typeOfBusiness);
  const businessCategory = typedData("BUSINESS_CATEGORY", business?.businessCategory);
  const primaryFocusArea = typedData("PRIMARY_FOCUS_AREA", business?.primaryFocusArea);
  const environmentalSubcategories = typedData("ENVIRONMENTAL_SUBCATEGORIES", business?.environmentalSubcategories);
  const sectionIndex = () => {
    return "0" + (sectionKey + 1);
  };

  const genarateYearsAndMonths = () => {
    const years = business?.NoYearsOperation;
    const months = business?.noOfMonthsInOperation;

    if (!years && !months) {
      return "N/A";

    } else if (years && months) {
      return `${years || "N/A"} Years ${months || "N/A"} Months`;

    } else if (years) {
      return `${years || "N/A"} Years`;

    } else if (months) {
      return `${months || "N/A"} Months`;
    }
  };

  return (
    <>
      <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
        {business?.nameOfTheBusiness ? `Business Details - ${business?.nameOfTheBusiness}` : `Business Details - ${sectionIndex()}`}
      </Grid>
      <MandatoryFilterComponent items={business} mandatory={["typeOfBusiness", "businessSector", "startDate"] as never[]} options={{ title: "Business Details" }}>
        <Grid container>
          <Grid container item p={1} spacing={1} className="data-box">
            {business?.typeOfBusiness !== 8 && (
              <Grid className="basic-font basic-font-color-bold font-size-16 align-left">{operationExperience()} in operation</Grid>
            )}
          </Grid>
          <Grid container item p={1} spacing={1} className="data-box">
            {addItem(business, "typeOfBusiness", typeOfBusiness?.name ?? "N/A", false, false)}
            {addItem(business, "businessSector", businessSector?.name ?? "N/A", false, false)}
            {addItem(business, "businessSubSector", businessSubSector?.name ?? "N/A", false, false)}
            {addItem(business, "natureOfBusiness", business?.natureOfBusiness ?? "N/A", false, false)}
            {addItem(business, "businessRegNo", business?.businessRegNo ?? "N/A", false, false)}
            {addItem(business, "businessCategory", businessCategory?.name ?? "N/A", false, false)}
            {addItem(business, "brDate", business?.brDate ?? "N/A", false, true)}
            {addItem(business, "startDate", business?.startDate ?? "N/A", false, true)}
            {addItem(business, "noOfEmployees", business?.noOfEmployees ?? "N/A", false, false)}
            {addItem(business, "noOfFemaleEmployees", business?.noOfFemaleEmployees ?? "N/A", false, false)}
            {addItem(business, "noOfMaleEmployees", business?.noOfMaleEmployees ?? "N/A", false, false)}
            {
              Boolean(structure?.womenLedBusiness?.visible) && <>
                {addItem(business, "womenLedBusiness", business?.womenLedBusiness ? "Yes" : "No", false, false)}
                {
                  Boolean(business?.womenLedBusiness) && <>{addItem(business, "envOfThewomenLedBusiness",
                    womenEntrepreneursData?.[business?.envOfThewomenLedBusiness as number]?.name || "N/A", false, false)}</>
                }
              </>
            }

            {addItem(business, "website", business?.website ?? "N/A", false, false)}
            {business?.taxPayment ? addItem(business, "Tax payment", business.taxPayment ? "YES" : "NO", false, false) : <></>}
            {addItem(business, "Years Operation", genarateYearsAndMonths(), false, false)}
            {addItem(business, "businessExperience", businessExperience(), false, false)}
            {
              isProductOf({ primaryApplicant: isMainContactDetails }) && <>
                {addItem(business, "Current Year Sales", formatCurrency(business?.currentYearSales) ?? "N/A")}
                {addItem(business, "Previous Year Sales", formatCurrency(business?.previousYearSales) ?? "N/A")}
              </>}
            {Boolean(structure?.sustainableFinance?.visible) && <>
              {business?.sustainableFinance === 1 ? addItem(business, "Primary Focus Area", primaryFocusArea?.name ?? "N/A", false, false) : <></>}
              {business?.primaryFocusArea === 1 && business?.sustainableFinance === 1 ? addItem(business, "Environmental Subcategories", environmentalSubcategories?.name ?? "N/A", false, false) : null}
            </>}
          </Grid>
          {business?.authorityCertifications?.length > 0 && (
            <AuthorityCertificationDetails business={business} />
          )}
          {business?.contactTypes?.filter((obj) => obj)
            ?.filter((obj) => !obj.removedItem)?.map((type, index) => (
              <ContactDetails contactDetails={type} key={index} index={index} nohead={false} isBusinessDetails={true}></ContactDetails>
            ))}

          {[business?.address]?.map((obj, index) => (
            <BusinessAddress key={index} address={obj}></BusinessAddress>
          ))}

          {business?.emailTypes
            ?.filter((obj) => obj)
            ?.filter((obj) => !obj.removedItem)
            ?.map((type, index) => (
              <EmailDetails emailDetails={type} key={index} index={index} nohead={true}></EmailDetails>
            ))}
        </Grid>
      </MandatoryFilterComponent>
    </>
  );
};
