import { State, useHookstate } from "@hookstate/core";
import SyncIcon from '@mui/icons-material/Sync';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Divider, Paper, Stack, Tab, Typography } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { ButtonComponent } from "../../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { CircularProgressComponent } from "../../../../../../../ProgressComponent/ProgressComponent";

type SyncItems = {
    isVisible: boolean
    onclick: () => void
    btnLabel?: string
    btnStartIcon?: React.ReactNode
    label?: string
}

const YearlyWorkingCapitalBuilder = ({ data, otherParams, id, Component, syncItems }: { data: State<any>; otherParams: any; id: string; Component: React.ElementType, syncItems?: SyncItems }) => {
    const yearArray = useHookstate<string[]>([]);
    const isLoading = useHookstate(true);
    const selectedTab = useHookstate<string>('')

    const fnPreIntiliaze = () => {
        const currentYear = (moment().year()).toString();
        try {
            const useSelectedYears: string[] = Object.keys(data).sort((a, b) => Number(a) - Number(b));
            yearArray.set(useSelectedYears);
            selectedTab.set(useSelectedYears.includes(currentYear) ? currentYear : useSelectedYears[0]);
        } catch (error) {
            throw new Error(`${error}`);
        } finally {
            isLoading.set(false);
        }
    }

    useEffect(() => {
        fnPreIntiliaze();
    }, [])

    if (isLoading.get()) {
        return <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgressComponent size={25} />
        </Stack>
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number | string) => {
        newValue = newValue.toString()
        selectedTab.set(newValue)
    }

    return (
        <Stack padding={3.5} component={Paper} m={1}>
            <TabContext value={selectedTab.get()}>
                <TabList onChange={handleChange} aria-label="Working Capital - Stocks" sx={{ zIndex: 500 }}>
                    {yearArray.get().map((year, index) => <Tab key={`tab-${year}`} label={year} value={year.toString()} />)}
                </TabList>
                <Divider />
                {Boolean(syncItems?.isVisible) && (
                    <Stack direction={"row"} sx={{ backgroundColor: "#7fb4de3d" }} borderRadius={"5px"} p={1} my={2} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant="subtitle2" color={"gray"} p={1} display={syncItems?.label ? "flex" : "none"}>
                            {syncItems?.label || ""}
                        </Typography>
                        <ButtonComponent startIcon={syncItems?.btnStartIcon || <SyncIcon />} title={syncItems?.btnLabel || ""} variant="outlined" onClick={syncItems?.onclick} color="info" />
                    </Stack>
                )}
                {yearArray.get().map((year, index) => {
                    const props = { otherParams, data: data?.[year] }

                    return <TabPanel key={`${id}-tab-panel-${year}`} value={year}>
                        <Component {...props} />
                    </TabPanel>
                })}
            </TabContext>
        </Stack>
    )
}

export default
    YearlyWorkingCapitalBuilder;