import { Box, Typography } from "@mui/material";
import A4Container from "../A4Container";
import EditableField from "../EditableField";

const DeedOfAssignment = () => {
    return (
        <A4Container>
            <Typography variant="h6" sx={{ textAlign: "center", fontWeight: "bold" }}>
                DEED OF ASSIGNMENT
            </Typography>

            <Typography variant="body1" sx={{ marginTop: 2, lineHeight: 1.6 }}>
                This Deed of Assignment is made on this
                <EditableField label="Date" /> at
                <EditableField label="Location" /> by
                <EditableField label="Assignor Name" />, holder of
                NIC No. <EditableField label="NIC No." />, in favor of
                <EditableField label="Assignee Name" />, holder of NIC No.
                <EditableField label="NIC No." />.
            </Typography>

            <Typography variant="body1" sx={{ marginTop: 2 }}>
                <span style={{ fontWeight: "bold" }}>1. Assigned Rights:</span> The Assignor hereby transfers all rights, title, and interest in
                the asset described as <EditableField label="Asset Description" /> to the
                Assignee, in consideration of LKR <EditableField label="Amount" />.
            </Typography>

            <Typography variant="body1" sx={{ marginTop: 2 }}>
                <span style={{ fontWeight: "bold" }}>2. Warranties:</span> The Assignor declares that the asset is free from encumbrances and
                indemnifies the Assignee from any claims arising from previous ownership.
            </Typography>

            <Box sx={{ marginTop: 4 }} display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={"bold"}>Assignor:</Typography>
                <Typography>Signature: ______________________</Typography>
                <Typography>Name: <EditableField label="Assignor Name" /></Typography>
            </Box>

            <Box sx={{ marginTop: 2 }} display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={"bold"}>Assignee:</Typography>
                <Typography>Signature: ______________________</Typography>
                <Typography>Name: <EditableField label="Assignee Name" /></Typography>
            </Box>
        </A4Container>
    );
};
export default DeedOfAssignment;