/*-------------------------PAPER DOCS-------------------------*/
export const fdAgainstLoansDefault = {
  fdValueOfSecurity: "",
  fdSecuredValue: "",
  fdDescription: "",
  fdLoanDetailsAccountNumber: "",
  fdCustomerNumber: "",
  fdLoanDetailsAccountOpenedOn: "",
  fdPurposeCode: "",
  fdFixedDepositAccountNumber: "",
  fdContractNumber: "",
  fdDepositDate: "",
  fdCurrency: "",
  fdPrincipalAmount: "",
  fdInterestAccured: "",
  fdMaturityDate: "",
  fdDepositInterestRateCbs: "",
  fdDepositAmount: "",
  fdMargin: "",
  fdAmountAvaibleForLoan: "",
  fdTotalFdAmount: "",
  fdTotalOfMaximumGrantingLadAmount: "",
  fdSactionedRefNumber: "",
  fdSanctionedAmount: "",
  fdSactionedBy: "",
  fdExpiryDate: "",
  fdDepositInterestRateFd: "",
  fdWeightedAverageDepositRate: "",
  fdIncrementalInterestAboveTheDepositRate: "",
  fdEffectiveInterestRate: "",
  fdRemarksInterestDetails: "",
  fdCreditAmount: "",
  fdSourceOfFundType: "",
  fdTransactionCurrency: "",
  fdTransactionAmount: "",
  fdSavingAccountAvaibleBalanace: "",
  actionIsCash: false,
  fdAmountByCash: "",
  actionIsBankTransfer: false,
  fdamountByTransfer: "",
  fdLoanAccountNumber: "",
  fdAccountNumberSavings: "",
  fdRemarksSettlementsDetails: "",
  fdGLAccessCode: "",
  fdEffectiveDate: "",
  fdProductCode: "",
  fdAccountCurrency: "",
  fdLoanAutoRecoveryAccountOpenedOn: "",
  fdTenorFixedDeposit: "",
  actionAutoInstallmentRecoveryRequired: false,
  fdLoanAutoRecoveryAccountNumber: "",
  fdAccountName: "",
};

export const loanProtectInsurance = {
  lpIssuingAuthority: "",
  lpInsuranceDetailsDescription: "",
  lpInsuranceAgreementPolicy: "",
  lpPolicyNumber: "",
  lpDocumentNumber: "",
  lpNoOfDocuments: "",
  lpFaceValue: "",
  lpDueDate: null,
  lpDescription: "",
  lpPolicyDate: null,
};

export const sharesDefault = {
  shareValueOfSecurity: "",
  shareSecuredValue: "",
  shareDescription: "",
  shareCertificateNo: "",
  shareIssuingDate: null,
  shareNumberOfShares: "",
  shareNominalValueOfShare: "",
  shareMarketValueOfShare: "",
};

export const treasuryBondDefault = {
  tbValueOfSecurity: "",
  tbSecuredValue: "",
  tbDescription: "",
  tbLetterNumber: "",
  tbIssuingAuthority: "",
  tbMatureDate: null,
  tbBondAmount: "",
  tbCoupounRate: "",
  tbSpecialConditions: "",
};

export const warehouseReceiptDefault = {
  whrValueOfSecurity: "",
  whrSecuredValue: "",
  whrDescription: "",
  whrReceiptNo: "",
  whrLocation: "",
  whrCropDepositorName: "",
  whrNic: "",
  isNew: false,
  whrAddress: "",
  whrGrainDepositedInWarehouse: "",
  whrDepositedDate: "",
  whrPhoneNumber: "",
  whrQuantity: "",
  whrNoOfBags: "",
};

export const lifeInsuranceDefault = {
  liValueOfSecurity: "",
  liSecuredValue: "",
  liDescription: "",
  liInsuranceCompanyCode: "",
  liRegistrationDate: "",
  liPolicyNumber: "",
  liDocumentNumber: "",
  liNoOfDocuments: "",
  liFaceValue: "",
  liMarketValue: "",
  liDueDate: "",
  liSurrendableValue: "",
  liDateCommencementInsurance: "",
  liDateInsurancePolicyCertified: "",
  liDateConfirmationLetterInsurance: "",
  liDateConfirmationLetterAgeAccepted: "",
  actionWhetherInsurancePolicySampledThirdParty: false,
  liDateLetterSignedThirdParty: "",
  liDateSignedLetterBeneficiaries: "",
};

/*-------------------------MOVABLE-------------------------*/
export const machineryEquipmentDefault = {
  mmValueOfSecurity: "",
  mmSecuredValue: "",
  mmDescription: "",
  mmMachineType: "",
  mmChasisNumber: "",
  mmEngineNumber: "",
  mmHPOrLienNotedOn: "",
  mmCapacity: "",
  mmModel: "",
  mmMakeHpOrCc: "",
  mmAddress: "",
  mmDistrict: "",
  mmCity: "",
  mmManufacturerName: "",
  mmManufactureCode: "",
  mmYearOfManufacture: "",
  mmRegistrationNumber: "",
  mmExpiryDate: "",
  mmPurchasedYear: "",
  mmCondition: "",
  mmSourceOfPower: "",
  mmSupplierOrDealerName: "",
  mmSupplierAddress: "",
  mmSuppliersDistrict: "",
  mmSuppliersCity: "",
  mmThirdPartyDealerCode: "",
  mmInvoiceNumber: "",
  mmInvoiceDate: "",
  mmInvoicePurchaseValue: "",
  mmTaxType: "",
  mmTaxNumber: "",
  mmTaxAmount: "",
  actionDuplicateKeyReceived: false,
  duplicateKeyNumber: "",
  mmLifespanNumberOfYears: "",
  mmLifespanNumberOfUnits: "",
  mmLifespanDepretiationRate: "",
  actionWarrantyAvailable: false,
  // natureOfWarrenty: "",
  dealersName: "",
  dealersContactNumber: null,
  warrantyStartDate: null,
  warrantyEndDate: null,
  actionInsuranceAvailable: false,
  insuranceCompanyName: "",
  insurancePolicyNumber: "",
  insuredAmount: "",
  policyExpiryDate: null,
  policyNextRenewalDate: null,
  insurancePremium: "",
  benificiaryName: "",
  mmMarketValue: "",
  mmCountryOfManufacture: "",
  mmUsedPeriodAfterPurchased: "",
  dateCommencementInsurance: "",
  mmMovablePropertyMortgageDeedNo: "",
  mmForceSaleValue: "",
  mmLawyerPreparedMortgageDeed: "",
  mmDatePreparedMortgageDeed: "",
  mmDateRegistrationMortgageDeed: "",
  mmOfficeNameRegistrationMortgageDeed: "",
  mmFinalRegisteredFolioNumber: "",
  mmDateFinalRegisteredFolio: "",
};

export const pledgeDefault = {
  pledgeValueOfSecurity: "",
  pledgeSecuredValue: "",
  pledgeDescription: "",
  pledgeInsuaranceValue: "",
  pledgeLoanToValueRatio: "",
  pledgeForceSaleValue: "",
  pledgeOwnership: "",
  pledgeOwarehousep: "",
  pledgeCropType: "",
  pledgeWeightOfPledge: "",
  pledgeTaxPeriod: "",
  actionOwnershipOfWarehouses: false,
  pledgeLeaseAgreementNumber: "",
  pledgeDateOfLeaseAgreement: "",
  pledgeCustomerType: "",
  pledgeRegistrationNumber: "",
  actionWarehouseDetailsRequired: false,
  pledgeWarehouseAddress: "",
  pledgeDistrict: "",
  pledgeCity: "",
  pledgeConditionOfTheWarehouse: "",
  pledgeApproximateDistanceFromTheBranch: "",
  actionStocksUnderBankLock: false,
  pledgeDateBanksLockKey: "",
  pledgeKeyNumber: "",
  pledgeStatementTemplate: "",
  pledgeStatementFreq: "",
  pledgeStockStatementValidUpToDate: null,
  pledgeToBeSubmittedBeforeGraceDays: "",
  actionInsuranceAvailable: false,
  insuranceCompanyName: "",
  insurancePolicyNumber: "",
  insuredAmount: "",
  policyExpiryDate: null,
  policyNextRenewalDate: "",
  insurancePrimium: "",
  benificiaryName: "",
  coveredPolicy: false,
  pledgeValueOfStock: "",
  pledgeAgreementNumber: "",
  pledgeDatePreparePledgeAgreement: "",
  pledgeNameLawyerPreparedPledgeAgreement: "",
  pledgeInventoryNumber: "",
  pledgeDateOfInventory: "",
  pledgeCumulativeWeight: "",
  pledgeAccumulatedValue: "",
  pledgeInventoryDetailsPricePerUnit: "",
  dateCommencementInsurance: "",
  pledgeDateOfInspection: "",
  pledgePretPricePerUnit: "",
  pledgePreQuantityOfUnitsTested: "",
  pledgePreTotalValueChecked: "",
  pledgePreStockQualityDetails: "",
};

export const vehicleDefault = {
  vhValueOfSecurity: "",
  vhSecuredValue: "",
  vhDescription: "",
  vhVehicleCatergory: "",
  vhVehicleMake: "",
  vhVehicleModel: "",
  vhEngineNo: "",
  vhChassisNo: "",
  vhForceSaleValue: "",
  vhCReleasedDate: null,
  vhFuelType: null,
  vhIntendedUse: "",
  vhRegitrationNumber: null,
  vhYearOfRegistration: null,
  vhValuerName: "",
  vhValuerRegNumber: "",
  vhValuerDate: null,
  vhManufacturerName: "",
  vhManufacturerCode: "",
  vhYearOfManufacture: null,
  vhCountryOfOrigin: "",
  vhYearOfVehiclePurchase: null,
  vhSupplierOrDealerName: "",
  vhSupplierAddress: "",
  vhThirdPartyDelaerCode: "",
  vhInvoiceNumber: "",
  vhInvoiceDate: null,
  vhInvoiceOrPurchaseValue: "",
  vhTaxType: null,
  vhTaxNumber: "",
  vhTaxAmount: "",
  actionRecondition: false,
  actionBrandNewRecondition: false,
  actionSecondHand: false,
  actionDuplicateKeyReceived: false,
  duplicateKeyNumber: "",
  actionWarrantyAvailable: false,
  // natureOfWarrenty: "",
  dealersName: "",
  dealersContactNumber: "",
  warrentyStartDate: null,
  warrentyEndDate: null,
  actionInsuranceAvailable: false,
  insuranceCompanyName: "",
  insurancePolcyNumber: null,
  insuredAmount: "",
  policyExpiryDate: null,
  policyNextRenewalDate: null,
  insurancePrimium: "",
  benificiaryName: "",
  vhInsuranceValue: "",
  vhAssetClass: "",
  vhUseAsset: "",
  vhColour: "",
  vhNameValuationOfficer: "",
  vhAddressValuationOfficer: "",
  vhContactNumberValuationOfficer: "",
  vhContactNumberSupplier: "",
  dateCommencementInsurance: "",
  vhKeptAtAddress: null,
};

export const stockAsGoldDefault = {
  sagValueOfSecurity: "",
  sagSecuredValue: "",
  sagDescription: "",
  sagNoOfJewelsOrPieces: "",
  sagArticleType: null,
  sagDescriptionOfTheOrnamentsOrArticle: "",
  sagKaratageOfArticle: null,
  sagGrossWeight: "",
  sagNetWeight: "",
  sagMarketRatePerGram: "",
  sagMarketValue: "",
  sagPermissibleRatePerGram: "",
  sagPermissibleValue: "",
  insuranceCompanyName: "",
  benificiaryName: "",
  insurancePrimium: "",
  policyNextRenewalDate: null,
  policyExpiryDate: null,
  insuredAmount: "",
  insurancePolicyNumber: "",
  coveredPolicy: "",
  actionInsuranceAvailable: false,
};

/*-------------------------IMMOVABLE-------------------------*/
export const propertyDefault = {
  propValueOfSecurity: "",
  // propSecuredValue: "",
  propDescription: "",
  propTypeOfMortagage: "",
  propDetailsOfPrimary: "",
  propMortagageName: "",
  propDateOfMortagage: "",
  propAddressOfMortgage: "",
  propDistric: "",
  propCity: "",
  propAddressOfTheLocationOfTheLandOrBuilding: "",
  propLocationCode: "",
  propSurveyPlanDate: "",
  propSuveryPlanNo: "",
  propLotNo: "",
  propDeedOrTitleCertificateNumber: "",
  propLocalAuthority: "",
  propLandMeasurementType: "",
  propLxtentOfLand: "",
  actionPropertyOrLandIsHavingBuilding: false,
  propBuildUpAreaOfBuildings: "",
  propLandValuationRate: "",
  propExtentOfLand: "",
  propRegistrationNumber: "",
  propRegistratonDate: "",
  propBuildingValue: "",
  approvedBuildingPlan: "",
  actionSpecialConditionOfApprovedBulidingPlan: "",
  propLandValue: "",
  propRoadWidth: "",
  propTypeofRoad: "",
  propRemarks: "",
  actionLegalOpinionObtained: false,
  propObtainedDate: null,
  propProvince: "",
  propVillage: "",
  propGramNiladariDivision: "",
  propDivisionalSecretaries: "",
  propPradeshiyaSabhaLimists: "",
  propValuationOfficerName: "",
  propMortgageDeedNumber: "",
  propDatePreparedMortgageDeed: "",
  propDateRegistrationMortgageDeed: "",
  propOfficeRegistrationMortgageDeed: "",
  propJurisdictionRegistrationMortgageDeed: "",
  propFinalRegisteredFolioNumber: "",
  propDateFinalRegisteredFolio: "",
  propIssuedDateTitleReport: "",
  proplawyerIssuedTitleReport: "",
  propForceSaleValue: "",
  propTitleDeedNumber: "",
  propNameNotaryPreparedDeed: "",
  propDateNotaryPreparedDeed: "",
  propDateRegistrationDeed: "",
  propNameSurveyorMeasuredPlan: "",
  propDateRegisteredPlan: "",
  propDateIssueStreetLineNonVestingCertificate: "",
  propNameAuthorityCertifiedBuildingPlan: "",
  propDateCertifiedBuildingPlan: "",
  propDateValuation: "",
  propNamePreparedBOQ: "",
  propPositionOfficerPreparedBOQ: "",
  propDatePreparedBOQ: "",
  dateCommencementInsurance: "",
  actionTitleInsurance: false,
  titleInsuranceCompanyName: "",
  titleInsurancePolicyNumber: "",
  titleInsuredAmount: "",
  insuranceCompanyName: "",
  insurancePolicyNumber: "",
  insuredAmount: "",
  policyExpiryDate: "",
  policyNextRenewalDate: null,
  insurancePrimium: "",
  coveredPolicy: "",
  benificiaryName: "",
};

export const immovableMachineryEquipementDefault = {
  immValueOfSecurity: "",
  immSecuredValue: "",
  immDescription: "",
  immMachineType: "",
  immChasisNumber: "",
  immEngineNumber: "",
  immHPOrLienNotedOn: "",
  immMakeHpOrCc: "",
  immAddress: "",
  immDistrict: "",
  immCity: "",
  immManufacturerName: "",
  immManufactureCode: "",
  immYearOfManufacture: "",
  immRegistrationNumber: "",
  immExpiryDate: "",
  immPurchasedYear: "",
  immCondition: "",
  immSourceOfPower: "",
  immSupplierOrDealerName: "",
  immSupplierAddress: "",
  immSuppliersDistrict: "",
  immSuppliersCity: "",
  immThirdPartyDealerCode: "",
  immInvoiceNumber: "",
  immInvoiceDate: "",
  immInvoicePurchaseValue: "",
  immTaxType: "",
  immTaxNumber: "",
  immTaxAmount: "",
  actionDuplicateKeyReceived: false,
  duplicateKeyNumber: "",
  immLifespanNumberOfYears: "",
  immLifespanNumberOfUnits: "",
  immLifespanDepretiationRate: "",
  actionWarrantyAvailable: false,
  natureWarranty: "",
  dealersName: "",
  dealersContactNumber: "",
  warrantyStartDate: null,
  warrantyEndDate: null,
  actionInsuranceAvailable: false,
  insuranceCompanyName: "",
  insurancePolicyNumber: "",
  insuredAmount: "",
  policyExpiryDate: "",
  policyNextRenewalDate: "",
  insurancePrimium: "",
  benificiaryName: "",
  coveredPolicy: "",
  immMarketValue: "",
  immCountryOfManufacture: "",
  immUsedPeriodAfterPurchased: "",
  dateCommencementInsurance: "",
  immMovablePropertyMortgageDeedNo: "",
  immForceSaleValue: "",
  immTitleInsurance: false,
  immTIInsuranceCompanyName: "",
  immTIInsurancePolicyNumber: "",
  immTIinsuredAmount: "",
  immLawyerPreparedMortgageDeed: "",
  immDatePreparedMortgageDeed: "",
  immDateRegistrationMortgageDeed: "",
  immOfficeNameRegistrationMortgageDeed: "",
  immFinalRegisteredFolioNumber: "",
  immCapacity: "",
  immModel: "",
  immDateFinalRegisteredFolio: "",
};
