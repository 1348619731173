import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Divider, Stack } from "@mui/material";
import React, { createRef, useEffect } from "react";
import { uploadApprovalFile } from "../../../../../../../../services/creditFileApiCall";
import { FileUploadComponent } from "../../../../../../DropzoneComponent/FileUploadComponent";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";


const PriorApprovalDocumentUpload = ({
  documentId,
  applicationRefId,
  fileList,
  setFileList,
}) => {
  const [status, setStatus] = React.useState(-1);
  const docUploadRef: any = createRef();
  const [refresh, setRefresh] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<any>(false);

  const onClickAddBtn = () => {
    setStatus(-1);
    let tempFileList = fileList;
    tempFileList.push({
      doc_index: fileList.length + 1,
      ref: docUploadRef,
      isMandatory: false,
      allowedFormats: "application/pdf",
      maxFileSize: 4000000,
      status,
    });

    setFileList(tempFileList);
    setRefresh(!refresh);
  };

  useEffect(() => {
    setFileList([
      {
        ref: docUploadRef,
        isMandatory: true,
        allowedFormats: "application/pdf",
        maxFileSize: 4000000,
        documentKey: 1,
        status: -1,
      },
    ]);
  }, []);

  return (
    <Stack spacing={2} mt={1} flex={1}>
      <Stack direction={"row"}>
        <div
          className="basic-font basic-font-color font-size-16 font-weight-100"
          style={{ marginBottom: 0, marginTop: 0, marginRight: 10 }}
        >
          Upload Inspection File
        </div>
        <AddCircleIcon style={{ color: "gray", fontSize: 25, cursor: "pointer" }} onClick={onClickAddBtn} />
      </Stack>

      <Divider></Divider>
      {isLoading ? (
        <Stack direction={"row"} justifyContent={"center"}>
          <CircularProgressComponent size={30} sx={{ top: "50%", right: "50%", position: "center", zIndex: 100 }} />
        </Stack>
      ) : (
        <Stack direction={"row"}>
          {fileList?.map((item, i) => (
            <FileUploadComponent
            key={`FileUploadComponent - ${i}`}
              fileDetails={item}
              uploadDataObject={{
                applicationId: applicationRefId,
                documentId: documentId,
                status: 0,
                docIndex: i,
              }}
              isPreviewHave={item.status != -1 ? true : false}
              fileUploadApi={uploadApprovalFile}
            />
          ))}
        </Stack>
      )}
      <Divider></Divider>
    </Stack>
  );
};

export default PriorApprovalDocumentUpload;
