import { yearArrayObject } from "../../../../../../CreditInspection/FormBuilder/Tools/Helpers/inspectionStores/inspectionMainStore";
import { onChangeHandler } from "../../Calculations/GenaralizedDataSetter";

export const generalizedSetStateFromTheBusinessData = ({ state, busineeData }) => {

    let yearArray: number[] = Object.values(yearArrayObject);

    yearArray = yearArray.sort((a, b) => a - b);

    const { businessIncome = {}, deductionsCostsOfGoods = {}, businessExpenses = {}, totaleBusinessAssertOtherDetails = {} } = busineeData;

    let itemObject = yearArray.reduce((dataObject: any, year: number) => {

        dataObject = onChangeHandler({ value: getTotal(businessIncome?.[year], "annualBusinessIncome"), yearDataKey: year, itemDataKey: "revenueIncome", formData: dataObject, selectedYears: yearArray });

        dataObject = onChangeHandler({ value: getTotal(deductionsCostsOfGoods?.[year]?.["rawMaterialsCost"], "annualDeductionsCOG"), yearDataKey: year, itemDataKey: "reductions", formData: dataObject, selectedYears: yearArray });

        dataObject = onChangeHandler({ value: getTotal(deductionsCostsOfGoods?.[year]?.["otherDirectCost"], "annualDeductionsCOG"), yearDataKey: year, itemDataKey: "reductions", formData: dataObject, selectedYears: yearArray });

        const { sellingDistributionExpenses, administrativeExpenses } = annualExpenseCalculationHandler(businessExpenses?.[year]);

        dataObject = onChangeHandler({ value: getTotal(sellingDistributionExpenses, "annualExpense"), yearDataKey: year, itemDataKey: "sellingDistributionExpenses", formData: dataObject, selectedYears: yearArray });

        dataObject = onChangeHandler({ value: getTotal(administrativeExpenses, "annualExpense"), yearDataKey: year, itemDataKey: "administrativeExpenses", formData: dataObject, selectedYears: yearArray });

        dataObject = onChangeHandler({ value: getTotal(totaleBusinessAssertOtherDetails?.[year], "depreciationExpense"), yearDataKey: year, itemDataKey: "depreciation", formData: dataObject, selectedYears: yearArray })

        return dataObject;
    }, {});


    let years = yearArray.map((year) => ({ title: year.toString(), dataKey: year.toString(), isEdited: 0, isRatioAnalysisCal: true, isAutoPopulatedColumns: false }));
    let followingYear = yearArray[yearArray.length - 1] + 1;

    years = [...years,
    { title: "Variable Rate", dataKey: "variableRate", isEdited: 0, isRatioAnalysisCal: false, isAutoPopulatedColumns: false },
    { title: followingYear.toString(), dataKey: followingYear.toString(), isEdited: 0, isRatioAnalysisCal: false, isAutoPopulatedColumns: false }
    ];

    let response = { data: itemObject, selectedYears: years };

    state(response);
}

const getTotal = (state, key: string) => {
    return Array.isArray(state) ? state.reduce((cal, item) => cal += Number(item?.[key] || 0), 0) : 0;
}

const annualExpenseCalculationHandler = (state) => {
    const response = { "sellingDistributionExpenses": [], "administrativeExpenses": [] }

    if (!Array.isArray(state)) return response;

    return state.reduce((filteredData, item) => {

        if (!item || item?.['removedItem']) return filteredData;

        if (item?.expenseType === "sellingDistributionExpenses") {
            filteredData.sellingDistributionExpenses.push(item)
        } else if (item?.expenseType === "administrativeExpenses") {
            filteredData.administrativeExpenses.push(item)
        }
        return filteredData
    }, response)
}