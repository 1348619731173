import URL from "../configs/connection";
import { ERROR_CODES } from "../configs/constants/errorCode";
import { endpoints } from "../configs/endpoints";
import Api from "./apiCallsHandler";
import CachingStorage from "./cacheStorage";
const api = Api.getApi();

const getSchemesByProductUrl = `${URL.REACT_APP_BACKEND_HOST}/los/schemes-by-Product?productCode=`;
const getCalInterestRateUrl = `${URL.REACT_APP_BACKEND_HOST}/los/calculate-interest-rate`;

//Product Type

export const getProductTypes = async () => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/get-types`,
      {}
    );
    return data;
  } catch (error) { }
};
export const getProductType = async (id) => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/get-type`,
      { id: id }
    );
    return data;
  } catch (error) { }
};
export const addProductType = async (typeData) => {
  try {
    const res = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/los/add-type`,
      typeData
    );
    return res;
  } catch (error) { }
};

export const editProductType = async (typeData) => {
  try {
    const res = await api.put(
      `${URL.REACT_APP_BACKEND_HOST}/los/edit-type`,
      typeData
    );
    return res;
  } catch (error) { }
};

export const inactiveProductType = async (typeData) => {
  try {
    const res = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/los/delete-type`,
      typeData
    );
    return res;
  } catch (error) { }
};

// Product Sector

export const getProductSectors = async () => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/get-sectors`,
      {}
    );
    return data;
  } catch (error) { }
};
export const getProductSector = async (id) => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/get-sector`,
      { id: id }
    );
    return data;
  } catch (error) { }
};
export const addProductSector = async (sectorData) => {
  try {
    const res = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/los/add-sector`,
      sectorData
    );
    return res;
  } catch (error) { }
};
export const editProductSector = async (sectorData) => {
  try {
    const res = await api.put(
      `${URL.REACT_APP_BACKEND_HOST}/los/edit-sector`,
      sectorData
    );
    return res;
  } catch (error) { }
};

export const inactiveProductSector = async (sectorData) => {
  try {
    const res = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/los/delete-sector`,
      sectorData
    );
    return res;
  } catch (error) { }
};

//Product Scheme

export const getProductSchemes = async () => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/get-schemes`,
      {}
    );
    return data;
  } catch (error) { }
};
export const getProductScheme = async (id) => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/get-scheme`,
      { id: id }
    );
    return data;
  } catch (error) { }
};
export const addProductScheme = async (schemeData) => {
  try {
    const res = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/los/add-scheme`,
      schemeData
    );
    return res;
  } catch (error) { }
};
export const editProductScheme = async (schemeData) => {
  try {
    const res = await api.put(
      `${URL.REACT_APP_BACKEND_HOST}/los/edit-scheme`,
      schemeData
    );
    return res;
  } catch (error) { }
};

export const inactiveProductScheme = async (schemeData) => {
  try {
    const res = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/los/delete-scheme`,
      schemeData
    );
    return res;
  } catch (error) { }
};

// Product type sector mapping

export const AddProductTypeSectorMapping = async (mappingData) => {
  try {
    const res = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/los/type-sector-mapping`,
      mappingData
    );
    return res;
  } catch (error) { }
};

export const AddProductSectorSchemeMapping = async (mappingData) => {
  try {
    const res = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/los/sector-scheme-mapping`,
      mappingData
    );
    return res;
  } catch (error) { }
};

export const getTypeSectorMappings = async () => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/type-sector-mappings`,
      {}
    );
    return data;
  } catch (error) { }
};

export const getSchemeSectorMappings = async () => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/sector-scheme-mappings`,
      {}
    );
    return data;
  } catch (error) { }
};

export const getMappingChanges = async () => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/get-changed-mappings`,
      {}
    );
    return data;
  } catch (error) { }
};

export const getOriginalMappingChanges = async () => {
  try {
    const { data } = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/get-original-mappings`,
      {}
    );
    return data;
  } catch (error) { }
};

export const saveMappingChanges = async (changes: any) => {
  // console.log("=== changes ===", changes);

  try {
    const res = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/los/save-changed-data`,
      changes
    );
    return res;
  } catch (error) { }
};

export const getSchemesByProduct = async (productRefCode) => {
  try {
    const { data } = await api.get(
      `${getSchemesByProductUrl}${productRefCode}`,
      {}
    );
    return data;
  } catch (error) { }
};

export const getCalculateInterestRate = async (appData) => {
  try {
    const res = await api.get(`${getCalInterestRateUrl}`, appData);
    return res;
  } catch (error) { }
};

export const getProductConditions = async (params) => {
  try {
    let url = `${endpoints.productConditions}`;
    const qparam: any = [];
    if (params.scheme) {
      qparam.push("scheme=" + params.scheme);
    }
    if (params.type) {
      qparam.push("type=" + params.type);
    }
    if (params.applicantType) {
      qparam.push("applicantType=" + params.applicantType);
    }

    if (qparam.length > 0) {
      url = url + "?" + qparam.join("&");
    }
    const res = await api.get(url);
    if (res.status === 1) return res.data ?? [];
    else return [];
  } catch (error) {
    console.log(error);
  }
};

export const removeProductConditions = async (params) => {
  try {
    let url = `${endpoints.productConditionsDelete}`;
    const qparam: any = [];
    qparam.push("type=" + params.type);

    if (qparam.length > 0) {
      url = url + "?" + qparam.join("&");
    }
    const res = await api.delete(url, { conditions: params.ids });
    if (res.status === 1) return res.data ?? [];
    else return [];
  } catch (error) {
    console.log(error);
  }
};

export const updateProductPreferences = async (data) => {
  try {
    const response = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/los/product/preferences`,
      data
    );

    if (response.status === ERROR_CODES.success) {
      return response;
    } else {
      throw new Error("Failed to update workflow.");
    }
  } catch (error) {
    return null;
  }
};

export const updateStageConfigs = async (data) => {
  try {
    const response = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/los/configs/products/stages`,
      data
    );

    if (response.status === ERROR_CODES.success) {
      return response;
    } else {
      throw new Error("Failed to update configs.");
    }
  } catch (error) {
    return null;
  }
};

export const getStageConfigs = async (workflowId) => {
  try {
    const response = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/configs/products/stages`,
      { workflowId: workflowId }
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    return null;
  }
};

export const getProductPreferences = async (id) => {
  try {
    const response = await api.get(
      `${URL.REACT_APP_BACKEND_HOST}/los/product/preferences`,
      { id: id }
    );

    if (response.data) {
      return response.data;
    } else {
      throw new Error("Failed to fetch data.");
    }
  } catch (error) {
    return null;
  }
};

export const registerProduct = async (payload) => {
  try {
    const { type } = payload;
    delete payload.type;
    const { data } = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/los/products/register/${type}`,
      payload
    );

    return data;
  } catch (error) {
    return null;
  }
};

export const mapWorkflowToProduct = async (product, workflowId) => {
  try {
    const payload = { catalog: product.id, workflowId: workflowId?.[0].id, workflowType: workflowId?.[0].workflowType };
    const { data } = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/los/products/workflows/configuration`,
      payload
    );

    return data;
  } catch (error) {
    return null;
  }
};

export const unregisterProduct = async (payload) => {
  try {
    const { type, status } = payload;
    if (status === 1) {
      payload.status = '0';
    } else {
      payload.status = '1';
    }
    delete payload.type;
    const { data } = await api.put(
      `${URL.REACT_APP_BACKEND_HOST}/los/products/register/${type}`,
      payload
    );

    return data;
  } catch (error) {
    return null;
  }
};

export const unregisterProductCatalog = async (id) => {
  try {
    const { data } = await api.delete(`${URL.REACT_APP_BACKEND_HOST}/los/products/catalog?id=${id}`);
    return data;
  } catch (error) {
    return null;
  }
};

export const getUnpublishedTypes = async () => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/products/unpublished/types`, {});
    return response;
  } catch (error) {
    return null;
  }
}

export const getStatusBasedList = async (type = "", action = true) => {
  let status;
  try {
    if (action) {
      status = 1;
    } else {
      status = 0;
    }
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/products/unpublished/${type}`, { status });
    return response;

  } catch (error) {
    return null;
  }
}

export const getUnpublishedSectors = async () => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/products/unpublished/sectors`, {});
    return response;

  } catch (error) {
    return null;
  }
}

export const getUnpublishedSchemes = async () => {
  try {
    const response = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/products/unpublished/schemes`, {});
    return response;

  } catch (error) {
    return null;
  }
}

export const productMapping = async () => {
  try {
    const data = await CachingStorage.invoke("los/products/mapping", () => api.get(`${URL.REACT_APP_BACKEND_HOST}/los/products/mapping`));
    return data;
  } catch (error) {
    return null;
  }
};

export const mapProductTypeSector = async (payload) => {
  try {
    const { data } = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/products/mapping/typesector`, payload);
    return data;
  } catch (error) {
    return null;
  }
};

export const mapProductTypeSectorScheme = async (payload) => {
  try {
    const { data } = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/products/mapping/typesectorscheme`, payload);
    return data;
  } catch (error) {
    return null;
  }
};

export const productMappingCatalogs = async (payload) => {
  try {
    let params: any = [];
    if (payload.type) {
      params.push(`type=${payload.type}`);
    }
    if (payload.sector) {
      params.push(`sector=${payload.sector}`);
    }
    if (payload.scheme) {
      params.push(`scheme=${payload.scheme}`);
    }
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/products/mapping/catalogs?` + params.join("&"));
    return data;
  } catch (error) {
    return null;
  }
};

export const workflowMappingFromCatalogs = async (catalog = null) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/products/mapping/workflows?catalog=` + catalog);
    return data;
  } catch (error) {
    return null;
  }
};

export const updateProductProperty = async ({ field, catalog }) => {
  try {
    const { data } = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/products/property`, { field, catalog });
    return data;
  } catch (error) {
    return null;
  }
};

export const createProductFromCatalog = async ({ catalog }) => {
  try {
    const { data } = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/products/create`, { catalog });
    return data;
  } catch (error) {
    return null;
  }
};

export const unregisterWorkflowProductMapping = async (payload) => {
  try {
    const { data } = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/products/workflow/detach`, payload);
    return data;
  } catch (error) {
    return null;
  }
};

export const setFollowUpStageConfig = async (payload) => {
  try {
    const { data } = await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/products/workflow/followup/stage`, payload);
    return data;
  } catch (error) {
    return null;
  }
};

export const getFollowUpStageConfig = async (stageId) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/products/workflow/followup/stage?stageId=${stageId}`);
    return data;
  } catch (error) {
    return null;
  }
};

export const productFormMapping = async () => {
  try {
    const data = await CachingStorage.invoke("los/products/form/mapping/products", () => api.get(`${URL.REACT_APP_BACKEND_HOST}/los/products/form/mapping/products`));
    return data;
  } catch (error) {
    return null;
  }
};


export const getProductForms = async (catalog = null) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/products/form/mapping`);
    return data;
  } catch (error) {
    return null;
  }
};

export const getProductFormMappedData = async (formId = null) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/products/form/mapping/form?formId=` + formId);
    return data;
  } catch (error) {
    return null;
  }
};

export const mapProdcustForm = async (form, products) => {
  try {
    const payload = { formId: form.formId, product: products?.[0].id };
    const { data } = await api.post(
      `${URL.REACT_APP_BACKEND_HOST}/los/products/form/mapping/configuration`,
      payload
    );

    return data;
  } catch (error) {
    return null;
  }
};

export const updateProductFormDependenciesData = async (payload) => {
  try {
    const data = await api.patch(`${URL.REACT_APP_BACKEND_HOST}/los/products/form/mapping/dependencies`, payload);
    return data;
  } catch (error) {
    return null;
  }
};

export const getDataVerificationChecklist = async (payload) => {
  try {
    const { data } = await api.get(`${URL.REACT_APP_BACKEND_HOST}/los/products/dataverification/checklist`, payload);
    return data;
  } catch (error) {
    return null;
  }
};

export const updateDataVerificationChecklist = async (payload) => {
  try {
    return await api.patch(`${URL.REACT_APP_BACKEND_HOST}/los/products/dataverification/checklist`, payload);
  } catch (error) {
    return { status: 'error', message: "Failed to update data verification checklist." };
  }
};

export const addDataVerificationChecklist = async (payload) => {
  try {
    return await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/products/dataverification/checklist`, payload);
  } catch (error) {
    return { status: 'error', message: "Failed to create data verification checklist." };
  }
};

export const cloneDataVerificationChecklist = async (payload) => {
  try {
    return await api.post(`${URL.REACT_APP_BACKEND_HOST}/los/products/dataverification/checklist/clone`, payload);
  } catch (error) {
    return { status: 'error', message: "Failed to clone data verification checklist." };
  }
};