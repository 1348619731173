import { permanantCityValidation } from "../configs/validations/application-form-on-next-validation-handlers/addres-details/addressDetailsValidationOnNext";
import { landNumberValidation } from "../configs/validations/application-form-on-next-validation-handlers/contact-details/contactDetailsValidationOnNext";
import { newNicValidation } from "../configs/validations/application-form-on-next-validation-handlers/primary-applicant-details/primaryIdentificationDetailsValidationOnNext";
import { gracePeriodValidation, loanAmountValidationHelper, loanCycleValidationHelper, loanTermValidationHelper, proposedRateValidationHelper } from "../configs/validations/leadvalidations-handlers/LeadCreditDetailsRevampValidationhandler";
import { oldNicValidation } from "../configs/validations/leadvalidations-handlers/leadPersonalInfoRevampValidationHandler";
import { validateEmail, validatePhoneNumber } from "../configs/validations/validation-rules/validationRules";

export class OnDemandValueValidator {
  static taxIdentificationNumber(value): boolean {
    if (!!value === false) {
      return false;
    }
    if (value.length > 4) {
      return false;
    }
    return true;
  }

  static phoneNumber(value): boolean {
    if (!!value === false) {
      return false;
    }
    const errors: any = validatePhoneNumber("Primary Contact No", value);
    if (errors && errors.length === 1 && errors[0]["status"] === 1) {
      return false;
    }
    if (errors && errors.length === 1 && errors[0]["status"] === -2 && errors[0]["message"] === "Primary Contact No must contain 10 digits.") {
      return false;
    }
    return errors != null;
  }

  static phoneLandNumber(value): boolean {
    if (!!value === false) {
      return false;
    }
    const errors: any = landNumberValidation("Land Number", value, 10);
    if (errors && errors.length === 1 && errors[0]["status"] === 1) {
      return false;
    }
    return errors != null;
  }

  static email(value): boolean {
    if (!!value === false) {
      return false;
    }
    const errors: any = validateEmail("Primary Email", value);
    if (errors && errors.length === 1 && errors[0]["status"] === 1) {
      return false;
    }
    return errors != null;
  }

  static city(value): boolean {
    if (!!value === false) {
      return false;
    }
    const errors: any = permanantCityValidation("Permanent City", value);
    if (errors && errors.length === 1 && errors[0]["status"] === 1) {
      return false;
    }
    return errors != null;
  }

  static newNIC(value): boolean {
    if (!!value === false) {
      return false;
    }
    const errors: any = newNicValidation("New NIC", value);
    if (errors && errors.length === 1 && errors[0]["status"] === 1) {
      return false;
    }
    return errors != null;
  }

  static oldNIC(value): boolean {
    if (!!value === false) {
      return false;
    }
    const errors: any = oldNicValidation("Old NIC", value);
    if (errors && errors.length === 1 && errors[0]["status"] === 1) {
      return false;
    }
    return errors != null;
  }

  static loanDetails(type, value, min, max): boolean {
    if (!!value === false) {
      return false;
    }
    if (value && value.length > 1 && parseInt(value) === 0) {
      return true;
    }
    const errors: any = loanAmountValidationHelper(type, value, 2, min, max);
    if (errors && errors.length === 1 && errors[0]["status"] === 1) {
      return false;
    }
    return errors != null;
  }

  static loanAmount(value, min, max): boolean {
    if (!!value === false) {
      return false;
    }
    if (value && value.length > 1 && parseFloat(value) === 0) {
      return true;
    }
    const errors: any = loanAmountValidationHelper("Loan Amount", value, 2, min, max);
    if (errors && errors.length === 1 && errors[0]["status"] === 1) {
      return false;
    }
    return errors != null;
  }

  static gracePeriod(value, min, max): boolean {
    if (!!value === false) {
      return false;
    }

    if (value && value.length > 1 && parseFloat(value) === 0) {
      return true;
    }

    const errors: any = gracePeriodValidation("Grace Period", value, min, max);
    if (errors && errors.length === 1 && errors[0]["status"] === 1) {
      return false;
    }
    return errors != null;
  }

  static loanCycles(value): boolean {
    if (!!value === false) {
      return false;
    }
    const errors: any = loanCycleValidationHelper("Loan Cycle", value, 1, 24);
    if (errors && errors.length === 1 && errors[0]["status"] === 1) {
      return false;
    }
    return errors != null;
  }

  static proposedRate(value, min, max): boolean {
    if (!!value === false) {
      return false;
    }
    const errors: any = proposedRateValidationHelper("Proposed Rate", value, 2, min, max);
    if (errors && errors.length === 1 && errors[0]["status"] === 1) {
      return false;
    }
    return errors != null;
  }

  static loanTenor(value, min, max): boolean {
    if (!!value === false) {
      return false;
    }
    const errors: any = loanTermValidationHelper("Loan Tenor", value, min, max);
    if (errors && errors.length === 1 && errors[0]["status"] === 1) {
      return false;
    }
    return errors != null;
  }

  static formattedOldNICValue = (value) => {
    return value;
  };
}

export class OnDemandLengthValidator {
  static oldNIC = (e) => {
    if (e.target.value && e.target.value.length < 10) {
      let value = Math.max(0, parseInt(e.target.value));
      if (isNaN(value)) {
        e.target.value = "";
      } else {
        const formattedVale = value.toString().slice(0, 10);
        e.target.value = formattedVale;
      }
    } else if (e.target.value.length === 10) {
      let lastChar = e.target.value.toString().slice(9);
      if (["v", "x"].includes(lastChar.toString().toLowerCase()) === false) {
        lastChar = "V";
      }
      e.target.value = e.target.value.toString().slice(0, 9) + lastChar.toUpperCase();
    } else {
      e.target.value = e.target.value.toString().slice(0, 10);
    }
  };

  static newNIC = (e) => {
    let value = Math.max(0, parseInt(e.target.value));
    if (isNaN(value)) {
      e.target.value = "";
    } else {
      const formattedVale = value.toString().slice(0, 12);
      e.target.value = formattedVale;
    }
  };

  static phoneNumber = (e) => {
    e.target.value = e.target.value.toString().slice(0, 10);
  };

  static currency = (e) => {
    e.target.value = e.target.value.toString().slice(0, 10);
  };

  static loanCycles = (e) => {
    e.target.value = e.target.value.toString().slice(0, 2);
  };

  static gracePeriod = (e) => {
    e.target.value = e.target.value.toString().slice(0, 2);
  };


  static loanTenor = (e, length = 3) => {
    e.target.value = e.target.value.toString().slice(0, length);
  };

  static followUpCheckList = (e) => {
    e.target.value = e.target.value.toString().slice(0, 100);
  };

  static proposedRate = (e) => {
    let value = e.target.value;
    if (value && parseInt(value) > 100) {
      value = "100";
    }
    if (value && parseInt(value) < 0) {
      value = "0";
    }
    e.target.value = value;
  };

  static year = (e) => {
    let value = Math.max(0, parseInt(e.target.value));
    if (isNaN(value)) {
      e.target.value = "";
    } else {
      const formattedVale = value.toString().slice(0, 4);
      e.target.value = formattedVale;
    }
  };

  static currentYear = (e) => {
    let value = Math.max(0, parseInt(e.target.value));
    if (isNaN(value)) {
      e.target.value = "";
    } else {
      const formattedVale = parseInt(value.toString().slice(0, 4));
      const d = new Date();
      let currentYear = d.getFullYear();

      if (formattedVale > currentYear) {
        e.target.value = "";
      } else {
        e.target.value = formattedVale;
      }
    }
  };
}

export class Formatter {
  static ANY = /xxx[\x00-\x7F]+xxx/gi;
  static NUMERIC = /[^0-9]+/gi;
  static NEGETIVEPOSITIVENUMERIC = /[^-?\d.]+/gi;
  static ALPHABETICAL = /[^a-zA-Z]+/gi;
  static ALPHA_NUMERIC = /[^a-zA-Z0-9]+/gi;
  static ALPHA_NUMERIC_COMMA = /[^a-zA-Z0-9,]+/gi;
  static ALPHA_NUMERIC_DOTTED = /[^a-zA-Z0-9.]+/gi;
  static ALPHA_NUMERIC_EXTENDED = /[^a-zA-Z0-9. ]+/gi;
  static ALPHABETICAL_EXTENDED = /[^a-zA-Z,. ']+/gi;
  static ALPHABETICAL_SPACE = /[^a-zA-Z ]+/gi;
  static ALPHABETICAL_ADVANCED = /[^a-zA-Z, ]+/gi;
  static ALPHA_NUMERIC_SPACE = /[^0-9a-zA-Z ]+/gi;
  static ALPHA_NUMERIC_UNDERSOCRE = /[^a-zA-Z_]+/gi;
  static NUMERIC_VERSIONS = /[^0-9.]+/gi;
  static DATE = /[^0-9\/]+/gi;
  static THOUSAND_SEPARATOR = /\B(?=(\d{3})+(?!\d))/g;
  static EMAIL = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  static PRECENTAGE_VERSIONS = /^(100|[1-9]?[0-9])$/;
  static PRECENTAGE_NEGATIVE_VERSIONS = /^(-?(100|[1-9]?\d))?$/;
}

export class OnDemandValueFormatter {
  private static evaluate = (e, formatter) => {
    let value = e.target.value ? e.target.value.replace(formatter, "") : "";

    let maxValue: any = e.target.maxLength;
    let ceilValue: string = e.target.max;

    let ceil = 0;

    try {
      ceil = parseInt(ceilValue);
    } catch (error) {
      ceil = 0;
    }

    if (maxValue > 0) {
      value = value.toString().slice(0, maxValue);
    }

    if (ceil > 0) {
      try {
        if (parseInt(value) > ceil) {
          value = ceil.toString();
        }
      } catch (error) {
        value = value.toString();
      }
    }

    e.target.value = value;
  };

  static numeric = (e) => {
    this.evaluate(e, Formatter.NUMERIC);
  };

  static negetivePositivenumeric = (e) => {
    this.evaluate(e, Formatter.NEGETIVEPOSITIVENUMERIC);
  };

  static abnAcnValidator = (e) => {
    this.evaluate(e, Formatter.NUMERIC);
    const formattedVale = e.target.value.slice(0, 11);
    e.target.value = formattedVale;
  };

  static date = (e) => {
    this.evaluate(e, Formatter.DATE);
  };

  static alphabetical = (e) => {
    this.evaluate(e, Formatter.ALPHABETICAL);
  };

  static any = (e) => {
    this.evaluate(e, Formatter.ANY);
  };

  static alphaNumeric = (e) => {
    this.evaluate(e, Formatter.ALPHA_NUMERIC);
  };

  static alphabeticalExtended = (e) => {
    this.evaluate(e, Formatter.ALPHABETICAL_EXTENDED);
  };

  static alphabeticalSpaces = (e) => {
    this.evaluate(e, Formatter.ALPHABETICAL_SPACE);
  };

  static alphabeticalUnderscore = (e) => {
    this.evaluate(e, Formatter.ALPHA_NUMERIC_UNDERSOCRE);
  };

  static validateVersion = (e) => {
    this.evaluate(e, Formatter.NUMERIC_VERSIONS);
  };

  static alphabeticalAutoSpaced = (e) => {
    this.evaluate(e, Formatter.ALPHABETICAL_SPACE);
    if (e.target.value && e.target.value.length > 1) {
      e.target.value = e.target.value
        .split("")
        .filter((char) => {
          return char != " ";
        })
        .join(" ")
        .toUpperCase();
    }
  };

  static alphaNumericUpper = (e) => {
    this.evaluate(e, Formatter.ALPHA_NUMERIC);
    if (e.target.value && e.target.value.length > 1) {
      e.target.value = e.target.value.toUpperCase();
    }
  };
  static numericPresentage = (e) => {
    if (Formatter.PRECENTAGE_VERSIONS.test(e.target.value)) {
      return e.target.value;
    }
  }

  static alphaNumericLower = (e) => {
    this.evaluate(e, Formatter.ANY);
    if (e.target.value && e.target.value.length > 1) {
      e.target.value = e.target.value.toLowerCase();
    }
  };

  static alphaNumericLowerCaseUnderscore = (e) => {
    this.evaluate(e, Formatter.ANY);
    if (e.target.value && e.target.value.length > 1) {
      e.target.value = e.target.value.toLowerCase().replaceAll(" ", "_");
    }
  };

  static alphaNumericSpaces = (e) => {
    this.evaluate(e, Formatter.ALPHA_NUMERIC_SPACE);
  };

  static alphabeticalComma = (e) => {
    this.evaluate(e, Formatter.ALPHA_NUMERIC_COMMA);
  };

  static alphabeticalAdvanced = (e) => {
    this.evaluate(e, Formatter.ALPHABETICAL_ADVANCED);
  };

  static formatSearchNIC = (e) => {
    this.evaluate(e, Formatter.NUMERIC);
    const value = e.target.value;
    if (value && value.length <= 10) {
      OnDemandLengthValidator.oldNIC(e);
    } else if (value && value.length > 10) {
      OnDemandLengthValidator.newNIC(e);
    }
  };

  static formatSearchBR = (e) => { };

  static thousandSeparator = (x) => {
    return x.toString().replace(Formatter.THOUSAND_SEPARATOR, ",");
  };

  static alphaNumericUpperTaxLimit = (e) => {
    this.evaluate(e, Formatter.ALPHA_NUMERIC);
    if (e.target.value && e.target.value.length > 1) {
      e.target.value = e.target.value.toUpperCase().substring(0, 15);
    }
  };

  static alphaNumericDotted = (e) => {
    this.evaluate(e, Formatter.ALPHA_NUMERIC_DOTTED);
  };

  static alphaNumericExtended = (e) => {
    this.evaluate(e, Formatter.ALPHA_NUMERIC_EXTENDED);
  };

  static yearFormat = (e) => {
    OnDemandLengthValidator.year(e);
  };

  static currentYearFormat = (e) => {
    OnDemandLengthValidator.currentYear(e);
  };

  static alphabaticToUpperUnderscore = (e) => {
    if (e.target.value && e.target.value.length > 1) {
      e.target.value = e.target.value.split(" ").join("_").toUpperCase();
    }
  };

  static percentage = (e) => {
    const formattedPresentage = e.target.value;
    if (formattedPresentage >= 0 && formattedPresentage <= 100) {
      e.target.value = formattedPresentage;
    }
  };

  static enforceValidPercentageRange = (e) => {

    if (Formatter.PRECENTAGE_NEGATIVE_VERSIONS.test(e.target.value)) {
      return e.target.value;
    } else if (e.target.value && e.target.value.length > 1) {
      let sliceIndex = e.target.value.startsWith("-") ? 3 : 2;
      e.target.value = e.target.value.slice(0, sliceIndex);
    }
    return e.target.value;
  };
    // static phoneNumber: any;
}
