import moment from "moment";
import { LengthItemValidate } from "../../../../../../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn";
import { employementDetails } from "../Helpers/CustomValidationKey";
import { inspectionMessageMappings, keyMapping } from "../Helpers/MessageMapping";
import { validValueFilter } from "../Helpers/ValidationExamingPlot";

export const businessInspection = (
  structure,
  state,
  validationMap,
  isAmountBelowTwoPointFive,
  isAmountAboveTwoPointFiveAndEqual
) => {
  let errorArray: string[] = [];
  let preparedMessageArray: any[] = [];
  let {
    business: {
      businessLiabilityExpenses = [],
      businessExpenses = [],
      businessIncome = [],
      salesAndPurchaseInformationOfFastMovingItemsOtherData: salesandPurchaseInformationofFastMovingItemsOtherdata = [],
      totaleBusinessAssertOtherDetails = [],
      totaleBusinessFixedAssertOtherDetails = [],
      totaleBusinessCurrentAssertOtherDetails = [],
      deductionsCostsOfGoods = []

    },
  } = validationMap;

  if (Object.keys(state).length === 0) {
    errorArray = [...errorArray, ...salesandPurchaseInformationofFastMovingItemsOtherdata];
    errorArray = [...errorArray, ...businessIncome];
    errorArray = [...errorArray, ...businessExpenses];
    return errorArray;
  }

  for (const key in state) {
    if (key === "businessIncome") {
      let theResult = yearWiseValidationHandler({ state, key, errorArray, sectionCustomValidationMap: businessIncome });

      preparedMessageArray = [...preparedMessageArray, ...theResult];
    }

    if (key === "deductionsCostsOfGoods") {
      let errorArray: string[] = [];
      let year = (moment().year()).toString();

      Object.keys(state[key]).forEach((currYear) => {

        if (currYear !== year) return;

        let rawMaterialsCostArray = objectArrayValidator(errorArray, state[key][currYear].rawMaterialsCost, deductionsCostsOfGoods);
        rawMaterialsCostArray = errorMessageCreator(rawMaterialsCostArray, key, [], currYear);

        if (rawMaterialsCostArray.length) {
          rawMaterialsCostArray.splice(1, 0, '|👉 Raw Materials Cost');
        }

        let otherDirectCostArray = objectArrayValidator(errorArray, state[key][currYear].otherDirectCost, deductionsCostsOfGoods);
        otherDirectCostArray = errorMessageCreator(otherDirectCostArray, key, [], currYear);

        if (otherDirectCostArray.length) {
          otherDirectCostArray.splice(1, 0, '|👉 Other Direct Cost');
        }

        errorArray = [...errorArray, ...rawMaterialsCostArray, ...otherDirectCostArray];
      });
      preparedMessageArray = [...preparedMessageArray, ...errorArray];
    }

    if (key === "businessExpenses") {
      let theResult = yearWiseValidationHandler({ state, key, errorArray, sectionCustomValidationMap: businessExpenses });
      preparedMessageArray = [...preparedMessageArray, ...theResult];
    }

    if (key === "totaleBusinessFixedAssertOtherDetails") {
      let array: string[] = Object.keys(state[key]).reduce((errorArray, currYear, index) => {

        let errors: string[] = objectArrayValidator(errorArray, state[key][currYear], totaleBusinessFixedAssertOtherDetails);

        state[key][currYear].forEach((currItem) => {

          if (currItem?.isNeedtoDepreciate && !currItem?.assertLifeTime) {
            errors.push("assertLifeTime");
          }
        })
        errorArray = errorMessageCreator(errors, key, errorArray, currYear)
        return errorArray;
      }, [])

      preparedMessageArray = [...preparedMessageArray, ...array]
    }

    if (key === "totaleBusinessCurrentAssertOtherDetails") {
      let array: string[] = Object.keys(state[key]).reduce((errorArray, currYear, index) => {

        let errors: string[] = objectArrayValidator(errorArray, state[key][currYear], totaleBusinessCurrentAssertOtherDetails);

        state[key][currYear].forEach((currItem) => {

          if (currItem?.isNeedtoDepreciate && !currItem?.assertLifeTime) {
            errors.push("assertLifeTime");
          }
        })
        errorArray = errorMessageCreator(errors, key, errorArray, currYear)
        return errorArray;
      }, [])

      preparedMessageArray = [...preparedMessageArray, ...array]
    }

    if (key === "businessLiabilityExpenses") {
      let array: string[] = Object.keys(state[key]).reduce((errorArray: string[], currYear) => {
        let errors: string[] = [];
        let stateData: any[] = LengthItemValidate.validatedArray(state[key][currYear]) || [];

        if (!stateData.length) {
          errors.push("Mandatory section cannot be empty!");
        } else {
          stateData.forEach((currItem) => {
            if (!currItem?.formVisibilityControl) {
              let structureArray: string[] = businessLiabilityExpenses?.[currItem.type] || [];
              let resultErrorArray = objectArrayValidator(errorArray, currItem, structureArray);
              errors.push(...resultErrorArray);
            }
          });
        }

        return errorMessageCreator(errors, key, errorArray, currYear);
      }, []);

      preparedMessageArray = [...preparedMessageArray, ...array]
    }

    if (key === "employementDetails") {
      let array = objectArrayValidator(errorArray, state[key], employementDetails);

      validValueFilter(state["isPayEpfEtfEmp"], array, "isPayEpfEtfEmp");

      if (state["isPayEpfEtfEmp"]) validValueFilter(state["noOfPayEpfEtfEmp"], array, "noOfPayEpfEtfEmp");

      preparedMessageArray = errorMessageCreator(array, key, preparedMessageArray);
    }

    if (key === "markettingArrangement") {
      let array: any = [];
      validValueFilter(state[key]["comment"], array, "comment");

      if (state[key]["isMarketingArrangementAvailable"] === 1) {
        validValueFilter(state[key]["markettingArrangement"], array, "markettingArrangement");
      } else {
        if (array.includes("markettingArrangement")) {
          array = array.filter(key => key != "markettingArrangement")
        }
      }

      preparedMessageArray = errorMessageCreator(array, key, preparedMessageArray);
    }
  }

  preparedMessageArray = [...preparedMessageArray, ...inspectionMessageMappings(errorArray)];

  return preparedMessageArray;
};

const objectArrayValidator = (errorArray, stateObj, structure): string[] => {
  let intermediateArray: string[] = [];

  if (!stateObj) return []

  if (Array.isArray(stateObj)) {

    if (!LengthItemValidate.validatedArray(stateObj).length) return ["Mandatory section cannot be empty!"];

    for (const key in stateObj) {
      let obj = stateObj[key];

      if (obj.hasOwnProperty("removedItem")) continue;

      intermediateArray = Object.keys(obj).reduce((acc: string[], key: string) => {
        if (Array.isArray(structure)) {
          if (structure.includes(key)) {
            validValueFilter(obj[key], acc, key);
          }
        } else if (Object.keys(structure).length > 0) {
          if (Object.keys(obj[key]).length > 0) {
            let resArray = objectArrayValidator(errorArray, obj[key], structure[key]);

            if (resArray.length > 0) {
              acc = [...acc, key, ...resArray];
            }
          }
        }

        return acc;
      }, []);
    }
    return intermediateArray;
  }

  if (typeof stateObj === "object" && Object.keys(stateObj).length) {
    let array: string[] = [];

    for (const key in stateObj) {
      let currItem = stateObj[key];
      let year = (moment().year()).toString();

      if (Array.isArray(currItem) && key === year) {
        array = objectArrayValidator(errorArray, currItem, structure);

        if (!array.length) continue;

        return array;
      }
    }
  }

  return objectArrayValidator(errorArray, [stateObj], structure);
};

const errorMessageCreator = (errorArray, headerMainKey, preparedMessageArray, year = "") => {
  if (errorArray.length === 0) {
    return preparedMessageArray;
  }

  errorArray = inspectionMessageMappings(errorArray);
  let errorTitle = year ? `👉 ${year}-${keyMapping[headerMainKey]}` : keyMapping[headerMainKey];
  return [...preparedMessageArray, errorTitle, ...errorArray];
};

const yearWiseValidationHandler = ({ state, key, errorArray, sectionCustomValidationMap }): string[] => {
  let errorMessages: string[] = []

  for (const currKey of Object.keys(state[key])) {
    let array = objectArrayValidator(errorArray, state[key][currKey], sectionCustomValidationMap);
    if (array.length) {
      errorMessages = errorMessageCreator(array, key, errorMessages, currKey);
      break;
    }
  }


  return errorMessages;
}