import { Downgraded } from "@hookstate/core";
import { LengthItemValidate } from "../../../components/OtherComponents/ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn";
import { getDateValidationErrorKeys } from "../../../configs/validations/validation-rules/validationRules";
import AppConfig from "../../AppConfig";
import { applyProductLevelFieldMeta } from "../../AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import { removeDuplicates } from "./employmentDetails";
import { messageMappings } from "./messageMapping";
import { validatedFieldKey, validateWithStructure } from "./structureValidation";


export const businessDetails = (structure, state, isGuarantor = false, isJointBorrower = false, participantState, isSecuritySectionNext?) => {
  let finalKeyArr: any = [];
  const preparedErrorMessages: Array<string> = [];
  const futureDateRelatedFieldsToValidate: any = ["startDate", "brDate"];
  const str = structure?.content[0]?.fields || {};

  state = LengthItemValidate.validatedArray(state?.currentBusiness.attach(Downgraded).get() || []);

  if (!state.length) return ["Business Details are required!"];

  return state.reduce((messageErroArray, element, index) => {

    let errorArray: string[] = [];

    if (errorArray["removedItem"]) return messageErroArray;

    if (element.typeOfBusiness == 8) return messageErroArray;

    let resultArray: string[] = validateWithStructure(structure, element);

    errorArray = resultArray.length ? errorArray.concat(resultArray) : errorArray;

    if (!element?.address?.addressLine1) {
      errorArray[errorArray.length] = "addressLine1";
    }

    if (!element?.address?.addressLine2) {
      errorArray[errorArray.length] = "addressLine2";
    }

    if (!element?.NoYearsOperation && !element?.noOfMonthsInOperation) {
      errorArray[errorArray.length] = "NoYearsOperation";
    }

    if (element?.womenLedBusiness) {
      let result = validatedFieldKey(element?.envOfThewomenLedBusiness, "envOfThewomenLedBusiness");
      errorArray = result ? [...errorArray, result] : errorArray;
    }

    if (element?.taxPayment == 1) {
      if (element?.taxData?.taxIdentificationNumber == null || element?.taxData?.taxIdentificationNumber == "") {
        errorArray[errorArray.length] = "taxIdentificationNumber"
      }
    }

    if (element.isCertificationHave === 1) {
      element?.authorityCertifications.forEach((certification) => {
        let result = validatedFieldKey(certification?.certification, "certification");
        if (result) {
          errorArray.push(result);
        }
      })
    }

    if (str?.sustainableFinance?.visible && str?.sustainableFinance?.mandatory) {
      if (element?.sustainableFinance !== 1 && element?.sustainableFinance !== 0) {
        let result = validatedFieldKey(
          element?.sustainableFinance, "sustainableFinance"
        );
        if (result) {
          errorArray.push(result);
        }
      }
      if (element.sustainableFinance === 1) {
        let result = validatedFieldKey(element?.primaryFocusArea, "primaryFocusArea");
        if (result) {
          errorArray.push(result);
        }

        if (element.primaryFocusArea === 1) {
          let subcategoryResult = validatedFieldKey(element?.environmentalSubcategories, "environmentalSubcategories");
          if (subcategoryResult) {
            errorArray.push(subcategoryResult);
          }
        }
      }
    }
    
    let contactErrorArray: string[] = element.contactTypes.reduce((contactErrorArray, contactObj) => {

      if (contactObj["removedItem"]) return [];

      if (contactObj.empContactType != 1) return [];

      if (contactObj.empContactName == "") {
        if (!errorArray.includes("empContactName")) {
          errorArray.push("empContactName");
        }
      }

      if (contactObj.empContactNumber == "") {
        if (!errorArray.includes("empContactNumber")) {
          errorArray.push("empContactNumber");
        }
      }


      if (contactObj.empContactNumber == "") {
        if (!errorArray.includes("empEmailAddress")) {
          errorArray.push("empEmailAddress");
        }
      }

      const contactNumberRegex = /^(0)[0-9]{9}$/;
      if (!contactNumberRegex.test(contactObj.empContactNumber)) {
        preparedErrorMessages.push("Contact Number is invalid")
      }

      if (contactObj.empEmailAddress) {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(contactObj.empEmailAddress)) {
          preparedErrorMessages.push("Contact Email Address is invalid")
        }
      }

      return [];
    }, []);

    errorArray = contactErrorArray.length ? errorArray.concat(contactErrorArray) : errorArray;

    let assetErrorArray: string[] = element.assets.reduce((assetErrorArray, assetObj) => {

      if (assetObj.assetType && assetObj.assetValue == "") {
        assetErrorArray.push("assetValue");
      }

      return assetErrorArray;
    }, []);

    errorArray = assetErrorArray.length ? errorArray.concat(assetErrorArray) : errorArray;

    if (element.NoYearsOperation != null && element.NoYearsOperation != "") {
      if (errorArray.includes("noOfYearsInOperation")) {
        errorArray = errorArray?.filter((key) => key !== "noOfYearsInOperation");
      }
    }

    let athamaruElementsArray: string[] = [];

    const isProductOf = applyProductLevelFieldMeta;
    const primaryApplicant = !(isGuarantor || isJointBorrower);

    if (!(element?.businessRunningOption) && isProductOf({ dataKey: "businessRunningOption", primaryApplicant })) {
      athamaruElementsArray.push("businessRunningOption");
    }

    if (!element?.currentYearSales && isProductOf({ dataKey: "currentYearSales", primaryApplicant })) {
      athamaruElementsArray.push("currentYearSales");
    }

    if (!element?.previousYearSales && isProductOf({ dataKey: "previousYearSales", primaryApplicant })) {
      athamaruElementsArray.push("previousYearSales");
    }

    if ((element?.leaseAgreementUnder === 2 || element?.propertyIsUnder === 2)
      && (isProductOf({ dataKey: "isLeaseAgreementUnderSelected", primaryApplicant }) || isProductOf({ dataKey: "isPropertyIsUnderSelected", primaryApplicant }))) {
      dataMandatoryValidator(element, ["otherName", "relationshipWithtThePrimaryApplicant"], athamaruElementsArray);
    }
    if (element?.isLeaseAgreementUnderSelected && isProductOf({ dataKey: "isLeaseAgreementUnderSelected", primaryApplicant })) {
      dataMandatoryValidator(element, ["leaseAgreementUnder", "fromDate", "toDate"], athamaruElementsArray);
    }
    if (element?.isPropertyIsUnderSelected && isProductOf({ dataKey: "isPropertyIsUnderSelected", primaryApplicant })) {
      dataMandatoryValidator(element, "propertyIsUnder", athamaruElementsArray);
    }
    if ((element?.partnershipDetails.length >= 0 && element?.typeOfBusiness === 1) && isProductOf({ dataKey: "partnershipDetails", primaryApplicant })) {
      // const mandatoryKeyArry = ["partnershipD_name", "partnershipD_newNic", "partnershipD_oldNic", "partnershipD_relationshipWithtThePrimaryApplicant", "partnershipD_addressLine1", "partnershipD_addressLine2"];
      const mandatoryKeyArry = [];
      for (let partnershipObjectId = 0; partnershipObjectId <= element?.partnershipDetails.length; partnershipObjectId++) {
        if (!element?.partnershipDetails[partnershipObjectId]) {
          continue;
        }
        let object = element?.partnershipDetails[partnershipObjectId];
        dataMandatoryValidator(object, mandatoryKeyArry, athamaruElementsArray);

        if (athamaruElementsArray.includes("partnershipD_oldNic") && athamaruElementsArray.includes("partnershipD_newNic")) {
          athamaruElementsArray = athamaruElementsArray.filter((elemnt) => elemnt !== "partnershipD_oldNic" && elemnt !== "partnershipD_newNic");
          athamaruElementsArray.push("partnershipD_bothNic");
        } else if (athamaruElementsArray.includes("partnershipD_oldNic") && !athamaruElementsArray.includes("partnershipD_newNic")) {
          athamaruElementsArray = athamaruElementsArray.filter((elemnt) => elemnt !== "partnershipD_oldNic");
        } else if (!athamaruElementsArray.includes("partnershipD_oldNic") && athamaruElementsArray.includes("partnershipD_newNic")) {
          athamaruElementsArray = athamaruElementsArray.filter((elemnt) => elemnt !== "partnershipD_newNic");
        }
      }
    }

    if ((element?.individualDetails.length >= 0 && element?.typeOfBusiness === 2) && isProductOf({ dataKey: "individualDetails", primaryApplicant })) {
      const mandatoryKeyArry = [];

      for (let individualObjectId = 0; individualObjectId <= element?.individualDetails.length; individualObjectId++) {
        if (!element?.individualDetails[individualObjectId]) {
          continue;
        }

        let object = element?.individualDetails[individualObjectId];
        dataMandatoryValidator(object, mandatoryKeyArry, athamaruElementsArray);

        if (athamaruElementsArray.includes("individualD_oldNic") && athamaruElementsArray.includes("individualD_newNic")) {
          athamaruElementsArray = athamaruElementsArray.filter((elemnt) => elemnt !== "individualD_oldNic" && elemnt !== "individualD_newNic");
          athamaruElementsArray.push("individualD_bothNic");
        } else if (athamaruElementsArray.includes("individualD_oldNic") && !athamaruElementsArray.includes("individualD_newNic")) {
          athamaruElementsArray = athamaruElementsArray.filter((elemnt) => elemnt !== "individualD_oldNic");
        } else if (!athamaruElementsArray.includes("individualD_oldNic") && athamaruElementsArray.includes("individualD_newNic")) {
          athamaruElementsArray = athamaruElementsArray.filter((elemnt) => elemnt !== "individualD_newNic");
        }
      }
    }

    if ((element?.directorDetails.length >= 0 && element?.typeOfBusiness != 1 && element?.typeOfBusiness != 2) && isProductOf({ dataKey: "directorDetails", primaryApplicant })) {
      // const mandatoryKeyArry = ["directorD_name", "directorD_newNic", "directorD_oldNic", "directorD_relationshipWithtThePrimaryApplicant", "directorD_addressLine1", "directorD_addressLine2"];
      const mandatoryKeyArry = [];

      for (let directorObjectId = 0; directorObjectId <= element?.directorDetails.length; directorObjectId++) {
        if (!element?.directorDetails[directorObjectId]) {
          continue;
        }

        let object = element?.directorDetails[directorObjectId];
        dataMandatoryValidator(object, mandatoryKeyArry, athamaruElementsArray);

        if (athamaruElementsArray.includes("directorD_oldNic") && athamaruElementsArray.includes("directorD_newNic")) {
          athamaruElementsArray = athamaruElementsArray.filter((elemnt) => elemnt !== "directorD_oldNic" && elemnt !== "directorD_newNic");
          athamaruElementsArray.push("directorD_bothNic");
        } else if (athamaruElementsArray.includes("directorD_oldNic") && !athamaruElementsArray.includes("directorD_newNic")) {
          athamaruElementsArray = athamaruElementsArray.filter((elemnt) => elemnt !== "directorD_oldNic");
        } else if (!athamaruElementsArray.includes("directorD_oldNic") && athamaruElementsArray.includes("directorD_newNic")) {
          athamaruElementsArray = athamaruElementsArray.filter((elemnt) => elemnt !== "directorD_newNic");
        }
      }
    }

    if ((element?.shareholderDetails.length >= 0 && element?.isShareholderAvailable) && isProductOf({ dataKey: "shareholderDetails", primaryApplicant })) {
      // const mandatoryKeyArry = ["shareholderD_name", "shareholderD_newNic", "shareholderD_oldNic", "shareholderD_relationshipWithtThePrimaryApplicant", "shareholderD_percentageOfShares", "shareholderD_addressLine1", "shareholderD_addressLine2"];
      const mandatoryKeyArry = [];
      let sumOfShares: any = 0;
      for (let shareholderObjectId = 0; shareholderObjectId <= element?.shareholderDetails.length; shareholderObjectId++) {
        if (!element?.shareholderDetails[shareholderObjectId]) {
          continue;
        }

        if (element?.shareholderDetails[shareholderObjectId]?.removedItem) {
          continue;
        }

        let object = element?.shareholderDetails[shareholderObjectId];
        dataMandatoryValidator(object, mandatoryKeyArry, athamaruElementsArray);

        if (athamaruElementsArray.includes("shareholderD_oldNic") && athamaruElementsArray.includes("shareholderD_newNic")) {
          athamaruElementsArray = athamaruElementsArray.filter((elemnt) => elemnt !== "shareholderD_oldNic" && elemnt !== "shareholderD_newNic");
          athamaruElementsArray.push("shareholderD_bothNic");
        } else if (athamaruElementsArray.includes("shareholderD_oldNic") && !athamaruElementsArray.includes("shareholderD_newNic")) {
          athamaruElementsArray = athamaruElementsArray.filter((elemnt) => elemnt !== "shareholderD_oldNic");
        } else if (!athamaruElementsArray.includes("shareholderD_oldNic") && athamaruElementsArray.includes("shareholderD_newNic")) {
          athamaruElementsArray = athamaruElementsArray.filter((elemnt) => elemnt !== "shareholderD_newNic");
        }

        // Calculate Sum of Share Percentage
        sumOfShares += parseInt(element?.shareholderDetails[shareholderObjectId]["shareholderD_percentageOfShares"]) || 0;
      }

      if (sumOfShares !== 100) {
        athamaruElementsArray.push("shareholderD_shareSum")
      }
    }

    errorArray = athamaruElementsArray.length ? errorArray.concat(athamaruElementsArray) : errorArray;

    futureDateRelatedFieldsToValidate.map((field) => {
      let errors: any[] = getDateValidationErrorKeys(element, field, finalKeyArr, "futureDate", "future");
      errorArray = errors.length ? errorArray.concat(errors) : errorArray;
    });

    errorArray = removeDuplicates(errorArray);
    errorArray = messageMappings(errorArray);

    let message: string = `Business Details ${index + 1} has errors`;

    let firstMsg: string = messageErroArray.length > 0 ? `\n ${message}` : message;

    errorArray = errorArray.length ? [firstMsg, ...errorArray] : []

    if (Boolean(AppConfig?._isStable !== 1)) {
      errorArray.push("Current Business Mandatory Validation Error")
    }

    return [...messageErroArray, ...errorArray];
  }, [])

};

const dataMandatoryValidator = (mainDataObj, objKey, targetMainErrArry) => {
  if (Array.isArray(objKey)) {
    objKey.map((keyElement) => {
      dataMandatoryValidator(mainDataObj, keyElement, targetMainErrArry);
    });
  } else {
    if (mainDataObj[objKey] === "" || mainDataObj[objKey] === "" || mainDataObj[objKey] === null || mainDataObj[objKey] === undefined) {
      targetMainErrArry.push(objKey);
    }
  }
};

