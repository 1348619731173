import { Box } from "@mui/material";

const A4Container = ({ children }) => (
    <Box
        sx={{
            width: "210mm",
            height: "297mm",
            padding: "20mm",
            backgroundColor: "white",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            boxShadow: 3,
        }}
    >
        {children}
    </Box>
);

export default A4Container;