import { Downgraded, State } from "@hookstate/core";
import { Grid, Stack, Typography } from "@mui/material";
import { workflowUpdatedData } from "../../../../configs/stores/workflow-stores/workflowStore";
import AutoCompleteProxyComponent from "../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { CheckBoxComponent } from "../../../InputComponents/CheckBoxComponent/CheckBoxComponent";
import { CircularProgressComponent } from "../../ProgressComponent/ProgressComponent";
import { StagePayload } from "./ProductPreferences";

const StageConfiguration = ({ payload, loading }) => {
  const stage: State<StagePayload> = payload;
  const workflowUpdatedDataStateData: any = workflowUpdatedData
    .attach(Downgraded)
    .get();
  const trackedStages = workflowUpdatedDataStateData?.wf_tracked_stages;

  return loading ? (
    <Stack direction="row">
      <CircularProgressComponent size={30} />
    </Stack>
  ) : (
    <Stack direction={"column"} spacing={2} margin={2} flex={1}>
      <Stack direction={"row"} flex={1} spacing={2}>
        <AutoCompleteProxyComponent
          options={workflowUpdatedDataStateData?.wf_tracked_stages}
          value={stage.startStage.get()}
          label={"Start Stage"}
          onChange={(e) => {
            stage.startStage.set(e.id);
          }}
          fullWidth={true}
        />
        <AutoCompleteProxyComponent
          options={workflowUpdatedDataStateData?.wf_tracked_stages}
          value={stage.disbursementStage.get()}
          label={"Disbursement Stage"}
          onChange={(e) => {
            stage.disbursementStage.set(e.id);
          }}
          fullWidth={true}
        />
      </Stack>
      <Grid item>
        <Typography color={"grey"} variant="caption">End Stage(s)</Typography>
        <Stack border={1} height={"calc(100vh - 550px)"} p={1} style={{ overflowY: "auto" }} borderRadius={1} borderColor={"lightGrey"} direction={"row"} flexWrap={"wrap"} gap={1}>
          {trackedStages.map((item: any, index) => (
            <Stack direction={"row"} width={"32%"} alignItems={"center"} key={index} onClick={(event) => {
              // handleListItemClick(item.id);
            }}>

              <Stack key={item.id ?? item.id} direction="row" width={"100%"} justifyContent={"space-between"}>
                <Stack direction={"column"}>
                  <Typography variant="inherit">{item?.name}</Typography>
                </Stack>
              </Stack>
              <Stack alignContent={"baseline"} justifyContent={"center"} direction={"row"}>
                <Typography
                  variant={"caption"}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {item.id ?? item.id}
                </Typography>
                <CheckBoxComponent
                  checked={(stage.endStages.get() && stage.endStages.get().includes(Number(item.id))) || false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      stage.endStages.set(prev => {
                        const prevArray = Array.isArray(prev) ? prev : [];
                        return [...prevArray, Number(item.id)];
                      });
                    } else {
                      stage.endStages.set(prev => {
                        const prevArray = Array.isArray(prev) ? prev : [];
                        return prevArray.filter((element) => element !== Number(item.id));
                      });
                    }
                  }}
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Grid>
    </Stack >
  );
};

export default StageConfiguration;