import { Downgraded, useState } from "@hookstate/core";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import { Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { MASTER_DATA_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";
import { userDetails } from "../../../../../../../../configs/mainStore";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import { select_basic } from "../../../../../../../../styles";
import AppConfig from "../../../../../../../../utility/AppConfig";
import isProductOf, { ProductType } from "../../../../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import { dateFormatWithTimeChange } from "../../../../../../../../utility/fomatters";
import { getUserDesignation, userName } from "../../../../../../../../utility/helpers/creditFileUtility";
import ErrorBoundary from "../../../../../../../ErrorHandleComponent/ErrorBoundary";
import { ButtonComponent } from "../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { CheckBoxComponent } from "../../../../../../../InputComponents/CheckBoxComponent/CheckBoxComponent";
import { ChipsComponent } from "../../../../../../../InputComponents/ChipsComponent/ChipsComponent";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { CMTextInput } from "../../../../../../CommunicationService/CMTextInput";
import { toast } from "../../../../../../GlobalToast";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";
import AnalysisOfJointBorrower from "./AnalysisOfJointBorrower";

export const AnalysisOfLoanApplicantVersionTwo = (props) => {
  const isCommercial = isProductOf({ types: [ProductType.COMMERCIAL] });
  const { userId }: any = userDetails.attach(Downgraded).get();
  const masterDataStore = MasterDataProvider.provideMasterData();
  const [appraisalData, setAppraisalData]: any = React.useState(props?.appraisalData);
  const { individualId, initials, primaryLastName } = props?.formData?.personalData || {};
  const jointBorrowerData = props?.formData?.jointBorrowerData || [];
  const loaders = useState({ verification: false, networth: false, relation: false });

  const bankRelation: any = useState(appraisalData.bankRelations?.[individualId] || {});
  const netWorth: any = useState(appraisalData.meta || {});
  const physicalVerification: any = useState({ ...(appraisalData?.physicalVerification || {}), comment: "" });
  const verificationComments: any = useState(appraisalData?.comments || {});
  const verificationTaken: any = useState(appraisalData?.physicalVerification?.verified || false);

  const updateWithAppraisalData = (res) => {
    bankRelation.set(res.bankRelations?.[individualId] || {});
    netWorth.set(res.meta || {});
    physicalVerification.set(res?.physicalVerification || {});
    verificationComments.set(res?.comments || {});
    verificationTaken.set(res?.physicalVerification?.verified || false);
  };

  const submitVerification = async () => {
    if (!physicalVerification?.comment?.get()) {
      return toast({ status: "error", message: "Physical verification comment required to proceed." });
    }

    appraisalData.physicalVerification = physicalVerification.attach(Downgraded).get() || {};
    const lastComment = latestComment();

    if (appraisalData.physicalVerification.comment != lastComment) {
      const comments = verificationComments.attach(Downgraded).get();
      let nextIndex: any = Object.keys(comments).pop() || "0";
      nextIndex = parseInt(nextIndex) + 1;
      let nextComment = {
        [nextIndex]: { id: nextIndex, comment: appraisalData.physicalVerification.comment, createdAt: new Date().toISOString(), createdBy: userId },
      };
      appraisalData.comments = { ...comments, ...nextComment };
    }

    setAppraisalData({ ...appraisalData });

    loaders.verification.set(true);

    const res = await onSubmit();
    updateWithAppraisalData(res);

    setVerificationData("comment", "");

    loaders.verification.set(false);
  };

  const submitNetWorth = async () => {
    if (!netWorth?.netWorth.get()) {
      return toast({ status: "error", message: "Net Worth amount required to proceed." });
    }

    appraisalData.meta = netWorth.attach(Downgraded).get() || {};
    setAppraisalData({ ...appraisalData });

    loaders.networth.set(true);

    const res = await onSubmit();
    updateWithAppraisalData(res);

    loaders.networth.set(false);
  };

  const syncAppraisalData = async (data) => {
    appraisalData.bankRelations = appraisalData?.bankRelations || {};
    appraisalData.bankRelations = { ...appraisalData.bankRelations, ...data };
    return await onSubmit();
  };

  const submitRelation = async () => {
    if (!isCommercial) {
      const comment = bankRelation["comment"]?.get(); 
      if (!comment || !comment.trim()) { 
        return toast({ status: "error", message: "Analysis of the Loan Applicant required to proceed." });
      }
    }

    appraisalData.bankRelations = appraisalData.bankRelations || {};
    appraisalData.bankRelations[individualId] = bankRelation.attach(Downgraded).get();

    setAppraisalData({ ...appraisalData });

    loaders.relation.set(true); 

    const res = await onSubmit();
    updateWithAppraisalData(res);

    loaders.relation.set(false); 
  };


  const onSubmit = async () => {
    const res = await props?.changeLoanApplicantData(userId, appraisalData);
    return res;
  };

  const Helper = {
    data: appraisalData,
    id: individualId,
    text: "",

    stamp({ createdBy, createdAt, updatedBy, updatedAt }) {
      let text = "";
      if (updatedBy || createdBy) text += userName(parseInt(updatedBy || createdBy));
      if ((updatedAt || createdAt)?.includes("Z")) text += " on " + dateFormatWithTimeChange(updatedAt || createdAt);
      else if (updatedAt || createdAt) text += " on " + (updatedAt || createdAt);
      return text;
    },

    get bankRelation() {
      return this.stamp((this.data as any)?.bankRelations?.[this.id] || {});
    },

    get netWorth() {
      return this.stamp((this.data as any)?.meta || {});
    },

    get verification() {
      const latestComment: any = Object.values(verificationComments.get() || {}).reverse()?.[0];
      return this.stamp(latestComment || {});
    },
  };

  const setRelationData = (key, value) => {
    let data: any = bankRelation.get();

    const { createdAt = new Date().toLocaleString(), createdBy = userId, ...rest } = data || {};

    data = { ...rest, createdAt, createdBy, updatedAt: new Date().toLocaleString(), [key]: value, updatedBy: userId };

    bankRelation.set(data);
  };

  const setNetWorthData = (key, value) => {
    let data: any = netWorth.get();

    const { createdAt = new Date().toLocaleString(), createdBy = userId, ...rest } = data || {};

    data = { ...rest, createdAt, createdBy, updatedAt: new Date().toLocaleString(), [key]: value };

    netWorth.set(data);
  };

  const setVerificationData = (key, value) => {
    let data: any = physicalVerification.get();

    const { createdAt = new Date().toLocaleString(), createdBy = userId, ...rest } = data || {};

    data = { ...rest, createdAt, createdBy, updatedAt: new Date().toLocaleString(), [key]: value };

    physicalVerification.set(data);
  };

  const latestComment = () => {
    const latestComment: any = Object.values(verificationComments.get() || {}).reverse()?.[0];
    return latestComment?.content || latestComment?.comment;
  };

  const openConfig = () => {
    alert("Modify verification status");
  };

  return (
    <ErrorBoundary>
      <Grid container columns={12} sx={{ justifyContent: "space-between" }} mt={1.5}>
        <Grid item xs={5.9} sx={{ height: "600px", overflowY: "scroll" }}>
          <Stack spacing={0} direction={"column"}>
            <Stack px={1}>
              <Paper style={{ padding: "15px", marginBottom: "20px" }}>
                <Grid container>
                  <Grid item xs={12} mb={2.5}>
                    <Typography variant="subtitle1" color={"gray"}>
                      Relationship With RDB (Primary Applicant): {[initials, primaryLastName].join(" ")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container pt={1}>
                      <Grid item xs={12} pt={1} mb={2}>
                        <InputGrid visibility={1} variant="fullWidth">
                          <SelectComponent
                            sx={select_basic}
                            label={"Relationship With RDB:"}
                            value={bankRelation.relation.get() || ""}
                            values={masterDataStore.getMasterData(MASTER_DATA_KEYS?.BANK_RELATION)}
                            onChange={(e) => {
                              setRelationData("relation", e.target.value);
                            }}
                            defaultPlaceholder={true}
                            disabled={props?.disabled}
                          />
                        </InputGrid>
                      </Grid>
                      <Grid item xs={12} mb={2}>
                        <CMTextInput
                          desc={`Analysis of the Loan Applicant ${!isCommercial ? "*" : ""}`}
                          component={
                            <TextBoxComponent
                              onChange={(e) => {
                                setRelationData("comment", e.target.value);
                              }}
                              multiline={true}
                              minRows={3}
                              maxRows={10}
                              value={bankRelation.comment.get()}
                              disabled={props?.disabled}
                              helperText={Helper.bankRelation}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ textAlign: "right" }}>
                        {loaders.relation.get() ? <CircularProgressComponent size={30} /> : <ButtonComponent title="SUBMIT" variant="contained" onClick={submitRelation} color="info" disabled={props?.disabled} />}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Stack>
            <Stack>
              {jointBorrowerData.map((jb, index) => (
                <Paper style={{ padding: "15px" }} key={index}>
                  <Stack>
                    <AnalysisOfJointBorrower jb={jb} appraisalData={appraisalData} onChange={syncAppraisalData} disableStatus={props?.disabled} />
                  </Stack>
                </Paper>
              ))}
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={5.9} sx={{ minHeight: "600px" }}>
          {isProductOf() && (
            <Paper style={{ padding: "15px", marginBottom: "20px" }}>
              <Grid item xs={12} mb={2}>
                <InputGrid visibility={1} variant="fullWidth">
                  <TextBoxComponent
                    label="Net Worth"
                    value={formatCurrency(netWorth.netWorth.get() || "")}
                    fullWidth={true}
                    onInput={OnDemandValueFormatter.numeric}
                    onChange={(e) => {
                      setNetWorthData("netWorth", e.target.value);
                    }}
                    required
                    disabled={props?.disabled}
                    helperText={Helper.netWorth}
                  />
                </InputGrid>
              </Grid>
              <Grid item xs={12} mt={2} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} mb={2}>
                <CheckBoxComponent
                  checked={netWorth.isCompanySignedAnyBusinessWithOther.get() || false}
                  disabled={props?.disabled}
                  onChange={(e) => {
                    setNetWorthData("isCompanySignedAnyBusinessWithOther", e.target.checked === true ? true : false);
                  }}
                  required={false}
                />
                <Typography variant="subtitle1" color={"gray"}>
                  {" "}
                  Company Signed any business agreements with 3rd party{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                {loaders.networth.get() ? <CircularProgressComponent size={30} /> : <ButtonComponent title="SUBMIT" variant="contained" onClick={submitNetWorth} color="info" disabled={props?.disabled} />}
              </Grid>
            </Paper>
          )}

          <Paper style={{ padding: "15px" }}>
            <Grid container>
              {!verificationTaken.get() && (
                <Stack width={"100%"}>
                  <Grid item xs={12} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} mb={2}>
                    <CheckBoxComponent
                      checked={physicalVerification?.verified?.get()}
                      disabled={loaders.networth.get() || props?.disabled || verificationTaken.get()}
                      onChange={(e) => {
                        setVerificationData("verified", e.target.checked === true ? true : false);
                      }}
                      required={false}
                    />
                    <Typography variant="subtitle1" color={"gray"}>
                      {" "}
                      Physical Verification Done for the Relation Contact {props?.formData.contactData?.relationLandNumber ? `(${props?.formData.contactData?.relationLandNumber})` : ""}{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <CMTextInput
                      desc={"Comment*"}
                      component={
                        <TextBoxComponent
                          onChange={(e) => {
                            setVerificationData("comment", e.target.value);
                          }}
                          multiline={true}
                          minRows={3}
                          maxRows={10}
                          value={physicalVerification.comment?.get() || ""}
                          disabled={props?.disabled || verificationTaken.get()}
                          helperText={Helper.verification}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "right" }} mb={2}>
                    {loaders.verification.get() ? <CircularProgressComponent size={30} /> : <ButtonComponent title={physicalVerification.verified?.get() ? "SUBMIT" : "ADD COMMENT"} variant="contained" onClick={submitVerification} color="info" disabled={props?.disabled || verificationTaken.get()} />}
                  </Grid>
                </Stack>
              )}

              <Grid item xs={12} mb={2}>
                {verificationComments.get()?.[0] && (
                  <Stack mb={2}>
                    <Typography variant="subtitle2" color={"gray"}>
                      {" "}
                      Comments:{" "}
                    </Typography>
                    <Divider sx={{ marginTop: 1 }}></Divider>
                  </Stack>
                )}

                {Object.values(verificationComments.get() || {})
                  .reverse()
                  .map((obj: any, index) => {
                    const _comment = obj?.content || obj?.comment || "";
                    let verified = obj?.status || obj?.verified;

                    if (verificationTaken.get() && index === 0) {
                      verified = true;
                    }

                    return (
                      <Stack key={"section_" + index}>
                        <Stack mb={2}>
                          <Typography variant="body2" color={"GrayText"} style={{ whiteSpace: "pre-line" }}>
                            {" "}
                            {_comment}
                          </Typography>
                        </Stack>
                        <Stack justifyContent={"space-between"} direction={"row"} width={"100%"}>
                          <Stack direction={"row"} spacing={1}>
                            <Typography variant="caption" color={"gray"}>
                              {" "}
                              Physical Verification{" "}
                            </Typography>
                            <ChipsComponent size="small" label={<Typography variant="caption">{verified ? "Yes" : "No"}</Typography>} color={verified ? "success" : "primary"} onClick={verified && AppConfig.control ? openConfig : null}></ChipsComponent>
                          </Stack>
                          <Stack direction={"row"} spacing={1}>
                            <PersonIcon color={"action"} fontSize={"small"} sx={{ fontSize: "14pt" }} />
                            <Typography variant="caption" color={"gray"}>{`${userName(obj.userId ?? obj.createdBy ?? "")} ${getUserDesignation(obj.userId ?? obj.createdBy ?? "")}`}</Typography>
                          </Stack>
                          <Stack direction={"row"} spacing={1}>
                            <CalendarMonthIcon color={"action"} fontSize={"small"} sx={{ fontSize: "14pt" }} />
                            <Typography variant="caption" color={"gray"}>{`${dateFormatWithTimeChange(obj?.created ?? obj?.createdAt ?? "")}`}</Typography>
                          </Stack>
                        </Stack>
                        <Divider sx={{ marginTop: 1, marginBottom: 4 }}></Divider>
                      </Stack>
                    );
                  })}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};
