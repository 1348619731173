import { useState } from "@hookstate/core";
import { Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { getAnalysisLoanRequirementCommentsData, getAnalysisOfLoanRequirementDetails, saveAppraisalData } from "../../../../../../../../services/creditFileApiCall";
import isProductOf, { ProductType } from "../../../../../../../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import { responseSuccessCode } from "../../../../../../../../utility/other";
import { toast } from "../../../../../../GlobalToast";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";
import { disableAccess } from "../../../../UserCreditComponents/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { AddLoanRequirementForm } from "./AddLoanRequirementForm";
import CommercialContent from "./CommercialContent";

const AnalysisOfLoanRequirement = ({ data, applicationId, isMainContactDetails = true, mainTabData = { isAppraisal: false }, isRawVersion, innerComponentData, tabsToEdit }) => {
  const [loanRequirementList, setLoanRequirementList] = React.useState<any>([]);
  const loading: any = useState({ details: true, saving: false, delete: -1 });
  //New Commercial Variables & Functions
  const [filteredComentList, setFilteredComentList]: any[] = React.useState([]);
  const [proposedProjectComment, setProposedProjectComment] = React.useState("");
  const commercial = isProductOf({ types: [ProductType.COMMERCIAL] });

  const compareByCommentId = (a, b) => {
    return b.id - a.id;
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (isMounted) {
        loading.details.set(true);
      }
      const [result = {}, resultComments = {}] = await Promise.all([getAnalysisOfLoanRequirementDetails({ applicationId, }), getAnalysisLoanRequirementCommentsData({ applicationId })]);

      let { data, comments, proposedProjectComment: _proposedProjectComment } = result;

      if (result instanceof Array) {
        data = result?.[0];
      }

      if (result.section === "analysis_loan_applicant") {
        if (comments && comments?.[0]?.comment) {
          let commentsArr: any[] = Object.values(comments);
          commentsArr.sort(compareByCommentId);
          if (isMounted) {
            setFilteredComentList(commentsArr);
          }

          if (commercial) {
            if (isMounted) {
              setLoanRequirementList({});
            }
          } else {
            if (isMounted) {
              setLoanRequirementList([]);
            }
          }
        }
        if (isMounted) {
          loading.details.set(false);
        }

        return;
      }

      if (data) {
        if (commercial) {
          if (isMounted) {
            setLoanRequirementList(data ?? {});
          }
        } else {
          if (isMounted) {
            setLoanRequirementList(data);
          }
        }
      }

      if (comments) {
        let commentsArr: any[] = Object.values(comments);
        commentsArr.sort(compareByCommentId);
        if (isMounted) {
          setFilteredComentList(commentsArr);
        }
      }

      if (_proposedProjectComment?.comment !== "") {
        setProposedProjectComment(_proposedProjectComment?.comment)
      }

      if (isMounted) {
        loading.details.set(false);
      }
    })();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId]);

  const changeRequirementData = async (items, userId) => {
    let tempObj = { ...items, userId, created: new Date() };
    await onSaveData([tempObj]);
  };

  const onSaveData = async (tempReqList) => {
    const alert = {
      status: "success",
      message: "",
    };

    try {
      loading.saving.set(true);
      const res: any = await saveAppraisalData({
        section: "analysis_loan_requirement",
        applicationId: applicationId,
        data: tempReqList,
      });

      alert.message = res?.status === responseSuccessCode ? res?.data?.msg : "Failed to proceed.";
    } catch (error: any) {
      alert.status = "error";
      alert.message = error?.message ?? "Failed to proceed.";
    } finally {
      toast(alert);
      loading.saving.set(false);
    }
  };

  const checkPrivilegeStatus = disableAccess({
    isTabInEditString: tabsToEdit.includes(innerComponentData?.id),
  });



  const RetailContent = () => {
    return (
      <Stack>
        <AddLoanRequirementForm changeRequirementData={changeRequirementData} checkPrivilegeStatus={checkPrivilegeStatus} loanRequirementList={loanRequirementList} />
      </Stack>
    );
  };

  const Header = () => {
    return (
      <Stack px={2} style={{ backgroundColor: "#e6f0fa", minHeight: "42pt", position: "sticky", zIndex: 100, top: "0px" }} justifyContent={"center"}>
        <Typography className="basic-font basic-font-color-bold">Analysis of the Loan Requirement</Typography>
      </Stack>
    );
  };

  if (loading.details.get()) {
    return (
      <Stack style={{ backgroundColor: "#fff", height: "calc(100vh - 120pt)", overflowY: "hidden" }}>
        <Header />
        <Stack justifyContent={"center"} alignItems={"center"} height={"100%"}>
          <CircularProgressComponent size={30} sx={{ left: "50%", top: "50%" }} />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack style={{ backgroundColor: "#fff", height: "calc(100vh - 120pt)" }}>
      <Header />
      {commercial ? <CommercialContent loanRequirement={loanRequirementList} disableStatus={checkPrivilegeStatus} data={data} loading={loading} proposedProjectComment={proposedProjectComment} applicationId={applicationId} filteredComentList={filteredComentList} setFilteredComentList={setFilteredComentList} /> : <RetailContent />}
    </Stack>
  );
};

export default AnalysisOfLoanRequirement;