export const fieldsMapping = {
    projectRelatedTotalLoansOutstanding: {
        fieldType: "detail",
        fieldKey: "projectRelatedTotalLoansOutstanding",
        fieldName: "Project Related Total Loans Outstanding Balance"
    },

    avgInterestRateAssume: {
        fieldType: "text",
        fieldKey: "avgInterestRateAssume",
        fieldName: "Average Interest Rate Assumed"
    },

    annualInterestExpenses: {
        fieldType: "detail",
        fieldKey: "annualInterestExpenses",
        fieldName: "Annual Interest Expenses"
    },

    existingLoan: {
        fieldType: "detail",
        fieldKey: "existingLoan",
        fieldName: "Existing Loan"
    },

    proposedLoan: {
        fieldType: "detail",
        fieldKey: "proposedLoan",
        fieldName: "Proposed Loan"
    },

    totalInterest: {
        fieldType: "detail",
        fieldKey: "totalInterest",
        fieldName: "Total Interest"
    }
};