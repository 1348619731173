import { State } from "@hookstate/core";
import { Grid } from "@mui/material";
import React from "react";
import { formatCurrency } from "../../../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../../../services/onDemandValidators";
import { TextBoxComponent } from "../../../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { FieldSectionWrapper } from "../../../../../../../../FieldSectionsWrapper.tsx/FieldSectionWrapper";
import { SubSectionsWrapper } from "../../../../../../../../SubSectionsWrapper/SubSectionsWrapper";
import GridDataTable from "../../../../../FormBuilder/Tools/Helpers/Components/GridDataTable";

interface TableProps {
    otherParams: any;
    data: State<
        {
            description: string;
            operatingCosts: number;
            numberOfDays: number;
            cost: number;
            dataId: string;
            removedItem?: State<boolean>;
            amount: number;
        }[]
    >;
}

const Table: React.FC<TableProps> = ({ otherParams, data }: TableProps) => {
    const [openModal, setOpenModal] = React.useState(false);
    const { isAmountAboveTwoPointFiveAndEqual, description } = otherParams;
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <SubSectionsWrapper>
                    <FieldSectionWrapper>
                        <GridDataTable
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                            totalBtnVisility={false}
                            sourceArray={data}
                            addButtonInvisible={true}
                            headerTitle={[(description || "Description"), "Operating Costs", "Number of Days", "Amount"]}
                        >
                            {data
                                ?.filter((itemObj) => !itemObj?.removedItem?.get())
                                .map((items, index) => (
                                    <Grid item xs={12} md={12} key={`working-capital-creditors-${items?.dataId.get() || index}`}>
                                        <Grid container gap={1}>
                                            <Grid item xs={2}>
                                                <TextBoxComponent
                                                    label={description || "Description"}
                                                    required={Boolean(isAmountAboveTwoPointFiveAndEqual)}
                                                    value={items?.description?.get()}
                                                    fullWidth={true}
                                                    onChange={(e) => items?.description?.set(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextBoxComponent
                                                    label={"Operating Costs"}
                                                    required={Boolean(isAmountAboveTwoPointFiveAndEqual)}
                                                    value={formatCurrency(items?.operatingCosts?.get()) || 0}
                                                    onInput={OnDemandValueFormatter.numeric}
                                                    fullWidth={true}
                                                    onChange={(e) => items?.operatingCosts?.set(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextBoxComponent
                                                    label={"Number of Days"}
                                                    onBlur={(e) => {
                                                        const calc = (Number(items?.operatingCosts?.get() || 0) / 365) * Number(e.target.value);
                                                        items?.amount?.set(calc);
                                                    }}
                                                    required={Boolean(isAmountAboveTwoPointFiveAndEqual)}
                                                    value={items?.numberOfDays?.get()}
                                                    fullWidth={true}
                                                    onChange={(e) => {
                                                        if (e.target.value >= 0 && e.target.value <= 500) {
                                                            items?.numberOfDays?.set(e.target.value);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextBoxComponent
                                                    label={""}
                                                    style={{ backgroundColor: "#D3D3D3", fontWeight: "bold" }}
                                                    required={Boolean(isAmountAboveTwoPointFiveAndEqual)}
                                                    value={formatCurrency(items?.amount?.get()) || 0}
                                                    onInput={OnDemandValueFormatter.numeric}
                                                    fullWidth={true}
                                                    disabled={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                        </GridDataTable>
                    </FieldSectionWrapper>
                </SubSectionsWrapper>
            </Grid>
        </Grid>
    )
}

export default Table