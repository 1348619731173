import { Divider, Grid, Paper, Typography } from "@mui/material";
import A4Container from "../A4Container";
import EditableField from "../EditableField";

const ChattelMortgage = ({
    date,
    borrowerName,
    borrowerNIC,
    borrowerAddress,
    loanAmount,
}) => {
    return (
        <A4Container>
            <Paper sx={{ padding: 3, boxShadow: "none", display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography variant="h5" align="center" fontWeight="bold">
                    CHATTEL MORTGAGE AGREEMENT
                </Typography>
                <Typography variant="body1" align="center">
                    This agreement is executed on <b>{date}</b> between <b>{borrowerName}</b>, NIC No. <b>{borrowerNIC}</b>, residing at <b>{borrowerAddress}</b>, and  <EditableField label="Lender Name" />, located at <EditableField label="Lender Address" />.
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body1">
                    <b>1. Secured Obligation:</b> The Mortgagor pledges <EditableField label="Asset Description" /> as collateral for a loan of LKR <b>{loanAmount}</b>.
                </Typography>
                <Typography variant="body1">
                    <b>2. Default & Repossession:</b> The Mortgagee has the right to seize the asset in case of non-payment.
                </Typography>
                <Typography variant="body1">
                    <b>3. Insurance Requirement:</b> The Mortgagor must insure the asset with the Mortgagee as beneficiary.
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="body1"><b>Mortgagor:</b></Typography>
                        <Typography variant="body1">Signature: ____________________</Typography>
                        <Typography variant="body1">Name: {borrowerName}</Typography>
                        <Typography variant="body1">Date: {date}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1"><b>Mortgagee:</b></Typography>
                        <Typography variant="body1">Signature: ____________________</Typography>
                        <Typography variant="body1">Name: <EditableField label="Lender Address" /></Typography>
                        <Typography variant="body1">Date: {date}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        </A4Container>
    );
};

export default ChattelMortgage;