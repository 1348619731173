import { Downgraded } from "@hookstate/core";
import { LengthItemValidate } from "../../components/OtherComponents/ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn";
import appStrings from "../../configs/constants/appStrings";
import { langDictionary } from "../../configs/languageDictionaryEn";
import { agricultureDetails } from "../../configs/mainStore";
import { clean } from "../CustomHooks/useSaveSectionChanges";
import { addressDetails } from "./ApplicationMandatoryValidations/addressDetails";
import { agricultureDetailsValidationCollector } from "./ApplicationMandatoryValidations/agricultureDetails";
import { assetsDetails } from "./ApplicationMandatoryValidations/assetsDetails";
import { bankDetails } from "./ApplicationMandatoryValidations/bankDetails";
import { businessDetails } from "./ApplicationMandatoryValidations/businessDetails";
import { contactDetails } from "./ApplicationMandatoryValidations/contactDetails";
import { creditDetails } from "./ApplicationMandatoryValidations/creditDetails";
import { educationDetails } from "./ApplicationMandatoryValidations/educationDetails";
import { employmentDetails } from "./ApplicationMandatoryValidations/employmentDetails";
import { expenseDetails } from "./ApplicationMandatoryValidations/expenseDetails";
import { incomeDetails } from "./ApplicationMandatoryValidations/incomeDetails";
import { lifeInsuranceDetails } from "./ApplicationMandatoryValidations/lifeInsuranceDetails";
import { obligationDetails } from "./ApplicationMandatoryValidations/obligationDetails";
import { otherParticipantSectionValidator } from "./ApplicationMandatoryValidations/otherParticipantSectionValidator";
import { primaryDetails } from "./ApplicationMandatoryValidations/personalDetails";
import { securityDetails } from "./ApplicationMandatoryValidations/securityDetails";
import { singleCustomerViewDetailsValidator } from "./ApplicationMandatoryValidations/singleCustomerViewDetails";
import { taxDetails } from "./ApplicationMandatoryValidations/taxDetails";
import { leaseDetails } from "./ApplicationMandatoryValidations/leaseDetails";
import { insuranceDetails } from "./ApplicationMandatoryValidations/insuranceDetails";


interface ValidateMandatoryHandler {
  formStore: any;
  structure: any[];
  section?: string;
  participantState?: {};
  participantStructure?: any;
  isGuarantor?: boolean;
  isJointBorrower?: boolean;
  isNoObligations?: boolean;
  guarantorStructure?: {};
  jointBorrowerStructure?: {};
  validation?: any;
  previousData?: any;
}

export const validateMandatoryHandler = ({
  formStore, structure,
  section = "", participantState = {}, participantStructure = {},
  isGuarantor = false, isJointBorrower = false, isNoObligations = false,
  guarantorStructure = {}, jointBorrowerStructure = {}, validation, previousData
}: ValidateMandatoryHandler) => {

  let response = [];
  const { personalData,
    creditData,
    singleCustomerViewData,
    contactData,
    addressData,
    educationData,
    employedData,
    businessData,
    incomeData,
    expenseData,
    assetsData,
    lifeInsuranceData,
    bankData,
    jointBorrowerData,
    taxDetailsData,
    leaseAssetsData,
    insuranceData,
    securityData, inquiryOfObligationsData } = formStore;

  const validatorData = {
    "primary-applicant-details": {
      function: primaryDetails,
      state: personalData,
      key: "personalData",
    },
    "credit-details": {
      function: creditDetails,
      state: creditData,
      key: "creditData",
    },
    "single-customer-view-details": {
      function: singleCustomerViewDetailsValidator,
      state: singleCustomerViewData,
      key: "singleCustomerViewDetails",
    },
    "contact-details": {
      function: contactDetails,
      state: contactData,
      key: "contactData",
    },
    "address-details": {
      function: addressDetails,
      state: addressData,
      key: "addressData",
    },
    "education-details": {
      function: educationDetails,
      state: educationData,
      key: "educationData",
    },
    "employment-details": {
      function: employmentDetails,
      state: employedData,
      key: "employedData",
    },
    "income-details": {
      function: incomeDetails,
      state: incomeData,
      key: "incomeData",
    },
    "expenses-details": {
      function: expenseDetails,
      state: expenseData,
      key: "expenseData",
    },
    "bank-details": {
      function: bankDetails,
      state: bankData,
      key: "bankData",
    },
    "assets-details": {
      function: assetsDetails,
      state: assetsData,
      key: "assetsData",
    },
    "lease-details": {
      function: leaseDetails,
      state: leaseAssetsData,
      key: "leaseAssetsData",
    },
    "insurance-details": {
      function: insuranceDetails,
      state: insuranceData,
      key: "insuranceData",
    },
    "security-details": {
      function: securityDetails,
      state: securityData,
      key: "securityData",
    },
    "joint-borrower-details": {
      function: () => [],
      state: jointBorrowerData,
      key: "",
    },
    "tax-details": {
      function: taxDetails,
      state: taxDetailsData,
      key: "taxDetailsData",
    },
    "inquiry-of-obligations": {
      function: obligationDetails,
      state: inquiryOfObligationsData,
      key: "inquiryOfObligationsData",
    },
    "business-details": {
      function: businessDetails,
      state: businessData,
      key: "businessData",
    },
    "life-insurance-details": {
      function: lifeInsuranceDetails,
      state: lifeInsuranceData,
      key: "lifeInsuranceData",
    },

    "agriculture": {
      function: agricultureDetailsValidationCollector,
      state: agricultureDetails,
      key: "agricultureData",
    }

  };
  /**
   * @param participantState this is the participant state list
   * @returns participant validator data like above, replacing the state with participant state
   */
  const convertToParticipantState = (participantState) => {
    let ParticipantValidatorData = {};

    // mainKeyFilterForSanitizing(participantState);

    Object.keys(validatorData)?.map((itemKey) => {
      const { state, ...rest } = validatorData?.[itemKey];
      const stateKey = validatorData?.[itemKey]?.key;
      ParticipantValidatorData[itemKey] = {
        ...rest,
        state: participantState[stateKey],
      };
    });

    return ParticipantValidatorData;
  };

  let otherParticipantSectionValidationProps = { convertToParticipantState, handleAllStructure, formStore, isGuarantor, isJointBorrower, previousData };

  const otherParticipantSectionValidationHandler = (participantStructure, section, pureData) => {
    let preparedErrorMessages: string[] = [];
    pureData = LengthItemValidate.validatedArray(pureData);
    let participant = appStrings.participant?.[section];

    let participantValidations = otherParticipantSectionValidator({ ...otherParticipantSectionValidationProps, state: pureData, participantStructure, participantSection: section });

    participantValidations.forEach((item, index) => {
      item = clean(item);
      let sectionsKeys = Object.keys(item);

      if (sectionsKeys.length) {
        preparedErrorMessages.push(`\n${participant} ${index + 1} mandatory Validation Error`);
        sectionsKeys.forEach((sectionKey, index) => {
          preparedErrorMessages.push(`👉 ${index + 1 < 10 ? `0${index + 1}` : `${index + 1}`}. ${langDictionary.applicationForm.titles?.[sectionKey]}`);
        });

      } else {
        delete participantValidations[index];
      }
    })

    return { preparedErrorMessages, participantValidations }
  }

  if (!section) {
    let errArr = handleAllStructure({ structure, validatorData, formStore, previousData });
    let personalDetails = personalData?.attach(Downgraded).get();

    let validatedjb = structure.find((item) => item.key === 'joint-borrower-details');

    if (personalDetails?.addJointBorrower && validatedjb) {
      let { preparedErrorMessages, participantValidations } = otherParticipantSectionValidationHandler(jointBorrowerStructure, 'joint-borrower-details', validatorData['joint-borrower-details'].state);
      errArr = { ...errArr, jointBorrowerData: [...preparedErrorMessages], [appStrings.otherSectionValidation?.["joint-borrower-details"]]: participantValidations }
    }

    if (errArr["securityData"]) {
      if (!errArr["securityData"].validatedGuarantor) {
        errArr["securityData"] = errArr["securityData"].preparedErrorMessages;

      } else {
        let { preparedErrorMessages, participantValidations } = otherParticipantSectionValidationHandler(guarantorStructure, 'security-details', validatorData['security-details'].state?.guarantors);
        errArr = { ...errArr, "securityData": [...errArr["securityData"].preparedErrorMessages, ...preparedErrorMessages], [appStrings.participant.securityData]: participantValidations }
      }
    }

    validation.set(prev => ({ ...prev, ...errArr }));

    delete errArr["guarantorSectionValidations"];
    delete errArr["jointBorrowerSectionValidations"];

    return errArr;
  } else if (section === "security-details") {
    type ValidationResult = { preparedErrorMessages: string[]; validatedGuarantor: boolean };

    // Determine the validator function for paper documents
    let validatorFunctionPaperDoc = typeof validatorData[section]?.function === 'function' ? validatorData[section]?.function : () => ({ preparedErrorMessages: [], validatedGuarantor: false });
    let { preparedErrorMessages = [], validatedGuarantor = false }: ValidationResult = validatorFunctionPaperDoc(structure["content"], validatorData[section]?.state);

    if (!validatedGuarantor) return preparedErrorMessages;

    let pureGuarantorData = validatorData[section]?.state?.guarantors;

    let { preparedErrorMessages: errorMessages, participantValidations: guarantorValidations } = otherParticipantSectionValidationHandler(participantStructure, section, pureGuarantorData);

    return {
      "securityData": [...preparedErrorMessages, ...errorMessages],
      [appStrings.participant.securityData]: guarantorValidations
    }

  } else if (section === "joint-borrower-details" || section === "supplementary-card") {
    let pureJointBorrower = validatorData[section]?.state;
    let { preparedErrorMessages, participantValidations } = otherParticipantSectionValidationHandler(participantStructure, section, pureJointBorrower);

    return {
      [appStrings.mainSectionValidationsKeys?.[section]]: [...preparedErrorMessages],
      [appStrings.otherSectionValidation?.[section]]: participantValidations
    }

  } else {
    isNoObligations = section === "inquiry-of-obligations" ? isNoObligations : false;

    let state = (participantState && Object.keys(participantState).length > 0)
      ? participantState[validatorData[section].key]
      : validatorData[section]?.state;

    let validatorFunction = validatorData[section]?.function || (() => []);

    response = validatorFunction(structure, state, isGuarantor, isJointBorrower, participantState, isNoObligations, formStore, previousData);
  }

  return response;
};

const handleAllStructure = ({ structure, validatorData, formStore, isGuarantor = false, isJointBorrower = false, isSecuritySectionNext = false, participantState = null, previousData }) => {
  let obj = {};

  try {
    structure.forEach(element => {
      const elementKey = element?.key;
      if (validatorData.hasOwnProperty(elementKey) && element?.visible) {
        const elementValues = validatorData[elementKey];
        const errorArr = elementValues.function(
          element,
          elementValues.state,
          isGuarantor,
          isJointBorrower,
          participantState,
          isSecuritySectionNext,
          formStore,
          previousData
        );

        obj[validatorData[elementKey]?.key] = errorArr;
      }
    });
  } catch (error) {
    console.error('Error in handleAllStructure:', error);
  }

  return obj;
};

export const mainKeyFilterForSanitizing = (participantState, recurse = false, mainObjKey = "") => {
  if (Array.isArray(participantState)) {
    participantState.forEach((participantStateObj) => mainKeyFilterForSanitizing(participantStateObj));
  } else {
    for (const key in participantState) {
      if (key === "inquiryOfObligationsData" || key === "incomeData") {
        mainKeyFilterForSanitizing(participantState[key], true, key);
      } else if (recurse) {
        recurseHandler(mainObjKey, key, participantState);
      }
    }
  }
};

export const keyValueSanitizer = (objectToBeSanitized) => {
  try {
    objectToBeSanitized = objectToBeSanitized.get()
  } catch (error) {
  }

  let objectData = objectToBeSanitized;

  if (Array.isArray(objectData)) {
    if (objectData.length === 0) return [];

    const sanitizedArray = objectData.filter((obj) => {
      if (obj && typeof obj === 'object') {
        const keysToRemove = Object.keys(obj).filter(key => !obj[key] || obj[key] === 0);
        return keysToRemove.length !== Object.keys(obj).length;
      }
      return true;
    });

    return sanitizedArray.length > 0 ? sanitizedArray : [];
  }

  return objectData;
};

const recurseHandler = (paramId, key, participantState) => {
  function processState(key, state) {
    let value = state.get();
    value = JSON.parse(JSON.stringify(value));
    state.set(value);
  }

  function processStateWithSanitizer(key, state) {
    let returnedData = keyValueSanitizer(state);
    returnedData = JSON.parse(JSON.stringify(returnedData));
    state.set(returnedData);
  }

  if (paramId === "inquiryOfObligationsData") {
    if (key === "sectionErrorStatus" || key === "totalLiabilityAmount") {
      processState(key, participantState[key]);
    } else {
      processStateWithSanitizer(key, participantState[key]);
    }
  } else if (paramId === "incomeData") {
    if (key === "sectionErrorStatus" || key === "selectedCategories") {
      processState(key, participantState[key]);
    } else {
      const { expense, income } = participantState[key];
      if (expense && income) {
        processStateWithSanitizer("income", participantState[key].income);
        processStateWithSanitizer("expense", participantState[key].expense);
      } else {
        processStateWithSanitizer(key, participantState[key]);
      }
    }
  }
};
