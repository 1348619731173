import AppConfig from "../../utility/AppConfig";
import isProductOf from "../../utility/AthamaruProductBusinessDetails/AthamaruComponentsValidator";
import { ParenDropdown, SimpleDropDownNumeric } from "../interfaces";

export const loanRequirementKey = "ADD_LOAN_REQUIREMENT_FORM";

export const authenticationStatus = {
  success: 1,
  fail: -1,
  unAuthorized: -2,
  emptyToken: -3,
  invalidToken: -4,
  mismatchToken: -5,
  tokenExpired: -6,
  notFound: -7,
  invalidUserType: -8,
  invalidAccessLimit: -9,
  updateError: -10,
  requestLimitExceed: -11,
  expiredToken: -12,
  invalidLogin: -13,
};

export const OfficeLocations = [
  {
    id: 1,
    name: "Branch Office",
  },
  {
    id: 2,
    name: "Distric Office",
  },
  {
    id: 3,
    name: "Province Office",
  },
  {
    id: 4,
    name: "Head Office",
  },
];

export const userTypes = [
  // {
  //   id: 1,
  //   name: 'System admin',
  // },
  {
    id: 2,
    name: "Internal",
  },
  // {
  //   id: 3,
  //   name: 'Decentralized',
  // },
  // {
  //   id: 4,
  //   name: 'External',
  // },
];

export const userRoles = [
  {
    id: 1,
    name: "Field Officer",
    pcode: 2,
    bcode: 1,
  },
  {
    id: 2,
    name: "Manager - Credit Unit (3-iv to 3-i)",
    pcode: 2,
    bcode: 1,
  },
  {
    id: 3,
    name: "Branch Manager (3 - iv to 3-i)",
    pcode: 2,
    bcode: 1,
  },
  {
    id: 4,
    name: "Branch Manager (Officer 1)",
    pcode: 2,
    bcode: 1,
  },
  {
    id: 5,
    name: "Assistant District Manager (Officer 1)",
    pcode: 2,
    bcode: 2,
  },
  {
    id: 6,
    name: "District Manager (Officer 2)",
    pcode: 2,
    bcode: 2,
  },
  {
    id: 7,
    name: "Chief Manager - Credit (Officer 2)",
    pcode: 2,
    bcode: 3,
  },
  {
    id: 8,
    name: "AGM - Province (Special 4)",
    pcode: 2,
    bcode: 3,
  },
  {
    id: 9,
    name: "RGM - Province (Special 3)",
    pcode: 2,
    bcode: 3,
  },
  {
    id: 10,
    name: "Manager - CCU  ",
    pcode: 2,
    bcode: 4,
  },
  {
    id: 11,
    name: "Senior Manager  - CCU ",
    pcode: 2,
    bcode: 4,
  },
  {
    id: 12,
    name: "Chief Manager - CCU",
    pcode: 2,
    bcode: 4,
  },
  {
    id: 13,
    name: "AGM - Credit",
    pcode: 2,
    bcode: 4,
  },
  {
    id: 14,
    name: "DGM - Credit",
    pcode: 2,
    bcode: 4,
  },
  {
    id: 15,
    name: "General Manager /CEO",
    pcode: 2,
    bcode: 4,
  },
  {
    id: 16,
    name: "Executive Credit Committee",
    pcode: 2,
    bcode: 4,
  },
  {
    id: 17,
    name: "Bord of Directors",
    pcode: 2,
    bcode: 4,
  },
  {
    id: 18,
    name: "Legal Team",
    pcode: 2,
    bcode: 4,
  },
  {
    id: 19,
    name: "Risk Team",
    pcode: 2,
    bcode: 4,
  },
  {
    id: 20,
    name: "Credit Admin  Team",
    pcode: 3,
    bcode: 0,
  },
  {
    id: 21,
    name: "Panel Lowyer",
    pcode: 4,
    bcode: 0,
  },
  {
    id: 22,
    name: "Panel Valuer",
    pcode: 4,
    bcode: 0,
  },
];

export const loanAmountRanges = {
  houseLoan: {
    min: 0,
    max: 100000000,
  },
  default: {
    min: 0,
    max: 100000000,
  },
  minOnly: {
    min: 0,
  },
  months: {
    min: 0,
    max: 12,
  },
  days: {
    min: 0,
    max: 31,
  },
};

export const applicationRequestStatus = {
  leadInprogress: 1,
  leadCompleted: 2,
  closed: 3,
};

export const applicationStatus = {
  pendingSubmission: 1,
  submitted: 2,
  closed: 3,
};

export const statusKeys = {
  leadInProgress: "leadInProgress",
  pendingSubmission: "pendingSubmission",
  leadClosed: "leadClosed",
  submitted: "submitted",
  closed: "closed",
  pendingLeads: "pendingLeads",
  pendingApplications: "pendingApplications",
  submittedLeads: "Submitted Leads",
  submittedApplication: "Submitted Application",
};

export const applicationStatusData = {
  1: {
    id: 1,
    name: "Pending Submission",
  },
  2: {
    id: 2,
    name: "Submitted",
  },
  3: {
    id: 3,
    name: "Closed",
  },
};

export const commonStatus = {
  active: 1,
  inactive: 0,
};

export const commonResponseStatus = {
  success: 1,
  error: -1,
};

export const applicationDocumentStatus = {
  pending: 0,
  approved: 1,
  rejected: 2,
};

export const SMS_MESSAGE_LENGTH = 300;

export const languageIdList = { english: "EN", sinhala: "SI" };

export const followUpChecklistStatus = {
  pending: 0,
  done: 1,
};

export const PRIMARY_PARTICIPANT_ID = 1;
export const CRIB_DOCUMENT_ID = 19;
export const RISK_DOCUMENT_ID = 33;
export const LEGAL_DOCUMENT_ID = 33;
export const CAU_OFFICER_DOCUMENT_ID = 108;
export const INSPECTION_DOCUMENT_ID = 9;

export const FORBIDDEN_CODE = 403;
export const NO_ACCESS_CODE = 450;

export const MISSING_PRIVILEGE_CODE = 405;

export const CORE_BANK_KEYS = {
  CIF: "CIF",
  REC_ACC: "REC_ACC",
  INVESTMENT_ACCOUNT: "INVESTMENT_ACCOUNT",
  SCHEME: "SCHEME",
  ARM: "ARM",
  INVESTMENT_AMOUNT: "INVESTMENT_AMOUNT",
  DIRECT_SELLING_AGENT_CODE: "DIRECT_SELLING_AGENT_CODE",
  MARKETTING_CHANNEL_CODE: "MARKETTING_CHANNEL_CODE",
  DISB_SCHEDULE: "DISB_SCHEDULE",
  INVESTMENT_SEARCH_REF_KEY: "INVESTMENT_SEARCH_REF_KEY",
  VILLAGE_LEADER: "VILLAGE_LEADER",
};

export const securityCategory = [
  { id: 1, name: "Secured" },
  { id: 2, name: "Unsecured" },
];

export const primaryEduLevelAwardMapping = {
  1: [1],
  2: [1],
  3: [1],
  4: [3, 4],
  5: [5, 2],
  6: [7, 6],
};

export const errorComponentNames: any = {
  "primary-applicant-details": "Personal",
  "credit-details": "Credit",
  "contact-details": "Contact",
  "address-details": "Address",
  "education-details": "Education",
  "employment-details": "Employment",
  "business-details": "Business",
  "income-details": "Income",
  "expenses-details": "Expense",
  "bank-details": "Bank",
  "assets-details": "Assets",
};

export const insuaranceCompany = [
  {
    id: "AVIVA NDB Insuarance PLC - 56892",
    code: 56892,
    name: "AVIVA NDB Insuarance PLC - 56892",
  },
  {
    id: "Janashakthi Insuarance PLC - 56890",
    code: 56890,
    name: "Janashakthi Insuarance PLC - 56890",
  },
  {
    id: "Amana Takaful PLC - 56899",
    code: 56899,
    name: "Amana Takaful PLC - 56899",
  },
  {
    id: "HNB Assuarance PLC - 60848",
    code: 60848,
    name: "HNB Assuarance PLC - 60848",
  },
  {
    id: "Ceylinco Insuarance PLC - 56889",
    code: 56889,
    name: "Ceylinco Insuarance PLC - 56889",
  },
  {
    id: "Asian Alliance Insuarance PLC - 56898",
    code: 56898,
    name: "Asian Alliance Insuarance PLC - 56898",
  },
  {
    id: "Union Assuarance PLC - 56896",
    code: 56896,
    name: "Union Assuarance PLC - 56896",
  },
  {
    id: "Sri Lanka Insuarance Corporation LTD - 56893",
    code: 56893,
    name: "Sri Lanka Insuarance Corporation LTD - 56893",
  },
  {
    id: "Continental Insuarance Lanka LTD - 60841",
    code: 60841,
    name: "Continental Insuarance Lanka LTD - 60841",
  },
  {
    id: "Ceylinco Takaful Insuarance - 60847",
    code: 60847,
    name: "Ceylinco Takaful Insuarance - 60847",
  },
  {
    id: "Co Operative Insuarance LTD - 60842",
    code: 60842,
    name: "Co Operative Insuarance LTD - 60842",
  },
  {
    id: "Alliance Insuarance Lanka LTD - 60843",
    code: 60843,
    name: "Alliance Insuarance Lanka LTD - 60843",
  },
  {
    id: "Orient Insuarance - 60850",
    code: 60850,
    name: "Orient Insuarance - 60850",
  },
  {
    id: "Sanasa Insuarance Company LTD - 60851",
    code: 60851,
    name: "Sanasa Insuarance Company LTD - 60851",
  },
  {
    id: "Peoples Insuarance - 60853",
    code: 60853,
    name: "Peoples Insuarance - 60853",
  },
  {
    id: "Fairfirst Insuarance company LTD - 798583",
    code: 798583,
    name: "Fairfirst Insuarance company LTD - 798583",
  },
  {
    id: "National Insuarance Trust Fund Board - 60854",
    code: 60854,
    name: "National Insuarance Trust Fund Board - 60854",
  },
  {
    id: "Arpico Insuarance PLC - 60856",
    code: 60856,
    name: "Arpico Insuarance PLC - 60856",
  },
  {
    id: "Softlogic Life Insuarance PLC - 60852",
    code: 60852,
    name: "Softlogic Life Insuarance PLC - 60852",
  },
  {
    id: "LOLC General Insuarance LTD - 60855",
    code: 60855,
    name: "LOLC General Insuarance LTD - 60855",
  },
];

export const userGroupList = {
  Legal: { name: "Legal", id: 9 },
  Risk: { name: "Risk", id: 8 },
  InsuranceDepartment: { name: "Insurance Department", id: 180 },
};

export const bankCode = "4";

export const creditComponentTitleMapping = {
  IdentificationDetails: "Identification Details",
  ContactDetails: "Contact Details",
  AddressInformation: "Address Information",
  EducationalDetails: "Educational Information",
  IncomeDetails: "Income Information",
  ExpensesDetails: "Expense Information",
  CreditDetails: "Credit Details",
  BankDetails: "Bank Information",
  EmploymentDetails: "Employment Information",
  BusinessDetails: "Business Details",
  AssetsDetails: "Assets Details",
  GuarantorData: "Guarantor Details",
  TaxDetails: "Tax Information",
  InquiryOfObligations: "Inquiry of Obligations",
  RisksConditionsAndGeneralFacts: "Risks, Conditions and General Facts",
  JointBorrower: "Joint Borrower Details",
  LifeInsuranceDetails: "Life Insurance Information",
  SecurityDetails: " Security Details",
};

// export const applicationSections: any = [
//   { id: "primary-applicant-details", name: "Personal Details" },
//   { id: "credit-details", name: "Credit Details" },
//   { id: "contact-details", name: "Contact Details" },
//   { id: "address-details", name: "Address Details" },
//   { id: "education-details", name: "Education Details" },
//   { id: "employment-details", name: "Employment Details" },
//   { id: "business-details", name: "Business Details" },
//   { id: "income-details", name: "Income Details" },
//   { id: "expenses-details", name: "Expense Details" },
//   { id: "bank-details", name: "Bank Details" },
//   { id: "assets-details", name: "Assets Details" },
//   { id: "promissory-note", name: "Promissory Note" },
//   { id: "loan-agreement", name: "Loan Agreement" },
//   { id: "loan-receipt", name: "Loan Receipt" },
//   { id: "guarantee-bond", name: "Guarantee Bond" },
//   { id: "letter-set-off-pa", name: "Letter of Set-off PA" },
//   { id: "letter-set-off-jb", name: "Letter of Set-off JB" },
//   { id: "standing-instructions", name: "Standing Instructions" },
//   { id: "offer-letter", name: "Offer Letter" },
//   { id: "signature-verification", name: "Signature Verification" },
// ];
export const applicationSections: any = [
  { id: "primary_applicant_details", name: "Personal Details" },
  { id: "credit_details", name: "Credit Details" },
  { id: "contact_details", name: "Contact Details" },
  { id: "address_details", name: "Address Details" },
  { id: "education_details", name: "Education Details" },
  { id: "employment_details", name: "Employment Details" },
  { id: "business_details", name: "Business Details" },
  { id: "income_details", name: "Income Details" },
  { id: "expenses_details", name: "Expense Details" },
  { id: "bank_details", name: "Bank Details" },
  { id: "assets_details", name: "Assets Details" },
  { id: "promissory_note", name: "Promissory Note" },
  { id: "loan_agreement", name: "Loan Agreement" },
  { id: "receipt", name: "Loan Receipt" },
  // { id: "declaration", name: "Declaration" },
  { id: "guarantee_bond", name: "Guarantee Bond" },
  { id: "letter_of_set_off_pa", name: "Letter of Set-off PA" },
  { id: "letter_of_set", name: "Letter of Set" },
  { id: "letter_of_set_off_jb", name: "Letter of Set-off JB" },
  { id: "standing_instruction", name: "Standing Instructions" },
  { id: "offer_letter", name: "Offer Letter" },
  { id: "signature_verification", name: "Signature Verification" },
  { id: "offer_letter_acceptance", name: "Offer Letter Acceptance" },
  { id: "other_documents", name: "Other Documents" },
  { id: "inquiry_of_obligations", name: "Inquiry Of Obligations" },
  { id: "tax_details", name: "Tax Details" },
];

export const securityDocumentTextCase = {
  1: { id: 1, name: "Default", typeCaseClass: "case-none" },
  2: { id: 2, name: "Uppercase", typeCaseClass: "case-upper" },
  3: { id: 3, name: "Lowercase", typeCaseClass: "case-lower" },
};

export const userProfilePreferencesDetails: any = {
  notifications: [
    { id: 1, name: "Close Automatically" },
    { id: 2, name: "Close Manually" },
  ],
  general: [
    { id: 1, name: "Tab Layout" },
    { id: 2, name: "Vertical Layout" },
  ],
};

export const collateralTypes = {
  loan_protection_insurance: "Loan Protect Insurance",
  gurantor: "Personal Guarantor",
  shares: "Shares",
  vehicle: "Vehicle",
};

export const descMap = {
  "dla-limit": "Stage to be applied the DLA Limit when application current stage set to Appraisal Completed",
  "show-revert": "Show Revert Appraisal Completed",
  "assign-loan-owner": "Assign Loan Owner Appraisal Completed",
  "tab-control": "Tab Control Appraisal Completed",
  "strict-assignee": "Strict Assignee Appraisal Completed",
  "restrict-pre-assignee": "Restrict Pre Assignee Appraisal Completed",
  "same-branch-allocation": "Same Branch Appraisal Completed",
  "refer-revert-history": "Refer Revert History Appraisal Completed",
  "stage-restriction": "Stage Restriction Appraisal Completed",
  "inspection-dla-applicable": "Inspection DLA Applicabledla",
};

export const applicationHints = {
  grossIncome: {
    status: "Auto populated",
    populated: "Populated From - Gross Income of the Application",
  },
  existingIncome: (salaryProportionValue) => ({
    status: "Formula",
    populated: `Calculated - Applicant's Gross Income X ${salaryProportionValue}%`,
  }),
  longTermDeduction: {
    status: "Auto populated",
    populated: "Populated From - Long term deduction of application",
  },
  interestRate: {
    status: "Auto populated",
    populated: "Populated From - Proposed rate of summary tab",
  },
  installments: {
    status: "Auto populated",
    populated: "Populated From - Maximum monthly installment of summary tab",
  },
  debtRepayment: (salaryProportionValue) => ({
    status: "Formula",
    populated: `Calculated - [(Long Term Deduction + Proposed Loan Installment)/Limit ${salaryProportionValue}% on existing income] * 100`,
  }),
  obligations: {
    status: "Auto populated",
    populated: "Populated From - Total of all annual obligations",
  },
  otherIncome: {
    status: "Formula",
    populated: "Populated from - Total other income - Total obligations",
  },
};

export const creditSummaryHints = {
  selectScheme: {
    status: "Auto populated",
    populated: "Populated from - Core Bank System",
  },
  reqLoanAmount: {
    status: "Auto populated",
    populated: "Requested amount from application",
  },
  reqInterestRate: {
    status: "Auto populated",
    populated: "Requested rate from application",
  },
  reqLoanTenor: {
    status: "Auto populated",
    populated: "Requested loan tenor from application",
  },
  reqSalaryProportion: {
    status: "Auto populated",
    populated: "Salary proportion entered in the application",
  },
  recommendedLoanAmount: {
    status: "Calculated",
    populated:
      "If the requested loan amount is less than or equal to the maximum amount, then the requested amount will appear. Or else, the maximum amount will appear",
  },

  recommendedInterestRate: {
    status: "Calculated",
    populated:
      "By considering the base rates of the product and interest calculated through analyzing the factors of the CBS",
  },

  disbursementLoanAmount: {
    status: "Auto populated",
    populated: "Disbursed loan amount of TC",
  },
  disbursementInterestRate: {
    status: "Auto populated",
    populated: "Disbursed interest rate submitted in TC",
  },
  disbursementLoanTenor: {
    status: "Auto populated",
    populated: "Disbursed loan tenor of TC",
  },
  selectLoanScheme: {
    status: "Auto populated",
    populated: "Populated From - Core Bank System",
  },
};

export const loanSummaryHints = {
  branch: {
    status: "Auto populated",
    populated:
      "Populated From - Originated branch of the submitted application",
  },

  branchCode: {
    status: "Auto populated",
    populated: "Populated From - Branch code of the application",
  },
  submittedDate: {
    status: "Auto populated",
    populated:
      "Populated From - Date of submission of final version of application",
  },
  name: {
    status: "Auto populated",
    populated:
      "Populated From - Initials in full+ Last name of the application",
  },
  address: {
    status: "Auto populated",
    populated:
      "Populated From - Address of the primary applicant of application",
  },
  reqLoanAmount: {
    status: "Auto populated",
    populated: "Populated From - Loan Amount Entered in the application form",
  },
  recommendedLoanAmount: {
    status: "Calculated",
    populated:
      "Populated From - If the requested loan amount is less than or equal to the maximum amount, then the requested amount will appear. Or else, the maximum amount will appear",
  },

  approvedLoanAmount: {
    status: "Auto populated",
    populated: "Populated From - Disbursement loan amount of application",
  },
  loanScheme: {
    status: "Auto populated",
    populated: "Populated From - Selected Loan scheme of application",
  },
  proposedRate: {
    status: "Auto populated",
    populated: "Populated From - Selected rate from CBS loan scheme dropdown",
  },
  loanPurpose: {
    status: "Auto populated",
    populated: "Populated From - Purpose selected as in the selection",
  },
  security: {
    status: "Auto populated",
    populated: "Populated From - Security type added as in the selection",
  },
  repaymentType: {
    status: "Auto populated",
    populated: "Populated From - Repayment type as selected in application",
  },
  loanTenor: {
    status: "Auto populated",
    populated: "Populated From - Loan tenor as selected in application",
  },
  frequency: {
    status: "Auto populated",
    populated: "Populated From - Loan tenor as selected in application",
  },
  loanRepayDay: {
    status: "Auto populated",
    populated: "Populated From - Loan tenor as selected in application",
  },
  maximumMonthInstallment: {
    status: "Auto populated",
    populated:
      "Populated From - The value of the 'termly installment' of the Term 2 TC of generated schedule",
  },
  maximumMonthInterest: {
    status: "Auto populated",
    populated:
      "Populated From - The value of the 'Interest Recovery' of the Term 2 of TC generated schedule",
  },
};

export const businessDetailsHints = {
  totalBusinessExperience: {
    status: "Auto populated",
    populated:
      "Populated From - (Total Business Experience + No of Years in Operation )",
  },
};

export const personalIncomeDetailsHints = {
  netAnnualIncome: {
    status: "Auto populated",
    populated: "Populated From - Sum of Annual Incomes",
  },
  grossIncome: {
    status: "Auto populated",
    populated:
      "Populated From - Gross Income [Gross Income = (Basic Salary/ Pension) + (Average Variable Allowances  Last 3 [frequency] average) + (Fixed Allowances)]",
  },
  netIncome: {
    status: "Auto populated",
    populated:
      "Populated From - Net Income [ Net Income = (Gross Income) - (Last three [frequency] average deduction) ]",
  },
  annualIncome: {
    status: "Auto populated",
    populated:
      "Populated From - Annual Income [ Annual Income =  (Net Income) X (frequency for annual) ]",
  },
};

export const expenseDetailsHints = {
  totalAnnualExpenses: {
    status: "Auto populated",
    populated: "Populated From - Sum of Annual Expenses",
  },
  annualExpenses: {
    status: "Auto populated",
    populated: "Populated From - Expenses, Frequency for annual expenses",
  },
};

export const inquiryOnCurrentObligations = {
  totalAnnualLiability: {
    status: "Auto populated",
    populated: "Populated From - Total of all Annual Amounts ",
  },
  loanLeaseAnnualAmount: {
    status: "Auto populated",
    populated:
      "Populated From - Number of installments left, Installment Amount",
  },
  creditCardsAnnualAmount: {
    status: "Auto populated",
    populated: "Populated From - Limit",
  },
  pawningAnnualAmount: {
    status: "Auto populated",
    populated:
      "Populated From - Amount Outstanding, Amount Outstanding, Annual Interest Rate",
  },
  overdraftAnnualAmount: {
    status: "Auto populated",
    populated: "Populated From - Outstanding",
  },
};

export const assetDetailsHints = {
  totalAssets: {
    status: "Auto populated",
    populated: "Populated From - Force Sales Values",
  },
};

export const applicationSummaryDetailsHints = {
  salaryAnnualIncome: {
    status: "Auto populated",
    populated: "Populated From - <Personal Income Section's> Net Annual Income",
  },
  businessAnnualIncome: {
    status: "Auto populated",
    populated:
      "Populated From - <Business Income Section's> Total Annual Profit ",
  },
  cultivateAnnualIncome: {
    status: "Auto populated",
    populated:
      "Populated From - <Cultivation Income Section's > Total Annual Profit",
  },
  totalAnnualIncome: {
    status: "Formula",
    populated:
      "Calculated From - Salaried Annual Income + Business Annual Income + Cultivation Annual Income",
  },

  livingExpenses: {
    status: "Formula",
    populated:
      "Calculated From - <Life Insurance Details Section's>  Premium * 12 + <Expense Details Section's>  Annual Expenses + Annual Liability Services Expenses Of the Primary applicant and all the borrowers",
  },
  liabilityServicesExpenses: {
    status: "Auto populated",
    populated:
      "Populated From - <Inquiry on Current Obligations Section's> Total Monthly Liability Amount (except indirect Loan/lease)",
  },
  annualLiabilityServicesExpenses: {
    status: "Auto populated",
    populated:
      "Populated From - <Inquiry on Current Obligations Section's> Total Annual Liability Amount (except indirect Loan/lease)",
  },
  totalAnnualExpenses: {
    status: "Auto populated",
    populated:
      "Calculated From - Other Annual Expenses + Liability Annual Expenses",
  },

  annualIncome: {
    status: "Auto populated",
    populated: "Populated From - Total Annual Income",
  },
  annualExpenses: {
    status: "Auto populated",
    populated:
      "Populated From - Annual Expenses + Annual Liability Services Expenses Of the Primary applicant and all the borrowers",
  },
  annualNetIncome: {
    status: "Auto populated",
    populated:
      "Calculated From - Total Annual Income - Annual Expenses + Annual Liability Services Expenses Of the Primary applicant and all the borrowers",
  },
  monthNetIncome: {
    status: "Auto populated",
    populated: "Calculated From - Annual Net Income / 12",
  },

  salaryProportion: {
    status: "Formula",
    populated: ``,
    conditional: { key: "salaryProportion" },
  },
  feasibleLoanInstallment: {
    status: "Formula",
    populated: ``,
    conditional: { key: "feasibleLoanInstallment" },
  },
  grossLoanRequirement: {
    status: "Auto Populated",
    populated: "Populated From - Requested Loan Amount",
  },
  existingContinuedFacilityOutstandingTotalBalance: {
    status: "Auto Populated",
    populated: "Loans marked as 'continued' in the Inquiry of Obligations tab + Outstanding lease balances",
  },
  totalExposureLimit: {
    status: "Auto Populated",
    populated: "Proposed Loan Amount + RDB Existing Continued Facility Outstanding Total Balance",
  },
  recommendedLoanAmount: {
    status: "Auto Populated",
    populated:
      "Populated From - Maximum Loan Amount or Requested Loan Amount (The smallest value from the above 2 is applied)",
  },
  loanTenor: {
    status: "Auto Populated",
    populated: "Populated From - Requested Loan Tenor",
  },
};

export const historySectionMapping = {
  "/los/followup-checklist": "Checklist",
  "/los/add-legal": "Legal Review",
  "/los/add-task": "Task",
  "/task": "Task",
  "/application": "Application",
  "/los/add-refer": "Add Refer",
  "/los/add-risk": "Risk Review",
  "/los/add-condition": "Review",
  "/los/update-refer": "Update Refer",
  "/workflow": "Workflow",
  "/note": "Note",
  "/workflow/reset": "Workflow Reset",
  "/los/document-checklist-status-change": "Document Checklist Status Change",
  "/product-document-upload": "Document Upload",
  "/crib-document-upload": "Crib Record",
  "/los/fund-release-data": "Fund release",
  "/risk-document-upload": "Risk record",
  "/legal-document-upload": "Legal record",
  "/los/predisbursement-checklist": "Pre Disbursement",
  "/bank-service/core-bank-data": "Bank service",
  "/crib/remove": "CRIB Remove",
  "/control-tabs": "Control Tabs",
  "/change-assignee": "Change Assignee",
  "/communication/email": "Manage Email",
  "/controltab": "Control Tab",
  "/application/controltab": "Control Tab",
  "/communication/sms": "Manage SMS",
  "/masterdata/managemasterdata": "Manage Master Data",
  "/interestrate/configureinterestrate": "Configure Interest Rate",
  "/product/managetype": "Manage Product Type",
  "/product/managesector": "Manage Product Sector",
  "/product/managescheme": "Manage Product Scheme",
  "/workflow/manageusergroup": "Manage User Group",
  "/workflow/manageproduct": "Manage Workflow Product",
  "/workflow/workflowmap": "Workflow Map",
  "/workflow/createworkflow": "Create Workflow",
  "/document/documentconfiguration": "Document Configuration",
  "/inspection": "Inspection",
  "/bankservice": "Bank Service",
  "/product": "Product",
  "/system": "System",
  "/stageprivilege": "Stage Privilege",
  "/underwritingpolicy": "Underwriting Policy",
  "/workflowtransition": "Workflow Transition",
  "/privilege": "Privilege",
  "/designation": "Designation",
  "/branch": "Branch",
  "/dla": "DLA",
  "/privilegegroup": "Privilege Group",
  "/groupowner": "Group Owner",
  "/appraisals": "Appraisal",
  "/document-detach": "Document Detach",
  "/document-approval": "Document Approval",
  "/user_management/create": "User Management",
  "/user_configurations/update": "User Configurations",
  "/charge": "Charges",
  "/los/data-verification-checklist-status-change": "Data Verification Checklist Status Change",
  "/los/review/offer-condition": "Review Offer Conditions",
  "/checklist/data_verification": "Checklist Data Verification"
};

export const LeasePropertyisUnder = [
  { id: 1, name: "Primary Applicant" },
  { id: 2, name: "Other" },
];

export const vulnerabilityClimaticAndEnvironmentalFactors = [
  {
    id: 1,
    name: "Performance of the business not dependent on climate",
  },
  {
    id: 2,
    name: "Rare instances of adverse weather impact in Geograpic Area",
  },
  {
    id: 3,
    name: "Geographic location subject to occasional adverse weather conditions",
  },
  {
    id: 4,
    name: "Geographic location subject to frequent adverse weather conditions",
  },
];

export const securityDocumentPermissions = {
  promissoryNote: "promissory_note",
  loanAgreement: "loan_agreement",
  loanReceipt: "receipt",
  // cardHolderAndBankOfficerDeclaration: "declaration",
  guaranteeBond: "guarantee_bond",
  letterOfSetOffPA: "letter_of_set_off_pa",
  letterOfSetOffJB: "letter_of_set_off_jb",
  standingInstruction: "standing_instruction",
  offerLetter: "offer_letter_acceptance",
  signatureVerification: "signature_verification",
  deedOfAssignment: "deed_of_assignment",
  leaseAgreement: "lease_agreement",
  powerOfAttorney: "power_of_attorney",
  chattelMortgage: "chattel_mortgage",
};

export const signatureListNumberMapping = {
  0: {
    0: 3,
    1: 4,
  },
  1: {
    0: 5,
    1: 6,
  },
  2: {
    0: 7,
    1: 8,
  },
  3: {
    0: 9,
    1: 10,
  },
};

export const securityDocumentReferenceTypes = [
  { id: 1, name: "Reference on the Bottom" },
  { id: 2, name: "Reference on the Top" },
];

export const deleteSectionKeys = {
  contactTypes: "contactData.contactTypes",
  emailTypes: "contactData.emailTypes",
  socialContactTypes: "contactData.socialContactTypes",
  awardTypes: "educationData.awardTypes",
  currentEmployment: "employedData.currentEmployment",
  previousEmployment: "employedData.previousEmployment",
  currentBusiness: "businessData.currentBusiness",
  personnelIncome: "incomeData.personnelIncome",
  businessIncome: "incomeData.businessIncome.income",
  businessExpense: "incomeData.businessIncome.expense",
  cultivationIncome: "incomeData.cultivationIncome.income",
  cultivationExpense: "incomeData.cultivationIncome.expense",
  expenses: "expenseData.expenses",
  lifeInsuranceData: "lifeInsuranceData",
  bankData: "bankData",
  assetsData: "assetsData",
  leaseAssetsData: "leaseAssetsData",
  insuranceData: "insuranceData",
  guarantors: "securityData.guarantors",
  loanProtection: "securityData.paperDocs.loanProtection",
  fdAgainstLoans: "securityData.paperDocs.fdAgainstLoans",
  lifeInsurance: "securityData.paperDocs.lifeInsurance",
  shares: "securityData.paperDocs.shares",
  treasuryBond: "securityData.paperDocs.treasuryBond",
  warehouseReceipt: "securityData.paperDocs.warehouseReceipt",
  creditCard: "inquiryOfObligationsData.creditCard",
  loanLease: "inquiryOfObligationsData.loanLease",
  pawning: "inquiryOfObligationsData.pawning",
  overdraft: "inquiryOfObligationsData.overdraft",
  authorityCertifications: "selfEmployedData.authorityCertifications"
};
export const dorinDoraAccountNotAvaiable = [
  { id: 0, name: "No Account Number Available!" },
];

export const securityDocumentAlignTypes = [
  { id: 1, name: "Content Justify" },
  { id: 2, name: "Content Left" },
];

export const collateralDropdownConfig = {
  1: {
    id: 1,
    status: 1,
    name: "Paper Doc",
    childs: [
      {
        id: 1,
        status: 1,
        name: "Loan Protect Insurance",
      },
      {
        id: 2,
        status: 0,
        name: "Life Insurance",
      },
      {
        id: 3,
        status: 0,
        name: "FD against Loan",
      },
      {
        id: 4,
        status: 0,
        name: "Warehouse Receipt",
      },
      {
        id: 5,
        status: 0,
        name: "Tresury Bond",
      },
      {
        id: 6,
        status: 0,
        name: "Shares",
      },
    ],
  },
  2: {
    id: 2,
    status: 1,
    name: "Personal Guarantor",
  },
  3: {
    id: 3,
    status: 0,
    name: "Movable",
    childs: [
      {
        id: 1,
        status: 1,
        name: "Pledge",
      },
      {
        id: 2,
        status: 1,
        name: "Stock As Gold",
      },
      {
        id: 3,
        status: 1,
        name: "Machinery & Equipement",
      },
      {
        id: 4,
        status: 1,
        name: "Vehicle",
      },
    ],
  },
  4: {
    id: 4,
    status: 0,
    name: "Immovable",
    childs: [
      {
        id: 1,
        status: 1,
        name: "Property",
      },
      {
        id: 2,
        status: 1,
        name: "Machinery And Equipment",
      },
    ],
  },
};

export const appicationMode = () => {
  if (process.env.REACT_APP_APPLICATION_MODE === "LOS") {
    return 1;
  }

  return 0;
};

export const addWatchlist = [
  {
    name: "ABN",
    placeholder: "text",
    length: 11,
    id: "abn",
  },
  {
    name: "ACN",
    placeholder: "text",
    length: 9,
    id: "acn",
  },
  {
    name: "Trading Name",
    placeholder: "text",
    length: 100,
    id: "tradingName",
  },
  {
    name: "Business Address",
    placeholder: "text",
    id: "businessAddress",
  },
  {
    name: "Primary Contact First Name",
    placeholder: "text",
    id: "primaryContactFirstName",
  },
  {
    name: "Primary Contact Last Name",
    placeholder: "text",
    id: "primaryContactLastName",
  },
  {
    name: "Primary Contact Email",
    placeholder: "text",
    id: "primaryContactEmail",
  },
  {
    name: "Primary Contact Mobile",
    placeholder: "text",
    id: "primaryContactMobile",
  },
  {
    name: "Primary Contact Phone",
    placeholder: "text",
    id: "primaryContactPhone",
  },
  {
    name: "Director First Name",
    placeholder: "text",
    id: "directorFirstName",
  },
  {
    name: "Director Middle Name",
    placeholder: "text",
    id: "directorMiddleName",
  },
  {
    name: "Director Last Name",
    placeholder: "text",
    id: "directorLastName",
  },
  {
    name: "Director DOB",
    placeholder: "text",
    id: "directorDOB",
  },
  {
    name: "Director Driver Licence",
    placeholder: "text",
    id: "directorDriverLicence",
  },
  {
    name: "Director Email	Director Mobile",
    placeholder: "text",
    id: "directorEmailDirectorMobile",
  },
  {
    name: "Director Phone",
    placeholder: "text",
    id: "directorPhone",
  },
  {
    name: "Director Address",
    placeholder: "text",
    id: "directorAddress",
  },
  {
    name: "Billing Mobile/Phone",
    placeholder: "text",
    id: "billingMobilePhone",
  },
  {
    name: "Billing Email",
    placeholder: "text",
    id: "billingEmail",
  },
  {
    name: "Comments",
    placeholder: "text",
    id: "comments",
  },
];

export const signatureRejectReasons = [
  {
    id: 1,
    name: "Missmathced Signature",
  },
  {
    id: 2,
    name: "Illegible Signature",
  },
  {
    id: 3,
    name: "Incomplete Signature",
  },
  {
    id: 4,
    name: "Forgery Suspicions",
  },
  {
    id: 5,
    name: "Mismathced Information",
  },
  {
    id: 6,
    name: "Expritred Document",
  },
];

export const signatureDocumentTypes = [
  {
    id: 1,
    name: "Passport",
  },
  {
    id: 2,
    name: "Driver License",
  },
];

export const extractWatchList = [
  {
    sectionKey: "organisationDetails",
    sectionLabel: "Business Details",
    fields: [
      {
        key: "unitNumber",
        label: "Unit Number",
      },
      {
        key: "entityType",
        label: "Type",
      },
      {
        key: "companyName",
        label: "Company Name",
      },
    ],
  },
  {
    sectionKey: "primaryContactDetails",
    sectionLabel: "Primary Contact Details",
    fields: [
      {
        key: "givenName",
        label: "Used Name",
      },
      {
        key: "familyName",
        label: "Last Name",
      },
      {
        key: "email",
        label: "Email",
      },
      {
        key: "mobile",
        label: "Primary Contact Mobile",
      },
      {
        key: "phone",
        label: "Primary Contact phone",
      },
    ],
  },
  {
    sectionKey: "monthlySpend",
    sectionLabel: "Monthly Spend",
    fields: [
      {
        key: "accountNumber",
        label: "Account Number",
      },
      {
        key: "provideFinanceNow",
        label: "Provide Finance",
      },
      {
        key: "heldUnitedCard",
        label: "Held United Card",
      },
    ],
  },
  {
    sectionKey: "directorDetails",
    sectionLabel: "Director Details",
    fields: [
      {
        key: "givenName",
        label: "Used Name",
      },
      {
        key: "familyName",
        label: "Last Name",
      },
      {
        key: "email",
        label: "Email",
      },
      {
        key: "mobile",
        label: "Primary Contact Mobile",
      },
      {
        key: "phoneNumber",
        label: "Primary Contact phone",
      },
      {
        key: "dob",
        label: "Director DOB",
      },
      {
        key: "driverLicense",
        label: "Director Driver Licence",
      },
      {
        key: "suburb",
        label: "Director Address",
      },
    ],
  },
  {
    sectionKey: "cardDetails",
    sectionLabel: "Card Details",
    fields: [
      {
        key: "cardHolderName",
        label: "Card Holder Name",
      },
      {
        key: "vehicleRegNo",
        label: "Vehicle Registration Number",
      },
      {
        key: "products",
        label: "Products",
      },
      {
        key: "businessName",
        label: "BusinessName",
      },
    ],
  },
  {
    sectionKey: "guarantorDetails",
    sectionLabel: "Guarantor Details",
    fields: [
      {
        key: "givenName",
        label: "Used Name",
      },
      {
        key: "familyName",
        label: "Last Name",
      },
      {
        key: "email",
        label: "Email",
      },
      {
        key: "mobile",
        label: "Primary Contact Mobile",
      },
      {
        key: "phoneNumber",
        label: "Primary Contact phone",
      },
      {
        key: "suburb",
        label: "Director Address",
      },
    ],
  },
  {
    sectionKey: "paymentDetails",
    sectionLabel: "Payment Details",
    fields: [
      {
        key: "instituteName",
        label: "Institute Name",
      },
      {
        key: "accountName",
        label: "Account Name",
      },
      {
        key: "bsbNo",
        label: "Account Name",
      },
      {
        key: "accountNo",
        label: "Account Number",
      },
    ],
  },
  {
    sectionKey: "additionalInformation",
    sectionLabel: "Additional Information",
    fields: [
      {
        key: "emailForStatement",
        label: "Email For Statement",
      },
      {
        key: "portalUserEmail",
        label: "User Email",
      },
      {
        key: "trackOdometer",
        label: "User Email",
      },
    ],
  },
];

export const payload = [
  {
    organisationDetails: {
      companyName: "WHITE CLELAND PTY",
      tradingName: "WHITE CLELAND PTY",
      dateBusinessCommenced: "27-04-2000",
      entityType: "Australian Private Company",
      unitNumber: "leve1",
      streetNumber: "1",
      streetName: "Queens",
      streetType: "ROAD",
      suburb: "Melbourne",
      state: "Victoria",
      postCode: "3004",
      version: 1695284459623,
    },
    version: 1695284459623,
    applicationId: "1",
    createdAt: "2023-09-11T05:34:38.391Z",
    updatedAt: "2023-09-21T08:20:59.623Z",
    primaryContactDetails: {
      title: "Mr",
      givenName: "dasdasd",
      familyName: "dadasd",
      position: "sse",
      email: "mailto:ss@gmail.com",
      mobile: "0455555555",
      phone: "(02) 3333-3333",
      unitNumber: "leve1",
      streetNumber: "1",
      streetName: "Queens",
      streetType: "ROAD",
      suburb: "Melbourne",
      state: "Victoria",
      postCode: "3004",
      address: {},
      version: 1695284459623,
    },
    monthlySpend: {
      monthlySpend: "10000",
      accountNumber: "32323223233332332333",
      provideFinanceNow: 1,
      heldUnitedCard: 1,
      version: 1695284459623,
    },
    directorDetails: {
      title: "Mrs",
      givenName: "Director",
      familyName: "One",
      email: "d1@gmail.com",
      mobile: "0411111111",
      phoneNumber: "(02) 1111-1111",
      dob: "01-09-2005",
      driverLicense: "111111111",
      unitNumber: "leve1",
      streetNumber: "1",
      streetName: "Queens",
      streetType: "ROAD",
      suburb: "Melbourne",
      state: "Victoria",
      postCode: "3004",
      sameMailing: true,
    },
    cardDetails: {
      cardHolderName: "dasadsad",
      vehicleRegNo: "fasdadasd",
      products: "dsa",
      dailySpend: "100",
      dailyLitres: "10",
      monthSpend: "2000",
      pin: "",
      isFourDigits: true,
    },
    guarantorDetails: {
      "1": {
        title: "Mrs",
        givenName: "dasdad",
        familyName: "dsadasd",
        email: "dd@gmai.com",
        mobile: "0422222222",
        phoneNumber: "(02) 6666-6666",
        unitNumber: "leve1",
        streetNumber: "1",
        streetName: "Queens",
        streetType: "ROAD",
        suburb: "Melbourne",
        state: "Victoria",
        postCode: "3004",
        sameMailing: 1,
      },
      "2": {
        title: "Mr",
        givenName: "dasdad",
        familyName: "dsadad",
        email: "ff@gmail.com",
        mobile: "0488888888",
        phoneNumber: "(02) 3666-6666",
        unitNumber: "leve1",
        streetNumber: "1",
        streetName: "Queens",
        streetType: "ROAD",
        suburb: "Melbourne",
        state: "Victoria",
        postCode: "3004",
        sameMailing: 1,
      },
      version: 1695284459623,
    },
    paymentDetails: {
      instituteName: "dasdad",
      accountName: "dsadad",
      bsbNo: "323232",
      accountNo: "323322223232",
      version: 1695284459623,
    },
    additionalInformation: {
      emailForStatement: "email@email.com",
      portalUserEmail: "email@email.com",
      password: "dssadadada",
      feedback: "dsadadasdasdasdad",
      trackOdometer: 1,
      expressPost: true,
      rate: 6,
      version: 1694410478391,
    },
    additionalInformationDetails: {
      version: 1695207342838,
    },
  },
];

export const checklist = [
  {
    id: "1",
    title: "Review Credit Applications for Accuracy",
    status: false,
    createdBy: "Hansika",
    createdAt: "",
    completedBy: "Hansika",
    completedAt: "",
  },
  {
    id: "2",
    title: "Analyze Applicant's Financial Health",
    status: false,
    createdBy: "Hansika",
    createdAt: "",
    completedBy: "Hansika",
    completedAt: "",
  },
  {
    id: "3",
    title: "Analyze Credit Scoring Model",
    status: false,
    createdBy: "Hansika",
    createdAt: "",
    completedBy: "Hansika",
    completedAt: "",
  },
  {
    id: "4",
    title: "Verify Information with Identification Documents",
    status: false,
    createdBy: "Hansika",
    createdAt: "",
    completedBy: "Hansika",
    completedAt: "",
  },
  {
    id: "5",
    title: "Verify the Signatures",
    status: true,
    createdBy: "Hansika",
    createdAt: "",
    completedBy: "Hansika",
    completedAt: "",
  },
];

export const cbsExtractionMethods: any = {
  recoveryAccount: [
    { id: 1, name: "Using PA NIC" },
    { id: 2, name: "Using PA CIF" },
  ],
  investmentAccount: [
    { id: 1, name: "Using PA NIC" },
    { id: 2, name: "Using PA CIF" },
  ],
};

export const businessSourceIncome = [
  { id: 1, name: "Agriculture" },
  { id: 2, name: "Fisheries" },
  { id: 3, name: "Livestock" },
  { id: 4, name: "Business" },
  { id: 5, name: "Services" },
  { id: 6, name: "Self-employment" },
];

export const cardTypes = [
  { id: 1, name: "Visa" },
  { id: 2, name: "Master Card" },
  { id: 3, name: "JCB" },
  { id: 4, name: "American Express" },
  { id: 5, name: "Discover" },
  { id: 6, name: "Union Pay" },
];

export const participantTypes = () => {
  let documentTypes: any = [];
  for (const [key, value] of Object.entries(AppConfig.config.documentTypes)) {
    documentTypes.push({ id: key, name: value });
  }
  return documentTypes;
};

export const employementOccupationArray = (
  params: any,
  isArrayOutput: boolean,
  selectedId = ""
) => {
  let employementsTypes: Array<any> = [];
  let empOccupationName: String = "";

  try {

    employementsTypes = isProductOf()
      ? [...params.get()]
      : params.get().filter((item: any) => (item.id ? item : ""));

    if (isArrayOutput) {
      return employementsTypes;
    } else {
      employementsTypes.forEach((item) => {
        if (item.id === selectedId) {
          empOccupationName = item.name;
        }
      });

      return empOccupationName;
    }
  } catch (error) { }

  return empOccupationName;
};

export const vehicleCategoryDropDown = () => {
  return [
    { id: 1, name: "Motor Cycle" },
    { id: 2, name: "Motor Tricycle" },
    { id: 3, name: "Motor Quadricycle" },
    { id: 4, name: "Three wheeler" },
    { id: 5, name: "Motor Cars" },
    { id: 6, name: "Dual Purpose Vehicle" },
    { id: 7, name: "Jeep" },
    { id: 8, name: "Double Cab" },
    { id: 9, name: "Single Cab" },
    { id: 10, name: "Bus/ Motor coach" },
    { id: 11, name: "Motor Lorries" },
    { id: 12, name: "Mini Truck" },
    { id: 13, name: "Crew Cab" },
    { id: 14, name: "Land Vehicle – Tractor" },
    { id: 15, name: "Land Vehicle – Tailor" },
    { id: 16, name: "Land Vehicle – Backhoes and excavators" },
    { id: 17, name: "Tipper" },
    { id: 18, name: "Prime Mover" },
    { id: 19, name: "Port Lift" },
    { id: 19, name: "Motor Boat" },
  ];
};

export const documentSectionParticipantMapping = {
  1: [
    "primary-applicant-details",
    "credit-details",
    "contact-details",
    "address-details",
    "education-details",
    "income-details",
    "expenses-details",
    "bank-details",
    "employment-details",
    "assets-details",
    "business-details",
    "tax-details",
    "inquiry-of-obligations",
    "life-insurance-details",
  ],
  2: ["joint-borrower-details"],
  3: ["guarantor-details"],
  5: ["security-details"],
};

export const fieldDropdown = [
  { id: "form-data", name: "Form Data" },
  { id: "master-data", name: "Master Data" },
  { id: "config-data", name: "Config Data" },
  { id: "condition", name: "Condition" },
];

export const operatorDropdown = [
  { id: "===", name: "Equal" },
  { id: ">=", name: "Greater Than Equal" },
  { id: "<=", name: "Less Than Equal" },
  { id: ">", name: "Greater" },
  { id: "<", name: "Less" },
  { id: "!==", name: "Not Equal" },
];

export const sectionOperatorDropdown = [
  { id: "&&", name: "AND" },
  { id: "||", name: "OR" },
];

export const departments = [
  {
    id: "1",
    name: "Branch",
    label: "Branch",
  },
  { id: "2", name: "CCU", label: "CCU" },
  { id: "3", name: "CAU", label: "CAU" },
];

export const locationTypes = [
  { id: "province", name: "Province" },
  { id: "district", name: "District" },
  { id: "zone", name: "Zone" },
  { id: "branch", name: "Branch" },
];

export const referenceTypes = [
  { id: "applicationId", name: "Application Id" },
  { id: "applicationRef", name: "Application Reference" },
  { id: "other", name: "Other" },
];

export const unSecuredKeyValuePair = {
  id: 0,
  name: "unSecured",
};

export const joinBorrowerSectionNodes = [
  "personalData",
  "contactData",
  "addressData",
  "educationData",
  "employedData",
  "businessData",
  "incomeData",
  "expenseData",
  "bankData",
  "assetsData",
  "lifeInsuranceData",
  "taxDetailsData",
  "inquiryOfObligationsData",
];

export const loanRequirementContributions = [
  { id: 1, name: "Immovable - Land" },
  { id: 2, name: "Immovable - Commercial Property" },
  { id: 3, name: "Immovable - Machinery and Equipment" },
  { id: 4, name: "Movable - Machinery and Equipment" },
  { id: 5, name: "Movable - Vehicle" },
  { id: 6, name: "Movable - Goods and Stock " },
  { id: 7, name: "Working Capital" },
  { id: 8, name: "Contingencies" },
  { id: 9, name: "Immovable - Residential Property" },
  { id: 10, name: "Pre-Operational Expenses" },
];

export const inspectionBusinessAssetTimeFrames = [
  { id: 1, name: "Current Asset" },
  { id: 2, name: "Non current Asset" },
];

export const inspectionBusinessAssetLiquidity = [
  { id: 1, name: "Liquid" },
  { id: 2, name: "Non Liquid" },
];

class LoanRequirement {
  static credit = { key: "credit", label: "Credit Request" };
  static applicant = { key: "applicant", label: "Primary Applicant" };
  static jb = { key: "jb", label: "Joint Borrower" };
  static collateral = { key: "collateral", label: "Collaterals" };
  static preview = { key: "preview", label: "Summary" };
}

export const applicationFormSections = LoanRequirement;

export const monthsOftheYear = [
  { id: 1, name: " January" },
  { id: 2, name: " February" },
  { id: 3, name: " March" },
  { id: 4, name: " April " },
  { id: 5, name: " May" },
  { id: 6, name: " June" },
  { id: 7, name: " July" },
  { id: 8, name: " August" },
  { id: 9, name: " September" },
  { id: 10, name: " Octomber" },
  { id: 11, name: " November" },
  { id: 12, name: " December" },
];

export const exisistingFaciltyValues = [
  { id: 1, name: "To be Continued" },
  { id: 2, name: "To be Settled" },
];

export const ifContinuedValues = [
  { id: 1, name: "Project Related" },
  { id: 2, name: "Non-Related" },
];

export const athamaruBusinessExpensesTypeValues: ParenDropdown[] = [
  {
    id: "administrativeExpenses", name: "Administrative Expenses", subValues: [
      { id: 1, name: "Salary" },
      { id: 2, name: "Stationery" },
      { id: 3, name: "Utilities" },
    ]
  },
  {
    id: "sellingDistributionExpenses", name: "Selling and Distribution Expenses", subValues: [
      { id: 1, name: "Fuel" },
      { id: 2, name: "Allowance" },
      { id: 3, name: "Vehicle Repairs" },
      { id: 4, name: "Vehicle Repairs" },
      { id: 5, name: "Promotional Expenses" },
    ]
  },
  {
    id: "financialExpenses", name: "Financial Expenses", subValues: [
      { id: 1, name: "Loan Interest" },
      { id: 2, name: "Depreciation" },
      { id: 3, name: "Bank Fees" },
    ]
  },
]

export const womenEntrepreneursData: Record<number, SimpleDropDownNumeric> = {
  1: {
    id: 1,
    name: "Grater than 50% Owned by the Female"
  },

  2: {
    id: 2,
    name: "Greater than 20% owned by females, with at least one female as a major decision-maker."
  },

  3: {
    id: 3,
    name: "Where a board exists, greater than 30% of the board of directors comprises women."
  }
}