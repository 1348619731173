import { Downgraded } from "@hookstate/core";
import { appSetting, branchDetails, creditData, productStageDocumentPermissionsState, userDetails } from "../../configs/mainStore";
import { languageListStore } from "../../configs/stores/languageStore";
import { userListStore } from "../../configs/stores/userStore";
import { getApplicationDocumentPathInfo } from "../../services/documentApis";
import AppConfig from "../AppConfig";
class PreInitializedStateData {

  private appSettings;
  private languageListStores;

  constructor() {
    this.appSettings = appSetting.attach(Downgraded).get();
    this.languageListStores = languageListStore.attach(Downgraded).get();
  }

  get languages() {
    return this.languageListStores;
  }

  get appSetting() {
    return this.appSettings;
  }
}

export const getUser = (id) => {
  const { userDetailsList }: any = userListStore?.get();
  const user = userDetailsList?.[id];
  return user;
};


export const currentCatalog = () => {
  const { type, sector, scheme }: any = creditData?.get()?.formData?.["creditData"] || {};
  const catalog = `${type || "*"}-${sector || "*"}-${scheme || "*"}`;
  return catalog;
}

export const userName = (id) => {
  let userName = getUser(id)?.name ?? "";

  if (!userName) {
    return "N/A";
  }

  userName = userName.replace(/null/g, "").replace(/undefined/g, "");

  if (!userName) {
    return String(id);
  }

  return userName;
};
export const employeeId = (id) => {
  let employeeId = getUser(id)?.employeeId ?? "";

  if (!employeeId) {
    return "N/A";
  }

  employeeId = employeeId.replace(/null/g, "").replace(/undefined/g, "");

  if (!employeeId) {
    return String(id);
  }

  return employeeId;
};
export const userDesignation = (id) => {
  const user = getUser(id);

  return user?.designation;
};

export const getUserDesignationFromJSON = (data) => {
  if (!data) return "N/A";
  const { officeLocation = "", designation = "", userType = "" } = data || {};
  const conf = AppConfig.config.userRoleMapping;
  const userRoleType = { "Internal": 1, "Government Audit": 2, "External": 3 };

  const type = typeof userType === "string" ? userRoleType[userType] : userType;
  let designationName = "N/A";
  const { children } = conf?.[type];

  if (type === 1) {
    designationName = children?.[officeLocation || 1]?.children?.[designation]?.name;
  } else if (type === 2) {
    designationName = children?.[officeLocation || 1]?.children?.[designation]?.name;
  } else if (type === 3) {
    designationName = children?.[designation]?.name;
  }

  return designationName;
}


export const getUserOfficeLocation = (data) => {
  if (!data) return "N/A";
  const { officeLocation = "", userType = "" } = data || {};
  const conf = AppConfig.config.userRoleMapping;
  const userRoleType = { "Internal": 1, "Government Audit": 2, "External": 3 };
  const type = userRoleType[userType];
  let officeName = "N/A";
  const { children } = conf?.[type];

  if (type === 1) {
    officeName = children?.[officeLocation || 1]?.name;
  } else if (type === 2) {
    officeName = children?.[officeLocation || 1]?.name;
  }

  return officeName;
}

export const getUserDesignation = (userId: string | null): string => {
  if (!userId) return "";

  const { USER_TYPE: userType = "", OFFICE_LOCATION: officeLocation = "", DESIGNATION: designation = "", userType: altUserType, officeLocation: altOfficeLocation, designation: altDesignation } = getUser(userId) || {};

  const finalUserType = altUserType || userType;
  const finalOfficeLocation = altOfficeLocation || officeLocation;
  const finalDesignation = altDesignation || designation;

  const userRoleMappings = AppConfig.config.userRoleMapping ?? {};

  if (finalUserType === 3) {
    return userRoleMappings[finalUserType]?.children?.[finalDesignation]?.name ?? "";
  }

  if (!finalOfficeLocation) return "";

  const userRoles = userRoleMappings[finalUserType]?.children?.[finalOfficeLocation];

  return userRoles?.children?.[finalDesignation]?.name || "-";
};

export const extractApplicantDetails = (dataMap, type, personalData) => {
  if (personalData instanceof Array) {
    personalData.forEach(item => {
      const { newNic, oldNic, initials, primaryLastName, individualId } = item?.personalData || {};
      dataMap[type] = dataMap[type] || [];
      dataMap[type].push({ newNic, oldNic, name: [initials, primaryLastName].join(" "), individualId });

      dataMap.nicMap = dataMap.nicMap || {};
      if (newNic) dataMap.nicMap[newNic] = type;
      if (oldNic) dataMap.nicMap[oldNic] = type;
    });
  } else if (personalData) {
    const { newNic, oldNic, initials, primaryLastName, individualId } = personalData;
    dataMap[type] = { newNic, oldNic, name: [initials, primaryLastName].join(" "), individualId };

    dataMap.nicMap = dataMap.nicMap || {};
    if (newNic) dataMap.nicMap[newNic] = type;
    if (oldNic) dataMap.nicMap[oldNic] = type;
  }
}

const appendNIC = (list, value, type) => {
  const { name, newNic, oldNic } = value as any;
  if (newNic) {
    list.push({ id: newNic, name: `${type} - ${name} (${newNic})` });
  }
  if (oldNic) {
    list.push({ id: oldNic, name: `${type} - ${name} (${oldNic})` });
  }
}

export const loadCustomerNICs = () => {
  const data = creditData.get() || {};
  const { dataMap } = loadCustomerDataMap(data);
  return dataMap || {};
}

export const loadCustomerDataMap = (data) => {
  const formData = data?.formData || { securityData: {} };
  if (!formData.securityData) {
    formData.securityData = { guarantors: [] };
  }
  const { personalData, securityData: { guarantors }, jointBorrowerData } = formData;
  const applicantDataMap: any = { primary: {}, gr: [], jb: [] };

  extractApplicantDetails(applicantDataMap, "primary", personalData);
  if (guarantors) {
    extractApplicantDetails(applicantDataMap, "gr", guarantors);
  }
  if (jointBorrowerData) {
    extractApplicantDetails(applicantDataMap, "jb", jointBorrowerData);
  }

  const { nicMap, ...dataMap } = applicantDataMap;
  return { nicMap, dataMap };
}

export const loadCustomerData = (data) => {
  const { dataMap } = loadCustomerDataMap(data);
  const list: any = [];
  for (const [key, value] of Object.entries(dataMap)) {
    if (key === "gr") {
      continue;
    }

    if (value instanceof Array) {
      value.forEach(item => appendNIC(list, item, "JB"));
    } else {
      appendNIC(list, value, "PA");
    }
  }

  return list;
};

export const userSession = () => {
  const { userDetails: data } = userDetails.get() || {};
  return data;
}

export const branchName = (branchId, branchCode = null) => {
  const map = branchDetails.branchMap.get() || {};
  return map[branchId].name;
}

export const branchWiseSchemes = (productSelected, schemeList) => {
  let typesOfSchemes = schemeList;
  const branchExcludeMap = AppConfig.config.branchBasedCatalogs;
  if (branchExcludeMap && productSelected) {
    const params = productSelected.get ? productSelected?.get() : productSelected;
    if (params.productType && params.sectorType) {
      const excludeCatalog = [params.productType?.id || "", params.sectorType?.id || "", params.schemeType?.id || ""].join("-");
      const matches = Object.keys(branchExcludeMap).some((key) => key.startsWith(excludeCatalog));
      const { branch }: any = userSession();
      if (matches) {
        const [type, sector] = excludeCatalog.split("-");
        const filteredSchemes = schemeList.filter((item) => {
          const list = branchExcludeMap?.[`${type}-${sector}-${item?.id}`];
          if (!list || list.length === 0) {
            return true;
          }
          return list.includes(branch);
        });
        typesOfSchemes = filteredSchemes;
      }
    }
  }
  return typesOfSchemes;
}

export const currentUserAccess = () => {
  let access = false;
  const { currentAssignee } = creditData.get() || {};
  const { userId } = userDetails.get() || {};

  if (Number(userId) === currentAssignee) {
    access = true;
  }

  return access;
}

export const hasAccess = ({ tab = null, prvKey = null } = {}) => {
  let access = 0;
  if (tab) {
    access = hasTabAccess({ tab });
  } else if (prvKey) {
    access = hasPrivilegeAccess({ prvKey });
  }
  return access;
}

export const hasStageAccess = ({ item = "" } = {}) => {
  let access = 0;
  const permissionData = productStageDocumentPermissionsState.get() || {};
  const { currentWFStage: currentStage }: any = creditData.get() || {};
  const itemName = item.toLowerCase().trim();

  const permission = permissionData?.[itemName]?.[currentStage];
  if (permission) {
    access = 1;
  }

  return access;
}

export const hasPrivilegeAccess = ({ prvKey = "" } = {}) => {
  let access = 0;
  const { currentAssignee, tabs, currentAssignees = null, currentWFStage: currentStage, }: any = creditData.get() || {};
  const { userId, userPrivilege = {} } = userDetails.get() || {};
  const { isBlocked = false }: any = tabs || {};

  if (currentAssignees && currentAssignees.includes(Number(userId))) {
    access = 1;
  } else if (Number(userId) === currentAssignee) {
    access = 1;
  } else if (prvKey) {
    if (userPrivilege![prvKey] === 1) {
      access = 1;
    };
  }

  if (isBlocked) {
    access = 0;
  } else if (currentStage === AppConfig?.config?.closeStage) {
    access = 0;
  }

  return access;
}

export const hasPrivilege = ({ key = "" } = {}) => {
  let access = 0;
  const { currentAssignee, tabs, currentAssignees = null, currentWFStage: currentStage, }: any = creditData.get() || {};
  const { userId, userPrivilege = {} } = userDetails.get() || {};
  const { isBlocked = false }: any = tabs || {};

  if (key) {
    if (userPrivilege![key] === 1) {
      access = 1;
    };
  } else if (currentAssignees && currentAssignees.includes(Number(userId))) {
    access = 1;
  } else if (Number(userId) === currentAssignee) {
    access = 1;
  }

  if (isBlocked) {
    access = 0;
  } else if (currentStage === AppConfig?.config?.closeStage) {
    access = 0;
  }

  return access;
}

export const hasTabAccess = ({ tab = "" } = {}) => {
  let access = 1;
  const { tabs }: any = creditData.get() || {};
  const { editingTabs: inactiveTabs = [] }: any = tabs || {};

  if (tab && inactiveTabs.includes(tab)) {
    access = 0;
  }

  return access;
}

const isSysAdmin = () => {
  const { userInGroups = [] } = userDetails.get() || {};
  return (userInGroups || []).filter((grp: any) => grp?.groupName === "System Admin")?.length > 0; //hard coded to avoid env id difference
}

export class Access {

  static get systemAdmin() {
    let isAdmin = false;
    if (isSysAdmin()) {
      isAdmin = true;
    }
    return isAdmin;
  }

  static tab(tabName = "") {
    return hasTabAccess({ tab: tabName });
  }

  static privilege(key = "") {
    return hasPrivilege({ key }) === 1;
  }

  static get currentAssignee() {
    return currentUserAccess();
  }

  static get currentAssigneeWithDisbursed() {
    const { disbursed }: any = creditData.get() || {};
    return currentUserAccess() && disbursed;
  }
}

export const jointApplication = () => {
  const { jointApplication = false }: any = creditData.get();
  return jointApplication;
}

export const extractClientDetails = (clientDetails, type = "") => {
  const { jointApplication = false }: any = creditData.get();

  let cifList: any = [];
  const cifIds: any = [];
  const cifIdsMap: any = [];

  if (clientDetails && Object.keys(clientDetails).length > 0) {
    const items = Object.entries(clientDetails).map(([nic, data]) => {
      const { IndividualProfiles = [], JoinedProfiles = [] } = (data as any);
      const profiles = [...IndividualProfiles, ...JoinedProfiles];
      return profiles;
    }).filter(item => item);

    for (const item of items) {
      let profiles = item;
      if (!(item instanceof Array)) {
        profiles = [item];
      }

      for (const profile of profiles) {
        const { CIF, initialInFull = "", customerType } = profile;
        if (cifIds.includes(CIF)) continue;

        if (type === "primary") {
          if (jointApplication && customerType === "J") {
            cifList.push({ id: CIF, name: `(${CIF}) ${initialInFull.trim()}` });
          } else if (!jointApplication && customerType === "I") {
            cifList.push({ id: CIF, name: `(${CIF}) ${initialInFull.trim()}` });
          } else {
            continue;
          }
        } else if (type === "gr") {
          if (customerType === "I") {
            cifList.push({ id: CIF, name: `(${CIF}) ${initialInFull.trim()}` });
          } else {
            continue;
          }
        } else {
          cifList.push({ id: CIF, name: `(${CIF}) ${initialInFull.trim()}` });
        }
        cifIds.push(CIF);
        cifIdsMap[CIF] = profile;
      }
    }
  }

  return { cifList, cifIds, cifIdsMap };
}

export const languages = () => {
  // CANNOT BE USED useHookstate() HOOK IN THIS FUNCTION. THIS FUNCTION IS NOT A REACT COMPONENT
  // const appSettings: any = useHookstate(appSetting);
  // const { systemLanguageList } = useHookstate(languageListStore);

  const { appSetting, languages } = new PreInitializedStateData();
  const { systemLanguageList } = languages;
  const { language } = appSetting;

  const languagesObj = language && systemLanguageList?.[language];
  const languagesArray = Object.keys(languagesObj)?.length > 0 ? Object.keys(languagesObj) : [];

  return languagesArray.map((id) => ({
    id: id,
    name: languagesObj[id]?.DESCRIPTION,
  }));
};

const exentionMapper = {
  ".html": "text/html",
  ".js": "text/javascript",
  ".css": "text/css",
  ".json": "application/json",
  ".png": "image/png",
  ".jpg": "image/jpg",
  ".gif": "image/gif",
  ".svg": "image/svg+xml",
  ".wav": "audio/wav",
  ".mp4": "video/mp4",
  ".woff": "application/font-woff",
  ".ttf": "application/font-sfnt",
  ".pdf": "application/pdf",
};

export const fetchDocumentPath = async (documentKey = "", applicationId = "") => {
  applicationId = applicationId || creditData.get().applicationId?.[0];
  const result = await getApplicationDocumentPathInfo(applicationId, documentKey, "");
  if (!result?.length) return null;
  const filePaths = result?.map(record => readStaticDocumentPath(record.filePath || ""));
  return filePaths;
}

export const fetchDocumentsWithPath = async (documentKey = "", applicationId = "") => {
  applicationId = applicationId || creditData.get().applicationId?.[0];
  const result = await getApplicationDocumentPathInfo(applicationId, documentKey, "");
  if (!result?.length) return null;
  const files = result?.map(record => {
    record.filePath = readStaticDocumentPath(record.filePath || "");
    return record;
  });
  return files;
}

export const readStaticDocumentPath = (path: any = null) => {
  const filePath = `${AppConfig.config.fileServer}`;
  path = path.replaceAll(process.env.REACT_APP_DOCUMENT_PATH, "");
  return `${filePath}${path}`;
}

export const readDocumentData = async (doc, path: any = null, progress: any = null) => {
  try {
    const filePath = `${AppConfig.config.fileServer}`;
    let contentType;

    const segments = [filePath];
    if (!segments.length) {
      throw new Error(`Document view url mapping error.`);
    }

    if (!path) {
      const applicationId = doc?.applicationId;
      const applicantId = doc?.participantId;
      const { fileName } = doc;
      contentType = exentionMapper?.[`.${fileName?.split(".")?.[1]}`];

      if (!applicationId) {
        throw new Error(`Applicant data access error.`);
      }
      segments.push(applicationId + "/");

      if (!applicantId) {
        throw new Error(`Applicant data process error.`);
      }
      segments.push("1_" + applicantId + "/");

      if (!fileName) {
        throw new Error(`File data process error.`);
      }
      segments.push(fileName);
    } else {
      contentType = path?.split(".")?.pop();
      segments.push(path);
    }

    // http://localhost:4003/4630/crib/17071591873293612.pdf
    let url = segments.join("");

    let response: any;

    // if (progress) {
    //   response = await getDocumentWithProgress(url, progress);
    // } else {
    // }
    response = await fetch(url);

    if (!response.ok) {
      throw new Error(`No file found.`);
    }

    const fileBuffer = await response.blob();
    const blobUrl = URL.createObjectURL(fileBuffer);

    const blobTimeOut = setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
      clearTimeout(blobTimeOut);
    }, 300000);

    return { contentType, localURL: blobUrl };
  } catch (e: any) {
    return null;
  }
}