import { Grid } from "@mui/material";
import { SubSectionHeaderBuilder } from "../../../../../FormBuilder/Tools/Widgets/SubSectionHeaderBuilder";
import Table from "./Table";

const RawMaterialRequirement = ({ data, otherParams, id }) => {
    otherParams = { ...otherParams, description: "Raw Material" }
    return <Grid container rowGap={2}>
        <Grid item xs={12}>
            <SubSectionHeaderBuilder metaData={{ title: "Raw Material Requirement" }} />
        </Grid>
        <Grid item xs={12}>
            <Table data={data} otherParams={otherParams} />
        </Grid>
    </Grid>
}

export default RawMaterialRequirement