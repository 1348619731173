import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    Stack,
    Typography
} from "@mui/material";
import React, { createRef, useEffect, useImperativeHandle } from "react";



import { useState } from "@hookstate/core";
import ErrorIcon from "@mui/icons-material/Error";
import { MASTER_DATA_KEYS } from "../../../../../../../configs/constants/masterDataKeys";
import { masterDataDetails } from "../../../../../../../configs/mainStore";
import { getBusinessAuthCertificateList } from "../../../../../../../services/documentApis";
import { specialCharacterstoUnderscore } from "../../../../../../../utility/util";
import { ErrorMessageComponent } from "../../../../../ErrorMessageComponent";
import { addToaster } from "../../../../../GlobalToast";
import { NavigationButtons } from "../../../../../NavigationButtons/NavigationButtons";
import { CircularProgressComponent } from "../../../../../ProgressComponent/ProgressComponent";
import { ImageCuploadComponent } from "./ImageUploadComponent";
interface DocumentPreviewPropTypes {
    applicationId?: any;
    innerComponentData?: any;
    tabsToEdit?: any;
    data?: any;
    currentApplicationWfData?: any
}

const BusinessObtainedAuthCertificateDocs = ({ applicationId, innerComponentData, tabsToEdit, data, currentApplicationWfData }: DocumentPreviewPropTypes) => {

    const { businessObtainedCertificates: { list: certificateList } } = currentApplicationWfData;

    const [isLoading, setIsLoading] = React.useState(false);
    const [riskList, setRiskList] = React.useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");

    const [documentDetails, setDocumentDetails] = React.useState<any>([]);

    const masterData: any = useState(masterDataDetails);

    let numOfUploadedMandatoryFiles: any = 5;
    let numOfMandatory: any = 4;
    let statusColor: any = "rgb(213, 200, 28)"; // Should be dynamic

    const colorStatus: any = [
        {
            borderRadius: "5px",
            backgroundColor: "rgba(213, 200, 28, 0.24)",
            border: "1px solid rgb(213, 200, 28)",
        },
        {
            borderRadius: "5px",
            backgroundColor: "rgba(0, 255, 76, 0.24)",
            border: "1px solid rgb(0, 255, 76)",
        },
        {
            borderRadius: "5px",
            backgroundColor: "rgba(255, 144, 144, 0.24)",
            border: "1px solid rgb(255, 144, 144)",
        },
    ];

    useEffect(() => {
        fetchData();
    }, [certificateList]);

    const fetchData = async () => {
        setIsLoading(true);
        if (applicationId) {
            const data = await getBusinessAuthCertificateList(applicationId);

            const mergedList = mergeCertificatesWithData(certificateList, data);

            setDocumentDetails(mergedList || certificateList);

        }
        setIsLoading(false);
    }

    const mergeCertificatesWithData = (certificateList, data) => {
        return certificateList.map(section => ({
            ...section,
            certificationList: section.certificationList.map(cert => {
                const certificationMasterData = masterData[MASTER_DATA_KEYS.AUTHORITY_OR_CERTIFICATION]?.get()?.find((t) => t.id === cert?.certification);

                let certificationName = "";

                if (cert?.certification === 5) {
                    certificationName = specialCharacterstoUnderscore(cert?.otherCertification ?? "");
                } else {
                    certificationName = specialCharacterstoUnderscore(certificationMasterData?.name);
                }

                const matchingData = data.find(d => d.businessAuthName === certificationName && section.slug === d.businessSection);

                return {
                    ...cert,
                    file: matchingData || {}
                };
            })
        }));
    };

    const docUploadRef: any = createRef();

    useImperativeHandle(docUploadRef, () => ({
        async normalDocumentUploadFunc() {
            const promises: any = [];

            documentDetails?.map((obj) => {
                obj.certificationList.map((item) => {
                    promises.push(item.ref.current?.uploadSingleImg())
                })
            })

            return await Promise.all(promises).then(() => {
                return true;
            });
        },
    }));

    const handleSubmit = async () => {
        const normalDocUploadResult = await docUploadRef?.current?.normalDocumentUploadFunc();

        if (normalDocUploadResult === true) {
            addToaster({
                status: "success",
                title: "Success",
                message: "Business Authentication Documet updated succesfully!",
            });

            return
        }

        addToaster({
            status: "error",
            title: "Error",
            message: "Something went wrong!",
        });
    }

    const handleReloadComponent = async (data) => {
        fetchData();
    }

    return (
        <Grid container className="full-width" alignItems="center">
            {isLoading ? (
                <CircularProgressComponent
                    size={30}
                    sx={{
                        marginTop: "30px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        zIndex: 100,
                    }}
                />
            ) : errorMessage ? (
                <ErrorMessageComponent headMessage={"Empty"} errorMessage={errorMessage} />
            ) : (
                <Grid container p={1} className="data-box full-width" m={2} width={"100%"}>
                    {
                        documentDetails.map((item, index) => {
                            return <Accordion style={{ width: "100%" }}>
                                <AccordionSummary
                                    expandIcon={<KeyboardDoubleArrowUpIcon className="basic-font-color" fontSize="medium" />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Grid container columns={12}>
                                        <Grid item xs={0.5}>
                                            {numOfUploadedMandatoryFiles === numOfMandatory ? (
                                                <CheckCircleIcon sx={{ color: "#00AB71" }} />
                                            ) : (
                                                <ErrorIcon sx={{ color: "#ffdd00" }} />
                                            )}
                                        </Grid>
                                        <Grid item xs={9.5}>
                                            <Typography>{item?.title}</Typography>
                                        </Grid>
                                        <Grid item xs={2} pl={0.75}>
                                            <Box className={`document-upload-wrapper-text`} sx={{ textAlign: "left" }}>
                                                <small className="basic-font opacity-7 font-size-14">Total: {riskList.length}</small>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack>
                                        <Grid container gap={2}>
                                            {item?.certificationList?.map((obj: any, i: any) => {
                                                let title = obj?.name;
                                                return (
                                                    <Grid item xs={3}>
                                                        <ImageCuploadComponent
                                                            key={i}
                                                            fileDetails={item}
                                                            uploadDataObject={{
                                                                applicationId: applicationId,
                                                                isPrimary: false,
                                                                ...obj
                                                            }}
                                                            isPreviewHave={Object.keys(obj.file || {}).length > 0}
                                                            status={0}
                                                            permissionToUploadDocs={false}
                                                            documentComponentId={""}
                                                            reloadComponent={handleReloadComponent}
                                                        />
                                                    </Grid>
                                                );
                                            })}

                                        </Grid>
                                        <Stack mt={2} direction="row-reverse" sx={{ justifyContent: 'space-between' }}>
                                            <Box sx={{ justifyContent: 'flex-end' }}>
                                                <NavigationButtons
                                                    onNext={handleSubmit}
                                                    noBack
                                                    loading={false}
                                                    disabled={false}
                                                />
                                            </Box>

                                        </Stack>
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        })
                    }

                </Grid>
            )}
        </Grid>
    );
};

export default BusinessObtainedAuthCertificateDocs;
