import { Grid, Typography } from "@mui/material";
import { formatCurrency } from "../../../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../../../services/onDemandValidators";
import { TextBoxComponent } from "../../../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { FieldSectionWrapper } from "../../../../../../../../FieldSectionsWrapper.tsx/FieldSectionWrapper";
import { SubSectionsWrapper } from "../../../../../../../../SubSectionsWrapper/SubSectionsWrapper";

const OtherCurrentAssets = ({ otherParams, data }) => {
    const { isAmountAboveTwoPointFiveAndEqual } = otherParams;
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <SubSectionsWrapper>
                    <FieldSectionWrapper>
                        <Grid container gap={1}>
                            <Grid item xs={12} md={12}>
                                <Grid container gap={1}>
                                    <Grid item xs={8} md={8}>
                                        <Typography>{"Description"}</Typography>
                                    </Grid>
                                    <Grid item xs={2} md={2}>
                                        <Typography>{"Amount"}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {data
                                ?.filter((itemObj) => !itemObj?.removedItem?.get())
                                .map((items, index) => (
                                    <Grid item xs={12} md={12} key={`working-capital-creditors-${items?.dataId.get() || index}`}>
                                        <Grid container gap={1}>
                                            <Grid item xs={8}>
                                                <TextBoxComponent
                                                    label={"Description"}
                                                    required={Boolean(isAmountAboveTwoPointFiveAndEqual)}
                                                    value={items?.description?.get()}
                                                    fullWidth={true}
                                                    onChange={(e) => items?.description?.set(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextBoxComponent
                                                    label={""}
                                                    style={{ backgroundColor: "#D3D3D3", fontWeight: "bold" }}
                                                    required={Boolean(isAmountAboveTwoPointFiveAndEqual)}
                                                    value={formatCurrency(items?.amount?.get()) || 0}
                                                    onInput={OnDemandValueFormatter.numeric}
                                                    fullWidth={true}
                                                    disabled={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                        </Grid>
                    </FieldSectionWrapper>
                </SubSectionsWrapper>
            </Grid>
        </Grid>
    )
}

export default OtherCurrentAssets