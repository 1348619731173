import { Downgraded, useHookstate } from "@hookstate/core";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import { Divider, Grid, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { loanRequirementContributions } from "../../../../../../../../configs/constants/contants";
import { userDetails } from "../../../../../../../../configs/mainStore";
import { deleteLoanRequirementComment, saveLoanRequirementCommentData, saveLoanRequirementPPCData, saveMicroLoanRequirements } from "../../../../../../../../services/creditFileApiCall";
import { select_basic } from "../../../../../../../../styles";
import { dateFormatWithTimeChange } from "../../../../../../../../utility/fomatters";
import { userName } from "../../../../../../../../utility/helpers/creditFileUtility";
import { getLowerCaseString } from "../../../../../../../../utility/util";
import { ButtonComponent } from "../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal, openGlobalModal } from "../../../../../../GlobalModal/GlobalModal";
import { addToaster, toast } from "../../../../../../GlobalToast";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";
import { getInspectionBusinesData } from "../../../Micro/Api/AppraisalApi";
import AnalysisLoanRequirement from "../../../Micro/Components/AnalysisOfLoanRequirement/AnalysisLoanRequirement";
import { RetuenedArray, setStateFromBusinessDataAnalysisOfLoanRequirement } from "../../../Micro/Components/AnalysisOfLoanRequirement/Helpers/SetStateFromBusinessData/SetStateFromBusinessData";
import TotalProjectContribution from "../../../Micro/Components/AnalysisOfLoanRequirement/TotalProjectContribution";
import WorkingCapitalRequirement from "../../../Micro/Components/AnalysisOfLoanRequirement/WorkingCapitalRequirement";

const CommercialContent = ({ data, loading, loanRequirement, disableStatus, proposedProjectComment, applicationId, filteredComentList, setFilteredComentList }) => {
    const [selectedRequirement, setSelectedRequirement] = React.useState(1);
    const [proposedProjectedComment, setProposedProjectComment] = React.useState(proposedProjectComment || "");
    const analysisOfLoanRequirementDetails = useHookstate(loanRequirement);
    const isLoading = useHookstate<boolean>(true);
    const { userId }: any = userDetails.attach(Downgraded).get();
    const [successFullResponse, setSuccessFullResponse] = React.useState(false);

    const compareByCommentId = (a, b) => {
        return b.id - a.id;
    };

    const deleteCommentDispatcher = (id) => {
        if (id != null) {
            openGlobalModal({
                modalSize: "sm",
                title: "Are you sure ?",
                bodyId: "micro-appraisal-delete-confirm",
                close: false,
                modalParams: {
                    textMessage: "Comment will be deleted.",
                    callBackOnAction: () => commentConfirmDelete(id),
                },
            });
        } else {
            addToaster({
                status: "error",
                title: "Comment cannot be empty.",
                message: "Comment id is required to proceed the deletion",
            });
        }
    };

    const commentConfirmDelete = async (id) => {
        let obj = {
            id: id,
            applicationId: applicationId,
        };

        const alert = {
            status: "success",
            message: "Comment deleted successfully!",
        };

        try {
            closeGlobalModal();

            loading.delete.set(id);
            let { status } = await deleteLoanRequirementComment(obj);

            if (status === 1) {
                setFilteredComentList(filteredComentList.filter((item) => item.id !== id));
            }
        } catch (error) {
            alert.status = "error";
            alert.message = "Failed to delete comment.";
        } finally {
            toast(alert);
            loading.delete.set(-1);
        }
    };

    const addNewRequirementHandler = (selectedRequirement, analysisOfLoanRequirementDetails) => {
        if (!selectedRequirement) {
            addToaster({
                status: "error",
                title: "Empty",
                message: "Please select a contribution",
            });
        } else {
            let existingRowItems: any = analysisOfLoanRequirementDetails.attach(Downgraded).get();
            const dataKeys = Object.keys(existingRowItems);
            const selectedContribution: any = loanRequirementContributions.find((obj) => obj.id == selectedRequirement) || {};
            let lastObjectIndex: any = dataKeys?.length > 0 ? dataKeys[dataKeys.length - 1] : 0;

            let defaultItemObj = {
                createdBy: userId,
                data: {
                    description: selectedContribution?.name,
                    existingEquality: 0,
                    existingBank: 0,
                    proposedEquality: 0,
                    proposedBank: 0,
                    totalCost: 0,
                    existingEqualityContribution: 0,
                    existingBankContribution: 0,
                    proposedEqualityContribution: 0,
                    proposedBankContribution: 0,
                },
            };

            if (dataKeys?.length < 1) {
                existingRowItems[lastObjectIndex] = defaultItemObj;
            } else {
                existingRowItems[parseInt(lastObjectIndex) + 1] = defaultItemObj;
            }

            analysisOfLoanRequirementDetails.set(existingRowItems);
        }
    };

    const getFormReady = async () => {
        let rawData: Record<number, RetuenedArray> = {};

        try {
            if (Object.values(loanRequirement).length) {
                rawData = loanRequirement;
                setSuccessFullResponse(true);
            } else {
                let response = await getInspectionBusinesData(applicationId);
                rawData = setStateFromBusinessDataAnalysisOfLoanRequirement({ busineeData: response });
            }

        } catch (error) {
        } finally {
            analysisOfLoanRequirementDetails.set(rawData);
            isLoading.set(false)
        }
    }

    useEffect(() => {
        getFormReady();
    }, [loanRequirement])

    const onDeleteRequirementItemRow = useCallback(
        (key: any) => {
            closeGlobalModal();
            let existingRowItems: any = analysisOfLoanRequirementDetails.attach(Downgraded).get();
            const dataKeys = Object.keys(existingRowItems);
            const newRowItems: any = {};

            dataKeys.forEach((element) => {
                if (element != key) {
                    newRowItems[element] = existingRowItems[element];
                }
            });
            analysisOfLoanRequirementDetails.set(newRowItems);
            saveMicroLoanRequirementData("delete");
        },
        [analysisOfLoanRequirementDetails]
    );

    const saveMicroLoanRequirementData = async (actionType = "") => {
        const alert = {
            status: "error",
            message: `Something wrong with data saving!`,
        };

        try {
            let existingRowItems: any = analysisOfLoanRequirementDetails.attach(Downgraded).get();
            const dataKeys: any = Object.keys(existingRowItems);

            let obj = {
                applicationId: applicationId,
                data: {},
                total: 0,
                totalExistingEqualityContribution: 0,
                totalExistingBankContribution: 0,
                totalProposedEqualityContribution: 0,
                totalProposedBankContribution: 0,
            };

            if (actionType == "submit") {
                if (dataKeys.length < 1) {
                    throw new Error(`Please add requirement items before saving!`);
                } else {
                    let calculatedData = calculateCostData(dataKeys, existingRowItems) || {};

                    if (Object.keys(calculatedData).length > 0) {
                        obj.total = calculatedData?.total;
                        obj.totalExistingEqualityContribution = calculatedData?.totalExistingEqualityContribution;
                        obj.totalExistingBankContribution = calculatedData?.totalExistingBankContribution;
                        obj.totalProposedEqualityContribution = calculatedData?.totalProposedEqualityContribution;
                        obj.totalProposedBankContribution = calculatedData?.totalProposedBankContribution;
                        obj.data = calculatedData?.data;
                    }
                }
            } else {
                let calculatedData = calculateCostData(dataKeys, existingRowItems) || {};

                if (Object.keys(calculatedData).length > 0) {
                    obj.total = calculatedData?.total;
                    obj.totalExistingEqualityContribution = calculatedData?.totalExistingEqualityContribution;
                    obj.totalExistingBankContribution = calculatedData?.totalExistingBankContribution;
                    obj.totalProposedEqualityContribution = calculatedData?.totalProposedEqualityContribution;
                    obj.totalProposedBankContribution = calculatedData?.totalProposedBankContribution;
                    obj.data = calculatedData?.data;
                }
            }

            const { status, data }: any = await saveMicroLoanRequirements(obj);

            if (status == 1) {
                alert.status = "success";
                alert.message = "Loan requirements saved successfully!";
                analysisOfLoanRequirementDetails?.set(data?.data ?? {});
            }
        } catch (error: any) {
            alert.message = error.message;
        } finally {
            toast(alert);
        }
    };

    const calculateCostData = (dataKeys, existingRowItems) => {
        let obj: any = {
            total: 0,
            totalExistingEqualityContribution: 0,
            totalExistingBankContribution: 0,
            totalProposedEqualityContribution: 0,
            totalProposedBankContribution: 0,
            data: {},
        };

        let total: any = 0;
        let total_ExistingEquality: any = 0;
        let total_ExistingBank: any = 0;
        let total_ProposedEquality: any = 0;
        let total_ProposedBank: any = 0;

        dataKeys.map((key) => {
            total += parseFloat(existingRowItems[key].data?.totalCost);
            total_ExistingEquality += parseFloat(existingRowItems[key].data?.existingEquality);
            total_ExistingBank += parseFloat(existingRowItems[key].data?.existingBank);
            total_ProposedEquality += parseFloat(existingRowItems[key].data?.proposedEquality);
            total_ProposedBank += parseFloat(existingRowItems[key].data?.proposedBank);
            obj["data"][key] = {
                id: key,
                data: existingRowItems[key].data,
            };
        });

        obj.total = total;
        obj.totalExistingEqualityContribution = parseFloat(((total_ExistingEquality / total) * 100).toFixed(2));
        obj.totalExistingBankContribution = parseFloat(((total_ExistingBank / total) * 100).toFixed(2));
        obj.totalProposedEqualityContribution = parseFloat(((total_ProposedEquality / total) * 100).toFixed(2));
        obj.totalProposedBankContribution = parseFloat(((total_ProposedBank / total) * 100).toFixed(2));

        return obj;
    };
    const sanitizedLoanRequirementContributions = useMemo(() => {
        let formData: any = analysisOfLoanRequirementDetails.attach(Downgraded).get();

        let alreadySelectedItems = Object.values(formData).map(({ data }: any) => {

            return getLowerCaseString(data.description)
        });

        return loanRequirementContributions.reduce((sanitizedArr: { id: number; name: string }[], { id, name }) => {
            const overrideName: string = getLowerCaseString(name);
            if (alreadySelectedItems.includes(overrideName)) {
                return sanitizedArr
            }

            return [...sanitizedArr, { id, name }]
        }, [])
    }, [selectedRequirement, analysisOfLoanRequirementDetails.get()]);

    const handleSaveProposeProjectComment = async () => {
        const alert = {
            status: "error",
            message: `Something wrong with data saving!`,
        };

        try {
            isLoading.set(true);
            const payload = {
                applicationId: applicationId,
                comment: proposedProjectedComment
            }

            const { status, data } = await saveLoanRequirementPPCData(payload);

            if (status == 1) {
                alert.status = "success";
                alert.message = "Loan requirements Propose Project Comment saved successfully!";
                analysisOfLoanRequirementDetails?.set(data?.data ?? {});
            }
            isLoading.set(false);
        } catch (error: any) {
            alert.message = error.message;
        } finally {
            toast(alert);
        }
    }

    const addCommentSection = () => {
        openGlobalModal({
            modalSize: "md",
            title: "",
            bodyId: "requirement-comment",
            close: false,
            modalParams: {
                callBackOnSave: callBackOnAddComment,
            },
        });
    };

    const callBackOnAddComment = async (comment) => {
        const obj = {
            applicationId: applicationId,
            comment: comment ? comment : "",
            createdAt: new Date().toISOString(),
        };

        const alert = {
            status: "error",
            message: "Comment cannot be empty",
        };

        try {
            if (!comment) {
                throw new Error("Comment cannot be empty");
            }

            closeGlobalModal();
            setFilteredComentList([{ comment, id: -2, createdBy: userName(userId) }, ...filteredComentList]);
            let {
                status,
                data: { comments },
            } = await saveLoanRequirementCommentData(obj);
            if (status == 1) {
                alert.status = "success";
                alert.message = "Comment saved successfully.";

                setFilteredComentList(Object.values(comments).sort(compareByCommentId));
            }
        } catch (error: any) {
            alert.message = error.message;
            setFilteredComentList(filteredComentList.splice(0, 1));
        } finally {
            toast(alert);
        }
    };

    if (isLoading.get()) return <CircularProgressComponent size={30} />

    return (
        <>
            <Grid container pt={2} pr={1.5} pb={2} pl={1.5}>
                <Stack>
                    <Stack direction={"row"} flex={1}>
                        <InputGrid visibility={1} variant="fullWidth">
                            <SelectComponent
                                sx={select_basic}
                                label={"Add contribution"}
                                value={selectedRequirement}
                                values={sanitizedLoanRequirementContributions}
                                onChange={(e) => {
                                    setSelectedRequirement(e.target.value);
                                }}
                                defaultPlaceholder={true}
                                disabled={disableStatus}
                            />
                        </InputGrid>
                        <ButtonComponent iconbtn onClick={() => addNewRequirementHandler(selectedRequirement, analysisOfLoanRequirementDetails)} disabled={disableStatus}>
                            <AddCircleOutlineIcon />
                        </ButtonComponent>
                    </Stack>
                </Stack>
                <Grid item xs={12} pt={2}>
                    {<AnalysisLoanRequirement creditData={data} applicationId={applicationId} assignLoanRequirementList={analysisOfLoanRequirementDetails} callBackOnDelete={() => onDeleteRequirementItemRow} disableStatus={disableStatus} />}
                </Grid>
                <Stack direction={"column"} flex={1}>
                    <Stack direction={"row-reverse"} style={{ backgroundColor: "white" }} pb={1}>
                        <ButtonComponent startIcon={<DoneIcon />} title="SUBMIT" variant="contained" onClick={() => saveMicroLoanRequirementData("submit")} loadingbtn={true} color="info" disabled={disableStatus} />
                    </Stack>
                    <Divider sx={{ marginTop: 2 }}></Divider>
                    <Paper sx={{ backgroundColor: "white", position: "sticky", top: "0pt", zIndex: 100, marginTop: 2, marginBottom: 2 }}>
                        <Stack direction={"row"} justifyContent={"space-between"} p={2}>
                            <Typography color={"gray"}>Details of the exsisting project ({filteredComentList.length})</Typography>
                            <ButtonComponent variant="outlined" startIcon={<AddCircleOutlineIcon />} onClick={() => addCommentSection()} title={"Add Comment"} disabled={disableStatus} />
                        </Stack>
                        <Stack p={2}>
                            <Stack direction={"row"} justifyContent={"space-between"} >
                                <Typography color={"gray"}>
                                    Details of the Proposed Project
                                </Typography>
                                <Stack width={"50%"}>
                                    <TextBoxComponent
                                        multiline={true}
                                        minRows={5}
                                        maxRows={10}
                                        value={proposedProjectedComment}
                                        onChange={(e) => setProposedProjectComment(e.target.value)}
                                    />
                                </Stack>
                            </Stack>
                            <Stack direction={"row-reverse"} style={{ backgroundColor: "white" }} pt={1}>
                                <ButtonComponent startIcon={<DoneIcon />} title="SUBMIT" variant="contained" onClick={handleSaveProposeProjectComment} loadingbtn={true} color="info" disabled={disableStatus} />
                            </Stack>
                        </Stack>
                    </Paper>
                    {filteredComentList.map((comment, index) => (
                        <Paper sx={{ marginBottom: 2 }}>
                            <Stack direction={"row"} flex={1} m={2}>
                                <Stack direction={"column"} flex={1}>
                                    <Stack direction={"row"} spacing={1}>
                                        <Typography variant="subtitle1">{comment?.comment}</Typography>
                                    </Stack>
                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography variant="caption" color={"gray"}>
                                                {userName(comment?.createdBy)}
                                            </Typography>
                                            {comment?.createdAt && <Typography variant="caption">on</Typography>}
                                            {comment?.createdAt && (
                                                <Typography variant="caption" color={"gray"}>
                                                    {dateFormatWithTimeChange(comment?.createdAt)}
                                                </Typography>
                                            )}
                                        </Stack>
                                        {loading.delete.get() === comment?.id || comment.id === -2 ? (
                                            <CircularProgressComponent size={20} />
                                        ) : (
                                            <Tooltip title="Delete">
                                                <IconButton aria-label="delete" onClick={() => deleteCommentDispatcher(comment?.id)} disabled={disableStatus}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Paper>
                    ))}
                    <Divider sx={{ marginTop: 2 }}></Divider>
                </Stack>

                <Grid item xs={12} pt={2}>
                    <TotalProjectContribution analysisOfLoanRequirementDetails={analysisOfLoanRequirementDetails} applicationId={applicationId} isSuccessFullResponse={{ get: () => successFullResponse, set: setSuccessFullResponse }} />
                    <Divider sx={{ marginTop: 2 }}></Divider>
                </Grid>

                <Grid item xs={12} pt={2}>
                    <WorkingCapitalRequirement applicationId={applicationId} />
                </Grid>
            </Grid>
        </>
    );
};

export default CommercialContent;